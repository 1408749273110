/**
 * @license
 * 
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * @fileoverview Blockly React Component.
 * @author samelh@google.com (Sam El-Husseini)
 */

/* eslint import/no-webpack-loader-syntax: off */
import toolboxsrc from '!!raw-loader!./toolbox.xml';
import React from 'react';
import './BlocklyComponent.css';
import Blockly from 'blockly/core';
import 'blockly/javascript';
import locale from 'blockly/msg/en';
import 'blockly/blocks';
import {addBlocks} from './blocks.js';
import {addBlockCode} from './blocks-code.js';

Blockly.setLocale(locale);

class BlocklyComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            h: props.blocklyHeight,
            w: props.blocklyWidth
        };
    }

    componentDidMount() {
        const { initialXml, children, ...rest } = this.props;
        addBlocks(Blockly); // Adds all of the blocks to the palette
        addBlockCode(Blockly); // Adds the blocks' generator code      

        this.primaryWorkspace = Blockly.inject(
            this.blocklyDiv,
            {
                toolbox: toolboxsrc,
                ...rest
            },
        );

        if (this.props.initialXml) {
            Blockly.Xml.domToWorkspace(Blockly.Xml.textToDom(this.props.initialXml), this.primaryWorkspace);
        }
        if (this.props.initialXmlEl) {
            Blockly.Xml.domToWorkspace(this.props.initialXmlEl, this.primaryWorkspace);
        }
        this.primaryWorkspace.clearUndo(); // Reset the undo stack
        this.updateWindowDimensions();
        Blockly.JavaScript.init(this.primaryWorkspace);
        this.addChangeListener(this.props.blocklyChangeListener);
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (   this.props.blocklyHeight !== prevProps.blocklyHeight
            ||  this.props.blocklyWidth !== prevProps.blocklyWidth) {
                this.updateWindowDimensions()  
        }
      }
      
    updateWindowDimensions() {
        this.setState({h: this.props.blocklyHeight, w: this.props.blocklyWidth}, 
            () => {Blockly.svgResize(this.primaryWorkspace)});
    }

    get workspace() {
        return this.primaryWorkspace;
    }

    set xmlString(xml) {
        this.primaryWorkspace.clear();
        Blockly.Xml.domToWorkspace(Blockly.Xml.textToDom(xml), this.primaryWorkspace);
        this.primaryWorkspace.clearUndo();
    }

    get xmlDom() {
        return Blockly.Xml.workspaceToDom(this.primaryWorkspace)
    }

    get xmlString() {
        return Blockly.Xml.domToPrettyText(this.xmlDom)
    }

    get topBlocks() {
        return this.primaryWorkspace.getTopBlocks(true)
    }

    addChangeListener(listener) {this.primaryWorkspace.addChangeListener(listener)}

    render() {
        return <React.Fragment>
            <div ref={e => this.blocklyDiv = e} 
                id="blocklyDiv" 
                style={{height: this.state.h + "px", width: this.state.w + "px"}}/>
        </React.Fragment>;
    }
}
export default BlocklyComponent;