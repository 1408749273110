export function addBlockCode(BlocklyObj) {
    const Blockly = BlocklyObj;
    
Blockly.JavaScript['createEnvelope'] = block =>
  `new docusign.EnvelopePlusJSON()${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
Blockly.JavaScript['addressInformation'] = block => 
  `\n.add_object("addressInformation", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['addressInformation__address1'] = block =>
         `address1: ${JSON.stringify(block.getFieldValue('address1'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['addressInformation__address2'] = block =>
         `address2: ${JSON.stringify(block.getFieldValue('address2'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['addressInformation__city'] = block =>
         `city: ${JSON.stringify(block.getFieldValue('city'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['addressInformation__country'] = block =>
         `country: ${JSON.stringify(block.getFieldValue('country'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['addressInformation__fax'] = block =>
         `fax: ${JSON.stringify(block.getFieldValue('fax'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['addressInformation__phone'] = block =>
         `phone: ${JSON.stringify(block.getFieldValue('phone'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['addressInformation__postalCode'] = block =>
         `postalCode: ${JSON.stringify(block.getFieldValue('postalCode'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['addressInformation__stateOrProvince'] = block =>
         `stateOrProvince: ${JSON.stringify(block.getFieldValue('stateOrProvince'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['addressInformation__zipPlus4'] = block =>
         `zipPlus4: ${JSON.stringify(block.getFieldValue('zipPlus4'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['addressInformationInput'] = block => 
  `\n.add_object("addressInformationInput", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['addressInformationInput__displayLevelCode'] = block =>
         `displayLevelCode: ${JSON.stringify(block.getFieldValue('displayLevelCode'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['addressInformationInput__receiveInResponse'] = block =>
         `receiveInResponse: ${JSON.stringify(block.getFieldValue('receiveInResponse'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['agent'] = block => 
  `\n.add_object("agent", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['agent__accessCode'] = block =>
         `accessCode: ${JSON.stringify(block.getFieldValue('accessCode'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['agent__accessCodeMetadata'] = block => {
         let out = `accessCodeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['agent__addAccessCodeToEmail'] = block =>
         `addAccessCodeToEmail: ${JSON.stringify(block.getFieldValue('addAccessCodeToEmail'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['agent__allowSystemOverrideForLockedRecipient'] = block =>
         `allowSystemOverrideForLockedRecipient: ${JSON.stringify(block.getFieldValue('allowSystemOverrideForLockedRecipient'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['agent__autoRespondedReason'] = block =>
         `autoRespondedReason: ${JSON.stringify(block.getFieldValue('autoRespondedReason'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['agent__clientUserId'] = block =>
         `clientUserId: ${JSON.stringify(block.getFieldValue('clientUserId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['agent__completedCount'] = block =>
         `completedCount: ${JSON.stringify(block.getFieldValue('completedCount'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['agent__customFields'] = block => {
         let out = `    customFields: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'customField', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['agent_customFields__customField'] = block =>
         `    ${JSON.stringify(block.getFieldValue('customField'))}${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['agent__declinedDateTime'] = block =>
         `declinedDateTime: ${JSON.stringify(block.getFieldValue('declinedDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['agent__declinedReason'] = block =>
         `declinedReason: ${JSON.stringify(block.getFieldValue('declinedReason'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['agent__deliveredDateTime'] = block =>
         `deliveredDateTime: ${JSON.stringify(block.getFieldValue('deliveredDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['agent__deliveryMethod'] = block =>
         `deliveryMethod: ${JSON.stringify(block.getFieldValue('deliveryMethod'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['agent__deliveryMethodMetadata'] = block => {
         let out = `deliveryMethodMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['agent__designatorId'] = block =>
         `designatorId: ${JSON.stringify(block.getFieldValue('designatorId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['agent__designatorIdGuid'] = block =>
         `designatorIdGuid: ${JSON.stringify(block.getFieldValue('designatorIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['agent__email'] = block =>
         `email: ${JSON.stringify(block.getFieldValue('email'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['agent__emailMetadata'] = block => {
         let out = `emailMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['agent__emailRecipientPostSigningURL'] = block =>
         `emailRecipientPostSigningURL: ${JSON.stringify(block.getFieldValue('emailRecipientPostSigningURL'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['agent__embeddedRecipientStartURL'] = block =>
         `embeddedRecipientStartURL: ${JSON.stringify(block.getFieldValue('embeddedRecipientStartURL'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['agent__excludedDocuments'] = block => {
         let out = `    excludedDocuments: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'excludedDocument', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['agent_excludedDocuments__excludedDocument'] = block =>
         `    ${JSON.stringify(block.getFieldValue('excludedDocument'))}${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['agent__faxNumber'] = block =>
         `faxNumber: ${JSON.stringify(block.getFieldValue('faxNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['agent__faxNumberMetadata'] = block => {
         let out = `faxNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['agent__firstName'] = block =>
         `firstName: ${JSON.stringify(block.getFieldValue('firstName'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['agent__firstNameMetadata'] = block => {
         let out = `firstNameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['agent__fullName'] = block =>
         `fullName: ${JSON.stringify(block.getFieldValue('fullName'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['agent__fullNameMetadata'] = block => {
         let out = `fullNameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['agent__idCheckConfigurationName'] = block =>
         `idCheckConfigurationName: ${JSON.stringify(block.getFieldValue('idCheckConfigurationName'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['agent__idCheckConfigurationNameMetadata'] = block => {
         let out = `idCheckConfigurationNameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['agent__inheritEmailNotificationConfiguration'] = block =>
         `inheritEmailNotificationConfiguration: ${JSON.stringify(block.getFieldValue('inheritEmailNotificationConfiguration'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['agent__lastName'] = block =>
         `lastName: ${JSON.stringify(block.getFieldValue('lastName'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['agent__lastNameMetadata'] = block => {
         let out = `lastNameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['agent__lockedRecipientPhoneAuthEditable'] = block =>
         `lockedRecipientPhoneAuthEditable: ${JSON.stringify(block.getFieldValue('lockedRecipientPhoneAuthEditable'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['agent__lockedRecipientSmsEditable'] = block =>
         `lockedRecipientSmsEditable: ${JSON.stringify(block.getFieldValue('lockedRecipientSmsEditable'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['agent__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['agent__nameMetadata'] = block => {
         let out = `nameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['agent__note'] = block =>
         `note: ${JSON.stringify(block.getFieldValue('note'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['agent__noteMetadata'] = block => {
         let out = `noteMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['agent__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['agent__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['agent__recipientType'] = block =>
         `recipientType: ${JSON.stringify(block.getFieldValue('recipientType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['agent__recipientTypeMetadata'] = block => {
         let out = `recipientTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['agent__requireIdLookup'] = block =>
         `requireIdLookup: ${JSON.stringify(block.getFieldValue('requireIdLookup'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['agent__requireIdLookupMetadata'] = block => {
         let out = `requireIdLookupMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['agent__roleName'] = block =>
         `roleName: ${JSON.stringify(block.getFieldValue('roleName'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['agent__routingOrder'] = block =>
         `routingOrder: ${JSON.stringify(block.getFieldValue('routingOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['agent__routingOrderMetadata'] = block => {
         let out = `routingOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['agent__sentDateTime'] = block =>
         `sentDateTime: ${JSON.stringify(block.getFieldValue('sentDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['agent__signedDateTime'] = block =>
         `signedDateTime: ${JSON.stringify(block.getFieldValue('signedDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['agent__signingGroupId'] = block =>
         `signingGroupId: ${JSON.stringify(block.getFieldValue('signingGroupId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['agent__signingGroupIdMetadata'] = block => {
         let out = `signingGroupIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['agent__signingGroupName'] = block =>
         `signingGroupName: ${JSON.stringify(block.getFieldValue('signingGroupName'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['agent__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['agent__statusCode'] = block =>
         `statusCode: ${JSON.stringify(block.getFieldValue('statusCode'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['agent__suppressEmails'] = block =>
         `suppressEmails: ${JSON.stringify(block.getFieldValue('suppressEmails'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['agent__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['agent__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['agent__totalTabCount'] = block =>
         `totalTabCount: ${JSON.stringify(block.getFieldValue('totalTabCount'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['agent__userId'] = block =>
         `userId: ${JSON.stringify(block.getFieldValue('userId'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['approve'] = block => 
  `\n.add_object("approve", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['approve__anchorAllowWhiteSpaceInCharacters'] = block =>
         `anchorAllowWhiteSpaceInCharacters: ${JSON.stringify(block.getFieldValue('anchorAllowWhiteSpaceInCharacters'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['approve__anchorAllowWhiteSpaceInCharactersMetadata'] = block => {
         let out = `anchorAllowWhiteSpaceInCharactersMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['approve__anchorCaseSensitive'] = block =>
         `anchorCaseSensitive: ${JSON.stringify(block.getFieldValue('anchorCaseSensitive'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['approve__anchorCaseSensitiveMetadata'] = block => {
         let out = `anchorCaseSensitiveMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['approve__anchorHorizontalAlignment'] = block =>
         `anchorHorizontalAlignment: ${JSON.stringify(block.getFieldValue('anchorHorizontalAlignment'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['approve__anchorHorizontalAlignmentMetadata'] = block => {
         let out = `anchorHorizontalAlignmentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['approve__anchorIgnoreIfNotPresent'] = block =>
         `anchorIgnoreIfNotPresent: ${JSON.stringify(block.getFieldValue('anchorIgnoreIfNotPresent'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['approve__anchorIgnoreIfNotPresentMetadata'] = block => {
         let out = `anchorIgnoreIfNotPresentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['approve__anchorMatchWholeWord'] = block =>
         `anchorMatchWholeWord: ${JSON.stringify(block.getFieldValue('anchorMatchWholeWord'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['approve__anchorMatchWholeWordMetadata'] = block => {
         let out = `anchorMatchWholeWordMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['approve__anchorString'] = block =>
         `anchorString: ${JSON.stringify(block.getFieldValue('anchorString'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['approve__anchorStringMetadata'] = block => {
         let out = `anchorStringMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['approve__anchorTabProcessorVersion'] = block =>
         `anchorTabProcessorVersion: ${JSON.stringify(block.getFieldValue('anchorTabProcessorVersion'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['approve__anchorTabProcessorVersionMetadata'] = block => {
         let out = `anchorTabProcessorVersionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['approve__anchorUnits'] = block =>
         `anchorUnits: ${JSON.stringify(block.getFieldValue('anchorUnits'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['approve__anchorUnitsMetadata'] = block => {
         let out = `anchorUnitsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['approve__anchorXOffset'] = block =>
         `anchorXOffset: ${JSON.stringify(block.getFieldValue('anchorXOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['approve__anchorXOffsetMetadata'] = block => {
         let out = `anchorXOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['approve__anchorYOffset'] = block =>
         `anchorYOffset: ${JSON.stringify(block.getFieldValue('anchorYOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['approve__anchorYOffsetMetadata'] = block => {
         let out = `anchorYOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['approve__bold'] = block =>
         `bold: ${JSON.stringify(block.getFieldValue('bold'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['approve__boldMetadata'] = block => {
         let out = `boldMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['approve__buttonText'] = block =>
         `buttonText: ${JSON.stringify(block.getFieldValue('buttonText'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['approve__buttonTextMetadata'] = block => {
         let out = `buttonTextMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['approve__conditionalParentLabel'] = block =>
         `conditionalParentLabel: ${JSON.stringify(block.getFieldValue('conditionalParentLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['approve__conditionalParentLabelMetadata'] = block => {
         let out = `conditionalParentLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['approve__conditionalParentValue'] = block =>
         `conditionalParentValue: ${JSON.stringify(block.getFieldValue('conditionalParentValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['approve__conditionalParentValueMetadata'] = block => {
         let out = `conditionalParentValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['approve__customTabId'] = block =>
         `customTabId: ${JSON.stringify(block.getFieldValue('customTabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['approve__customTabIdMetadata'] = block => {
         let out = `customTabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['approve__documentId'] = block =>
         `documentId: ${JSON.stringify(block.getFieldValue('documentId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['approve__documentIdMetadata'] = block => {
         let out = `documentIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['approve__font'] = block =>
         `font: ${JSON.stringify(block.getFieldValue('font'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['approve__fontColor'] = block =>
         `fontColor: ${JSON.stringify(block.getFieldValue('fontColor'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['approve__fontColorMetadata'] = block => {
         let out = `fontColorMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['approve__fontMetadata'] = block => {
         let out = `fontMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['approve__fontSize'] = block =>
         `fontSize: ${JSON.stringify(block.getFieldValue('fontSize'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['approve__fontSizeMetadata'] = block => {
         let out = `fontSizeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['approve__formOrder'] = block =>
         `formOrder: ${JSON.stringify(block.getFieldValue('formOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['approve__formOrderMetadata'] = block => {
         let out = `formOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['approve__formPageLabel'] = block =>
         `formPageLabel: ${JSON.stringify(block.getFieldValue('formPageLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['approve__formPageLabelMetadata'] = block => {
         let out = `formPageLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['approve__formPageNumber'] = block =>
         `formPageNumber: ${JSON.stringify(block.getFieldValue('formPageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['approve__formPageNumberMetadata'] = block => {
         let out = `formPageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['approve__height'] = block =>
         `height: ${JSON.stringify(block.getFieldValue('height'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['approve__heightMetadata'] = block => {
         let out = `heightMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['approve__italic'] = block =>
         `italic: ${JSON.stringify(block.getFieldValue('italic'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['approve__italicMetadata'] = block => {
         let out = `italicMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['approve__mergeFieldXml'] = block =>
         `mergeFieldXml: ${JSON.stringify(block.getFieldValue('mergeFieldXml'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['approve__pageNumber'] = block =>
         `pageNumber: ${JSON.stringify(block.getFieldValue('pageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['approve__pageNumberMetadata'] = block => {
         let out = `pageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['approve__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['approve__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['approve__recipientIdGuidMetadata'] = block => {
         let out = `recipientIdGuidMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['approve__recipientIdMetadata'] = block => {
         let out = `recipientIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['approve__source'] = block =>
         `source: ${JSON.stringify(block.getFieldValue('source'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['approve__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['approve__statusMetadata'] = block => {
         let out = `statusMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['approve__tabGroupLabels'] = block => {
         let out = `    tabGroupLabels: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'tabGroupLabel', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['approve_tabGroupLabels__tabGroupLabel'] = block =>
         `    ${JSON.stringify(block.getFieldValue('tabGroupLabel'))}${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['approve__tabGroupLabelsMetadata'] = block => {
         let out = `tabGroupLabelsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['approve__tabId'] = block =>
         `tabId: ${JSON.stringify(block.getFieldValue('tabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['approve__tabIdMetadata'] = block => {
         let out = `tabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['approve__tabLabel'] = block =>
         `tabLabel: ${JSON.stringify(block.getFieldValue('tabLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['approve__tabLabelMetadata'] = block => {
         let out = `tabLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['approve__tabOrder'] = block =>
         `tabOrder: ${JSON.stringify(block.getFieldValue('tabOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['approve__tabOrderMetadata'] = block => {
         let out = `tabOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['approve__tabType'] = block =>
         `tabType: ${JSON.stringify(block.getFieldValue('tabType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['approve__tabTypeMetadata'] = block => {
         let out = `tabTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['approve__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['approve__templateLockedMetadata'] = block => {
         let out = `templateLockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['approve__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['approve__templateRequiredMetadata'] = block => {
         let out = `templateRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['approve__toolTipMetadata'] = block => {
         let out = `toolTipMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['approve__tooltip'] = block =>
         `tooltip: ${JSON.stringify(block.getFieldValue('tooltip'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['approve__underline'] = block =>
         `underline: ${JSON.stringify(block.getFieldValue('underline'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['approve__underlineMetadata'] = block => {
         let out = `underlineMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['approve__width'] = block =>
         `width: ${JSON.stringify(block.getFieldValue('width'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['approve__widthMetadata'] = block => {
         let out = `widthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['approve__xPosition'] = block =>
         `xPosition: ${JSON.stringify(block.getFieldValue('xPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['approve__xPositionMetadata'] = block => {
         let out = `xPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['approve__yPosition'] = block =>
         `yPosition: ${JSON.stringify(block.getFieldValue('yPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['approve__yPositionMetadata'] = block => {
         let out = `yPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

Blockly.JavaScript['attachment'] = block => 
  `\n.add_object("attachment", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['attachment__accessControl'] = block =>
         `accessControl: ${JSON.stringify(block.getFieldValue('accessControl'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['attachment__attachmentId'] = block =>
         `attachmentId: ${JSON.stringify(block.getFieldValue('attachmentId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['attachment__attachmentType'] = block =>
         `attachmentType: ${JSON.stringify(block.getFieldValue('attachmentType'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['attachment__data'] = block =>
         `data: ${JSON.stringify(block.getFieldValue('data'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['attachment__label'] = block =>
         `label: ${JSON.stringify(block.getFieldValue('label'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['attachment__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['attachment__remoteUrl'] = block =>
         `remoteUrl: ${JSON.stringify(block.getFieldValue('remoteUrl'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['bccEmailAddress'] = block => 
  `\n.add_object("bccEmailAddress", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['bccEmailAddress__bccEmailAddressId'] = block =>
         `bccEmailAddressId: ${JSON.stringify(block.getFieldValue('bccEmailAddressId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['bccEmailAddress__email'] = block =>
         `email: ${JSON.stringify(block.getFieldValue('email'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['carbonCopy'] = block => 
  `\n.add_object("carbonCopy", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['carbonCopy__accessCode'] = block =>
         `accessCode: ${JSON.stringify(block.getFieldValue('accessCode'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['carbonCopy__accessCodeMetadata'] = block => {
         let out = `accessCodeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['carbonCopy__addAccessCodeToEmail'] = block =>
         `addAccessCodeToEmail: ${JSON.stringify(block.getFieldValue('addAccessCodeToEmail'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['carbonCopy__agentCanEditEmail'] = block =>
         `agentCanEditEmail: ${JSON.stringify(block.getFieldValue('agentCanEditEmail'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['carbonCopy__agentCanEditName'] = block =>
         `agentCanEditName: ${JSON.stringify(block.getFieldValue('agentCanEditName'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['carbonCopy__allowSystemOverrideForLockedRecipient'] = block =>
         `allowSystemOverrideForLockedRecipient: ${JSON.stringify(block.getFieldValue('allowSystemOverrideForLockedRecipient'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['carbonCopy__autoRespondedReason'] = block =>
         `autoRespondedReason: ${JSON.stringify(block.getFieldValue('autoRespondedReason'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['carbonCopy__clientUserId'] = block =>
         `clientUserId: ${JSON.stringify(block.getFieldValue('clientUserId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['carbonCopy__completedCount'] = block =>
         `completedCount: ${JSON.stringify(block.getFieldValue('completedCount'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['carbonCopy__customFields'] = block => {
         let out = `    customFields: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'customField', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['carbonCopy_customFields__customField'] = block =>
         `    ${JSON.stringify(block.getFieldValue('customField'))}${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['carbonCopy__declinedDateTime'] = block =>
         `declinedDateTime: ${JSON.stringify(block.getFieldValue('declinedDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['carbonCopy__declinedReason'] = block =>
         `declinedReason: ${JSON.stringify(block.getFieldValue('declinedReason'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['carbonCopy__deliveredDateTime'] = block =>
         `deliveredDateTime: ${JSON.stringify(block.getFieldValue('deliveredDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['carbonCopy__deliveryMethod'] = block =>
         `deliveryMethod: ${JSON.stringify(block.getFieldValue('deliveryMethod'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['carbonCopy__deliveryMethodMetadata'] = block => {
         let out = `deliveryMethodMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['carbonCopy__designatorId'] = block =>
         `designatorId: ${JSON.stringify(block.getFieldValue('designatorId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['carbonCopy__designatorIdGuid'] = block =>
         `designatorIdGuid: ${JSON.stringify(block.getFieldValue('designatorIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['carbonCopy__email'] = block =>
         `email: ${JSON.stringify(block.getFieldValue('email'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['carbonCopy__emailMetadata'] = block => {
         let out = `emailMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['carbonCopy__emailRecipientPostSigningURL'] = block =>
         `emailRecipientPostSigningURL: ${JSON.stringify(block.getFieldValue('emailRecipientPostSigningURL'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['carbonCopy__embeddedRecipientStartURL'] = block =>
         `embeddedRecipientStartURL: ${JSON.stringify(block.getFieldValue('embeddedRecipientStartURL'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['carbonCopy__excludedDocuments'] = block => {
         let out = `    excludedDocuments: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'excludedDocument', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['carbonCopy_excludedDocuments__excludedDocument'] = block =>
         `    ${JSON.stringify(block.getFieldValue('excludedDocument'))}${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['carbonCopy__faxNumber'] = block =>
         `faxNumber: ${JSON.stringify(block.getFieldValue('faxNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['carbonCopy__faxNumberMetadata'] = block => {
         let out = `faxNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['carbonCopy__firstName'] = block =>
         `firstName: ${JSON.stringify(block.getFieldValue('firstName'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['carbonCopy__firstNameMetadata'] = block => {
         let out = `firstNameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['carbonCopy__fullName'] = block =>
         `fullName: ${JSON.stringify(block.getFieldValue('fullName'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['carbonCopy__fullNameMetadata'] = block => {
         let out = `fullNameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['carbonCopy__idCheckConfigurationName'] = block =>
         `idCheckConfigurationName: ${JSON.stringify(block.getFieldValue('idCheckConfigurationName'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['carbonCopy__idCheckConfigurationNameMetadata'] = block => {
         let out = `idCheckConfigurationNameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['carbonCopy__inheritEmailNotificationConfiguration'] = block =>
         `inheritEmailNotificationConfiguration: ${JSON.stringify(block.getFieldValue('inheritEmailNotificationConfiguration'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['carbonCopy__lastName'] = block =>
         `lastName: ${JSON.stringify(block.getFieldValue('lastName'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['carbonCopy__lastNameMetadata'] = block => {
         let out = `lastNameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['carbonCopy__linkedAccountConfigurationId'] = block =>
         `linkedAccountConfigurationId: ${JSON.stringify(block.getFieldValue('linkedAccountConfigurationId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['carbonCopy__lockedRecipientPhoneAuthEditable'] = block =>
         `lockedRecipientPhoneAuthEditable: ${JSON.stringify(block.getFieldValue('lockedRecipientPhoneAuthEditable'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['carbonCopy__lockedRecipientSmsEditable'] = block =>
         `lockedRecipientSmsEditable: ${JSON.stringify(block.getFieldValue('lockedRecipientSmsEditable'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['carbonCopy__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['carbonCopy__nameMetadata'] = block => {
         let out = `nameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['carbonCopy__note'] = block =>
         `note: ${JSON.stringify(block.getFieldValue('note'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['carbonCopy__noteMetadata'] = block => {
         let out = `noteMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['carbonCopy__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['carbonCopy__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['carbonCopy__recipientType'] = block =>
         `recipientType: ${JSON.stringify(block.getFieldValue('recipientType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['carbonCopy__recipientTypeMetadata'] = block => {
         let out = `recipientTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['carbonCopy__requireIdLookup'] = block =>
         `requireIdLookup: ${JSON.stringify(block.getFieldValue('requireIdLookup'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['carbonCopy__requireIdLookupMetadata'] = block => {
         let out = `requireIdLookupMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['carbonCopy__roleName'] = block =>
         `roleName: ${JSON.stringify(block.getFieldValue('roleName'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['carbonCopy__routingOrder'] = block =>
         `routingOrder: ${JSON.stringify(block.getFieldValue('routingOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['carbonCopy__routingOrderMetadata'] = block => {
         let out = `routingOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['carbonCopy__sentDateTime'] = block =>
         `sentDateTime: ${JSON.stringify(block.getFieldValue('sentDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['carbonCopy__signedDateTime'] = block =>
         `signedDateTime: ${JSON.stringify(block.getFieldValue('signedDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['carbonCopy__signingGroupId'] = block =>
         `signingGroupId: ${JSON.stringify(block.getFieldValue('signingGroupId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['carbonCopy__signingGroupIdMetadata'] = block => {
         let out = `signingGroupIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['carbonCopy__signingGroupName'] = block =>
         `signingGroupName: ${JSON.stringify(block.getFieldValue('signingGroupName'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['carbonCopy__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['carbonCopy__statusCode'] = block =>
         `statusCode: ${JSON.stringify(block.getFieldValue('statusCode'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['carbonCopy__suppressEmails'] = block =>
         `suppressEmails: ${JSON.stringify(block.getFieldValue('suppressEmails'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['carbonCopy__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['carbonCopy__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['carbonCopy__totalTabCount'] = block =>
         `totalTabCount: ${JSON.stringify(block.getFieldValue('totalTabCount'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['carbonCopy__userId'] = block =>
         `userId: ${JSON.stringify(block.getFieldValue('userId'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['certifiedDelivery'] = block => 
  `\n.add_object("certifiedDelivery", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['certifiedDelivery__accessCode'] = block =>
         `accessCode: ${JSON.stringify(block.getFieldValue('accessCode'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['certifiedDelivery__accessCodeMetadata'] = block => {
         let out = `accessCodeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['certifiedDelivery__addAccessCodeToEmail'] = block =>
         `addAccessCodeToEmail: ${JSON.stringify(block.getFieldValue('addAccessCodeToEmail'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['certifiedDelivery__agentCanEditEmail'] = block =>
         `agentCanEditEmail: ${JSON.stringify(block.getFieldValue('agentCanEditEmail'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['certifiedDelivery__agentCanEditName'] = block =>
         `agentCanEditName: ${JSON.stringify(block.getFieldValue('agentCanEditName'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['certifiedDelivery__allowSystemOverrideForLockedRecipient'] = block =>
         `allowSystemOverrideForLockedRecipient: ${JSON.stringify(block.getFieldValue('allowSystemOverrideForLockedRecipient'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['certifiedDelivery__autoRespondedReason'] = block =>
         `autoRespondedReason: ${JSON.stringify(block.getFieldValue('autoRespondedReason'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['certifiedDelivery__clientUserId'] = block =>
         `clientUserId: ${JSON.stringify(block.getFieldValue('clientUserId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['certifiedDelivery__completedCount'] = block =>
         `completedCount: ${JSON.stringify(block.getFieldValue('completedCount'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['certifiedDelivery__customFields'] = block => {
         let out = `    customFields: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'customField', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['certifiedDelivery_customFields__customField'] = block =>
         `    ${JSON.stringify(block.getFieldValue('customField'))}${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['certifiedDelivery__declinedDateTime'] = block =>
         `declinedDateTime: ${JSON.stringify(block.getFieldValue('declinedDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['certifiedDelivery__declinedReason'] = block =>
         `declinedReason: ${JSON.stringify(block.getFieldValue('declinedReason'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['certifiedDelivery__deliveredDateTime'] = block =>
         `deliveredDateTime: ${JSON.stringify(block.getFieldValue('deliveredDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['certifiedDelivery__deliveryMethod'] = block =>
         `deliveryMethod: ${JSON.stringify(block.getFieldValue('deliveryMethod'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['certifiedDelivery__deliveryMethodMetadata'] = block => {
         let out = `deliveryMethodMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['certifiedDelivery__designatorId'] = block =>
         `designatorId: ${JSON.stringify(block.getFieldValue('designatorId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['certifiedDelivery__designatorIdGuid'] = block =>
         `designatorIdGuid: ${JSON.stringify(block.getFieldValue('designatorIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['certifiedDelivery__email'] = block =>
         `email: ${JSON.stringify(block.getFieldValue('email'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['certifiedDelivery__emailMetadata'] = block => {
         let out = `emailMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['certifiedDelivery__emailRecipientPostSigningURL'] = block =>
         `emailRecipientPostSigningURL: ${JSON.stringify(block.getFieldValue('emailRecipientPostSigningURL'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['certifiedDelivery__embeddedRecipientStartURL'] = block =>
         `embeddedRecipientStartURL: ${JSON.stringify(block.getFieldValue('embeddedRecipientStartURL'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['certifiedDelivery__excludedDocuments'] = block => {
         let out = `    excludedDocuments: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'excludedDocument', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['certifiedDelivery_excludedDocuments__excludedDocument'] = block =>
         `    ${JSON.stringify(block.getFieldValue('excludedDocument'))}${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['certifiedDelivery__faxNumber'] = block =>
         `faxNumber: ${JSON.stringify(block.getFieldValue('faxNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['certifiedDelivery__faxNumberMetadata'] = block => {
         let out = `faxNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['certifiedDelivery__firstName'] = block =>
         `firstName: ${JSON.stringify(block.getFieldValue('firstName'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['certifiedDelivery__firstNameMetadata'] = block => {
         let out = `firstNameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['certifiedDelivery__fullName'] = block =>
         `fullName: ${JSON.stringify(block.getFieldValue('fullName'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['certifiedDelivery__fullNameMetadata'] = block => {
         let out = `fullNameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['certifiedDelivery__idCheckConfigurationName'] = block =>
         `idCheckConfigurationName: ${JSON.stringify(block.getFieldValue('idCheckConfigurationName'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['certifiedDelivery__idCheckConfigurationNameMetadata'] = block => {
         let out = `idCheckConfigurationNameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['certifiedDelivery__inheritEmailNotificationConfiguration'] = block =>
         `inheritEmailNotificationConfiguration: ${JSON.stringify(block.getFieldValue('inheritEmailNotificationConfiguration'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['certifiedDelivery__lastName'] = block =>
         `lastName: ${JSON.stringify(block.getFieldValue('lastName'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['certifiedDelivery__lastNameMetadata'] = block => {
         let out = `lastNameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['certifiedDelivery__lockedRecipientPhoneAuthEditable'] = block =>
         `lockedRecipientPhoneAuthEditable: ${JSON.stringify(block.getFieldValue('lockedRecipientPhoneAuthEditable'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['certifiedDelivery__lockedRecipientSmsEditable'] = block =>
         `lockedRecipientSmsEditable: ${JSON.stringify(block.getFieldValue('lockedRecipientSmsEditable'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['certifiedDelivery__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['certifiedDelivery__nameMetadata'] = block => {
         let out = `nameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['certifiedDelivery__note'] = block =>
         `note: ${JSON.stringify(block.getFieldValue('note'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['certifiedDelivery__noteMetadata'] = block => {
         let out = `noteMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['certifiedDelivery__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['certifiedDelivery__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['certifiedDelivery__recipientType'] = block =>
         `recipientType: ${JSON.stringify(block.getFieldValue('recipientType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['certifiedDelivery__recipientTypeMetadata'] = block => {
         let out = `recipientTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['certifiedDelivery__requireIdLookup'] = block =>
         `requireIdLookup: ${JSON.stringify(block.getFieldValue('requireIdLookup'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['certifiedDelivery__requireIdLookupMetadata'] = block => {
         let out = `requireIdLookupMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['certifiedDelivery__roleName'] = block =>
         `roleName: ${JSON.stringify(block.getFieldValue('roleName'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['certifiedDelivery__routingOrder'] = block =>
         `routingOrder: ${JSON.stringify(block.getFieldValue('routingOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['certifiedDelivery__routingOrderMetadata'] = block => {
         let out = `routingOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['certifiedDelivery__sentDateTime'] = block =>
         `sentDateTime: ${JSON.stringify(block.getFieldValue('sentDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['certifiedDelivery__signedDateTime'] = block =>
         `signedDateTime: ${JSON.stringify(block.getFieldValue('signedDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['certifiedDelivery__signingGroupId'] = block =>
         `signingGroupId: ${JSON.stringify(block.getFieldValue('signingGroupId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['certifiedDelivery__signingGroupIdMetadata'] = block => {
         let out = `signingGroupIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['certifiedDelivery__signingGroupName'] = block =>
         `signingGroupName: ${JSON.stringify(block.getFieldValue('signingGroupName'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['certifiedDelivery__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['certifiedDelivery__statusCode'] = block =>
         `statusCode: ${JSON.stringify(block.getFieldValue('statusCode'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['certifiedDelivery__suppressEmails'] = block =>
         `suppressEmails: ${JSON.stringify(block.getFieldValue('suppressEmails'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['certifiedDelivery__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['certifiedDelivery__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['certifiedDelivery__totalTabCount'] = block =>
         `totalTabCount: ${JSON.stringify(block.getFieldValue('totalTabCount'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['certifiedDelivery__userId'] = block =>
         `userId: ${JSON.stringify(block.getFieldValue('userId'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['checkbox'] = block => 
  `\n.add_object("checkbox", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['checkbox__anchorAllowWhiteSpaceInCharacters'] = block =>
         `anchorAllowWhiteSpaceInCharacters: ${JSON.stringify(block.getFieldValue('anchorAllowWhiteSpaceInCharacters'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['checkbox__anchorAllowWhiteSpaceInCharactersMetadata'] = block => {
         let out = `anchorAllowWhiteSpaceInCharactersMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['checkbox__anchorCaseSensitive'] = block =>
         `anchorCaseSensitive: ${JSON.stringify(block.getFieldValue('anchorCaseSensitive'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['checkbox__anchorCaseSensitiveMetadata'] = block => {
         let out = `anchorCaseSensitiveMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['checkbox__anchorHorizontalAlignment'] = block =>
         `anchorHorizontalAlignment: ${JSON.stringify(block.getFieldValue('anchorHorizontalAlignment'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['checkbox__anchorHorizontalAlignmentMetadata'] = block => {
         let out = `anchorHorizontalAlignmentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['checkbox__anchorIgnoreIfNotPresent'] = block =>
         `anchorIgnoreIfNotPresent: ${JSON.stringify(block.getFieldValue('anchorIgnoreIfNotPresent'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['checkbox__anchorIgnoreIfNotPresentMetadata'] = block => {
         let out = `anchorIgnoreIfNotPresentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['checkbox__anchorMatchWholeWord'] = block =>
         `anchorMatchWholeWord: ${JSON.stringify(block.getFieldValue('anchorMatchWholeWord'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['checkbox__anchorMatchWholeWordMetadata'] = block => {
         let out = `anchorMatchWholeWordMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['checkbox__anchorString'] = block =>
         `anchorString: ${JSON.stringify(block.getFieldValue('anchorString'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['checkbox__anchorStringMetadata'] = block => {
         let out = `anchorStringMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['checkbox__anchorTabProcessorVersion'] = block =>
         `anchorTabProcessorVersion: ${JSON.stringify(block.getFieldValue('anchorTabProcessorVersion'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['checkbox__anchorTabProcessorVersionMetadata'] = block => {
         let out = `anchorTabProcessorVersionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['checkbox__anchorUnits'] = block =>
         `anchorUnits: ${JSON.stringify(block.getFieldValue('anchorUnits'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['checkbox__anchorUnitsMetadata'] = block => {
         let out = `anchorUnitsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['checkbox__anchorXOffset'] = block =>
         `anchorXOffset: ${JSON.stringify(block.getFieldValue('anchorXOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['checkbox__anchorXOffsetMetadata'] = block => {
         let out = `anchorXOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['checkbox__anchorYOffset'] = block =>
         `anchorYOffset: ${JSON.stringify(block.getFieldValue('anchorYOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['checkbox__anchorYOffsetMetadata'] = block => {
         let out = `anchorYOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['checkbox__bold'] = block =>
         `bold: ${JSON.stringify(block.getFieldValue('bold'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['checkbox__boldMetadata'] = block => {
         let out = `boldMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['checkbox__conditionalParentLabel'] = block =>
         `conditionalParentLabel: ${JSON.stringify(block.getFieldValue('conditionalParentLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['checkbox__conditionalParentLabelMetadata'] = block => {
         let out = `conditionalParentLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['checkbox__conditionalParentValue'] = block =>
         `conditionalParentValue: ${JSON.stringify(block.getFieldValue('conditionalParentValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['checkbox__conditionalParentValueMetadata'] = block => {
         let out = `conditionalParentValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['checkbox__customTabId'] = block =>
         `customTabId: ${JSON.stringify(block.getFieldValue('customTabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['checkbox__customTabIdMetadata'] = block => {
         let out = `customTabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['checkbox__documentId'] = block =>
         `documentId: ${JSON.stringify(block.getFieldValue('documentId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['checkbox__documentIdMetadata'] = block => {
         let out = `documentIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['checkbox__font'] = block =>
         `font: ${JSON.stringify(block.getFieldValue('font'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['checkbox__fontColor'] = block =>
         `fontColor: ${JSON.stringify(block.getFieldValue('fontColor'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['checkbox__fontColorMetadata'] = block => {
         let out = `fontColorMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['checkbox__fontMetadata'] = block => {
         let out = `fontMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['checkbox__fontSize'] = block =>
         `fontSize: ${JSON.stringify(block.getFieldValue('fontSize'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['checkbox__fontSizeMetadata'] = block => {
         let out = `fontSizeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['checkbox__formOrder'] = block =>
         `formOrder: ${JSON.stringify(block.getFieldValue('formOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['checkbox__formOrderMetadata'] = block => {
         let out = `formOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['checkbox__formPageLabel'] = block =>
         `formPageLabel: ${JSON.stringify(block.getFieldValue('formPageLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['checkbox__formPageLabelMetadata'] = block => {
         let out = `formPageLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['checkbox__formPageNumber'] = block =>
         `formPageNumber: ${JSON.stringify(block.getFieldValue('formPageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['checkbox__formPageNumberMetadata'] = block => {
         let out = `formPageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['checkbox__height'] = block =>
         `height: ${JSON.stringify(block.getFieldValue('height'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['checkbox__heightMetadata'] = block => {
         let out = `heightMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['checkbox__italic'] = block =>
         `italic: ${JSON.stringify(block.getFieldValue('italic'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['checkbox__italicMetadata'] = block => {
         let out = `italicMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['checkbox__locked'] = block =>
         `locked: ${JSON.stringify(block.getFieldValue('locked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['checkbox__lockedMetadata'] = block => {
         let out = `lockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['checkbox__mergeFieldXml'] = block =>
         `mergeFieldXml: ${JSON.stringify(block.getFieldValue('mergeFieldXml'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['checkbox__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['checkbox__nameMetadata'] = block => {
         let out = `nameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['checkbox__originalValue'] = block =>
         `originalValue: ${JSON.stringify(block.getFieldValue('originalValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['checkbox__originalValueMetadata'] = block => {
         let out = `originalValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['checkbox__pageNumber'] = block =>
         `pageNumber: ${JSON.stringify(block.getFieldValue('pageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['checkbox__pageNumberMetadata'] = block => {
         let out = `pageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['checkbox__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['checkbox__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['checkbox__recipientIdGuidMetadata'] = block => {
         let out = `recipientIdGuidMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['checkbox__recipientIdMetadata'] = block => {
         let out = `recipientIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['checkbox__requireInitialOnSharedChange'] = block =>
         `requireInitialOnSharedChange: ${JSON.stringify(block.getFieldValue('requireInitialOnSharedChange'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['checkbox__requireInitialOnSharedChangeMetadata'] = block => {
         let out = `requireInitialOnSharedChangeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['checkbox__required'] = block =>
         `required: ${JSON.stringify(block.getFieldValue('required'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['checkbox__requiredMetadata'] = block => {
         let out = `requiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['checkbox__selected'] = block =>
         `selected: ${JSON.stringify(block.getFieldValue('selected'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['checkbox__selectedMetadata'] = block => {
         let out = `selectedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['checkbox__selectedOriginal'] = block =>
         `selectedOriginal: ${JSON.stringify(block.getFieldValue('selectedOriginal'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['checkbox__selectedOriginalMetadata'] = block => {
         let out = `selectedOriginalMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['checkbox__shareToRecipients'] = block =>
         `shareToRecipients: ${JSON.stringify(block.getFieldValue('shareToRecipients'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['checkbox__shareToRecipientsMetadata'] = block => {
         let out = `shareToRecipientsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['checkbox__shared'] = block =>
         `shared: ${JSON.stringify(block.getFieldValue('shared'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['checkbox__sharedMetadata'] = block => {
         let out = `sharedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['checkbox__source'] = block =>
         `source: ${JSON.stringify(block.getFieldValue('source'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['checkbox__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['checkbox__statusMetadata'] = block => {
         let out = `statusMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['checkbox__tabGroupLabels'] = block => {
         let out = `    tabGroupLabels: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'tabGroupLabel', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['checkbox_tabGroupLabels__tabGroupLabel'] = block =>
         `    ${JSON.stringify(block.getFieldValue('tabGroupLabel'))}${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['checkbox__tabGroupLabelsMetadata'] = block => {
         let out = `tabGroupLabelsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['checkbox__tabId'] = block =>
         `tabId: ${JSON.stringify(block.getFieldValue('tabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['checkbox__tabIdMetadata'] = block => {
         let out = `tabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['checkbox__tabLabel'] = block =>
         `tabLabel: ${JSON.stringify(block.getFieldValue('tabLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['checkbox__tabLabelMetadata'] = block => {
         let out = `tabLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['checkbox__tabOrder'] = block =>
         `tabOrder: ${JSON.stringify(block.getFieldValue('tabOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['checkbox__tabOrderMetadata'] = block => {
         let out = `tabOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['checkbox__tabType'] = block =>
         `tabType: ${JSON.stringify(block.getFieldValue('tabType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['checkbox__tabTypeMetadata'] = block => {
         let out = `tabTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['checkbox__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['checkbox__templateLockedMetadata'] = block => {
         let out = `templateLockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['checkbox__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['checkbox__templateRequiredMetadata'] = block => {
         let out = `templateRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['checkbox__toolTipMetadata'] = block => {
         let out = `toolTipMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['checkbox__tooltip'] = block =>
         `tooltip: ${JSON.stringify(block.getFieldValue('tooltip'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['checkbox__underline'] = block =>
         `underline: ${JSON.stringify(block.getFieldValue('underline'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['checkbox__underlineMetadata'] = block => {
         let out = `underlineMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['checkbox__value'] = block =>
         `value: ${JSON.stringify(block.getFieldValue('value'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['checkbox__valueMetadata'] = block => {
         let out = `valueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['checkbox__width'] = block =>
         `width: ${JSON.stringify(block.getFieldValue('width'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['checkbox__widthMetadata'] = block => {
         let out = `widthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['checkbox__xPosition'] = block =>
         `xPosition: ${JSON.stringify(block.getFieldValue('xPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['checkbox__xPositionMetadata'] = block => {
         let out = `xPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['checkbox__yPosition'] = block =>
         `yPosition: ${JSON.stringify(block.getFieldValue('yPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['checkbox__yPositionMetadata'] = block => {
         let out = `yPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

Blockly.JavaScript['comment'] = block => 
  `\n.add_object("comment", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['comment__envelopeId'] = block =>
         `envelopeId: ${JSON.stringify(block.getFieldValue('envelopeId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['comment__hmac'] = block =>
         `hmac: ${JSON.stringify(block.getFieldValue('hmac'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['comment__id'] = block =>
         `id: ${JSON.stringify(block.getFieldValue('id'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['comment__mentions'] = block => {
         let out = `    mentions: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'mention', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['comment_mentions__mention'] = block =>
         `    ${JSON.stringify(block.getFieldValue('mention'))}${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['comment__read'] = block =>
         `read: ${block.getFieldValue('read')}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['comment__sentByEmail'] = block =>
         `sentByEmail: ${JSON.stringify(block.getFieldValue('sentByEmail'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['comment__sentByFullName'] = block =>
         `sentByFullName: ${JSON.stringify(block.getFieldValue('sentByFullName'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['comment__sentByImageId'] = block =>
         `sentByImageId: ${JSON.stringify(block.getFieldValue('sentByImageId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['comment__sentByInitials'] = block =>
         `sentByInitials: ${JSON.stringify(block.getFieldValue('sentByInitials'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['comment__sentByRecipientId'] = block =>
         `sentByRecipientId: ${JSON.stringify(block.getFieldValue('sentByRecipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['comment__sentByUserId'] = block =>
         `sentByUserId: ${JSON.stringify(block.getFieldValue('sentByUserId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['comment__signingGroupId'] = block =>
         `signingGroupId: ${JSON.stringify(block.getFieldValue('signingGroupId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['comment__signingGroupName'] = block =>
         `signingGroupName: ${JSON.stringify(block.getFieldValue('signingGroupName'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['comment__subject'] = block =>
         `subject: ${JSON.stringify(block.getFieldValue('subject'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['comment__tabId'] = block =>
         `tabId: ${JSON.stringify(block.getFieldValue('tabId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['comment__text'] = block =>
         `text: ${JSON.stringify(block.getFieldValue('text'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['comment__threadId'] = block =>
         `threadId: ${JSON.stringify(block.getFieldValue('threadId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['comment__threadOriginatorId'] = block =>
         `threadOriginatorId: ${JSON.stringify(block.getFieldValue('threadOriginatorId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['comment__timeStampFormatted'] = block =>
         `timeStampFormatted: ${JSON.stringify(block.getFieldValue('timeStampFormatted'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['comment__timestamp'] = block =>
         `timestamp: ${JSON.stringify(block.getFieldValue('timestamp'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['comment__visibleTo'] = block => {
         let out = `    visibleTo: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'visibleTo', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['comment_visibleTo__visibleTo'] = block =>
         `    ${JSON.stringify(block.getFieldValue('visibleTo'))}${block.getNextBlock() ? ', ': ''}\n`;

Blockly.JavaScript['commentThread'] = block => 
  `\n.add_object("commentThread", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['commentThread__anchorAllowWhiteSpaceInCharacters'] = block =>
         `anchorAllowWhiteSpaceInCharacters: ${JSON.stringify(block.getFieldValue('anchorAllowWhiteSpaceInCharacters'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commentThread__anchorAllowWhiteSpaceInCharactersMetadata'] = block => {
         let out = `anchorAllowWhiteSpaceInCharactersMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commentThread__anchorCaseSensitive'] = block =>
         `anchorCaseSensitive: ${JSON.stringify(block.getFieldValue('anchorCaseSensitive'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commentThread__anchorCaseSensitiveMetadata'] = block => {
         let out = `anchorCaseSensitiveMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commentThread__anchorHorizontalAlignment'] = block =>
         `anchorHorizontalAlignment: ${JSON.stringify(block.getFieldValue('anchorHorizontalAlignment'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commentThread__anchorHorizontalAlignmentMetadata'] = block => {
         let out = `anchorHorizontalAlignmentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commentThread__anchorIgnoreIfNotPresent'] = block =>
         `anchorIgnoreIfNotPresent: ${JSON.stringify(block.getFieldValue('anchorIgnoreIfNotPresent'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commentThread__anchorIgnoreIfNotPresentMetadata'] = block => {
         let out = `anchorIgnoreIfNotPresentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commentThread__anchorMatchWholeWord'] = block =>
         `anchorMatchWholeWord: ${JSON.stringify(block.getFieldValue('anchorMatchWholeWord'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commentThread__anchorMatchWholeWordMetadata'] = block => {
         let out = `anchorMatchWholeWordMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commentThread__anchorString'] = block =>
         `anchorString: ${JSON.stringify(block.getFieldValue('anchorString'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commentThread__anchorStringMetadata'] = block => {
         let out = `anchorStringMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commentThread__anchorTabProcessorVersion'] = block =>
         `anchorTabProcessorVersion: ${JSON.stringify(block.getFieldValue('anchorTabProcessorVersion'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commentThread__anchorTabProcessorVersionMetadata'] = block => {
         let out = `anchorTabProcessorVersionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commentThread__anchorUnits'] = block =>
         `anchorUnits: ${JSON.stringify(block.getFieldValue('anchorUnits'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commentThread__anchorUnitsMetadata'] = block => {
         let out = `anchorUnitsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commentThread__anchorXOffset'] = block =>
         `anchorXOffset: ${JSON.stringify(block.getFieldValue('anchorXOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commentThread__anchorXOffsetMetadata'] = block => {
         let out = `anchorXOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commentThread__anchorYOffset'] = block =>
         `anchorYOffset: ${JSON.stringify(block.getFieldValue('anchorYOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commentThread__anchorYOffsetMetadata'] = block => {
         let out = `anchorYOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commentThread__bold'] = block =>
         `bold: ${JSON.stringify(block.getFieldValue('bold'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commentThread__boldMetadata'] = block => {
         let out = `boldMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commentThread__conditionalParentLabel'] = block =>
         `conditionalParentLabel: ${JSON.stringify(block.getFieldValue('conditionalParentLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commentThread__conditionalParentLabelMetadata'] = block => {
         let out = `conditionalParentLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commentThread__conditionalParentValue'] = block =>
         `conditionalParentValue: ${JSON.stringify(block.getFieldValue('conditionalParentValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commentThread__conditionalParentValueMetadata'] = block => {
         let out = `conditionalParentValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commentThread__customTabId'] = block =>
         `customTabId: ${JSON.stringify(block.getFieldValue('customTabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commentThread__customTabIdMetadata'] = block => {
         let out = `customTabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commentThread__documentId'] = block =>
         `documentId: ${JSON.stringify(block.getFieldValue('documentId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commentThread__documentIdMetadata'] = block => {
         let out = `documentIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commentThread__font'] = block =>
         `font: ${JSON.stringify(block.getFieldValue('font'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['commentThread__fontColor'] = block =>
         `fontColor: ${JSON.stringify(block.getFieldValue('fontColor'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commentThread__fontColorMetadata'] = block => {
         let out = `fontColorMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['commentThread__fontMetadata'] = block => {
         let out = `fontMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commentThread__fontSize'] = block =>
         `fontSize: ${JSON.stringify(block.getFieldValue('fontSize'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commentThread__fontSizeMetadata'] = block => {
         let out = `fontSizeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commentThread__formOrder'] = block =>
         `formOrder: ${JSON.stringify(block.getFieldValue('formOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commentThread__formOrderMetadata'] = block => {
         let out = `formOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commentThread__formPageLabel'] = block =>
         `formPageLabel: ${JSON.stringify(block.getFieldValue('formPageLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commentThread__formPageLabelMetadata'] = block => {
         let out = `formPageLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commentThread__formPageNumber'] = block =>
         `formPageNumber: ${JSON.stringify(block.getFieldValue('formPageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commentThread__formPageNumberMetadata'] = block => {
         let out = `formPageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commentThread__height'] = block =>
         `height: ${JSON.stringify(block.getFieldValue('height'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commentThread__heightMetadata'] = block => {
         let out = `heightMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commentThread__italic'] = block =>
         `italic: ${JSON.stringify(block.getFieldValue('italic'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commentThread__italicMetadata'] = block => {
         let out = `italicMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commentThread__mergeFieldXml'] = block =>
         `mergeFieldXml: ${JSON.stringify(block.getFieldValue('mergeFieldXml'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['commentThread__pageNumber'] = block =>
         `pageNumber: ${JSON.stringify(block.getFieldValue('pageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commentThread__pageNumberMetadata'] = block => {
         let out = `pageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commentThread__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['commentThread__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commentThread__recipientIdGuidMetadata'] = block => {
         let out = `recipientIdGuidMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['commentThread__recipientIdMetadata'] = block => {
         let out = `recipientIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commentThread__source'] = block =>
         `source: ${JSON.stringify(block.getFieldValue('source'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['commentThread__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commentThread__statusMetadata'] = block => {
         let out = `statusMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['commentThread__tabGroupLabels'] = block => {
         let out = `    tabGroupLabels: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'tabGroupLabel', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['commentThread_tabGroupLabels__tabGroupLabel'] = block =>
         `    ${JSON.stringify(block.getFieldValue('tabGroupLabel'))}${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['commentThread__tabGroupLabelsMetadata'] = block => {
         let out = `tabGroupLabelsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commentThread__tabId'] = block =>
         `tabId: ${JSON.stringify(block.getFieldValue('tabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commentThread__tabIdMetadata'] = block => {
         let out = `tabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commentThread__tabLabel'] = block =>
         `tabLabel: ${JSON.stringify(block.getFieldValue('tabLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commentThread__tabLabelMetadata'] = block => {
         let out = `tabLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commentThread__tabOrder'] = block =>
         `tabOrder: ${JSON.stringify(block.getFieldValue('tabOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commentThread__tabOrderMetadata'] = block => {
         let out = `tabOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commentThread__tabType'] = block =>
         `tabType: ${JSON.stringify(block.getFieldValue('tabType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commentThread__tabTypeMetadata'] = block => {
         let out = `tabTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commentThread__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commentThread__templateLockedMetadata'] = block => {
         let out = `templateLockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commentThread__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commentThread__templateRequiredMetadata'] = block => {
         let out = `templateRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commentThread__threadId'] = block =>
         `threadId: ${JSON.stringify(block.getFieldValue('threadId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commentThread__toolTipMetadata'] = block => {
         let out = `toolTipMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commentThread__tooltip'] = block =>
         `tooltip: ${JSON.stringify(block.getFieldValue('tooltip'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['commentThread__underline'] = block =>
         `underline: ${JSON.stringify(block.getFieldValue('underline'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commentThread__underlineMetadata'] = block => {
         let out = `underlineMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commentThread__width'] = block =>
         `width: ${JSON.stringify(block.getFieldValue('width'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commentThread__widthMetadata'] = block => {
         let out = `widthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commentThread__xPosition'] = block =>
         `xPosition: ${JSON.stringify(block.getFieldValue('xPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commentThread__xPositionMetadata'] = block => {
         let out = `xPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commentThread__yPosition'] = block =>
         `yPosition: ${JSON.stringify(block.getFieldValue('yPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commentThread__yPositionMetadata'] = block => {
         let out = `yPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

Blockly.JavaScript['commissionCounty'] = block => 
  `\n.add_object("commissionCounty", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['commissionCounty__anchorAllowWhiteSpaceInCharacters'] = block =>
         `anchorAllowWhiteSpaceInCharacters: ${JSON.stringify(block.getFieldValue('anchorAllowWhiteSpaceInCharacters'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionCounty__anchorAllowWhiteSpaceInCharactersMetadata'] = block => {
         let out = `anchorAllowWhiteSpaceInCharactersMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionCounty__anchorCaseSensitive'] = block =>
         `anchorCaseSensitive: ${JSON.stringify(block.getFieldValue('anchorCaseSensitive'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionCounty__anchorCaseSensitiveMetadata'] = block => {
         let out = `anchorCaseSensitiveMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionCounty__anchorHorizontalAlignment'] = block =>
         `anchorHorizontalAlignment: ${JSON.stringify(block.getFieldValue('anchorHorizontalAlignment'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionCounty__anchorHorizontalAlignmentMetadata'] = block => {
         let out = `anchorHorizontalAlignmentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionCounty__anchorIgnoreIfNotPresent'] = block =>
         `anchorIgnoreIfNotPresent: ${JSON.stringify(block.getFieldValue('anchorIgnoreIfNotPresent'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionCounty__anchorIgnoreIfNotPresentMetadata'] = block => {
         let out = `anchorIgnoreIfNotPresentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionCounty__anchorMatchWholeWord'] = block =>
         `anchorMatchWholeWord: ${JSON.stringify(block.getFieldValue('anchorMatchWholeWord'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionCounty__anchorMatchWholeWordMetadata'] = block => {
         let out = `anchorMatchWholeWordMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionCounty__anchorString'] = block =>
         `anchorString: ${JSON.stringify(block.getFieldValue('anchorString'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionCounty__anchorStringMetadata'] = block => {
         let out = `anchorStringMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionCounty__anchorTabProcessorVersion'] = block =>
         `anchorTabProcessorVersion: ${JSON.stringify(block.getFieldValue('anchorTabProcessorVersion'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionCounty__anchorTabProcessorVersionMetadata'] = block => {
         let out = `anchorTabProcessorVersionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionCounty__anchorUnits'] = block =>
         `anchorUnits: ${JSON.stringify(block.getFieldValue('anchorUnits'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionCounty__anchorUnitsMetadata'] = block => {
         let out = `anchorUnitsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionCounty__anchorXOffset'] = block =>
         `anchorXOffset: ${JSON.stringify(block.getFieldValue('anchorXOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionCounty__anchorXOffsetMetadata'] = block => {
         let out = `anchorXOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionCounty__anchorYOffset'] = block =>
         `anchorYOffset: ${JSON.stringify(block.getFieldValue('anchorYOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionCounty__anchorYOffsetMetadata'] = block => {
         let out = `anchorYOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionCounty__bold'] = block =>
         `bold: ${JSON.stringify(block.getFieldValue('bold'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionCounty__boldMetadata'] = block => {
         let out = `boldMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionCounty__concealValueOnDocument'] = block =>
         `concealValueOnDocument: ${JSON.stringify(block.getFieldValue('concealValueOnDocument'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionCounty__concealValueOnDocumentMetadata'] = block => {
         let out = `concealValueOnDocumentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionCounty__conditionalParentLabel'] = block =>
         `conditionalParentLabel: ${JSON.stringify(block.getFieldValue('conditionalParentLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionCounty__conditionalParentLabelMetadata'] = block => {
         let out = `conditionalParentLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionCounty__conditionalParentValue'] = block =>
         `conditionalParentValue: ${JSON.stringify(block.getFieldValue('conditionalParentValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionCounty__conditionalParentValueMetadata'] = block => {
         let out = `conditionalParentValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionCounty__customTabId'] = block =>
         `customTabId: ${JSON.stringify(block.getFieldValue('customTabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionCounty__customTabIdMetadata'] = block => {
         let out = `customTabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionCounty__disableAutoSize'] = block =>
         `disableAutoSize: ${JSON.stringify(block.getFieldValue('disableAutoSize'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionCounty__disableAutoSizeMetadata'] = block => {
         let out = `disableAutoSizeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionCounty__documentId'] = block =>
         `documentId: ${JSON.stringify(block.getFieldValue('documentId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionCounty__documentIdMetadata'] = block => {
         let out = `documentIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionCounty__font'] = block =>
         `font: ${JSON.stringify(block.getFieldValue('font'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['commissionCounty__fontColor'] = block =>
         `fontColor: ${JSON.stringify(block.getFieldValue('fontColor'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionCounty__fontColorMetadata'] = block => {
         let out = `fontColorMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['commissionCounty__fontMetadata'] = block => {
         let out = `fontMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionCounty__fontSize'] = block =>
         `fontSize: ${JSON.stringify(block.getFieldValue('fontSize'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionCounty__fontSizeMetadata'] = block => {
         let out = `fontSizeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionCounty__formOrder'] = block =>
         `formOrder: ${JSON.stringify(block.getFieldValue('formOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionCounty__formOrderMetadata'] = block => {
         let out = `formOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionCounty__formPageLabel'] = block =>
         `formPageLabel: ${JSON.stringify(block.getFieldValue('formPageLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionCounty__formPageLabelMetadata'] = block => {
         let out = `formPageLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionCounty__formPageNumber'] = block =>
         `formPageNumber: ${JSON.stringify(block.getFieldValue('formPageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionCounty__formPageNumberMetadata'] = block => {
         let out = `formPageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionCounty__height'] = block =>
         `height: ${JSON.stringify(block.getFieldValue('height'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionCounty__heightMetadata'] = block => {
         let out = `heightMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionCounty__italic'] = block =>
         `italic: ${JSON.stringify(block.getFieldValue('italic'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionCounty__italicMetadata'] = block => {
         let out = `italicMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionCounty__locked'] = block =>
         `locked: ${JSON.stringify(block.getFieldValue('locked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionCounty__lockedMetadata'] = block => {
         let out = `lockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionCounty__maxLength'] = block =>
         `maxLength: ${JSON.stringify(block.getFieldValue('maxLength'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionCounty__maxLengthMetadata'] = block => {
         let out = `maxLengthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionCounty__mergeFieldXml'] = block =>
         `mergeFieldXml: ${JSON.stringify(block.getFieldValue('mergeFieldXml'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['commissionCounty__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionCounty__nameMetadata'] = block => {
         let out = `nameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionCounty__originalValue'] = block =>
         `originalValue: ${JSON.stringify(block.getFieldValue('originalValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionCounty__originalValueMetadata'] = block => {
         let out = `originalValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionCounty__pageNumber'] = block =>
         `pageNumber: ${JSON.stringify(block.getFieldValue('pageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionCounty__pageNumberMetadata'] = block => {
         let out = `pageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionCounty__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['commissionCounty__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionCounty__recipientIdGuidMetadata'] = block => {
         let out = `recipientIdGuidMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['commissionCounty__recipientIdMetadata'] = block => {
         let out = `recipientIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionCounty__required'] = block =>
         `required: ${JSON.stringify(block.getFieldValue('required'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionCounty__requiredMetadata'] = block => {
         let out = `requiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionCounty__source'] = block =>
         `source: ${JSON.stringify(block.getFieldValue('source'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['commissionCounty__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionCounty__statusMetadata'] = block => {
         let out = `statusMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['commissionCounty__tabGroupLabels'] = block => {
         let out = `    tabGroupLabels: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'tabGroupLabel', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['commissionCounty_tabGroupLabels__tabGroupLabel'] = block =>
         `    ${JSON.stringify(block.getFieldValue('tabGroupLabel'))}${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['commissionCounty__tabGroupLabelsMetadata'] = block => {
         let out = `tabGroupLabelsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionCounty__tabId'] = block =>
         `tabId: ${JSON.stringify(block.getFieldValue('tabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionCounty__tabIdMetadata'] = block => {
         let out = `tabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionCounty__tabLabel'] = block =>
         `tabLabel: ${JSON.stringify(block.getFieldValue('tabLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionCounty__tabLabelMetadata'] = block => {
         let out = `tabLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionCounty__tabOrder'] = block =>
         `tabOrder: ${JSON.stringify(block.getFieldValue('tabOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionCounty__tabOrderMetadata'] = block => {
         let out = `tabOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionCounty__tabType'] = block =>
         `tabType: ${JSON.stringify(block.getFieldValue('tabType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionCounty__tabTypeMetadata'] = block => {
         let out = `tabTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionCounty__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionCounty__templateLockedMetadata'] = block => {
         let out = `templateLockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionCounty__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionCounty__templateRequiredMetadata'] = block => {
         let out = `templateRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['commissionCounty__toolTipMetadata'] = block => {
         let out = `toolTipMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionCounty__tooltip'] = block =>
         `tooltip: ${JSON.stringify(block.getFieldValue('tooltip'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['commissionCounty__underline'] = block =>
         `underline: ${JSON.stringify(block.getFieldValue('underline'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionCounty__underlineMetadata'] = block => {
         let out = `underlineMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionCounty__value'] = block =>
         `value: ${JSON.stringify(block.getFieldValue('value'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionCounty__valueMetadata'] = block => {
         let out = `valueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionCounty__width'] = block =>
         `width: ${JSON.stringify(block.getFieldValue('width'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionCounty__widthMetadata'] = block => {
         let out = `widthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionCounty__xPosition'] = block =>
         `xPosition: ${JSON.stringify(block.getFieldValue('xPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionCounty__xPositionMetadata'] = block => {
         let out = `xPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionCounty__yPosition'] = block =>
         `yPosition: ${JSON.stringify(block.getFieldValue('yPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionCounty__yPositionMetadata'] = block => {
         let out = `yPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

Blockly.JavaScript['commissionExpiration'] = block => 
  `\n.add_object("commissionExpiration", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['commissionExpiration__anchorAllowWhiteSpaceInCharacters'] = block =>
         `anchorAllowWhiteSpaceInCharacters: ${JSON.stringify(block.getFieldValue('anchorAllowWhiteSpaceInCharacters'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionExpiration__anchorAllowWhiteSpaceInCharactersMetadata'] = block => {
         let out = `anchorAllowWhiteSpaceInCharactersMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionExpiration__anchorCaseSensitive'] = block =>
         `anchorCaseSensitive: ${JSON.stringify(block.getFieldValue('anchorCaseSensitive'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionExpiration__anchorCaseSensitiveMetadata'] = block => {
         let out = `anchorCaseSensitiveMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionExpiration__anchorHorizontalAlignment'] = block =>
         `anchorHorizontalAlignment: ${JSON.stringify(block.getFieldValue('anchorHorizontalAlignment'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionExpiration__anchorHorizontalAlignmentMetadata'] = block => {
         let out = `anchorHorizontalAlignmentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionExpiration__anchorIgnoreIfNotPresent'] = block =>
         `anchorIgnoreIfNotPresent: ${JSON.stringify(block.getFieldValue('anchorIgnoreIfNotPresent'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionExpiration__anchorIgnoreIfNotPresentMetadata'] = block => {
         let out = `anchorIgnoreIfNotPresentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionExpiration__anchorMatchWholeWord'] = block =>
         `anchorMatchWholeWord: ${JSON.stringify(block.getFieldValue('anchorMatchWholeWord'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionExpiration__anchorMatchWholeWordMetadata'] = block => {
         let out = `anchorMatchWholeWordMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionExpiration__anchorString'] = block =>
         `anchorString: ${JSON.stringify(block.getFieldValue('anchorString'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionExpiration__anchorStringMetadata'] = block => {
         let out = `anchorStringMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionExpiration__anchorTabProcessorVersion'] = block =>
         `anchorTabProcessorVersion: ${JSON.stringify(block.getFieldValue('anchorTabProcessorVersion'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionExpiration__anchorTabProcessorVersionMetadata'] = block => {
         let out = `anchorTabProcessorVersionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionExpiration__anchorUnits'] = block =>
         `anchorUnits: ${JSON.stringify(block.getFieldValue('anchorUnits'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionExpiration__anchorUnitsMetadata'] = block => {
         let out = `anchorUnitsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionExpiration__anchorXOffset'] = block =>
         `anchorXOffset: ${JSON.stringify(block.getFieldValue('anchorXOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionExpiration__anchorXOffsetMetadata'] = block => {
         let out = `anchorXOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionExpiration__anchorYOffset'] = block =>
         `anchorYOffset: ${JSON.stringify(block.getFieldValue('anchorYOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionExpiration__anchorYOffsetMetadata'] = block => {
         let out = `anchorYOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionExpiration__bold'] = block =>
         `bold: ${JSON.stringify(block.getFieldValue('bold'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionExpiration__boldMetadata'] = block => {
         let out = `boldMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionExpiration__concealValueOnDocument'] = block =>
         `concealValueOnDocument: ${JSON.stringify(block.getFieldValue('concealValueOnDocument'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionExpiration__concealValueOnDocumentMetadata'] = block => {
         let out = `concealValueOnDocumentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionExpiration__conditionalParentLabel'] = block =>
         `conditionalParentLabel: ${JSON.stringify(block.getFieldValue('conditionalParentLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionExpiration__conditionalParentLabelMetadata'] = block => {
         let out = `conditionalParentLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionExpiration__conditionalParentValue'] = block =>
         `conditionalParentValue: ${JSON.stringify(block.getFieldValue('conditionalParentValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionExpiration__conditionalParentValueMetadata'] = block => {
         let out = `conditionalParentValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionExpiration__customTabId'] = block =>
         `customTabId: ${JSON.stringify(block.getFieldValue('customTabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionExpiration__customTabIdMetadata'] = block => {
         let out = `customTabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionExpiration__disableAutoSize'] = block =>
         `disableAutoSize: ${JSON.stringify(block.getFieldValue('disableAutoSize'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionExpiration__disableAutoSizeMetadata'] = block => {
         let out = `disableAutoSizeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionExpiration__documentId'] = block =>
         `documentId: ${JSON.stringify(block.getFieldValue('documentId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionExpiration__documentIdMetadata'] = block => {
         let out = `documentIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionExpiration__font'] = block =>
         `font: ${JSON.stringify(block.getFieldValue('font'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['commissionExpiration__fontColor'] = block =>
         `fontColor: ${JSON.stringify(block.getFieldValue('fontColor'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionExpiration__fontColorMetadata'] = block => {
         let out = `fontColorMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['commissionExpiration__fontMetadata'] = block => {
         let out = `fontMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionExpiration__fontSize'] = block =>
         `fontSize: ${JSON.stringify(block.getFieldValue('fontSize'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionExpiration__fontSizeMetadata'] = block => {
         let out = `fontSizeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionExpiration__formOrder'] = block =>
         `formOrder: ${JSON.stringify(block.getFieldValue('formOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionExpiration__formOrderMetadata'] = block => {
         let out = `formOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionExpiration__formPageLabel'] = block =>
         `formPageLabel: ${JSON.stringify(block.getFieldValue('formPageLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionExpiration__formPageLabelMetadata'] = block => {
         let out = `formPageLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionExpiration__formPageNumber'] = block =>
         `formPageNumber: ${JSON.stringify(block.getFieldValue('formPageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionExpiration__formPageNumberMetadata'] = block => {
         let out = `formPageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionExpiration__height'] = block =>
         `height: ${JSON.stringify(block.getFieldValue('height'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionExpiration__heightMetadata'] = block => {
         let out = `heightMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionExpiration__italic'] = block =>
         `italic: ${JSON.stringify(block.getFieldValue('italic'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionExpiration__italicMetadata'] = block => {
         let out = `italicMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionExpiration__locked'] = block =>
         `locked: ${JSON.stringify(block.getFieldValue('locked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionExpiration__lockedMetadata'] = block => {
         let out = `lockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionExpiration__maxLength'] = block =>
         `maxLength: ${JSON.stringify(block.getFieldValue('maxLength'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionExpiration__maxLengthMetadata'] = block => {
         let out = `maxLengthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionExpiration__mergeFieldXml'] = block =>
         `mergeFieldXml: ${JSON.stringify(block.getFieldValue('mergeFieldXml'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['commissionExpiration__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionExpiration__nameMetadata'] = block => {
         let out = `nameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionExpiration__originalValue'] = block =>
         `originalValue: ${JSON.stringify(block.getFieldValue('originalValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionExpiration__originalValueMetadata'] = block => {
         let out = `originalValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionExpiration__pageNumber'] = block =>
         `pageNumber: ${JSON.stringify(block.getFieldValue('pageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionExpiration__pageNumberMetadata'] = block => {
         let out = `pageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionExpiration__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['commissionExpiration__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionExpiration__recipientIdGuidMetadata'] = block => {
         let out = `recipientIdGuidMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['commissionExpiration__recipientIdMetadata'] = block => {
         let out = `recipientIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionExpiration__required'] = block =>
         `required: ${JSON.stringify(block.getFieldValue('required'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionExpiration__requiredMetadata'] = block => {
         let out = `requiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionExpiration__source'] = block =>
         `source: ${JSON.stringify(block.getFieldValue('source'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['commissionExpiration__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionExpiration__statusMetadata'] = block => {
         let out = `statusMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['commissionExpiration__tabGroupLabels'] = block => {
         let out = `    tabGroupLabels: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'tabGroupLabel', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['commissionExpiration_tabGroupLabels__tabGroupLabel'] = block =>
         `    ${JSON.stringify(block.getFieldValue('tabGroupLabel'))}${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['commissionExpiration__tabGroupLabelsMetadata'] = block => {
         let out = `tabGroupLabelsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionExpiration__tabId'] = block =>
         `tabId: ${JSON.stringify(block.getFieldValue('tabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionExpiration__tabIdMetadata'] = block => {
         let out = `tabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionExpiration__tabLabel'] = block =>
         `tabLabel: ${JSON.stringify(block.getFieldValue('tabLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionExpiration__tabLabelMetadata'] = block => {
         let out = `tabLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionExpiration__tabOrder'] = block =>
         `tabOrder: ${JSON.stringify(block.getFieldValue('tabOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionExpiration__tabOrderMetadata'] = block => {
         let out = `tabOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionExpiration__tabType'] = block =>
         `tabType: ${JSON.stringify(block.getFieldValue('tabType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionExpiration__tabTypeMetadata'] = block => {
         let out = `tabTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionExpiration__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionExpiration__templateLockedMetadata'] = block => {
         let out = `templateLockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionExpiration__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionExpiration__templateRequiredMetadata'] = block => {
         let out = `templateRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['commissionExpiration__toolTipMetadata'] = block => {
         let out = `toolTipMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionExpiration__tooltip'] = block =>
         `tooltip: ${JSON.stringify(block.getFieldValue('tooltip'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['commissionExpiration__underline'] = block =>
         `underline: ${JSON.stringify(block.getFieldValue('underline'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionExpiration__underlineMetadata'] = block => {
         let out = `underlineMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionExpiration__value'] = block =>
         `value: ${JSON.stringify(block.getFieldValue('value'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionExpiration__valueMetadata'] = block => {
         let out = `valueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionExpiration__width'] = block =>
         `width: ${JSON.stringify(block.getFieldValue('width'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionExpiration__widthMetadata'] = block => {
         let out = `widthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionExpiration__xPosition'] = block =>
         `xPosition: ${JSON.stringify(block.getFieldValue('xPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionExpiration__xPositionMetadata'] = block => {
         let out = `xPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionExpiration__yPosition'] = block =>
         `yPosition: ${JSON.stringify(block.getFieldValue('yPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionExpiration__yPositionMetadata'] = block => {
         let out = `yPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

Blockly.JavaScript['commissionNumber'] = block => 
  `\n.add_object("commissionNumber", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['commissionNumber__anchorAllowWhiteSpaceInCharacters'] = block =>
         `anchorAllowWhiteSpaceInCharacters: ${JSON.stringify(block.getFieldValue('anchorAllowWhiteSpaceInCharacters'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionNumber__anchorAllowWhiteSpaceInCharactersMetadata'] = block => {
         let out = `anchorAllowWhiteSpaceInCharactersMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionNumber__anchorCaseSensitive'] = block =>
         `anchorCaseSensitive: ${JSON.stringify(block.getFieldValue('anchorCaseSensitive'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionNumber__anchorCaseSensitiveMetadata'] = block => {
         let out = `anchorCaseSensitiveMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionNumber__anchorHorizontalAlignment'] = block =>
         `anchorHorizontalAlignment: ${JSON.stringify(block.getFieldValue('anchorHorizontalAlignment'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionNumber__anchorHorizontalAlignmentMetadata'] = block => {
         let out = `anchorHorizontalAlignmentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionNumber__anchorIgnoreIfNotPresent'] = block =>
         `anchorIgnoreIfNotPresent: ${JSON.stringify(block.getFieldValue('anchorIgnoreIfNotPresent'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionNumber__anchorIgnoreIfNotPresentMetadata'] = block => {
         let out = `anchorIgnoreIfNotPresentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionNumber__anchorMatchWholeWord'] = block =>
         `anchorMatchWholeWord: ${JSON.stringify(block.getFieldValue('anchorMatchWholeWord'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionNumber__anchorMatchWholeWordMetadata'] = block => {
         let out = `anchorMatchWholeWordMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionNumber__anchorString'] = block =>
         `anchorString: ${JSON.stringify(block.getFieldValue('anchorString'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionNumber__anchorStringMetadata'] = block => {
         let out = `anchorStringMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionNumber__anchorTabProcessorVersion'] = block =>
         `anchorTabProcessorVersion: ${JSON.stringify(block.getFieldValue('anchorTabProcessorVersion'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionNumber__anchorTabProcessorVersionMetadata'] = block => {
         let out = `anchorTabProcessorVersionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionNumber__anchorUnits'] = block =>
         `anchorUnits: ${JSON.stringify(block.getFieldValue('anchorUnits'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionNumber__anchorUnitsMetadata'] = block => {
         let out = `anchorUnitsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionNumber__anchorXOffset'] = block =>
         `anchorXOffset: ${JSON.stringify(block.getFieldValue('anchorXOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionNumber__anchorXOffsetMetadata'] = block => {
         let out = `anchorXOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionNumber__anchorYOffset'] = block =>
         `anchorYOffset: ${JSON.stringify(block.getFieldValue('anchorYOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionNumber__anchorYOffsetMetadata'] = block => {
         let out = `anchorYOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionNumber__bold'] = block =>
         `bold: ${JSON.stringify(block.getFieldValue('bold'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionNumber__boldMetadata'] = block => {
         let out = `boldMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionNumber__concealValueOnDocument'] = block =>
         `concealValueOnDocument: ${JSON.stringify(block.getFieldValue('concealValueOnDocument'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionNumber__concealValueOnDocumentMetadata'] = block => {
         let out = `concealValueOnDocumentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionNumber__conditionalParentLabel'] = block =>
         `conditionalParentLabel: ${JSON.stringify(block.getFieldValue('conditionalParentLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionNumber__conditionalParentLabelMetadata'] = block => {
         let out = `conditionalParentLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionNumber__conditionalParentValue'] = block =>
         `conditionalParentValue: ${JSON.stringify(block.getFieldValue('conditionalParentValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionNumber__conditionalParentValueMetadata'] = block => {
         let out = `conditionalParentValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionNumber__customTabId'] = block =>
         `customTabId: ${JSON.stringify(block.getFieldValue('customTabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionNumber__customTabIdMetadata'] = block => {
         let out = `customTabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionNumber__disableAutoSize'] = block =>
         `disableAutoSize: ${JSON.stringify(block.getFieldValue('disableAutoSize'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionNumber__disableAutoSizeMetadata'] = block => {
         let out = `disableAutoSizeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionNumber__documentId'] = block =>
         `documentId: ${JSON.stringify(block.getFieldValue('documentId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionNumber__documentIdMetadata'] = block => {
         let out = `documentIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionNumber__font'] = block =>
         `font: ${JSON.stringify(block.getFieldValue('font'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['commissionNumber__fontColor'] = block =>
         `fontColor: ${JSON.stringify(block.getFieldValue('fontColor'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionNumber__fontColorMetadata'] = block => {
         let out = `fontColorMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['commissionNumber__fontMetadata'] = block => {
         let out = `fontMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionNumber__fontSize'] = block =>
         `fontSize: ${JSON.stringify(block.getFieldValue('fontSize'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionNumber__fontSizeMetadata'] = block => {
         let out = `fontSizeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionNumber__formOrder'] = block =>
         `formOrder: ${JSON.stringify(block.getFieldValue('formOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionNumber__formOrderMetadata'] = block => {
         let out = `formOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionNumber__formPageLabel'] = block =>
         `formPageLabel: ${JSON.stringify(block.getFieldValue('formPageLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionNumber__formPageLabelMetadata'] = block => {
         let out = `formPageLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionNumber__formPageNumber'] = block =>
         `formPageNumber: ${JSON.stringify(block.getFieldValue('formPageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionNumber__formPageNumberMetadata'] = block => {
         let out = `formPageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionNumber__height'] = block =>
         `height: ${JSON.stringify(block.getFieldValue('height'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionNumber__heightMetadata'] = block => {
         let out = `heightMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionNumber__italic'] = block =>
         `italic: ${JSON.stringify(block.getFieldValue('italic'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionNumber__italicMetadata'] = block => {
         let out = `italicMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionNumber__locked'] = block =>
         `locked: ${JSON.stringify(block.getFieldValue('locked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionNumber__lockedMetadata'] = block => {
         let out = `lockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionNumber__maxLength'] = block =>
         `maxLength: ${JSON.stringify(block.getFieldValue('maxLength'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionNumber__maxLengthMetadata'] = block => {
         let out = `maxLengthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionNumber__mergeFieldXml'] = block =>
         `mergeFieldXml: ${JSON.stringify(block.getFieldValue('mergeFieldXml'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['commissionNumber__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionNumber__nameMetadata'] = block => {
         let out = `nameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionNumber__originalValue'] = block =>
         `originalValue: ${JSON.stringify(block.getFieldValue('originalValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionNumber__originalValueMetadata'] = block => {
         let out = `originalValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionNumber__pageNumber'] = block =>
         `pageNumber: ${JSON.stringify(block.getFieldValue('pageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionNumber__pageNumberMetadata'] = block => {
         let out = `pageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionNumber__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['commissionNumber__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionNumber__recipientIdGuidMetadata'] = block => {
         let out = `recipientIdGuidMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['commissionNumber__recipientIdMetadata'] = block => {
         let out = `recipientIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionNumber__required'] = block =>
         `required: ${JSON.stringify(block.getFieldValue('required'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionNumber__requiredMetadata'] = block => {
         let out = `requiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionNumber__source'] = block =>
         `source: ${JSON.stringify(block.getFieldValue('source'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['commissionNumber__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionNumber__statusMetadata'] = block => {
         let out = `statusMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['commissionNumber__tabGroupLabels'] = block => {
         let out = `    tabGroupLabels: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'tabGroupLabel', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['commissionNumber_tabGroupLabels__tabGroupLabel'] = block =>
         `    ${JSON.stringify(block.getFieldValue('tabGroupLabel'))}${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['commissionNumber__tabGroupLabelsMetadata'] = block => {
         let out = `tabGroupLabelsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionNumber__tabId'] = block =>
         `tabId: ${JSON.stringify(block.getFieldValue('tabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionNumber__tabIdMetadata'] = block => {
         let out = `tabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionNumber__tabLabel'] = block =>
         `tabLabel: ${JSON.stringify(block.getFieldValue('tabLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionNumber__tabLabelMetadata'] = block => {
         let out = `tabLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionNumber__tabOrder'] = block =>
         `tabOrder: ${JSON.stringify(block.getFieldValue('tabOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionNumber__tabOrderMetadata'] = block => {
         let out = `tabOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionNumber__tabType'] = block =>
         `tabType: ${JSON.stringify(block.getFieldValue('tabType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionNumber__tabTypeMetadata'] = block => {
         let out = `tabTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionNumber__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionNumber__templateLockedMetadata'] = block => {
         let out = `templateLockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionNumber__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionNumber__templateRequiredMetadata'] = block => {
         let out = `templateRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['commissionNumber__toolTipMetadata'] = block => {
         let out = `toolTipMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionNumber__tooltip'] = block =>
         `tooltip: ${JSON.stringify(block.getFieldValue('tooltip'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['commissionNumber__underline'] = block =>
         `underline: ${JSON.stringify(block.getFieldValue('underline'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionNumber__underlineMetadata'] = block => {
         let out = `underlineMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionNumber__value'] = block =>
         `value: ${JSON.stringify(block.getFieldValue('value'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionNumber__valueMetadata'] = block => {
         let out = `valueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionNumber__width'] = block =>
         `width: ${JSON.stringify(block.getFieldValue('width'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionNumber__widthMetadata'] = block => {
         let out = `widthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionNumber__xPosition'] = block =>
         `xPosition: ${JSON.stringify(block.getFieldValue('xPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionNumber__xPositionMetadata'] = block => {
         let out = `xPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionNumber__yPosition'] = block =>
         `yPosition: ${JSON.stringify(block.getFieldValue('yPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionNumber__yPositionMetadata'] = block => {
         let out = `yPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

Blockly.JavaScript['commissionState'] = block => 
  `\n.add_object("commissionState", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['commissionState__anchorAllowWhiteSpaceInCharacters'] = block =>
         `anchorAllowWhiteSpaceInCharacters: ${JSON.stringify(block.getFieldValue('anchorAllowWhiteSpaceInCharacters'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionState__anchorAllowWhiteSpaceInCharactersMetadata'] = block => {
         let out = `anchorAllowWhiteSpaceInCharactersMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionState__anchorCaseSensitive'] = block =>
         `anchorCaseSensitive: ${JSON.stringify(block.getFieldValue('anchorCaseSensitive'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionState__anchorCaseSensitiveMetadata'] = block => {
         let out = `anchorCaseSensitiveMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionState__anchorHorizontalAlignment'] = block =>
         `anchorHorizontalAlignment: ${JSON.stringify(block.getFieldValue('anchorHorizontalAlignment'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionState__anchorHorizontalAlignmentMetadata'] = block => {
         let out = `anchorHorizontalAlignmentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionState__anchorIgnoreIfNotPresent'] = block =>
         `anchorIgnoreIfNotPresent: ${JSON.stringify(block.getFieldValue('anchorIgnoreIfNotPresent'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionState__anchorIgnoreIfNotPresentMetadata'] = block => {
         let out = `anchorIgnoreIfNotPresentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionState__anchorMatchWholeWord'] = block =>
         `anchorMatchWholeWord: ${JSON.stringify(block.getFieldValue('anchorMatchWholeWord'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionState__anchorMatchWholeWordMetadata'] = block => {
         let out = `anchorMatchWholeWordMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionState__anchorString'] = block =>
         `anchorString: ${JSON.stringify(block.getFieldValue('anchorString'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionState__anchorStringMetadata'] = block => {
         let out = `anchorStringMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionState__anchorTabProcessorVersion'] = block =>
         `anchorTabProcessorVersion: ${JSON.stringify(block.getFieldValue('anchorTabProcessorVersion'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionState__anchorTabProcessorVersionMetadata'] = block => {
         let out = `anchorTabProcessorVersionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionState__anchorUnits'] = block =>
         `anchorUnits: ${JSON.stringify(block.getFieldValue('anchorUnits'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionState__anchorUnitsMetadata'] = block => {
         let out = `anchorUnitsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionState__anchorXOffset'] = block =>
         `anchorXOffset: ${JSON.stringify(block.getFieldValue('anchorXOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionState__anchorXOffsetMetadata'] = block => {
         let out = `anchorXOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionState__anchorYOffset'] = block =>
         `anchorYOffset: ${JSON.stringify(block.getFieldValue('anchorYOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionState__anchorYOffsetMetadata'] = block => {
         let out = `anchorYOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionState__bold'] = block =>
         `bold: ${JSON.stringify(block.getFieldValue('bold'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionState__boldMetadata'] = block => {
         let out = `boldMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionState__concealValueOnDocument'] = block =>
         `concealValueOnDocument: ${JSON.stringify(block.getFieldValue('concealValueOnDocument'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionState__concealValueOnDocumentMetadata'] = block => {
         let out = `concealValueOnDocumentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionState__conditionalParentLabel'] = block =>
         `conditionalParentLabel: ${JSON.stringify(block.getFieldValue('conditionalParentLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionState__conditionalParentLabelMetadata'] = block => {
         let out = `conditionalParentLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionState__conditionalParentValue'] = block =>
         `conditionalParentValue: ${JSON.stringify(block.getFieldValue('conditionalParentValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionState__conditionalParentValueMetadata'] = block => {
         let out = `conditionalParentValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionState__customTabId'] = block =>
         `customTabId: ${JSON.stringify(block.getFieldValue('customTabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionState__customTabIdMetadata'] = block => {
         let out = `customTabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionState__disableAutoSize'] = block =>
         `disableAutoSize: ${JSON.stringify(block.getFieldValue('disableAutoSize'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionState__disableAutoSizeMetadata'] = block => {
         let out = `disableAutoSizeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionState__documentId'] = block =>
         `documentId: ${JSON.stringify(block.getFieldValue('documentId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionState__documentIdMetadata'] = block => {
         let out = `documentIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionState__font'] = block =>
         `font: ${JSON.stringify(block.getFieldValue('font'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['commissionState__fontColor'] = block =>
         `fontColor: ${JSON.stringify(block.getFieldValue('fontColor'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionState__fontColorMetadata'] = block => {
         let out = `fontColorMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['commissionState__fontMetadata'] = block => {
         let out = `fontMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionState__fontSize'] = block =>
         `fontSize: ${JSON.stringify(block.getFieldValue('fontSize'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionState__fontSizeMetadata'] = block => {
         let out = `fontSizeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionState__formOrder'] = block =>
         `formOrder: ${JSON.stringify(block.getFieldValue('formOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionState__formOrderMetadata'] = block => {
         let out = `formOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionState__formPageLabel'] = block =>
         `formPageLabel: ${JSON.stringify(block.getFieldValue('formPageLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionState__formPageLabelMetadata'] = block => {
         let out = `formPageLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionState__formPageNumber'] = block =>
         `formPageNumber: ${JSON.stringify(block.getFieldValue('formPageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionState__formPageNumberMetadata'] = block => {
         let out = `formPageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionState__height'] = block =>
         `height: ${JSON.stringify(block.getFieldValue('height'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionState__heightMetadata'] = block => {
         let out = `heightMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionState__italic'] = block =>
         `italic: ${JSON.stringify(block.getFieldValue('italic'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionState__italicMetadata'] = block => {
         let out = `italicMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionState__locked'] = block =>
         `locked: ${JSON.stringify(block.getFieldValue('locked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionState__lockedMetadata'] = block => {
         let out = `lockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionState__maxLength'] = block =>
         `maxLength: ${JSON.stringify(block.getFieldValue('maxLength'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionState__maxLengthMetadata'] = block => {
         let out = `maxLengthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionState__mergeFieldXml'] = block =>
         `mergeFieldXml: ${JSON.stringify(block.getFieldValue('mergeFieldXml'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['commissionState__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionState__nameMetadata'] = block => {
         let out = `nameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionState__originalValue'] = block =>
         `originalValue: ${JSON.stringify(block.getFieldValue('originalValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionState__originalValueMetadata'] = block => {
         let out = `originalValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionState__pageNumber'] = block =>
         `pageNumber: ${JSON.stringify(block.getFieldValue('pageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionState__pageNumberMetadata'] = block => {
         let out = `pageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionState__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['commissionState__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionState__recipientIdGuidMetadata'] = block => {
         let out = `recipientIdGuidMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['commissionState__recipientIdMetadata'] = block => {
         let out = `recipientIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionState__required'] = block =>
         `required: ${JSON.stringify(block.getFieldValue('required'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionState__requiredMetadata'] = block => {
         let out = `requiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionState__source'] = block =>
         `source: ${JSON.stringify(block.getFieldValue('source'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['commissionState__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionState__statusMetadata'] = block => {
         let out = `statusMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['commissionState__tabGroupLabels'] = block => {
         let out = `    tabGroupLabels: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'tabGroupLabel', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['commissionState_tabGroupLabels__tabGroupLabel'] = block =>
         `    ${JSON.stringify(block.getFieldValue('tabGroupLabel'))}${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['commissionState__tabGroupLabelsMetadata'] = block => {
         let out = `tabGroupLabelsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionState__tabId'] = block =>
         `tabId: ${JSON.stringify(block.getFieldValue('tabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionState__tabIdMetadata'] = block => {
         let out = `tabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionState__tabLabel'] = block =>
         `tabLabel: ${JSON.stringify(block.getFieldValue('tabLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionState__tabLabelMetadata'] = block => {
         let out = `tabLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionState__tabOrder'] = block =>
         `tabOrder: ${JSON.stringify(block.getFieldValue('tabOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionState__tabOrderMetadata'] = block => {
         let out = `tabOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionState__tabType'] = block =>
         `tabType: ${JSON.stringify(block.getFieldValue('tabType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionState__tabTypeMetadata'] = block => {
         let out = `tabTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionState__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionState__templateLockedMetadata'] = block => {
         let out = `templateLockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionState__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionState__templateRequiredMetadata'] = block => {
         let out = `templateRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['commissionState__toolTipMetadata'] = block => {
         let out = `toolTipMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionState__tooltip'] = block =>
         `tooltip: ${JSON.stringify(block.getFieldValue('tooltip'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['commissionState__underline'] = block =>
         `underline: ${JSON.stringify(block.getFieldValue('underline'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionState__underlineMetadata'] = block => {
         let out = `underlineMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionState__value'] = block =>
         `value: ${JSON.stringify(block.getFieldValue('value'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionState__valueMetadata'] = block => {
         let out = `valueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionState__width'] = block =>
         `width: ${JSON.stringify(block.getFieldValue('width'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionState__widthMetadata'] = block => {
         let out = `widthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionState__xPosition'] = block =>
         `xPosition: ${JSON.stringify(block.getFieldValue('xPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionState__xPositionMetadata'] = block => {
         let out = `xPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['commissionState__yPosition'] = block =>
         `yPosition: ${JSON.stringify(block.getFieldValue('yPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['commissionState__yPositionMetadata'] = block => {
         let out = `yPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

Blockly.JavaScript['company'] = block => 
  `\n.add_object("company", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['company__anchorAllowWhiteSpaceInCharacters'] = block =>
         `anchorAllowWhiteSpaceInCharacters: ${JSON.stringify(block.getFieldValue('anchorAllowWhiteSpaceInCharacters'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['company__anchorAllowWhiteSpaceInCharactersMetadata'] = block => {
         let out = `anchorAllowWhiteSpaceInCharactersMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['company__anchorCaseSensitive'] = block =>
         `anchorCaseSensitive: ${JSON.stringify(block.getFieldValue('anchorCaseSensitive'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['company__anchorCaseSensitiveMetadata'] = block => {
         let out = `anchorCaseSensitiveMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['company__anchorHorizontalAlignment'] = block =>
         `anchorHorizontalAlignment: ${JSON.stringify(block.getFieldValue('anchorHorizontalAlignment'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['company__anchorHorizontalAlignmentMetadata'] = block => {
         let out = `anchorHorizontalAlignmentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['company__anchorIgnoreIfNotPresent'] = block =>
         `anchorIgnoreIfNotPresent: ${JSON.stringify(block.getFieldValue('anchorIgnoreIfNotPresent'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['company__anchorIgnoreIfNotPresentMetadata'] = block => {
         let out = `anchorIgnoreIfNotPresentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['company__anchorMatchWholeWord'] = block =>
         `anchorMatchWholeWord: ${JSON.stringify(block.getFieldValue('anchorMatchWholeWord'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['company__anchorMatchWholeWordMetadata'] = block => {
         let out = `anchorMatchWholeWordMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['company__anchorString'] = block =>
         `anchorString: ${JSON.stringify(block.getFieldValue('anchorString'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['company__anchorStringMetadata'] = block => {
         let out = `anchorStringMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['company__anchorTabProcessorVersion'] = block =>
         `anchorTabProcessorVersion: ${JSON.stringify(block.getFieldValue('anchorTabProcessorVersion'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['company__anchorTabProcessorVersionMetadata'] = block => {
         let out = `anchorTabProcessorVersionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['company__anchorUnits'] = block =>
         `anchorUnits: ${JSON.stringify(block.getFieldValue('anchorUnits'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['company__anchorUnitsMetadata'] = block => {
         let out = `anchorUnitsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['company__anchorXOffset'] = block =>
         `anchorXOffset: ${JSON.stringify(block.getFieldValue('anchorXOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['company__anchorXOffsetMetadata'] = block => {
         let out = `anchorXOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['company__anchorYOffset'] = block =>
         `anchorYOffset: ${JSON.stringify(block.getFieldValue('anchorYOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['company__anchorYOffsetMetadata'] = block => {
         let out = `anchorYOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['company__bold'] = block =>
         `bold: ${JSON.stringify(block.getFieldValue('bold'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['company__boldMetadata'] = block => {
         let out = `boldMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['company__concealValueOnDocument'] = block =>
         `concealValueOnDocument: ${JSON.stringify(block.getFieldValue('concealValueOnDocument'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['company__concealValueOnDocumentMetadata'] = block => {
         let out = `concealValueOnDocumentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['company__conditionalParentLabel'] = block =>
         `conditionalParentLabel: ${JSON.stringify(block.getFieldValue('conditionalParentLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['company__conditionalParentLabelMetadata'] = block => {
         let out = `conditionalParentLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['company__conditionalParentValue'] = block =>
         `conditionalParentValue: ${JSON.stringify(block.getFieldValue('conditionalParentValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['company__conditionalParentValueMetadata'] = block => {
         let out = `conditionalParentValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['company__customTabId'] = block =>
         `customTabId: ${JSON.stringify(block.getFieldValue('customTabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['company__customTabIdMetadata'] = block => {
         let out = `customTabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['company__disableAutoSize'] = block =>
         `disableAutoSize: ${JSON.stringify(block.getFieldValue('disableAutoSize'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['company__disableAutoSizeMetadata'] = block => {
         let out = `disableAutoSizeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['company__documentId'] = block =>
         `documentId: ${JSON.stringify(block.getFieldValue('documentId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['company__documentIdMetadata'] = block => {
         let out = `documentIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['company__font'] = block =>
         `font: ${JSON.stringify(block.getFieldValue('font'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['company__fontColor'] = block =>
         `fontColor: ${JSON.stringify(block.getFieldValue('fontColor'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['company__fontColorMetadata'] = block => {
         let out = `fontColorMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['company__fontMetadata'] = block => {
         let out = `fontMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['company__fontSize'] = block =>
         `fontSize: ${JSON.stringify(block.getFieldValue('fontSize'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['company__fontSizeMetadata'] = block => {
         let out = `fontSizeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['company__formOrder'] = block =>
         `formOrder: ${JSON.stringify(block.getFieldValue('formOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['company__formOrderMetadata'] = block => {
         let out = `formOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['company__formPageLabel'] = block =>
         `formPageLabel: ${JSON.stringify(block.getFieldValue('formPageLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['company__formPageLabelMetadata'] = block => {
         let out = `formPageLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['company__formPageNumber'] = block =>
         `formPageNumber: ${JSON.stringify(block.getFieldValue('formPageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['company__formPageNumberMetadata'] = block => {
         let out = `formPageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['company__height'] = block =>
         `height: ${JSON.stringify(block.getFieldValue('height'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['company__heightMetadata'] = block => {
         let out = `heightMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['company__italic'] = block =>
         `italic: ${JSON.stringify(block.getFieldValue('italic'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['company__italicMetadata'] = block => {
         let out = `italicMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['company__locked'] = block =>
         `locked: ${JSON.stringify(block.getFieldValue('locked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['company__lockedMetadata'] = block => {
         let out = `lockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['company__maxLength'] = block =>
         `maxLength: ${JSON.stringify(block.getFieldValue('maxLength'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['company__maxLengthMetadata'] = block => {
         let out = `maxLengthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['company__mergeFieldXml'] = block =>
         `mergeFieldXml: ${JSON.stringify(block.getFieldValue('mergeFieldXml'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['company__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['company__nameMetadata'] = block => {
         let out = `nameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['company__originalValue'] = block =>
         `originalValue: ${JSON.stringify(block.getFieldValue('originalValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['company__originalValueMetadata'] = block => {
         let out = `originalValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['company__pageNumber'] = block =>
         `pageNumber: ${JSON.stringify(block.getFieldValue('pageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['company__pageNumberMetadata'] = block => {
         let out = `pageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['company__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['company__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['company__recipientIdGuidMetadata'] = block => {
         let out = `recipientIdGuidMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['company__recipientIdMetadata'] = block => {
         let out = `recipientIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['company__required'] = block =>
         `required: ${JSON.stringify(block.getFieldValue('required'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['company__requiredMetadata'] = block => {
         let out = `requiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['company__source'] = block =>
         `source: ${JSON.stringify(block.getFieldValue('source'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['company__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['company__statusMetadata'] = block => {
         let out = `statusMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['company__tabGroupLabels'] = block => {
         let out = `    tabGroupLabels: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'tabGroupLabel', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['company_tabGroupLabels__tabGroupLabel'] = block =>
         `    ${JSON.stringify(block.getFieldValue('tabGroupLabel'))}${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['company__tabGroupLabelsMetadata'] = block => {
         let out = `tabGroupLabelsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['company__tabId'] = block =>
         `tabId: ${JSON.stringify(block.getFieldValue('tabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['company__tabIdMetadata'] = block => {
         let out = `tabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['company__tabLabel'] = block =>
         `tabLabel: ${JSON.stringify(block.getFieldValue('tabLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['company__tabLabelMetadata'] = block => {
         let out = `tabLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['company__tabOrder'] = block =>
         `tabOrder: ${JSON.stringify(block.getFieldValue('tabOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['company__tabOrderMetadata'] = block => {
         let out = `tabOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['company__tabType'] = block =>
         `tabType: ${JSON.stringify(block.getFieldValue('tabType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['company__tabTypeMetadata'] = block => {
         let out = `tabTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['company__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['company__templateLockedMetadata'] = block => {
         let out = `templateLockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['company__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['company__templateRequiredMetadata'] = block => {
         let out = `templateRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['company__toolTipMetadata'] = block => {
         let out = `toolTipMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['company__tooltip'] = block =>
         `tooltip: ${JSON.stringify(block.getFieldValue('tooltip'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['company__underline'] = block =>
         `underline: ${JSON.stringify(block.getFieldValue('underline'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['company__underlineMetadata'] = block => {
         let out = `underlineMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['company__value'] = block =>
         `value: ${JSON.stringify(block.getFieldValue('value'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['company__valueMetadata'] = block => {
         let out = `valueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['company__width'] = block =>
         `width: ${JSON.stringify(block.getFieldValue('width'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['company__widthMetadata'] = block => {
         let out = `widthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['company__xPosition'] = block =>
         `xPosition: ${JSON.stringify(block.getFieldValue('xPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['company__xPositionMetadata'] = block => {
         let out = `xPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['company__yPosition'] = block =>
         `yPosition: ${JSON.stringify(block.getFieldValue('yPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['company__yPositionMetadata'] = block => {
         let out = `yPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

Blockly.JavaScript['compositeTemplate'] = block => 
  `\n.add_object("compositeTemplate", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['compositeTemplate__compositeTemplateId'] = block =>
         `compositeTemplateId: ${JSON.stringify(block.getFieldValue('compositeTemplateId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['compositeTemplate__pdfMetaDataTemplateSequence'] = block =>
         `pdfMetaDataTemplateSequence: ${JSON.stringify(block.getFieldValue('pdfMetaDataTemplateSequence'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['conditionalRecipientRule'] = block => 
  `\n.add_object("conditionalRecipientRule", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['conditionalRecipientRule__order'] = block =>
         `order: ${JSON.stringify(block.getFieldValue('order'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['conditionalRecipientRule__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['conditionalRecipientRuleCondition'] = block => 
  `\n.add_object("conditionalRecipientRuleCondition", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['conditionalRecipientRuleCondition__order'] = block =>
         `order: ${JSON.stringify(block.getFieldValue('order'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['conditionalRecipientRuleCondition__recipientLabel'] = block =>
         `recipientLabel: ${JSON.stringify(block.getFieldValue('recipientLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['conditionalRecipientRuleFilter'] = block => 
  `\n.add_object("conditionalRecipientRuleFilter", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['conditionalRecipientRuleFilter__operator'] = block =>
         `operator: ${JSON.stringify(block.getFieldValue('operator'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['conditionalRecipientRuleFilter__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['conditionalRecipientRuleFilter__scope'] = block =>
         `scope: ${JSON.stringify(block.getFieldValue('scope'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['conditionalRecipientRuleFilter__tabId'] = block =>
         `tabId: ${JSON.stringify(block.getFieldValue('tabId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['conditionalRecipientRuleFilter__tabLabel'] = block =>
         `tabLabel: ${JSON.stringify(block.getFieldValue('tabLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['conditionalRecipientRuleFilter__tabType'] = block =>
         `tabType: ${JSON.stringify(block.getFieldValue('tabType'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['conditionalRecipientRuleFilter__value'] = block =>
         `value: ${JSON.stringify(block.getFieldValue('value'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['connectEventData'] = block => 
  `\n.add_object("connectEventData", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['connectEventData__format'] = block =>
         `format: ${JSON.stringify(block.getFieldValue('format'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['connectEventData__includeData'] = block => {
         let out = `    includeData: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'includeDatum', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['connectEventData_includeData__includeDatum'] = block =>
         `    ${JSON.stringify(block.getFieldValue('includeDatum'))}${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['connectEventData__version'] = block =>
         `version: ${JSON.stringify(block.getFieldValue('version'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['CreateEnvelope__cdse_mode'] = block =>
         `\n.add_envQp("cdse_mode", ${JSON.stringify(block.getFieldValue('cdse_mode'))})`;
   Blockly.JavaScript['CreateEnvelope__change_routing_order'] = block =>
         `\n.add_envQp("change_routing_order", ${JSON.stringify(block.getFieldValue('change_routing_order'))})`;
   Blockly.JavaScript['CreateEnvelope__completed_documents_only'] = block =>
         `\n.add_envQp("completed_documents_only", ${JSON.stringify(block.getFieldValue('completed_documents_only'))})`;
   Blockly.JavaScript['CreateEnvelope__merge_roles_on_draft'] = block =>
         `\n.add_envQp("merge_roles_on_draft", ${JSON.stringify(block.getFieldValue('merge_roles_on_draft'))})`;
Blockly.JavaScript['currency'] = block => 
  `\n.add_object("currency", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['currency__anchorAllowWhiteSpaceInCharacters'] = block =>
         `anchorAllowWhiteSpaceInCharacters: ${JSON.stringify(block.getFieldValue('anchorAllowWhiteSpaceInCharacters'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['currency__anchorAllowWhiteSpaceInCharactersMetadata'] = block => {
         let out = `anchorAllowWhiteSpaceInCharactersMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['currency__anchorCaseSensitive'] = block =>
         `anchorCaseSensitive: ${JSON.stringify(block.getFieldValue('anchorCaseSensitive'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['currency__anchorCaseSensitiveMetadata'] = block => {
         let out = `anchorCaseSensitiveMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['currency__anchorHorizontalAlignment'] = block =>
         `anchorHorizontalAlignment: ${JSON.stringify(block.getFieldValue('anchorHorizontalAlignment'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['currency__anchorHorizontalAlignmentMetadata'] = block => {
         let out = `anchorHorizontalAlignmentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['currency__anchorIgnoreIfNotPresent'] = block =>
         `anchorIgnoreIfNotPresent: ${JSON.stringify(block.getFieldValue('anchorIgnoreIfNotPresent'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['currency__anchorIgnoreIfNotPresentMetadata'] = block => {
         let out = `anchorIgnoreIfNotPresentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['currency__anchorMatchWholeWord'] = block =>
         `anchorMatchWholeWord: ${JSON.stringify(block.getFieldValue('anchorMatchWholeWord'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['currency__anchorMatchWholeWordMetadata'] = block => {
         let out = `anchorMatchWholeWordMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['currency__anchorString'] = block =>
         `anchorString: ${JSON.stringify(block.getFieldValue('anchorString'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['currency__anchorStringMetadata'] = block => {
         let out = `anchorStringMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['currency__anchorTabProcessorVersion'] = block =>
         `anchorTabProcessorVersion: ${JSON.stringify(block.getFieldValue('anchorTabProcessorVersion'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['currency__anchorTabProcessorVersionMetadata'] = block => {
         let out = `anchorTabProcessorVersionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['currency__anchorUnits'] = block =>
         `anchorUnits: ${JSON.stringify(block.getFieldValue('anchorUnits'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['currency__anchorUnitsMetadata'] = block => {
         let out = `anchorUnitsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['currency__anchorXOffset'] = block =>
         `anchorXOffset: ${JSON.stringify(block.getFieldValue('anchorXOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['currency__anchorXOffsetMetadata'] = block => {
         let out = `anchorXOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['currency__anchorYOffset'] = block =>
         `anchorYOffset: ${JSON.stringify(block.getFieldValue('anchorYOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['currency__anchorYOffsetMetadata'] = block => {
         let out = `anchorYOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['currency__bold'] = block =>
         `bold: ${JSON.stringify(block.getFieldValue('bold'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['currency__boldMetadata'] = block => {
         let out = `boldMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['currency__concealValueOnDocument'] = block =>
         `concealValueOnDocument: ${JSON.stringify(block.getFieldValue('concealValueOnDocument'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['currency__concealValueOnDocumentMetadata'] = block => {
         let out = `concealValueOnDocumentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['currency__conditionalParentLabel'] = block =>
         `conditionalParentLabel: ${JSON.stringify(block.getFieldValue('conditionalParentLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['currency__conditionalParentLabelMetadata'] = block => {
         let out = `conditionalParentLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['currency__conditionalParentValue'] = block =>
         `conditionalParentValue: ${JSON.stringify(block.getFieldValue('conditionalParentValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['currency__conditionalParentValueMetadata'] = block => {
         let out = `conditionalParentValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['currency__customTabId'] = block =>
         `customTabId: ${JSON.stringify(block.getFieldValue('customTabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['currency__customTabIdMetadata'] = block => {
         let out = `customTabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['currency__disableAutoSize'] = block =>
         `disableAutoSize: ${JSON.stringify(block.getFieldValue('disableAutoSize'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['currency__disableAutoSizeMetadata'] = block => {
         let out = `disableAutoSizeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['currency__documentId'] = block =>
         `documentId: ${JSON.stringify(block.getFieldValue('documentId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['currency__documentIdMetadata'] = block => {
         let out = `documentIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['currency__font'] = block =>
         `font: ${JSON.stringify(block.getFieldValue('font'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['currency__fontColor'] = block =>
         `fontColor: ${JSON.stringify(block.getFieldValue('fontColor'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['currency__fontColorMetadata'] = block => {
         let out = `fontColorMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['currency__fontMetadata'] = block => {
         let out = `fontMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['currency__fontSize'] = block =>
         `fontSize: ${JSON.stringify(block.getFieldValue('fontSize'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['currency__fontSizeMetadata'] = block => {
         let out = `fontSizeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['currency__formOrder'] = block =>
         `formOrder: ${JSON.stringify(block.getFieldValue('formOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['currency__formOrderMetadata'] = block => {
         let out = `formOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['currency__formPageLabel'] = block =>
         `formPageLabel: ${JSON.stringify(block.getFieldValue('formPageLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['currency__formPageLabelMetadata'] = block => {
         let out = `formPageLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['currency__formPageNumber'] = block =>
         `formPageNumber: ${JSON.stringify(block.getFieldValue('formPageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['currency__formPageNumberMetadata'] = block => {
         let out = `formPageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['currency__height'] = block =>
         `height: ${JSON.stringify(block.getFieldValue('height'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['currency__heightMetadata'] = block => {
         let out = `heightMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['currency__italic'] = block =>
         `italic: ${JSON.stringify(block.getFieldValue('italic'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['currency__italicMetadata'] = block => {
         let out = `italicMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['currency__locked'] = block =>
         `locked: ${JSON.stringify(block.getFieldValue('locked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['currency__lockedMetadata'] = block => {
         let out = `lockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['currency__maxLength'] = block =>
         `maxLength: ${JSON.stringify(block.getFieldValue('maxLength'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['currency__maxLengthMetadata'] = block => {
         let out = `maxLengthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['currency__maxNumericalValue'] = block =>
         `maxNumericalValue: ${JSON.stringify(block.getFieldValue('maxNumericalValue'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['currency__mergeFieldXml'] = block =>
         `mergeFieldXml: ${JSON.stringify(block.getFieldValue('mergeFieldXml'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['currency__minNumericalValue'] = block =>
         `minNumericalValue: ${JSON.stringify(block.getFieldValue('minNumericalValue'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['currency__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['currency__nameMetadata'] = block => {
         let out = `nameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['currency__numericalValue'] = block =>
         `numericalValue: ${JSON.stringify(block.getFieldValue('numericalValue'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['currency__originalValue'] = block =>
         `originalValue: ${JSON.stringify(block.getFieldValue('originalValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['currency__originalValueMetadata'] = block => {
         let out = `originalValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['currency__pageNumber'] = block =>
         `pageNumber: ${JSON.stringify(block.getFieldValue('pageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['currency__pageNumberMetadata'] = block => {
         let out = `pageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['currency__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['currency__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['currency__recipientIdGuidMetadata'] = block => {
         let out = `recipientIdGuidMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['currency__recipientIdMetadata'] = block => {
         let out = `recipientIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['currency__requireAll'] = block =>
         `requireAll: ${JSON.stringify(block.getFieldValue('requireAll'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['currency__requireAllMetadata'] = block => {
         let out = `requireAllMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['currency__requireInitialOnSharedChange'] = block =>
         `requireInitialOnSharedChange: ${JSON.stringify(block.getFieldValue('requireInitialOnSharedChange'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['currency__requireInitialOnSharedChangeMetadata'] = block => {
         let out = `requireInitialOnSharedChangeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['currency__required'] = block =>
         `required: ${JSON.stringify(block.getFieldValue('required'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['currency__requiredMetadata'] = block => {
         let out = `requiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['currency__senderRequired'] = block =>
         `senderRequired: ${JSON.stringify(block.getFieldValue('senderRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['currency__senderRequiredMetadata'] = block => {
         let out = `senderRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['currency__shared'] = block =>
         `shared: ${JSON.stringify(block.getFieldValue('shared'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['currency__sharedMetadata'] = block => {
         let out = `sharedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['currency__source'] = block =>
         `source: ${JSON.stringify(block.getFieldValue('source'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['currency__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['currency__statusMetadata'] = block => {
         let out = `statusMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['currency__tabGroupLabels'] = block => {
         let out = `    tabGroupLabels: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'tabGroupLabel', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['currency_tabGroupLabels__tabGroupLabel'] = block =>
         `    ${JSON.stringify(block.getFieldValue('tabGroupLabel'))}${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['currency__tabGroupLabelsMetadata'] = block => {
         let out = `tabGroupLabelsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['currency__tabId'] = block =>
         `tabId: ${JSON.stringify(block.getFieldValue('tabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['currency__tabIdMetadata'] = block => {
         let out = `tabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['currency__tabLabel'] = block =>
         `tabLabel: ${JSON.stringify(block.getFieldValue('tabLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['currency__tabLabelMetadata'] = block => {
         let out = `tabLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['currency__tabOrder'] = block =>
         `tabOrder: ${JSON.stringify(block.getFieldValue('tabOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['currency__tabOrderMetadata'] = block => {
         let out = `tabOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['currency__tabType'] = block =>
         `tabType: ${JSON.stringify(block.getFieldValue('tabType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['currency__tabTypeMetadata'] = block => {
         let out = `tabTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['currency__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['currency__templateLockedMetadata'] = block => {
         let out = `templateLockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['currency__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['currency__templateRequiredMetadata'] = block => {
         let out = `templateRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['currency__toolTipMetadata'] = block => {
         let out = `toolTipMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['currency__tooltip'] = block =>
         `tooltip: ${JSON.stringify(block.getFieldValue('tooltip'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['currency__underline'] = block =>
         `underline: ${JSON.stringify(block.getFieldValue('underline'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['currency__underlineMetadata'] = block => {
         let out = `underlineMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['currency__value'] = block =>
         `value: ${JSON.stringify(block.getFieldValue('value'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['currency__valueMetadata'] = block => {
         let out = `valueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['currency__width'] = block =>
         `width: ${JSON.stringify(block.getFieldValue('width'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['currency__widthMetadata'] = block => {
         let out = `widthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['currency__xPosition'] = block =>
         `xPosition: ${JSON.stringify(block.getFieldValue('xPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['currency__xPositionMetadata'] = block => {
         let out = `xPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['currency__yPosition'] = block =>
         `yPosition: ${JSON.stringify(block.getFieldValue('yPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['currency__yPositionMetadata'] = block => {
         let out = `yPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

Blockly.JavaScript['date'] = block => 
  `\n.add_object("date", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['date__anchorAllowWhiteSpaceInCharacters'] = block =>
         `anchorAllowWhiteSpaceInCharacters: ${JSON.stringify(block.getFieldValue('anchorAllowWhiteSpaceInCharacters'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__anchorAllowWhiteSpaceInCharactersMetadata'] = block => {
         let out = `anchorAllowWhiteSpaceInCharactersMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['date__anchorCaseSensitive'] = block =>
         `anchorCaseSensitive: ${JSON.stringify(block.getFieldValue('anchorCaseSensitive'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__anchorCaseSensitiveMetadata'] = block => {
         let out = `anchorCaseSensitiveMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['date__anchorHorizontalAlignment'] = block =>
         `anchorHorizontalAlignment: ${JSON.stringify(block.getFieldValue('anchorHorizontalAlignment'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__anchorHorizontalAlignmentMetadata'] = block => {
         let out = `anchorHorizontalAlignmentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['date__anchorIgnoreIfNotPresent'] = block =>
         `anchorIgnoreIfNotPresent: ${JSON.stringify(block.getFieldValue('anchorIgnoreIfNotPresent'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__anchorIgnoreIfNotPresentMetadata'] = block => {
         let out = `anchorIgnoreIfNotPresentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['date__anchorMatchWholeWord'] = block =>
         `anchorMatchWholeWord: ${JSON.stringify(block.getFieldValue('anchorMatchWholeWord'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__anchorMatchWholeWordMetadata'] = block => {
         let out = `anchorMatchWholeWordMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['date__anchorString'] = block =>
         `anchorString: ${JSON.stringify(block.getFieldValue('anchorString'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__anchorStringMetadata'] = block => {
         let out = `anchorStringMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['date__anchorTabProcessorVersion'] = block =>
         `anchorTabProcessorVersion: ${JSON.stringify(block.getFieldValue('anchorTabProcessorVersion'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__anchorTabProcessorVersionMetadata'] = block => {
         let out = `anchorTabProcessorVersionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['date__anchorUnits'] = block =>
         `anchorUnits: ${JSON.stringify(block.getFieldValue('anchorUnits'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__anchorUnitsMetadata'] = block => {
         let out = `anchorUnitsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['date__anchorXOffset'] = block =>
         `anchorXOffset: ${JSON.stringify(block.getFieldValue('anchorXOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__anchorXOffsetMetadata'] = block => {
         let out = `anchorXOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['date__anchorYOffset'] = block =>
         `anchorYOffset: ${JSON.stringify(block.getFieldValue('anchorYOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__anchorYOffsetMetadata'] = block => {
         let out = `anchorYOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['date__bold'] = block =>
         `bold: ${JSON.stringify(block.getFieldValue('bold'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__boldMetadata'] = block => {
         let out = `boldMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['date__concealValueOnDocument'] = block =>
         `concealValueOnDocument: ${JSON.stringify(block.getFieldValue('concealValueOnDocument'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__concealValueOnDocumentMetadata'] = block => {
         let out = `concealValueOnDocumentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['date__conditionalParentLabel'] = block =>
         `conditionalParentLabel: ${JSON.stringify(block.getFieldValue('conditionalParentLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__conditionalParentLabelMetadata'] = block => {
         let out = `conditionalParentLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['date__conditionalParentValue'] = block =>
         `conditionalParentValue: ${JSON.stringify(block.getFieldValue('conditionalParentValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__conditionalParentValueMetadata'] = block => {
         let out = `conditionalParentValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['date__customTabId'] = block =>
         `customTabId: ${JSON.stringify(block.getFieldValue('customTabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__customTabIdMetadata'] = block => {
         let out = `customTabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['date__disableAutoSize'] = block =>
         `disableAutoSize: ${JSON.stringify(block.getFieldValue('disableAutoSize'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__disableAutoSizeMetadata'] = block => {
         let out = `disableAutoSizeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['date__documentId'] = block =>
         `documentId: ${JSON.stringify(block.getFieldValue('documentId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__documentIdMetadata'] = block => {
         let out = `documentIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['date__font'] = block =>
         `font: ${JSON.stringify(block.getFieldValue('font'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['date__fontColor'] = block =>
         `fontColor: ${JSON.stringify(block.getFieldValue('fontColor'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__fontColorMetadata'] = block => {
         let out = `fontColorMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['date__fontMetadata'] = block => {
         let out = `fontMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['date__fontSize'] = block =>
         `fontSize: ${JSON.stringify(block.getFieldValue('fontSize'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__fontSizeMetadata'] = block => {
         let out = `fontSizeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['date__formOrder'] = block =>
         `formOrder: ${JSON.stringify(block.getFieldValue('formOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__formOrderMetadata'] = block => {
         let out = `formOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['date__formPageLabel'] = block =>
         `formPageLabel: ${JSON.stringify(block.getFieldValue('formPageLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__formPageLabelMetadata'] = block => {
         let out = `formPageLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['date__formPageNumber'] = block =>
         `formPageNumber: ${JSON.stringify(block.getFieldValue('formPageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__formPageNumberMetadata'] = block => {
         let out = `formPageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['date__height'] = block =>
         `height: ${JSON.stringify(block.getFieldValue('height'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__heightMetadata'] = block => {
         let out = `heightMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['date__italic'] = block =>
         `italic: ${JSON.stringify(block.getFieldValue('italic'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__italicMetadata'] = block => {
         let out = `italicMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['date__locked'] = block =>
         `locked: ${JSON.stringify(block.getFieldValue('locked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__lockedMetadata'] = block => {
         let out = `lockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['date__maxLength'] = block =>
         `maxLength: ${JSON.stringify(block.getFieldValue('maxLength'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__maxLengthMetadata'] = block => {
         let out = `maxLengthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['date__mergeFieldXml'] = block =>
         `mergeFieldXml: ${JSON.stringify(block.getFieldValue('mergeFieldXml'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['date__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__nameMetadata'] = block => {
         let out = `nameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['date__originalValue'] = block =>
         `originalValue: ${JSON.stringify(block.getFieldValue('originalValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__originalValueMetadata'] = block => {
         let out = `originalValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['date__pageNumber'] = block =>
         `pageNumber: ${JSON.stringify(block.getFieldValue('pageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__pageNumberMetadata'] = block => {
         let out = `pageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['date__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['date__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__recipientIdGuidMetadata'] = block => {
         let out = `recipientIdGuidMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['date__recipientIdMetadata'] = block => {
         let out = `recipientIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['date__requireAll'] = block =>
         `requireAll: ${JSON.stringify(block.getFieldValue('requireAll'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__requireAllMetadata'] = block => {
         let out = `requireAllMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['date__requireInitialOnSharedChange'] = block =>
         `requireInitialOnSharedChange: ${JSON.stringify(block.getFieldValue('requireInitialOnSharedChange'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__requireInitialOnSharedChangeMetadata'] = block => {
         let out = `requireInitialOnSharedChangeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['date__required'] = block =>
         `required: ${JSON.stringify(block.getFieldValue('required'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__requiredMetadata'] = block => {
         let out = `requiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['date__senderRequired'] = block =>
         `senderRequired: ${JSON.stringify(block.getFieldValue('senderRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__senderRequiredMetadata'] = block => {
         let out = `senderRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['date__shareToRecipients'] = block =>
         `shareToRecipients: ${JSON.stringify(block.getFieldValue('shareToRecipients'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__shareToRecipientsMetadata'] = block => {
         let out = `shareToRecipientsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['date__shared'] = block =>
         `shared: ${JSON.stringify(block.getFieldValue('shared'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__sharedMetadata'] = block => {
         let out = `sharedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['date__source'] = block =>
         `source: ${JSON.stringify(block.getFieldValue('source'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['date__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__statusMetadata'] = block => {
         let out = `statusMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['date__tabGroupLabels'] = block => {
         let out = `    tabGroupLabels: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'tabGroupLabel', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['date_tabGroupLabels__tabGroupLabel'] = block =>
         `    ${JSON.stringify(block.getFieldValue('tabGroupLabel'))}${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['date__tabGroupLabelsMetadata'] = block => {
         let out = `tabGroupLabelsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['date__tabId'] = block =>
         `tabId: ${JSON.stringify(block.getFieldValue('tabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__tabIdMetadata'] = block => {
         let out = `tabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['date__tabLabel'] = block =>
         `tabLabel: ${JSON.stringify(block.getFieldValue('tabLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__tabLabelMetadata'] = block => {
         let out = `tabLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['date__tabOrder'] = block =>
         `tabOrder: ${JSON.stringify(block.getFieldValue('tabOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__tabOrderMetadata'] = block => {
         let out = `tabOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['date__tabType'] = block =>
         `tabType: ${JSON.stringify(block.getFieldValue('tabType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__tabTypeMetadata'] = block => {
         let out = `tabTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['date__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__templateLockedMetadata'] = block => {
         let out = `templateLockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['date__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__templateRequiredMetadata'] = block => {
         let out = `templateRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['date__toolTipMetadata'] = block => {
         let out = `toolTipMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['date__tooltip'] = block =>
         `tooltip: ${JSON.stringify(block.getFieldValue('tooltip'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['date__underline'] = block =>
         `underline: ${JSON.stringify(block.getFieldValue('underline'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__underlineMetadata'] = block => {
         let out = `underlineMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['date__validationMessage'] = block =>
         `validationMessage: ${JSON.stringify(block.getFieldValue('validationMessage'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__validationMessageMetadata'] = block => {
         let out = `validationMessageMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['date__validationPattern'] = block =>
         `validationPattern: ${JSON.stringify(block.getFieldValue('validationPattern'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__validationPatternMetadata'] = block => {
         let out = `validationPatternMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['date__value'] = block =>
         `value: ${JSON.stringify(block.getFieldValue('value'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__valueMetadata'] = block => {
         let out = `valueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['date__width'] = block =>
         `width: ${JSON.stringify(block.getFieldValue('width'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__widthMetadata'] = block => {
         let out = `widthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['date__xPosition'] = block =>
         `xPosition: ${JSON.stringify(block.getFieldValue('xPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__xPositionMetadata'] = block => {
         let out = `xPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['date__yPosition'] = block =>
         `yPosition: ${JSON.stringify(block.getFieldValue('yPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['date__yPositionMetadata'] = block => {
         let out = `yPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

Blockly.JavaScript['dateSigned'] = block => 
  `\n.add_object("dateSigned", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['dateSigned__anchorAllowWhiteSpaceInCharacters'] = block =>
         `anchorAllowWhiteSpaceInCharacters: ${JSON.stringify(block.getFieldValue('anchorAllowWhiteSpaceInCharacters'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['dateSigned__anchorAllowWhiteSpaceInCharactersMetadata'] = block => {
         let out = `anchorAllowWhiteSpaceInCharactersMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['dateSigned__anchorCaseSensitive'] = block =>
         `anchorCaseSensitive: ${JSON.stringify(block.getFieldValue('anchorCaseSensitive'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['dateSigned__anchorCaseSensitiveMetadata'] = block => {
         let out = `anchorCaseSensitiveMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['dateSigned__anchorHorizontalAlignment'] = block =>
         `anchorHorizontalAlignment: ${JSON.stringify(block.getFieldValue('anchorHorizontalAlignment'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['dateSigned__anchorHorizontalAlignmentMetadata'] = block => {
         let out = `anchorHorizontalAlignmentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['dateSigned__anchorIgnoreIfNotPresent'] = block =>
         `anchorIgnoreIfNotPresent: ${JSON.stringify(block.getFieldValue('anchorIgnoreIfNotPresent'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['dateSigned__anchorIgnoreIfNotPresentMetadata'] = block => {
         let out = `anchorIgnoreIfNotPresentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['dateSigned__anchorMatchWholeWord'] = block =>
         `anchorMatchWholeWord: ${JSON.stringify(block.getFieldValue('anchorMatchWholeWord'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['dateSigned__anchorMatchWholeWordMetadata'] = block => {
         let out = `anchorMatchWholeWordMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['dateSigned__anchorString'] = block =>
         `anchorString: ${JSON.stringify(block.getFieldValue('anchorString'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['dateSigned__anchorStringMetadata'] = block => {
         let out = `anchorStringMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['dateSigned__anchorTabProcessorVersion'] = block =>
         `anchorTabProcessorVersion: ${JSON.stringify(block.getFieldValue('anchorTabProcessorVersion'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['dateSigned__anchorTabProcessorVersionMetadata'] = block => {
         let out = `anchorTabProcessorVersionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['dateSigned__anchorUnits'] = block =>
         `anchorUnits: ${JSON.stringify(block.getFieldValue('anchorUnits'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['dateSigned__anchorUnitsMetadata'] = block => {
         let out = `anchorUnitsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['dateSigned__anchorXOffset'] = block =>
         `anchorXOffset: ${JSON.stringify(block.getFieldValue('anchorXOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['dateSigned__anchorXOffsetMetadata'] = block => {
         let out = `anchorXOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['dateSigned__anchorYOffset'] = block =>
         `anchorYOffset: ${JSON.stringify(block.getFieldValue('anchorYOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['dateSigned__anchorYOffsetMetadata'] = block => {
         let out = `anchorYOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['dateSigned__bold'] = block =>
         `bold: ${JSON.stringify(block.getFieldValue('bold'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['dateSigned__boldMetadata'] = block => {
         let out = `boldMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['dateSigned__conditionalParentLabel'] = block =>
         `conditionalParentLabel: ${JSON.stringify(block.getFieldValue('conditionalParentLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['dateSigned__conditionalParentLabelMetadata'] = block => {
         let out = `conditionalParentLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['dateSigned__conditionalParentValue'] = block =>
         `conditionalParentValue: ${JSON.stringify(block.getFieldValue('conditionalParentValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['dateSigned__conditionalParentValueMetadata'] = block => {
         let out = `conditionalParentValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['dateSigned__customTabId'] = block =>
         `customTabId: ${JSON.stringify(block.getFieldValue('customTabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['dateSigned__customTabIdMetadata'] = block => {
         let out = `customTabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['dateSigned__documentId'] = block =>
         `documentId: ${JSON.stringify(block.getFieldValue('documentId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['dateSigned__documentIdMetadata'] = block => {
         let out = `documentIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['dateSigned__font'] = block =>
         `font: ${JSON.stringify(block.getFieldValue('font'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['dateSigned__fontColor'] = block =>
         `fontColor: ${JSON.stringify(block.getFieldValue('fontColor'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['dateSigned__fontColorMetadata'] = block => {
         let out = `fontColorMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['dateSigned__fontMetadata'] = block => {
         let out = `fontMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['dateSigned__fontSize'] = block =>
         `fontSize: ${JSON.stringify(block.getFieldValue('fontSize'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['dateSigned__fontSizeMetadata'] = block => {
         let out = `fontSizeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['dateSigned__formOrder'] = block =>
         `formOrder: ${JSON.stringify(block.getFieldValue('formOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['dateSigned__formOrderMetadata'] = block => {
         let out = `formOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['dateSigned__formPageLabel'] = block =>
         `formPageLabel: ${JSON.stringify(block.getFieldValue('formPageLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['dateSigned__formPageLabelMetadata'] = block => {
         let out = `formPageLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['dateSigned__formPageNumber'] = block =>
         `formPageNumber: ${JSON.stringify(block.getFieldValue('formPageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['dateSigned__formPageNumberMetadata'] = block => {
         let out = `formPageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['dateSigned__height'] = block =>
         `height: ${JSON.stringify(block.getFieldValue('height'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['dateSigned__heightMetadata'] = block => {
         let out = `heightMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['dateSigned__italic'] = block =>
         `italic: ${JSON.stringify(block.getFieldValue('italic'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['dateSigned__italicMetadata'] = block => {
         let out = `italicMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['dateSigned__mergeFieldXml'] = block =>
         `mergeFieldXml: ${JSON.stringify(block.getFieldValue('mergeFieldXml'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['dateSigned__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['dateSigned__nameMetadata'] = block => {
         let out = `nameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['dateSigned__pageNumber'] = block =>
         `pageNumber: ${JSON.stringify(block.getFieldValue('pageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['dateSigned__pageNumberMetadata'] = block => {
         let out = `pageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['dateSigned__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['dateSigned__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['dateSigned__recipientIdGuidMetadata'] = block => {
         let out = `recipientIdGuidMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['dateSigned__recipientIdMetadata'] = block => {
         let out = `recipientIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['dateSigned__source'] = block =>
         `source: ${JSON.stringify(block.getFieldValue('source'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['dateSigned__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['dateSigned__statusMetadata'] = block => {
         let out = `statusMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['dateSigned__tabGroupLabels'] = block => {
         let out = `    tabGroupLabels: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'tabGroupLabel', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['dateSigned_tabGroupLabels__tabGroupLabel'] = block =>
         `    ${JSON.stringify(block.getFieldValue('tabGroupLabel'))}${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['dateSigned__tabGroupLabelsMetadata'] = block => {
         let out = `tabGroupLabelsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['dateSigned__tabId'] = block =>
         `tabId: ${JSON.stringify(block.getFieldValue('tabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['dateSigned__tabIdMetadata'] = block => {
         let out = `tabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['dateSigned__tabLabel'] = block =>
         `tabLabel: ${JSON.stringify(block.getFieldValue('tabLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['dateSigned__tabLabelMetadata'] = block => {
         let out = `tabLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['dateSigned__tabOrder'] = block =>
         `tabOrder: ${JSON.stringify(block.getFieldValue('tabOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['dateSigned__tabOrderMetadata'] = block => {
         let out = `tabOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['dateSigned__tabType'] = block =>
         `tabType: ${JSON.stringify(block.getFieldValue('tabType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['dateSigned__tabTypeMetadata'] = block => {
         let out = `tabTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['dateSigned__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['dateSigned__templateLockedMetadata'] = block => {
         let out = `templateLockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['dateSigned__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['dateSigned__templateRequiredMetadata'] = block => {
         let out = `templateRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['dateSigned__toolTipMetadata'] = block => {
         let out = `toolTipMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['dateSigned__tooltip'] = block =>
         `tooltip: ${JSON.stringify(block.getFieldValue('tooltip'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['dateSigned__underline'] = block =>
         `underline: ${JSON.stringify(block.getFieldValue('underline'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['dateSigned__underlineMetadata'] = block => {
         let out = `underlineMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['dateSigned__value'] = block =>
         `value: ${JSON.stringify(block.getFieldValue('value'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['dateSigned__valueMetadata'] = block => {
         let out = `valueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['dateSigned__width'] = block =>
         `width: ${JSON.stringify(block.getFieldValue('width'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['dateSigned__widthMetadata'] = block => {
         let out = `widthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['dateSigned__xPosition'] = block =>
         `xPosition: ${JSON.stringify(block.getFieldValue('xPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['dateSigned__xPositionMetadata'] = block => {
         let out = `xPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['dateSigned__yPosition'] = block =>
         `yPosition: ${JSON.stringify(block.getFieldValue('yPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['dateSigned__yPositionMetadata'] = block => {
         let out = `yPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

Blockly.JavaScript['dateStampProperties'] = block => 
  `\n.add_object("dateStampProperties", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['dateStampProperties__dateAreaHeight'] = block =>
         `dateAreaHeight: ${JSON.stringify(block.getFieldValue('dateAreaHeight'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['dateStampProperties__dateAreaWidth'] = block =>
         `dateAreaWidth: ${JSON.stringify(block.getFieldValue('dateAreaWidth'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['dateStampProperties__dateAreaX'] = block =>
         `dateAreaX: ${JSON.stringify(block.getFieldValue('dateAreaX'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['dateStampProperties__dateAreaY'] = block =>
         `dateAreaY: ${JSON.stringify(block.getFieldValue('dateAreaY'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['decline'] = block => 
  `\n.add_object("decline", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['decline__anchorAllowWhiteSpaceInCharacters'] = block =>
         `anchorAllowWhiteSpaceInCharacters: ${JSON.stringify(block.getFieldValue('anchorAllowWhiteSpaceInCharacters'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['decline__anchorAllowWhiteSpaceInCharactersMetadata'] = block => {
         let out = `anchorAllowWhiteSpaceInCharactersMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['decline__anchorCaseSensitive'] = block =>
         `anchorCaseSensitive: ${JSON.stringify(block.getFieldValue('anchorCaseSensitive'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['decline__anchorCaseSensitiveMetadata'] = block => {
         let out = `anchorCaseSensitiveMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['decline__anchorHorizontalAlignment'] = block =>
         `anchorHorizontalAlignment: ${JSON.stringify(block.getFieldValue('anchorHorizontalAlignment'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['decline__anchorHorizontalAlignmentMetadata'] = block => {
         let out = `anchorHorizontalAlignmentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['decline__anchorIgnoreIfNotPresent'] = block =>
         `anchorIgnoreIfNotPresent: ${JSON.stringify(block.getFieldValue('anchorIgnoreIfNotPresent'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['decline__anchorIgnoreIfNotPresentMetadata'] = block => {
         let out = `anchorIgnoreIfNotPresentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['decline__anchorMatchWholeWord'] = block =>
         `anchorMatchWholeWord: ${JSON.stringify(block.getFieldValue('anchorMatchWholeWord'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['decline__anchorMatchWholeWordMetadata'] = block => {
         let out = `anchorMatchWholeWordMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['decline__anchorString'] = block =>
         `anchorString: ${JSON.stringify(block.getFieldValue('anchorString'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['decline__anchorStringMetadata'] = block => {
         let out = `anchorStringMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['decline__anchorTabProcessorVersion'] = block =>
         `anchorTabProcessorVersion: ${JSON.stringify(block.getFieldValue('anchorTabProcessorVersion'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['decline__anchorTabProcessorVersionMetadata'] = block => {
         let out = `anchorTabProcessorVersionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['decline__anchorUnits'] = block =>
         `anchorUnits: ${JSON.stringify(block.getFieldValue('anchorUnits'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['decline__anchorUnitsMetadata'] = block => {
         let out = `anchorUnitsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['decline__anchorXOffset'] = block =>
         `anchorXOffset: ${JSON.stringify(block.getFieldValue('anchorXOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['decline__anchorXOffsetMetadata'] = block => {
         let out = `anchorXOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['decline__anchorYOffset'] = block =>
         `anchorYOffset: ${JSON.stringify(block.getFieldValue('anchorYOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['decline__anchorYOffsetMetadata'] = block => {
         let out = `anchorYOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['decline__bold'] = block =>
         `bold: ${JSON.stringify(block.getFieldValue('bold'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['decline__boldMetadata'] = block => {
         let out = `boldMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['decline__buttonText'] = block =>
         `buttonText: ${JSON.stringify(block.getFieldValue('buttonText'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['decline__buttonTextMetadata'] = block => {
         let out = `buttonTextMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['decline__conditionalParentLabel'] = block =>
         `conditionalParentLabel: ${JSON.stringify(block.getFieldValue('conditionalParentLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['decline__conditionalParentLabelMetadata'] = block => {
         let out = `conditionalParentLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['decline__conditionalParentValue'] = block =>
         `conditionalParentValue: ${JSON.stringify(block.getFieldValue('conditionalParentValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['decline__conditionalParentValueMetadata'] = block => {
         let out = `conditionalParentValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['decline__customTabId'] = block =>
         `customTabId: ${JSON.stringify(block.getFieldValue('customTabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['decline__customTabIdMetadata'] = block => {
         let out = `customTabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['decline__declineReason'] = block =>
         `declineReason: ${JSON.stringify(block.getFieldValue('declineReason'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['decline__declineReasonMetadata'] = block => {
         let out = `declineReasonMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['decline__documentId'] = block =>
         `documentId: ${JSON.stringify(block.getFieldValue('documentId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['decline__documentIdMetadata'] = block => {
         let out = `documentIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['decline__font'] = block =>
         `font: ${JSON.stringify(block.getFieldValue('font'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['decline__fontColor'] = block =>
         `fontColor: ${JSON.stringify(block.getFieldValue('fontColor'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['decline__fontColorMetadata'] = block => {
         let out = `fontColorMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['decline__fontMetadata'] = block => {
         let out = `fontMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['decline__fontSize'] = block =>
         `fontSize: ${JSON.stringify(block.getFieldValue('fontSize'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['decline__fontSizeMetadata'] = block => {
         let out = `fontSizeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['decline__formOrder'] = block =>
         `formOrder: ${JSON.stringify(block.getFieldValue('formOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['decline__formOrderMetadata'] = block => {
         let out = `formOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['decline__formPageLabel'] = block =>
         `formPageLabel: ${JSON.stringify(block.getFieldValue('formPageLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['decline__formPageLabelMetadata'] = block => {
         let out = `formPageLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['decline__formPageNumber'] = block =>
         `formPageNumber: ${JSON.stringify(block.getFieldValue('formPageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['decline__formPageNumberMetadata'] = block => {
         let out = `formPageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['decline__height'] = block =>
         `height: ${JSON.stringify(block.getFieldValue('height'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['decline__heightMetadata'] = block => {
         let out = `heightMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['decline__italic'] = block =>
         `italic: ${JSON.stringify(block.getFieldValue('italic'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['decline__italicMetadata'] = block => {
         let out = `italicMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['decline__mergeFieldXml'] = block =>
         `mergeFieldXml: ${JSON.stringify(block.getFieldValue('mergeFieldXml'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['decline__pageNumber'] = block =>
         `pageNumber: ${JSON.stringify(block.getFieldValue('pageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['decline__pageNumberMetadata'] = block => {
         let out = `pageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['decline__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['decline__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['decline__recipientIdGuidMetadata'] = block => {
         let out = `recipientIdGuidMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['decline__recipientIdMetadata'] = block => {
         let out = `recipientIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['decline__source'] = block =>
         `source: ${JSON.stringify(block.getFieldValue('source'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['decline__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['decline__statusMetadata'] = block => {
         let out = `statusMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['decline__tabGroupLabels'] = block => {
         let out = `    tabGroupLabels: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'tabGroupLabel', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['decline_tabGroupLabels__tabGroupLabel'] = block =>
         `    ${JSON.stringify(block.getFieldValue('tabGroupLabel'))}${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['decline__tabGroupLabelsMetadata'] = block => {
         let out = `tabGroupLabelsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['decline__tabId'] = block =>
         `tabId: ${JSON.stringify(block.getFieldValue('tabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['decline__tabIdMetadata'] = block => {
         let out = `tabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['decline__tabLabel'] = block =>
         `tabLabel: ${JSON.stringify(block.getFieldValue('tabLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['decline__tabLabelMetadata'] = block => {
         let out = `tabLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['decline__tabOrder'] = block =>
         `tabOrder: ${JSON.stringify(block.getFieldValue('tabOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['decline__tabOrderMetadata'] = block => {
         let out = `tabOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['decline__tabType'] = block =>
         `tabType: ${JSON.stringify(block.getFieldValue('tabType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['decline__tabTypeMetadata'] = block => {
         let out = `tabTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['decline__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['decline__templateLockedMetadata'] = block => {
         let out = `templateLockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['decline__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['decline__templateRequiredMetadata'] = block => {
         let out = `templateRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['decline__toolTipMetadata'] = block => {
         let out = `toolTipMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['decline__tooltip'] = block =>
         `tooltip: ${JSON.stringify(block.getFieldValue('tooltip'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['decline__underline'] = block =>
         `underline: ${JSON.stringify(block.getFieldValue('underline'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['decline__underlineMetadata'] = block => {
         let out = `underlineMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['decline__width'] = block =>
         `width: ${JSON.stringify(block.getFieldValue('width'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['decline__widthMetadata'] = block => {
         let out = `widthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['decline__xPosition'] = block =>
         `xPosition: ${JSON.stringify(block.getFieldValue('xPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['decline__xPositionMetadata'] = block => {
         let out = `xPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['decline__yPosition'] = block =>
         `yPosition: ${JSON.stringify(block.getFieldValue('yPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['decline__yPositionMetadata'] = block => {
         let out = `yPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

Blockly.JavaScript['delayedRoutingApiModel'] = block => 
  `\n.add_object("delayedRoutingApiModel", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['delayedRoutingApiModel__resumeDate'] = block =>
         `resumeDate: ${JSON.stringify(block.getFieldValue('resumeDate'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['delayedRoutingApiModel__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['delegationInfo'] = block => 
  `\n.add_object("delegationInfo", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['delegationInfo__Email'] = block =>
         `Email: ${JSON.stringify(block.getFieldValue('Email'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['delegationInfo__Name'] = block =>
         `Name: ${JSON.stringify(block.getFieldValue('Name'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['delegationInfo__UserAuthorizationId'] = block =>
         `UserAuthorizationId: ${JSON.stringify(block.getFieldValue('UserAuthorizationId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['delegationInfo__UserId'] = block =>
         `UserId: ${JSON.stringify(block.getFieldValue('UserId'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['dobInformationInput'] = block => 
  `\n.add_object("dobInformationInput", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['dobInformationInput__dateOfBirth'] = block =>
         `dateOfBirth: ${JSON.stringify(block.getFieldValue('dateOfBirth'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['dobInformationInput__displayLevelCode'] = block =>
         `displayLevelCode: ${JSON.stringify(block.getFieldValue('displayLevelCode'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['dobInformationInput__receiveInResponse'] = block =>
         `receiveInResponse: ${JSON.stringify(block.getFieldValue('receiveInResponse'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['document'] = block =>{
  const filename = block.getFieldValue('filename')
      , doFilename = filename !== "NO_DOCUMENT"
      , filenameCode = `\n  filename: ${JSON.stringify(filename)},`;
  return (
  `\n.add_object("document", {${doFilename ? filenameCode : ""}
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`)};
   Blockly.JavaScript['document__applyAnchorTabs'] = block =>
         `applyAnchorTabs: ${JSON.stringify(block.getFieldValue('applyAnchorTabs'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['document__assignTabsToRecipientId'] = block =>
         `assignTabsToRecipientId: ${JSON.stringify(block.getFieldValue('assignTabsToRecipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['document__display'] = block =>
         `display: ${JSON.stringify(block.getFieldValue('display'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['document__documentBase64'] = block =>
         `documentBase64: ${JSON.stringify(block.getFieldValue('documentBase64'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['document__documentId'] = block =>
         `documentId: ${JSON.stringify(block.getFieldValue('documentId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['document__encryptedWithKeyManager'] = block =>
         `encryptedWithKeyManager: ${JSON.stringify(block.getFieldValue('encryptedWithKeyManager'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['document__fileExtension'] = block =>
         `fileExtension: ${JSON.stringify(block.getFieldValue('fileExtension'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['document__fileFormatHint'] = block =>
         `fileFormatHint: ${JSON.stringify(block.getFieldValue('fileFormatHint'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['document__includeInDownload'] = block =>
         `includeInDownload: ${JSON.stringify(block.getFieldValue('includeInDownload'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['document__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['document__order'] = block =>
         `order: ${JSON.stringify(block.getFieldValue('order'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['document__pages'] = block =>
         `pages: ${JSON.stringify(block.getFieldValue('pages'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['document__password'] = block =>
         `password: ${JSON.stringify(block.getFieldValue('password'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['document__pdfFormFieldOption'] = block =>
         `pdfFormFieldOption: ${JSON.stringify(block.getFieldValue('pdfFormFieldOption'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['document__remoteUrl'] = block =>
         `remoteUrl: ${JSON.stringify(block.getFieldValue('remoteUrl'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['document__signerMustAcknowledge'] = block =>
         `signerMustAcknowledge: ${JSON.stringify(block.getFieldValue('signerMustAcknowledge'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['document__signerMustAcknowledgeUseAccountDefault'] = block =>
         `signerMustAcknowledgeUseAccountDefault: ${block.getFieldValue('signerMustAcknowledgeUseAccountDefault')}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['document__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['document__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['document__transformPdfFields'] = block =>
         `transformPdfFields: ${JSON.stringify(block.getFieldValue('transformPdfFields'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['document__uri'] = block =>
         `uri: ${JSON.stringify(block.getFieldValue('uri'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['documentHtmlCollapsibleDisplaySettings'] = block => 
  `\n.add_object("documentHtmlCollapsibleDisplaySettings", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['documentHtmlCollapsibleDisplaySettings__arrowClosed'] = block =>
         `arrowClosed: ${JSON.stringify(block.getFieldValue('arrowClosed'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['documentHtmlCollapsibleDisplaySettings__arrowColor'] = block =>
         `arrowColor: ${JSON.stringify(block.getFieldValue('arrowColor'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['documentHtmlCollapsibleDisplaySettings__arrowLocation'] = block =>
         `arrowLocation: ${JSON.stringify(block.getFieldValue('arrowLocation'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['documentHtmlCollapsibleDisplaySettings__arrowOpen'] = block =>
         `arrowOpen: ${JSON.stringify(block.getFieldValue('arrowOpen'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['documentHtmlCollapsibleDisplaySettings__arrowSize'] = block =>
         `arrowSize: ${JSON.stringify(block.getFieldValue('arrowSize'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['documentHtmlCollapsibleDisplaySettings__arrowStyle'] = block =>
         `arrowStyle: ${JSON.stringify(block.getFieldValue('arrowStyle'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['documentHtmlCollapsibleDisplaySettings__containerStyle'] = block =>
         `containerStyle: ${JSON.stringify(block.getFieldValue('containerStyle'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['documentHtmlCollapsibleDisplaySettings__labelStyle'] = block =>
         `labelStyle: ${JSON.stringify(block.getFieldValue('labelStyle'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['documentHtmlCollapsibleDisplaySettings__onlyArrowIsClickable'] = block =>
         `onlyArrowIsClickable: ${block.getFieldValue('onlyArrowIsClickable')}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['documentHtmlCollapsibleDisplaySettings__outerLabelAndArrowStyle'] = block =>
         `outerLabelAndArrowStyle: ${JSON.stringify(block.getFieldValue('outerLabelAndArrowStyle'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['documentHtmlDefinition'] = block => 
  `\n.add_object("documentHtmlDefinition", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['documentHtmlDefinition__displayAnchorPrefix'] = block =>
         `displayAnchorPrefix: ${JSON.stringify(block.getFieldValue('displayAnchorPrefix'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['documentHtmlDefinition__displayOrder'] = block =>
         `displayOrder: ${JSON.stringify(block.getFieldValue('displayOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['documentHtmlDefinition__displayPageNumber'] = block =>
         `displayPageNumber: ${JSON.stringify(block.getFieldValue('displayPageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['documentHtmlDefinition__documentGuid'] = block =>
         `documentGuid: ${JSON.stringify(block.getFieldValue('documentGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['documentHtmlDefinition__documentId'] = block =>
         `documentId: ${JSON.stringify(block.getFieldValue('documentId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['documentHtmlDefinition__headerLabel'] = block =>
         `headerLabel: ${JSON.stringify(block.getFieldValue('headerLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['documentHtmlDefinition__maxScreenWidth'] = block =>
         `maxScreenWidth: ${JSON.stringify(block.getFieldValue('maxScreenWidth'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['documentHtmlDefinition__removeEmptyTags'] = block =>
         `removeEmptyTags: ${JSON.stringify(block.getFieldValue('removeEmptyTags'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['documentHtmlDefinition__showMobileOptimizedToggle'] = block =>
         `showMobileOptimizedToggle: ${JSON.stringify(block.getFieldValue('showMobileOptimizedToggle'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['documentHtmlDefinition__source'] = block =>
         `source: ${JSON.stringify(block.getFieldValue('source'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['documentHtmlDisplayAnchor'] = block => 
  `\n.add_object("documentHtmlDisplayAnchor", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['documentHtmlDisplayAnchor__caseSensitive'] = block =>
         `caseSensitive: ${block.getFieldValue('caseSensitive')}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['documentHtmlDisplayAnchor__endAnchor'] = block =>
         `endAnchor: ${JSON.stringify(block.getFieldValue('endAnchor'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['documentHtmlDisplayAnchor__removeEndAnchor'] = block =>
         `removeEndAnchor: ${block.getFieldValue('removeEndAnchor')}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['documentHtmlDisplayAnchor__removeStartAnchor'] = block =>
         `removeStartAnchor: ${block.getFieldValue('removeStartAnchor')}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['documentHtmlDisplayAnchor__startAnchor'] = block =>
         `startAnchor: ${JSON.stringify(block.getFieldValue('startAnchor'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['documentHtmlDisplaySettings'] = block => 
  `\n.add_object("documentHtmlDisplaySettings", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['documentHtmlDisplaySettings__cellStyle'] = block =>
         `cellStyle: ${JSON.stringify(block.getFieldValue('cellStyle'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['documentHtmlDisplaySettings__display'] = block =>
         `display: ${JSON.stringify(block.getFieldValue('display'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['documentHtmlDisplaySettings__displayLabel'] = block =>
         `displayLabel: ${JSON.stringify(block.getFieldValue('displayLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['documentHtmlDisplaySettings__displayOrder'] = block =>
         `displayOrder: ${block.getFieldValue('displayOrder')}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['documentHtmlDisplaySettings__displayPageNumber'] = block =>
         `displayPageNumber: ${block.getFieldValue('displayPageNumber')}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['documentHtmlDisplaySettings__hideLabelWhenOpened'] = block =>
         `hideLabelWhenOpened: ${block.getFieldValue('hideLabelWhenOpened')}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['documentHtmlDisplaySettings__inlineOuterStyle'] = block =>
         `inlineOuterStyle: ${JSON.stringify(block.getFieldValue('inlineOuterStyle'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['documentHtmlDisplaySettings__labelWhenOpened'] = block =>
         `labelWhenOpened: ${JSON.stringify(block.getFieldValue('labelWhenOpened'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['documentHtmlDisplaySettings__preLabel'] = block =>
         `preLabel: ${JSON.stringify(block.getFieldValue('preLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['documentHtmlDisplaySettings__scrollToTopWhenOpened'] = block =>
         `scrollToTopWhenOpened: ${block.getFieldValue('scrollToTopWhenOpened')}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['documentHtmlDisplaySettings__tableStyle'] = block =>
         `tableStyle: ${JSON.stringify(block.getFieldValue('tableStyle'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['documentVisibility'] = block => 
  `\n.add_object("documentVisibility", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['documentVisibility__documentId'] = block =>
         `documentId: ${JSON.stringify(block.getFieldValue('documentId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['documentVisibility__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['documentVisibility__rights'] = block =>
         `rights: ${JSON.stringify(block.getFieldValue('rights'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['documentVisibility__visible'] = block =>
         `visible: ${JSON.stringify(block.getFieldValue('visible'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['draw'] = block => 
  `\n.add_object("draw", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['draw__allowSignerUpload'] = block =>
         `allowSignerUpload: ${JSON.stringify(block.getFieldValue('allowSignerUpload'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['draw__anchorAllowWhiteSpaceInCharacters'] = block =>
         `anchorAllowWhiteSpaceInCharacters: ${JSON.stringify(block.getFieldValue('anchorAllowWhiteSpaceInCharacters'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['draw__anchorAllowWhiteSpaceInCharactersMetadata'] = block => {
         let out = `anchorAllowWhiteSpaceInCharactersMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['draw__anchorCaseSensitive'] = block =>
         `anchorCaseSensitive: ${JSON.stringify(block.getFieldValue('anchorCaseSensitive'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['draw__anchorCaseSensitiveMetadata'] = block => {
         let out = `anchorCaseSensitiveMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['draw__anchorHorizontalAlignment'] = block =>
         `anchorHorizontalAlignment: ${JSON.stringify(block.getFieldValue('anchorHorizontalAlignment'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['draw__anchorHorizontalAlignmentMetadata'] = block => {
         let out = `anchorHorizontalAlignmentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['draw__anchorIgnoreIfNotPresent'] = block =>
         `anchorIgnoreIfNotPresent: ${JSON.stringify(block.getFieldValue('anchorIgnoreIfNotPresent'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['draw__anchorIgnoreIfNotPresentMetadata'] = block => {
         let out = `anchorIgnoreIfNotPresentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['draw__anchorMatchWholeWord'] = block =>
         `anchorMatchWholeWord: ${JSON.stringify(block.getFieldValue('anchorMatchWholeWord'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['draw__anchorMatchWholeWordMetadata'] = block => {
         let out = `anchorMatchWholeWordMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['draw__anchorString'] = block =>
         `anchorString: ${JSON.stringify(block.getFieldValue('anchorString'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['draw__anchorStringMetadata'] = block => {
         let out = `anchorStringMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['draw__anchorTabProcessorVersion'] = block =>
         `anchorTabProcessorVersion: ${JSON.stringify(block.getFieldValue('anchorTabProcessorVersion'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['draw__anchorTabProcessorVersionMetadata'] = block => {
         let out = `anchorTabProcessorVersionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['draw__anchorUnits'] = block =>
         `anchorUnits: ${JSON.stringify(block.getFieldValue('anchorUnits'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['draw__anchorUnitsMetadata'] = block => {
         let out = `anchorUnitsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['draw__anchorXOffset'] = block =>
         `anchorXOffset: ${JSON.stringify(block.getFieldValue('anchorXOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['draw__anchorXOffsetMetadata'] = block => {
         let out = `anchorXOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['draw__anchorYOffset'] = block =>
         `anchorYOffset: ${JSON.stringify(block.getFieldValue('anchorYOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['draw__anchorYOffsetMetadata'] = block => {
         let out = `anchorYOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['draw__conditionalParentLabel'] = block =>
         `conditionalParentLabel: ${JSON.stringify(block.getFieldValue('conditionalParentLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['draw__conditionalParentLabelMetadata'] = block => {
         let out = `conditionalParentLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['draw__conditionalParentValue'] = block =>
         `conditionalParentValue: ${JSON.stringify(block.getFieldValue('conditionalParentValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['draw__conditionalParentValueMetadata'] = block => {
         let out = `conditionalParentValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['draw__customTabId'] = block =>
         `customTabId: ${JSON.stringify(block.getFieldValue('customTabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['draw__customTabIdMetadata'] = block => {
         let out = `customTabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['draw__documentId'] = block =>
         `documentId: ${JSON.stringify(block.getFieldValue('documentId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['draw__documentIdMetadata'] = block => {
         let out = `documentIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['draw__formOrder'] = block =>
         `formOrder: ${JSON.stringify(block.getFieldValue('formOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['draw__formOrderMetadata'] = block => {
         let out = `formOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['draw__formPageLabel'] = block =>
         `formPageLabel: ${JSON.stringify(block.getFieldValue('formPageLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['draw__formPageLabelMetadata'] = block => {
         let out = `formPageLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['draw__formPageNumber'] = block =>
         `formPageNumber: ${JSON.stringify(block.getFieldValue('formPageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['draw__formPageNumberMetadata'] = block => {
         let out = `formPageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['draw__height'] = block =>
         `height: ${JSON.stringify(block.getFieldValue('height'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['draw__heightMetadata'] = block => {
         let out = `heightMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['draw__locked'] = block =>
         `locked: ${JSON.stringify(block.getFieldValue('locked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['draw__lockedMetadata'] = block => {
         let out = `lockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['draw__mergeFieldXml'] = block =>
         `mergeFieldXml: ${JSON.stringify(block.getFieldValue('mergeFieldXml'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['draw__pageNumber'] = block =>
         `pageNumber: ${JSON.stringify(block.getFieldValue('pageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['draw__pageNumberMetadata'] = block => {
         let out = `pageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['draw__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['draw__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['draw__recipientIdGuidMetadata'] = block => {
         let out = `recipientIdGuidMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['draw__recipientIdMetadata'] = block => {
         let out = `recipientIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['draw__required'] = block =>
         `required: ${JSON.stringify(block.getFieldValue('required'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['draw__requiredMetadata'] = block => {
         let out = `requiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['draw__shared'] = block =>
         `shared: ${JSON.stringify(block.getFieldValue('shared'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['draw__sharedMetadata'] = block => {
         let out = `sharedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['draw__source'] = block =>
         `source: ${JSON.stringify(block.getFieldValue('source'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['draw__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['draw__statusMetadata'] = block => {
         let out = `statusMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['draw__tabGroupLabels'] = block => {
         let out = `    tabGroupLabels: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'tabGroupLabel', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['draw_tabGroupLabels__tabGroupLabel'] = block =>
         `    ${JSON.stringify(block.getFieldValue('tabGroupLabel'))}${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['draw__tabGroupLabelsMetadata'] = block => {
         let out = `tabGroupLabelsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['draw__tabId'] = block =>
         `tabId: ${JSON.stringify(block.getFieldValue('tabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['draw__tabIdMetadata'] = block => {
         let out = `tabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['draw__tabLabelMetadata'] = block => {
         let out = `tabLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['draw__tabOrder'] = block =>
         `tabOrder: ${JSON.stringify(block.getFieldValue('tabOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['draw__tabOrderMetadata'] = block => {
         let out = `tabOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['draw__tabType'] = block =>
         `tabType: ${JSON.stringify(block.getFieldValue('tabType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['draw__tabTypeMetadata'] = block => {
         let out = `tabTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['draw__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['draw__templateLockedMetadata'] = block => {
         let out = `templateLockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['draw__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['draw__templateRequiredMetadata'] = block => {
         let out = `templateRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['draw__toolTipMetadata'] = block => {
         let out = `toolTipMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['draw__tooltip'] = block =>
         `tooltip: ${JSON.stringify(block.getFieldValue('tooltip'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['draw__useBackgroundAsCanvas'] = block =>
         `useBackgroundAsCanvas: ${JSON.stringify(block.getFieldValue('useBackgroundAsCanvas'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['draw__width'] = block =>
         `width: ${JSON.stringify(block.getFieldValue('width'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['draw__widthMetadata'] = block => {
         let out = `widthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['draw__xPosition'] = block =>
         `xPosition: ${JSON.stringify(block.getFieldValue('xPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['draw__xPositionMetadata'] = block => {
         let out = `xPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['draw__yPosition'] = block =>
         `yPosition: ${JSON.stringify(block.getFieldValue('yPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['draw__yPositionMetadata'] = block => {
         let out = `yPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

Blockly.JavaScript['editor'] = block => 
  `\n.add_object("editor", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['editor__accessCode'] = block =>
         `accessCode: ${JSON.stringify(block.getFieldValue('accessCode'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['editor__accessCodeMetadata'] = block => {
         let out = `accessCodeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['editor__addAccessCodeToEmail'] = block =>
         `addAccessCodeToEmail: ${JSON.stringify(block.getFieldValue('addAccessCodeToEmail'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['editor__allowSystemOverrideForLockedRecipient'] = block =>
         `allowSystemOverrideForLockedRecipient: ${JSON.stringify(block.getFieldValue('allowSystemOverrideForLockedRecipient'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['editor__autoRespondedReason'] = block =>
         `autoRespondedReason: ${JSON.stringify(block.getFieldValue('autoRespondedReason'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['editor__clientUserId'] = block =>
         `clientUserId: ${JSON.stringify(block.getFieldValue('clientUserId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['editor__completedCount'] = block =>
         `completedCount: ${JSON.stringify(block.getFieldValue('completedCount'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['editor__customFields'] = block => {
         let out = `    customFields: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'customField', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['editor_customFields__customField'] = block =>
         `    ${JSON.stringify(block.getFieldValue('customField'))}${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['editor__declinedDateTime'] = block =>
         `declinedDateTime: ${JSON.stringify(block.getFieldValue('declinedDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['editor__declinedReason'] = block =>
         `declinedReason: ${JSON.stringify(block.getFieldValue('declinedReason'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['editor__deliveredDateTime'] = block =>
         `deliveredDateTime: ${JSON.stringify(block.getFieldValue('deliveredDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['editor__deliveryMethod'] = block =>
         `deliveryMethod: ${JSON.stringify(block.getFieldValue('deliveryMethod'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['editor__deliveryMethodMetadata'] = block => {
         let out = `deliveryMethodMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['editor__designatorId'] = block =>
         `designatorId: ${JSON.stringify(block.getFieldValue('designatorId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['editor__designatorIdGuid'] = block =>
         `designatorIdGuid: ${JSON.stringify(block.getFieldValue('designatorIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['editor__email'] = block =>
         `email: ${JSON.stringify(block.getFieldValue('email'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['editor__emailMetadata'] = block => {
         let out = `emailMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['editor__emailRecipientPostSigningURL'] = block =>
         `emailRecipientPostSigningURL: ${JSON.stringify(block.getFieldValue('emailRecipientPostSigningURL'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['editor__embeddedRecipientStartURL'] = block =>
         `embeddedRecipientStartURL: ${JSON.stringify(block.getFieldValue('embeddedRecipientStartURL'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['editor__faxNumber'] = block =>
         `faxNumber: ${JSON.stringify(block.getFieldValue('faxNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['editor__faxNumberMetadata'] = block => {
         let out = `faxNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['editor__firstName'] = block =>
         `firstName: ${JSON.stringify(block.getFieldValue('firstName'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['editor__firstNameMetadata'] = block => {
         let out = `firstNameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['editor__fullName'] = block =>
         `fullName: ${JSON.stringify(block.getFieldValue('fullName'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['editor__fullNameMetadata'] = block => {
         let out = `fullNameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['editor__idCheckConfigurationName'] = block =>
         `idCheckConfigurationName: ${JSON.stringify(block.getFieldValue('idCheckConfigurationName'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['editor__idCheckConfigurationNameMetadata'] = block => {
         let out = `idCheckConfigurationNameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['editor__inheritEmailNotificationConfiguration'] = block =>
         `inheritEmailNotificationConfiguration: ${JSON.stringify(block.getFieldValue('inheritEmailNotificationConfiguration'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['editor__lastName'] = block =>
         `lastName: ${JSON.stringify(block.getFieldValue('lastName'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['editor__lastNameMetadata'] = block => {
         let out = `lastNameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['editor__lockedRecipientPhoneAuthEditable'] = block =>
         `lockedRecipientPhoneAuthEditable: ${JSON.stringify(block.getFieldValue('lockedRecipientPhoneAuthEditable'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['editor__lockedRecipientSmsEditable'] = block =>
         `lockedRecipientSmsEditable: ${JSON.stringify(block.getFieldValue('lockedRecipientSmsEditable'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['editor__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['editor__nameMetadata'] = block => {
         let out = `nameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['editor__note'] = block =>
         `note: ${JSON.stringify(block.getFieldValue('note'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['editor__noteMetadata'] = block => {
         let out = `noteMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['editor__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['editor__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['editor__recipientType'] = block =>
         `recipientType: ${JSON.stringify(block.getFieldValue('recipientType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['editor__recipientTypeMetadata'] = block => {
         let out = `recipientTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['editor__requireIdLookup'] = block =>
         `requireIdLookup: ${JSON.stringify(block.getFieldValue('requireIdLookup'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['editor__requireIdLookupMetadata'] = block => {
         let out = `requireIdLookupMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['editor__roleName'] = block =>
         `roleName: ${JSON.stringify(block.getFieldValue('roleName'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['editor__routingOrder'] = block =>
         `routingOrder: ${JSON.stringify(block.getFieldValue('routingOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['editor__routingOrderMetadata'] = block => {
         let out = `routingOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['editor__sentDateTime'] = block =>
         `sentDateTime: ${JSON.stringify(block.getFieldValue('sentDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['editor__signedDateTime'] = block =>
         `signedDateTime: ${JSON.stringify(block.getFieldValue('signedDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['editor__signingGroupId'] = block =>
         `signingGroupId: ${JSON.stringify(block.getFieldValue('signingGroupId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['editor__signingGroupIdMetadata'] = block => {
         let out = `signingGroupIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['editor__signingGroupName'] = block =>
         `signingGroupName: ${JSON.stringify(block.getFieldValue('signingGroupName'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['editor__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['editor__statusCode'] = block =>
         `statusCode: ${JSON.stringify(block.getFieldValue('statusCode'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['editor__suppressEmails'] = block =>
         `suppressEmails: ${JSON.stringify(block.getFieldValue('suppressEmails'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['editor__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['editor__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['editor__totalTabCount'] = block =>
         `totalTabCount: ${JSON.stringify(block.getFieldValue('totalTabCount'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['editor__userId'] = block =>
         `userId: ${JSON.stringify(block.getFieldValue('userId'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['email'] = block => 
  `\n.add_object("email", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['email__anchorAllowWhiteSpaceInCharacters'] = block =>
         `anchorAllowWhiteSpaceInCharacters: ${JSON.stringify(block.getFieldValue('anchorAllowWhiteSpaceInCharacters'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__anchorAllowWhiteSpaceInCharactersMetadata'] = block => {
         let out = `anchorAllowWhiteSpaceInCharactersMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['email__anchorCaseSensitive'] = block =>
         `anchorCaseSensitive: ${JSON.stringify(block.getFieldValue('anchorCaseSensitive'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__anchorCaseSensitiveMetadata'] = block => {
         let out = `anchorCaseSensitiveMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['email__anchorHorizontalAlignment'] = block =>
         `anchorHorizontalAlignment: ${JSON.stringify(block.getFieldValue('anchorHorizontalAlignment'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__anchorHorizontalAlignmentMetadata'] = block => {
         let out = `anchorHorizontalAlignmentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['email__anchorIgnoreIfNotPresent'] = block =>
         `anchorIgnoreIfNotPresent: ${JSON.stringify(block.getFieldValue('anchorIgnoreIfNotPresent'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__anchorIgnoreIfNotPresentMetadata'] = block => {
         let out = `anchorIgnoreIfNotPresentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['email__anchorMatchWholeWord'] = block =>
         `anchorMatchWholeWord: ${JSON.stringify(block.getFieldValue('anchorMatchWholeWord'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__anchorMatchWholeWordMetadata'] = block => {
         let out = `anchorMatchWholeWordMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['email__anchorString'] = block =>
         `anchorString: ${JSON.stringify(block.getFieldValue('anchorString'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__anchorStringMetadata'] = block => {
         let out = `anchorStringMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['email__anchorTabProcessorVersion'] = block =>
         `anchorTabProcessorVersion: ${JSON.stringify(block.getFieldValue('anchorTabProcessorVersion'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__anchorTabProcessorVersionMetadata'] = block => {
         let out = `anchorTabProcessorVersionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['email__anchorUnits'] = block =>
         `anchorUnits: ${JSON.stringify(block.getFieldValue('anchorUnits'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__anchorUnitsMetadata'] = block => {
         let out = `anchorUnitsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['email__anchorXOffset'] = block =>
         `anchorXOffset: ${JSON.stringify(block.getFieldValue('anchorXOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__anchorXOffsetMetadata'] = block => {
         let out = `anchorXOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['email__anchorYOffset'] = block =>
         `anchorYOffset: ${JSON.stringify(block.getFieldValue('anchorYOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__anchorYOffsetMetadata'] = block => {
         let out = `anchorYOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['email__bold'] = block =>
         `bold: ${JSON.stringify(block.getFieldValue('bold'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__boldMetadata'] = block => {
         let out = `boldMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['email__concealValueOnDocument'] = block =>
         `concealValueOnDocument: ${JSON.stringify(block.getFieldValue('concealValueOnDocument'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__concealValueOnDocumentMetadata'] = block => {
         let out = `concealValueOnDocumentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['email__conditionalParentLabel'] = block =>
         `conditionalParentLabel: ${JSON.stringify(block.getFieldValue('conditionalParentLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__conditionalParentLabelMetadata'] = block => {
         let out = `conditionalParentLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['email__conditionalParentValue'] = block =>
         `conditionalParentValue: ${JSON.stringify(block.getFieldValue('conditionalParentValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__conditionalParentValueMetadata'] = block => {
         let out = `conditionalParentValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['email__customTabId'] = block =>
         `customTabId: ${JSON.stringify(block.getFieldValue('customTabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__customTabIdMetadata'] = block => {
         let out = `customTabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['email__disableAutoSize'] = block =>
         `disableAutoSize: ${JSON.stringify(block.getFieldValue('disableAutoSize'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__disableAutoSizeMetadata'] = block => {
         let out = `disableAutoSizeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['email__documentId'] = block =>
         `documentId: ${JSON.stringify(block.getFieldValue('documentId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__documentIdMetadata'] = block => {
         let out = `documentIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['email__font'] = block =>
         `font: ${JSON.stringify(block.getFieldValue('font'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['email__fontColor'] = block =>
         `fontColor: ${JSON.stringify(block.getFieldValue('fontColor'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__fontColorMetadata'] = block => {
         let out = `fontColorMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['email__fontMetadata'] = block => {
         let out = `fontMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['email__fontSize'] = block =>
         `fontSize: ${JSON.stringify(block.getFieldValue('fontSize'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__fontSizeMetadata'] = block => {
         let out = `fontSizeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['email__formOrder'] = block =>
         `formOrder: ${JSON.stringify(block.getFieldValue('formOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__formOrderMetadata'] = block => {
         let out = `formOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['email__formPageLabel'] = block =>
         `formPageLabel: ${JSON.stringify(block.getFieldValue('formPageLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__formPageLabelMetadata'] = block => {
         let out = `formPageLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['email__formPageNumber'] = block =>
         `formPageNumber: ${JSON.stringify(block.getFieldValue('formPageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__formPageNumberMetadata'] = block => {
         let out = `formPageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['email__height'] = block =>
         `height: ${JSON.stringify(block.getFieldValue('height'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__heightMetadata'] = block => {
         let out = `heightMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['email__italic'] = block =>
         `italic: ${JSON.stringify(block.getFieldValue('italic'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__italicMetadata'] = block => {
         let out = `italicMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['email__locked'] = block =>
         `locked: ${JSON.stringify(block.getFieldValue('locked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__lockedMetadata'] = block => {
         let out = `lockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['email__maxLength'] = block =>
         `maxLength: ${JSON.stringify(block.getFieldValue('maxLength'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__maxLengthMetadata'] = block => {
         let out = `maxLengthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['email__mergeFieldXml'] = block =>
         `mergeFieldXml: ${JSON.stringify(block.getFieldValue('mergeFieldXml'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['email__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__nameMetadata'] = block => {
         let out = `nameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['email__originalValue'] = block =>
         `originalValue: ${JSON.stringify(block.getFieldValue('originalValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__originalValueMetadata'] = block => {
         let out = `originalValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['email__pageNumber'] = block =>
         `pageNumber: ${JSON.stringify(block.getFieldValue('pageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__pageNumberMetadata'] = block => {
         let out = `pageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['email__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['email__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__recipientIdGuidMetadata'] = block => {
         let out = `recipientIdGuidMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['email__recipientIdMetadata'] = block => {
         let out = `recipientIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['email__requireAll'] = block =>
         `requireAll: ${JSON.stringify(block.getFieldValue('requireAll'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__requireAllMetadata'] = block => {
         let out = `requireAllMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['email__requireInitialOnSharedChange'] = block =>
         `requireInitialOnSharedChange: ${JSON.stringify(block.getFieldValue('requireInitialOnSharedChange'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__requireInitialOnSharedChangeMetadata'] = block => {
         let out = `requireInitialOnSharedChangeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['email__required'] = block =>
         `required: ${JSON.stringify(block.getFieldValue('required'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__requiredMetadata'] = block => {
         let out = `requiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['email__senderRequired'] = block =>
         `senderRequired: ${JSON.stringify(block.getFieldValue('senderRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__senderRequiredMetadata'] = block => {
         let out = `senderRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['email__shareToRecipients'] = block =>
         `shareToRecipients: ${JSON.stringify(block.getFieldValue('shareToRecipients'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__shareToRecipientsMetadata'] = block => {
         let out = `shareToRecipientsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['email__shared'] = block =>
         `shared: ${JSON.stringify(block.getFieldValue('shared'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__sharedMetadata'] = block => {
         let out = `sharedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['email__source'] = block =>
         `source: ${JSON.stringify(block.getFieldValue('source'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['email__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__statusMetadata'] = block => {
         let out = `statusMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['email__tabGroupLabels'] = block => {
         let out = `    tabGroupLabels: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'tabGroupLabel', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['email_tabGroupLabels__tabGroupLabel'] = block =>
         `    ${JSON.stringify(block.getFieldValue('tabGroupLabel'))}${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['email__tabGroupLabelsMetadata'] = block => {
         let out = `tabGroupLabelsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['email__tabId'] = block =>
         `tabId: ${JSON.stringify(block.getFieldValue('tabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__tabIdMetadata'] = block => {
         let out = `tabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['email__tabLabel'] = block =>
         `tabLabel: ${JSON.stringify(block.getFieldValue('tabLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__tabLabelMetadata'] = block => {
         let out = `tabLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['email__tabOrder'] = block =>
         `tabOrder: ${JSON.stringify(block.getFieldValue('tabOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__tabOrderMetadata'] = block => {
         let out = `tabOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['email__tabType'] = block =>
         `tabType: ${JSON.stringify(block.getFieldValue('tabType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__tabTypeMetadata'] = block => {
         let out = `tabTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['email__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__templateLockedMetadata'] = block => {
         let out = `templateLockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['email__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__templateRequiredMetadata'] = block => {
         let out = `templateRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['email__toolTipMetadata'] = block => {
         let out = `toolTipMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['email__tooltip'] = block =>
         `tooltip: ${JSON.stringify(block.getFieldValue('tooltip'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['email__underline'] = block =>
         `underline: ${JSON.stringify(block.getFieldValue('underline'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__underlineMetadata'] = block => {
         let out = `underlineMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['email__validationMessage'] = block =>
         `validationMessage: ${JSON.stringify(block.getFieldValue('validationMessage'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__validationMessageMetadata'] = block => {
         let out = `validationMessageMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['email__validationPattern'] = block =>
         `validationPattern: ${JSON.stringify(block.getFieldValue('validationPattern'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__validationPatternMetadata'] = block => {
         let out = `validationPatternMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['email__value'] = block =>
         `value: ${JSON.stringify(block.getFieldValue('value'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__valueMetadata'] = block => {
         let out = `valueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['email__width'] = block =>
         `width: ${JSON.stringify(block.getFieldValue('width'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__widthMetadata'] = block => {
         let out = `widthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['email__xPosition'] = block =>
         `xPosition: ${JSON.stringify(block.getFieldValue('xPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__xPositionMetadata'] = block => {
         let out = `xPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['email__yPosition'] = block =>
         `yPosition: ${JSON.stringify(block.getFieldValue('yPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['email__yPositionMetadata'] = block => {
         let out = `yPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

Blockly.JavaScript['emailAddress'] = block => 
  `\n.add_object("emailAddress", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['emailAddress__anchorAllowWhiteSpaceInCharacters'] = block =>
         `anchorAllowWhiteSpaceInCharacters: ${JSON.stringify(block.getFieldValue('anchorAllowWhiteSpaceInCharacters'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['emailAddress__anchorAllowWhiteSpaceInCharactersMetadata'] = block => {
         let out = `anchorAllowWhiteSpaceInCharactersMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['emailAddress__anchorCaseSensitive'] = block =>
         `anchorCaseSensitive: ${JSON.stringify(block.getFieldValue('anchorCaseSensitive'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['emailAddress__anchorCaseSensitiveMetadata'] = block => {
         let out = `anchorCaseSensitiveMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['emailAddress__anchorHorizontalAlignment'] = block =>
         `anchorHorizontalAlignment: ${JSON.stringify(block.getFieldValue('anchorHorizontalAlignment'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['emailAddress__anchorHorizontalAlignmentMetadata'] = block => {
         let out = `anchorHorizontalAlignmentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['emailAddress__anchorIgnoreIfNotPresent'] = block =>
         `anchorIgnoreIfNotPresent: ${JSON.stringify(block.getFieldValue('anchorIgnoreIfNotPresent'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['emailAddress__anchorIgnoreIfNotPresentMetadata'] = block => {
         let out = `anchorIgnoreIfNotPresentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['emailAddress__anchorMatchWholeWord'] = block =>
         `anchorMatchWholeWord: ${JSON.stringify(block.getFieldValue('anchorMatchWholeWord'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['emailAddress__anchorMatchWholeWordMetadata'] = block => {
         let out = `anchorMatchWholeWordMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['emailAddress__anchorString'] = block =>
         `anchorString: ${JSON.stringify(block.getFieldValue('anchorString'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['emailAddress__anchorStringMetadata'] = block => {
         let out = `anchorStringMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['emailAddress__anchorTabProcessorVersion'] = block =>
         `anchorTabProcessorVersion: ${JSON.stringify(block.getFieldValue('anchorTabProcessorVersion'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['emailAddress__anchorTabProcessorVersionMetadata'] = block => {
         let out = `anchorTabProcessorVersionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['emailAddress__anchorUnits'] = block =>
         `anchorUnits: ${JSON.stringify(block.getFieldValue('anchorUnits'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['emailAddress__anchorUnitsMetadata'] = block => {
         let out = `anchorUnitsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['emailAddress__anchorXOffset'] = block =>
         `anchorXOffset: ${JSON.stringify(block.getFieldValue('anchorXOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['emailAddress__anchorXOffsetMetadata'] = block => {
         let out = `anchorXOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['emailAddress__anchorYOffset'] = block =>
         `anchorYOffset: ${JSON.stringify(block.getFieldValue('anchorYOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['emailAddress__anchorYOffsetMetadata'] = block => {
         let out = `anchorYOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['emailAddress__bold'] = block =>
         `bold: ${JSON.stringify(block.getFieldValue('bold'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['emailAddress__boldMetadata'] = block => {
         let out = `boldMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['emailAddress__conditionalParentLabel'] = block =>
         `conditionalParentLabel: ${JSON.stringify(block.getFieldValue('conditionalParentLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['emailAddress__conditionalParentLabelMetadata'] = block => {
         let out = `conditionalParentLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['emailAddress__conditionalParentValue'] = block =>
         `conditionalParentValue: ${JSON.stringify(block.getFieldValue('conditionalParentValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['emailAddress__conditionalParentValueMetadata'] = block => {
         let out = `conditionalParentValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['emailAddress__customTabId'] = block =>
         `customTabId: ${JSON.stringify(block.getFieldValue('customTabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['emailAddress__customTabIdMetadata'] = block => {
         let out = `customTabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['emailAddress__documentId'] = block =>
         `documentId: ${JSON.stringify(block.getFieldValue('documentId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['emailAddress__documentIdMetadata'] = block => {
         let out = `documentIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['emailAddress__font'] = block =>
         `font: ${JSON.stringify(block.getFieldValue('font'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['emailAddress__fontColor'] = block =>
         `fontColor: ${JSON.stringify(block.getFieldValue('fontColor'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['emailAddress__fontColorMetadata'] = block => {
         let out = `fontColorMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['emailAddress__fontMetadata'] = block => {
         let out = `fontMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['emailAddress__fontSize'] = block =>
         `fontSize: ${JSON.stringify(block.getFieldValue('fontSize'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['emailAddress__fontSizeMetadata'] = block => {
         let out = `fontSizeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['emailAddress__formOrder'] = block =>
         `formOrder: ${JSON.stringify(block.getFieldValue('formOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['emailAddress__formOrderMetadata'] = block => {
         let out = `formOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['emailAddress__formPageLabel'] = block =>
         `formPageLabel: ${JSON.stringify(block.getFieldValue('formPageLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['emailAddress__formPageLabelMetadata'] = block => {
         let out = `formPageLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['emailAddress__formPageNumber'] = block =>
         `formPageNumber: ${JSON.stringify(block.getFieldValue('formPageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['emailAddress__formPageNumberMetadata'] = block => {
         let out = `formPageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['emailAddress__height'] = block =>
         `height: ${JSON.stringify(block.getFieldValue('height'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['emailAddress__heightMetadata'] = block => {
         let out = `heightMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['emailAddress__italic'] = block =>
         `italic: ${JSON.stringify(block.getFieldValue('italic'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['emailAddress__italicMetadata'] = block => {
         let out = `italicMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['emailAddress__mergeFieldXml'] = block =>
         `mergeFieldXml: ${JSON.stringify(block.getFieldValue('mergeFieldXml'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['emailAddress__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['emailAddress__nameMetadata'] = block => {
         let out = `nameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['emailAddress__pageNumber'] = block =>
         `pageNumber: ${JSON.stringify(block.getFieldValue('pageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['emailAddress__pageNumberMetadata'] = block => {
         let out = `pageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['emailAddress__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['emailAddress__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['emailAddress__recipientIdGuidMetadata'] = block => {
         let out = `recipientIdGuidMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['emailAddress__recipientIdMetadata'] = block => {
         let out = `recipientIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['emailAddress__source'] = block =>
         `source: ${JSON.stringify(block.getFieldValue('source'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['emailAddress__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['emailAddress__statusMetadata'] = block => {
         let out = `statusMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['emailAddress__tabGroupLabels'] = block => {
         let out = `    tabGroupLabels: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'tabGroupLabel', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['emailAddress_tabGroupLabels__tabGroupLabel'] = block =>
         `    ${JSON.stringify(block.getFieldValue('tabGroupLabel'))}${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['emailAddress__tabGroupLabelsMetadata'] = block => {
         let out = `tabGroupLabelsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['emailAddress__tabId'] = block =>
         `tabId: ${JSON.stringify(block.getFieldValue('tabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['emailAddress__tabIdMetadata'] = block => {
         let out = `tabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['emailAddress__tabLabel'] = block =>
         `tabLabel: ${JSON.stringify(block.getFieldValue('tabLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['emailAddress__tabLabelMetadata'] = block => {
         let out = `tabLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['emailAddress__tabOrder'] = block =>
         `tabOrder: ${JSON.stringify(block.getFieldValue('tabOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['emailAddress__tabOrderMetadata'] = block => {
         let out = `tabOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['emailAddress__tabType'] = block =>
         `tabType: ${JSON.stringify(block.getFieldValue('tabType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['emailAddress__tabTypeMetadata'] = block => {
         let out = `tabTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['emailAddress__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['emailAddress__templateLockedMetadata'] = block => {
         let out = `templateLockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['emailAddress__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['emailAddress__templateRequiredMetadata'] = block => {
         let out = `templateRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['emailAddress__toolTipMetadata'] = block => {
         let out = `toolTipMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['emailAddress__tooltip'] = block =>
         `tooltip: ${JSON.stringify(block.getFieldValue('tooltip'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['emailAddress__underline'] = block =>
         `underline: ${JSON.stringify(block.getFieldValue('underline'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['emailAddress__underlineMetadata'] = block => {
         let out = `underlineMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['emailAddress__value'] = block =>
         `value: ${JSON.stringify(block.getFieldValue('value'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['emailAddress__valueMetadata'] = block => {
         let out = `valueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['emailAddress__width'] = block =>
         `width: ${JSON.stringify(block.getFieldValue('width'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['emailAddress__widthMetadata'] = block => {
         let out = `widthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['emailAddress__xPosition'] = block =>
         `xPosition: ${JSON.stringify(block.getFieldValue('xPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['emailAddress__xPositionMetadata'] = block => {
         let out = `xPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['emailAddress__yPosition'] = block =>
         `yPosition: ${JSON.stringify(block.getFieldValue('yPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['emailAddress__yPositionMetadata'] = block => {
         let out = `yPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

Blockly.JavaScript['emailSettings'] = block => 
  `\n.add_object("emailSettings", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['emailSettings__replyEmailAddressOverride'] = block =>
         `replyEmailAddressOverride: ${JSON.stringify(block.getFieldValue('replyEmailAddressOverride'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['emailSettings__replyEmailNameOverride'] = block =>
         `replyEmailNameOverride: ${JSON.stringify(block.getFieldValue('replyEmailNameOverride'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['recipientViewRequest'] = block => 
  `\n.add_object("recipientViewRequest", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['recipientViewRequest__assertionId'] = block =>
         `assertionId: ${JSON.stringify(block.getFieldValue('assertionId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['recipientViewRequest__authenticationInstant'] = block =>
         `authenticationInstant: ${JSON.stringify(block.getFieldValue('authenticationInstant'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['recipientViewRequest__authenticationMethod'] = block =>
         `authenticationMethod: ${JSON.stringify(block.getFieldValue('authenticationMethod'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['recipientViewRequest__clientUserId'] = block =>
         `clientUserId: ${JSON.stringify(block.getFieldValue('clientUserId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['recipientViewRequest__email'] = block =>
         `email: ${JSON.stringify(block.getFieldValue('email'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['recipientViewRequest__frameAncestors'] = block => {
         let out = `    frameAncestors: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'frameAncestor', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['recipientViewRequest_frameAncestors__frameAncestor'] = block =>
         `    ${JSON.stringify(block.getFieldValue('frameAncestor'))}${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['recipientViewRequest__messageOrigins'] = block => {
         let out = `    messageOrigins: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'messageOrigin', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['recipientViewRequest_messageOrigins__messageOrigin'] = block =>
         `    ${JSON.stringify(block.getFieldValue('messageOrigin'))}${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['recipientViewRequest__pingFrequency'] = block =>
         `pingFrequency: ${JSON.stringify(block.getFieldValue('pingFrequency'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['recipientViewRequest__pingUrl'] = block =>
         `pingUrl: ${JSON.stringify(block.getFieldValue('pingUrl'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['recipientViewRequest__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['recipientViewRequest__returnUrl'] = block =>
         `returnUrl: ${JSON.stringify(block.getFieldValue('returnUrl'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['recipientViewRequest__securityDomain'] = block =>
         `securityDomain: ${JSON.stringify(block.getFieldValue('securityDomain'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['recipientViewRequest__userId'] = block =>
         `userId: ${JSON.stringify(block.getFieldValue('userId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['recipientViewRequest__userName'] = block =>
         `userName: ${JSON.stringify(block.getFieldValue('userName'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['recipientViewRequest__xFrameOptions'] = block =>
         `xFrameOptions: ${JSON.stringify(block.getFieldValue('xFrameOptions'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['recipientViewRequest__xFrameOptionsAllowFromUrl'] = block =>
         `xFrameOptionsAllowFromUrl: ${JSON.stringify(block.getFieldValue('xFrameOptionsAllowFromUrl'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['envelope'] = block => 
  `\n.add_object("envelope", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['envelope__accessControlListBase64'] = block =>
         `accessControlListBase64: ${JSON.stringify(block.getFieldValue('accessControlListBase64'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__allowComments'] = block =>
         `allowComments: ${JSON.stringify(block.getFieldValue('allowComments'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__allowMarkup'] = block =>
         `allowMarkup: ${JSON.stringify(block.getFieldValue('allowMarkup'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__allowReassign'] = block =>
         `allowReassign: ${JSON.stringify(block.getFieldValue('allowReassign'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__allowViewHistory'] = block =>
         `allowViewHistory: ${JSON.stringify(block.getFieldValue('allowViewHistory'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__anySigner'] = block =>
         `anySigner: ${JSON.stringify(block.getFieldValue('anySigner'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__asynchronous'] = block =>
         `asynchronous: ${JSON.stringify(block.getFieldValue('asynchronous'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__attachmentsUri'] = block =>
         `attachmentsUri: ${JSON.stringify(block.getFieldValue('attachmentsUri'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__authoritativeCopy'] = block =>
         `authoritativeCopy: ${JSON.stringify(block.getFieldValue('authoritativeCopy'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__authoritativeCopyDefault'] = block =>
         `authoritativeCopyDefault: ${JSON.stringify(block.getFieldValue('authoritativeCopyDefault'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__autoNavigation'] = block =>
         `autoNavigation: ${JSON.stringify(block.getFieldValue('autoNavigation'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__brandId'] = block =>
         `brandId: ${JSON.stringify(block.getFieldValue('brandId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__brandLock'] = block =>
         `brandLock: ${JSON.stringify(block.getFieldValue('brandLock'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__certificateUri'] = block =>
         `certificateUri: ${JSON.stringify(block.getFieldValue('certificateUri'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__completedDateTime'] = block =>
         `completedDateTime: ${JSON.stringify(block.getFieldValue('completedDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__copyRecipientData'] = block =>
         `copyRecipientData: ${JSON.stringify(block.getFieldValue('copyRecipientData'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__createdDateTime'] = block =>
         `createdDateTime: ${JSON.stringify(block.getFieldValue('createdDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__customFieldsUri'] = block =>
         `customFieldsUri: ${JSON.stringify(block.getFieldValue('customFieldsUri'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__declinedDateTime'] = block =>
         `declinedDateTime: ${JSON.stringify(block.getFieldValue('declinedDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__deletedDateTime'] = block =>
         `deletedDateTime: ${JSON.stringify(block.getFieldValue('deletedDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__deliveredDateTime'] = block =>
         `deliveredDateTime: ${JSON.stringify(block.getFieldValue('deliveredDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__disableResponsiveDocument'] = block =>
         `disableResponsiveDocument: ${JSON.stringify(block.getFieldValue('disableResponsiveDocument'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__documentBase64'] = block =>
         `documentBase64: ${JSON.stringify(block.getFieldValue('documentBase64'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__documentsCombinedUri'] = block =>
         `documentsCombinedUri: ${JSON.stringify(block.getFieldValue('documentsCombinedUri'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__documentsUri'] = block =>
         `documentsUri: ${JSON.stringify(block.getFieldValue('documentsUri'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__emailBlurb'] = block =>
         `emailBlurb: ${JSON.stringify(block.getFieldValue('emailBlurb'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__emailSubject'] = block =>
         `emailSubject: ${JSON.stringify(block.getFieldValue('emailSubject'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__enableWetSign'] = block =>
         `enableWetSign: ${JSON.stringify(block.getFieldValue('enableWetSign'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__enforceSignerVisibility'] = block =>
         `enforceSignerVisibility: ${JSON.stringify(block.getFieldValue('enforceSignerVisibility'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__envelopeId'] = block =>
         `envelopeId: ${JSON.stringify(block.getFieldValue('envelopeId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__envelopeIdStamping'] = block =>
         `envelopeIdStamping: ${JSON.stringify(block.getFieldValue('envelopeIdStamping'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__envelopeLocation'] = block =>
         `envelopeLocation: ${JSON.stringify(block.getFieldValue('envelopeLocation'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__envelopeUri'] = block =>
         `envelopeUri: ${JSON.stringify(block.getFieldValue('envelopeUri'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__expireAfter'] = block =>
         `expireAfter: ${JSON.stringify(block.getFieldValue('expireAfter'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__expireDateTime'] = block =>
         `expireDateTime: ${JSON.stringify(block.getFieldValue('expireDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__expireEnabled'] = block =>
         `expireEnabled: ${JSON.stringify(block.getFieldValue('expireEnabled'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__externalEnvelopeId'] = block =>
         `externalEnvelopeId: ${JSON.stringify(block.getFieldValue('externalEnvelopeId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__hasComments'] = block =>
         `hasComments: ${JSON.stringify(block.getFieldValue('hasComments'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__hasFormDataChanged'] = block =>
         `hasFormDataChanged: ${JSON.stringify(block.getFieldValue('hasFormDataChanged'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__hasWavFile'] = block =>
         `hasWavFile: ${JSON.stringify(block.getFieldValue('hasWavFile'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__holder'] = block =>
         `holder: ${JSON.stringify(block.getFieldValue('holder'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__initialSentDateTime'] = block =>
         `initialSentDateTime: ${JSON.stringify(block.getFieldValue('initialSentDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__isDynamicEnvelope'] = block =>
         `isDynamicEnvelope: ${JSON.stringify(block.getFieldValue('isDynamicEnvelope'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__isSignatureProviderEnvelope'] = block =>
         `isSignatureProviderEnvelope: ${JSON.stringify(block.getFieldValue('isSignatureProviderEnvelope'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__is21CFRPart11'] = block =>
         `is21CFRPart11: ${JSON.stringify(block.getFieldValue('is21CFRPart11'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__lastModifiedDateTime'] = block =>
         `lastModifiedDateTime: ${JSON.stringify(block.getFieldValue('lastModifiedDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__location'] = block =>
         `location: ${JSON.stringify(block.getFieldValue('location'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__messageLock'] = block =>
         `messageLock: ${JSON.stringify(block.getFieldValue('messageLock'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__notificationUri'] = block =>
         `notificationUri: ${JSON.stringify(block.getFieldValue('notificationUri'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__purgeCompletedDate'] = block =>
         `purgeCompletedDate: ${JSON.stringify(block.getFieldValue('purgeCompletedDate'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__purgeRequestDate'] = block =>
         `purgeRequestDate: ${JSON.stringify(block.getFieldValue('purgeRequestDate'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__purgeState'] = block =>
         `purgeState: ${JSON.stringify(block.getFieldValue('purgeState'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__recipientsLock'] = block =>
         `recipientsLock: ${JSON.stringify(block.getFieldValue('recipientsLock'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__recipientsUri'] = block =>
         `recipientsUri: ${JSON.stringify(block.getFieldValue('recipientsUri'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__sentDateTime'] = block =>
         `sentDateTime: ${JSON.stringify(block.getFieldValue('sentDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__signerCanSignOnMobile'] = block =>
         `signerCanSignOnMobile: ${JSON.stringify(block.getFieldValue('signerCanSignOnMobile'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__signingLocation'] = block =>
         `signingLocation: ${JSON.stringify(block.getFieldValue('signingLocation'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__statusChangedDateTime'] = block =>
         `statusChangedDateTime: ${JSON.stringify(block.getFieldValue('statusChangedDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__statusDateTime'] = block =>
         `statusDateTime: ${JSON.stringify(block.getFieldValue('statusDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__templatesUri'] = block =>
         `templatesUri: ${JSON.stringify(block.getFieldValue('templatesUri'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__transactionId'] = block =>
         `transactionId: ${JSON.stringify(block.getFieldValue('transactionId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__useDisclosure'] = block =>
         `useDisclosure: ${JSON.stringify(block.getFieldValue('useDisclosure'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__voidedDateTime'] = block =>
         `voidedDateTime: ${JSON.stringify(block.getFieldValue('voidedDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelope__voidedReason'] = block =>
         `voidedReason: ${JSON.stringify(block.getFieldValue('voidedReason'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelopeDefinition__accessControlListBase64'] = block =>
         `\n.add_envDefAttribute("accessControlListBase64", ${JSON.stringify(block.getFieldValue('accessControlListBase64'))})`;   
   Blockly.JavaScript['envelopeDefinition__accessibility'] = block =>
         `\n.add_envDefAttribute("accessibility", ${JSON.stringify(block.getFieldValue('accessibility'))})`;   
   Blockly.JavaScript['envelopeDefinition__allowComments'] = block =>
         `\n.add_envDefAttribute("allowComments", ${JSON.stringify(block.getFieldValue('allowComments'))})`;   
   Blockly.JavaScript['envelopeDefinition__allowMarkup'] = block =>
         `\n.add_envDefAttribute("allowMarkup", ${JSON.stringify(block.getFieldValue('allowMarkup'))})`;   
   Blockly.JavaScript['envelopeDefinition__allowReassign'] = block =>
         `\n.add_envDefAttribute("allowReassign", ${JSON.stringify(block.getFieldValue('allowReassign'))})`;   
   Blockly.JavaScript['envelopeDefinition__allowRecipientRecursion'] = block =>
         `\n.add_envDefAttribute("allowRecipientRecursion", ${JSON.stringify(block.getFieldValue('allowRecipientRecursion'))})`;   
   Blockly.JavaScript['envelopeDefinition__allowViewHistory'] = block =>
         `\n.add_envDefAttribute("allowViewHistory", ${JSON.stringify(block.getFieldValue('allowViewHistory'))})`;   
   Blockly.JavaScript['envelopeDefinition__anySigner'] = block =>
         `\n.add_envDefAttribute("anySigner", ${JSON.stringify(block.getFieldValue('anySigner'))})`;   
   Blockly.JavaScript['envelopeDefinition__asynchronous'] = block =>
         `\n.add_envDefAttribute("asynchronous", ${JSON.stringify(block.getFieldValue('asynchronous'))})`;   
   Blockly.JavaScript['envelopeDefinition__attachmentsUri'] = block =>
         `\n.add_envDefAttribute("attachmentsUri", ${JSON.stringify(block.getFieldValue('attachmentsUri'))})`;   
   Blockly.JavaScript['envelopeDefinition__authoritativeCopy'] = block =>
         `\n.add_envDefAttribute("authoritativeCopy", ${JSON.stringify(block.getFieldValue('authoritativeCopy'))})`;   
   Blockly.JavaScript['envelopeDefinition__authoritativeCopyDefault'] = block =>
         `\n.add_envDefAttribute("authoritativeCopyDefault", ${JSON.stringify(block.getFieldValue('authoritativeCopyDefault'))})`;   
   Blockly.JavaScript['envelopeDefinition__autoNavigation'] = block =>
         `\n.add_envDefAttribute("autoNavigation", ${JSON.stringify(block.getFieldValue('autoNavigation'))})`;   
   Blockly.JavaScript['envelopeDefinition__brandId'] = block =>
         `\n.add_envDefAttribute("brandId", ${JSON.stringify(block.getFieldValue('brandId'))})`;   
   Blockly.JavaScript['envelopeDefinition__brandLock'] = block =>
         `\n.add_envDefAttribute("brandLock", ${JSON.stringify(block.getFieldValue('brandLock'))})`;   
   Blockly.JavaScript['envelopeDefinition__certificateUri'] = block =>
         `\n.add_envDefAttribute("certificateUri", ${JSON.stringify(block.getFieldValue('certificateUri'))})`;   
   Blockly.JavaScript['envelopeDefinition__completedDateTime'] = block =>
         `\n.add_envDefAttribute("completedDateTime", ${JSON.stringify(block.getFieldValue('completedDateTime'))})`;   
   Blockly.JavaScript['envelopeDefinition__copyRecipientData'] = block =>
         `\n.add_envDefAttribute("copyRecipientData", ${JSON.stringify(block.getFieldValue('copyRecipientData'))})`;   
   Blockly.JavaScript['envelopeDefinition__createdDateTime'] = block =>
         `\n.add_envDefAttribute("createdDateTime", ${JSON.stringify(block.getFieldValue('createdDateTime'))})`;   
   Blockly.JavaScript['envelopeDefinition__customFieldsUri'] = block =>
         `\n.add_envDefAttribute("customFieldsUri", ${JSON.stringify(block.getFieldValue('customFieldsUri'))})`;   
   Blockly.JavaScript['envelopeDefinition__declinedDateTime'] = block =>
         `\n.add_envDefAttribute("declinedDateTime", ${JSON.stringify(block.getFieldValue('declinedDateTime'))})`;   
   Blockly.JavaScript['envelopeDefinition__deletedDateTime'] = block =>
         `\n.add_envDefAttribute("deletedDateTime", ${JSON.stringify(block.getFieldValue('deletedDateTime'))})`;   
   Blockly.JavaScript['envelopeDefinition__deliveredDateTime'] = block =>
         `\n.add_envDefAttribute("deliveredDateTime", ${JSON.stringify(block.getFieldValue('deliveredDateTime'))})`;   
   Blockly.JavaScript['envelopeDefinition__disableResponsiveDocument'] = block =>
         `\n.add_envDefAttribute("disableResponsiveDocument", ${JSON.stringify(block.getFieldValue('disableResponsiveDocument'))})`;   
   Blockly.JavaScript['envelopeDefinition__documentBase64'] = block =>
         `\n.add_envDefAttribute("documentBase64", ${JSON.stringify(block.getFieldValue('documentBase64'))})`;   
   Blockly.JavaScript['envelopeDefinition__documentsCombinedUri'] = block =>
         `\n.add_envDefAttribute("documentsCombinedUri", ${JSON.stringify(block.getFieldValue('documentsCombinedUri'))})`;   
   Blockly.JavaScript['envelopeDefinition__documentsUri'] = block =>
         `\n.add_envDefAttribute("documentsUri", ${JSON.stringify(block.getFieldValue('documentsUri'))})`;   
   Blockly.JavaScript['envelopeDefinition__emailBlurb'] = block =>
         `\n.add_envDefAttribute("emailBlurb", ${JSON.stringify(block.getFieldValue('emailBlurb'))})`;   
   Blockly.JavaScript['envelopeDefinition__emailSubject'] = block =>
         `\n.add_envDefAttribute("emailSubject", ${JSON.stringify(block.getFieldValue('emailSubject'))})`;   
   Blockly.JavaScript['envelopeDefinition__enableWetSign'] = block =>
         `\n.add_envDefAttribute("enableWetSign", ${JSON.stringify(block.getFieldValue('enableWetSign'))})`;   
   Blockly.JavaScript['envelopeDefinition__enforceSignerVisibility'] = block =>
         `\n.add_envDefAttribute("enforceSignerVisibility", ${JSON.stringify(block.getFieldValue('enforceSignerVisibility'))})`;   
   Blockly.JavaScript['envelopeDefinition__envelopeId'] = block =>
         `\n.add_envDefAttribute("envelopeId", ${JSON.stringify(block.getFieldValue('envelopeId'))})`;   
   Blockly.JavaScript['envelopeDefinition__envelopeIdStamping'] = block =>
         `\n.add_envDefAttribute("envelopeIdStamping", ${JSON.stringify(block.getFieldValue('envelopeIdStamping'))})`;   
   Blockly.JavaScript['envelopeDefinition__envelopeLocation'] = block =>
         `\n.add_envDefAttribute("envelopeLocation", ${JSON.stringify(block.getFieldValue('envelopeLocation'))})`;   
   Blockly.JavaScript['envelopeDefinition__envelopeUri'] = block =>
         `\n.add_envDefAttribute("envelopeUri", ${JSON.stringify(block.getFieldValue('envelopeUri'))})`;   
   Blockly.JavaScript['envelopeDefinition__expireAfter'] = block =>
         `\n.add_envDefAttribute("expireAfter", ${JSON.stringify(block.getFieldValue('expireAfter'))})`;   
   Blockly.JavaScript['envelopeDefinition__expireDateTime'] = block =>
         `\n.add_envDefAttribute("expireDateTime", ${JSON.stringify(block.getFieldValue('expireDateTime'))})`;   
   Blockly.JavaScript['envelopeDefinition__expireEnabled'] = block =>
         `\n.add_envDefAttribute("expireEnabled", ${JSON.stringify(block.getFieldValue('expireEnabled'))})`;   
   Blockly.JavaScript['envelopeDefinition__externalEnvelopeId'] = block =>
         `\n.add_envDefAttribute("externalEnvelopeId", ${JSON.stringify(block.getFieldValue('externalEnvelopeId'))})`;   
   Blockly.JavaScript['envelopeDefinition__hasComments'] = block =>
         `\n.add_envDefAttribute("hasComments", ${JSON.stringify(block.getFieldValue('hasComments'))})`;   
   Blockly.JavaScript['envelopeDefinition__hasFormDataChanged'] = block =>
         `\n.add_envDefAttribute("hasFormDataChanged", ${JSON.stringify(block.getFieldValue('hasFormDataChanged'))})`;   
   Blockly.JavaScript['envelopeDefinition__hasWavFile'] = block =>
         `\n.add_envDefAttribute("hasWavFile", ${JSON.stringify(block.getFieldValue('hasWavFile'))})`;   
   Blockly.JavaScript['envelopeDefinition__holder'] = block =>
         `\n.add_envDefAttribute("holder", ${JSON.stringify(block.getFieldValue('holder'))})`;   
   Blockly.JavaScript['envelopeDefinition__initialSentDateTime'] = block =>
         `\n.add_envDefAttribute("initialSentDateTime", ${JSON.stringify(block.getFieldValue('initialSentDateTime'))})`;   
   Blockly.JavaScript['envelopeDefinition__isDynamicEnvelope'] = block =>
         `\n.add_envDefAttribute("isDynamicEnvelope", ${JSON.stringify(block.getFieldValue('isDynamicEnvelope'))})`;   
   Blockly.JavaScript['envelopeDefinition__isSignatureProviderEnvelope'] = block =>
         `\n.add_envDefAttribute("isSignatureProviderEnvelope", ${JSON.stringify(block.getFieldValue('isSignatureProviderEnvelope'))})`;   
   Blockly.JavaScript['envelopeDefinition__is21CFRPart11'] = block =>
         `\n.add_envDefAttribute("is21CFRPart11", ${JSON.stringify(block.getFieldValue('is21CFRPart11'))})`;   
   Blockly.JavaScript['envelopeDefinition__lastModifiedDateTime'] = block =>
         `\n.add_envDefAttribute("lastModifiedDateTime", ${JSON.stringify(block.getFieldValue('lastModifiedDateTime'))})`;   
   Blockly.JavaScript['envelopeDefinition__location'] = block =>
         `\n.add_envDefAttribute("location", ${JSON.stringify(block.getFieldValue('location'))})`;   
   Blockly.JavaScript['envelopeDefinition__messageLock'] = block =>
         `\n.add_envDefAttribute("messageLock", ${JSON.stringify(block.getFieldValue('messageLock'))})`;   
   Blockly.JavaScript['envelopeDefinition__notificationUri'] = block =>
         `\n.add_envDefAttribute("notificationUri", ${JSON.stringify(block.getFieldValue('notificationUri'))})`;   
   Blockly.JavaScript['envelopeDefinition__password'] = block =>
         `\n.add_envDefAttribute("password", ${JSON.stringify(block.getFieldValue('password'))})`;   
   Blockly.JavaScript['envelopeDefinition__purgeCompletedDate'] = block =>
         `\n.add_envDefAttribute("purgeCompletedDate", ${JSON.stringify(block.getFieldValue('purgeCompletedDate'))})`;   
   Blockly.JavaScript['envelopeDefinition__purgeRequestDate'] = block =>
         `\n.add_envDefAttribute("purgeRequestDate", ${JSON.stringify(block.getFieldValue('purgeRequestDate'))})`;   
   Blockly.JavaScript['envelopeDefinition__purgeState'] = block =>
         `\n.add_envDefAttribute("purgeState", ${JSON.stringify(block.getFieldValue('purgeState'))})`;   
   Blockly.JavaScript['envelopeDefinition__recipientsLock'] = block =>
         `\n.add_envDefAttribute("recipientsLock", ${JSON.stringify(block.getFieldValue('recipientsLock'))})`;   
   Blockly.JavaScript['envelopeDefinition__recipientsUri'] = block =>
         `\n.add_envDefAttribute("recipientsUri", ${JSON.stringify(block.getFieldValue('recipientsUri'))})`;   
   Blockly.JavaScript['envelopeDefinition__sentDateTime'] = block =>
         `\n.add_envDefAttribute("sentDateTime", ${JSON.stringify(block.getFieldValue('sentDateTime'))})`;   
   Blockly.JavaScript['envelopeDefinition__signerCanSignOnMobile'] = block =>
         `\n.add_envDefAttribute("signerCanSignOnMobile", ${JSON.stringify(block.getFieldValue('signerCanSignOnMobile'))})`;   
   Blockly.JavaScript['envelopeDefinition__signingLocation'] = block =>
         `\n.add_envDefAttribute("signingLocation", ${JSON.stringify(block.getFieldValue('signingLocation'))})`;   
   Blockly.JavaScript['envelopeDefinition__status'] = block =>
         `\n.add_envDefAttribute("status", ${JSON.stringify(block.getFieldValue('status'))})`;   
   Blockly.JavaScript['envelopeDefinition__statusChangedDateTime'] = block =>
         `\n.add_envDefAttribute("statusChangedDateTime", ${JSON.stringify(block.getFieldValue('statusChangedDateTime'))})`;   
   Blockly.JavaScript['envelopeDefinition__statusDateTime'] = block =>
         `\n.add_envDefAttribute("statusDateTime", ${JSON.stringify(block.getFieldValue('statusDateTime'))})`;   
   Blockly.JavaScript['envelopeDefinition__templateId'] = block =>
         `\n.add_envDefAttribute("templateId", ${JSON.stringify(block.getFieldValue('templateId'))})`;   
   Blockly.JavaScript['envelopeDefinition__templatesUri'] = block =>
         `\n.add_envDefAttribute("templatesUri", ${JSON.stringify(block.getFieldValue('templatesUri'))})`;   
   Blockly.JavaScript['envelopeDefinition__transactionId'] = block =>
         `\n.add_envDefAttribute("transactionId", ${JSON.stringify(block.getFieldValue('transactionId'))})`;   
   Blockly.JavaScript['envelopeDefinition__useDisclosure'] = block =>
         `\n.add_envDefAttribute("useDisclosure", ${JSON.stringify(block.getFieldValue('useDisclosure'))})`;   
   Blockly.JavaScript['envelopeDefinition__voidedDateTime'] = block =>
         `\n.add_envDefAttribute("voidedDateTime", ${JSON.stringify(block.getFieldValue('voidedDateTime'))})`;   
   Blockly.JavaScript['envelopeDefinition__voidedReason'] = block =>
         `\n.add_envDefAttribute("voidedReason", ${JSON.stringify(block.getFieldValue('voidedReason'))})`;   
Blockly.JavaScript['envelopeDelayRuleApiModel'] = block => 
  `\n.add_object("envelopeDelayRuleApiModel", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['envelopeDelayRuleApiModel__delay'] = block =>
         `delay: ${JSON.stringify(block.getFieldValue('delay'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelopeDelayRuleApiModel__resumeDate'] = block =>
         `resumeDate: ${JSON.stringify(block.getFieldValue('resumeDate'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['envelopeDocument'] = block => 
  `\n.add_object("envelopeDocument", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
      Blockly.JavaScript['envelopeDocument__addedRecipientIds'] = block => {
         let out = `    addedRecipientIds: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'addedRecipientId', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['envelopeDocument_addedRecipientIds__addedRecipientId'] = block =>
         `    ${JSON.stringify(block.getFieldValue('addedRecipientId'))}${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['envelopeDocument__attachmentTabId'] = block =>
         `attachmentTabId: ${JSON.stringify(block.getFieldValue('attachmentTabId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelopeDocument__authoritativeCopy'] = block =>
         `authoritativeCopy: ${JSON.stringify(block.getFieldValue('authoritativeCopy'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['envelopeDocument__authoritativeCopyMetadata'] = block => {
         let out = `authoritativeCopyMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['envelopeDocument__containsPdfFormFields'] = block =>
         `containsPdfFormFields: ${JSON.stringify(block.getFieldValue('containsPdfFormFields'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelopeDocument__display'] = block =>
         `display: ${JSON.stringify(block.getFieldValue('display'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['envelopeDocument__displayMetadata'] = block => {
         let out = `displayMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['envelopeDocument__documentBase64'] = block =>
         `documentBase64: ${JSON.stringify(block.getFieldValue('documentBase64'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelopeDocument__documentId'] = block =>
         `documentId: ${JSON.stringify(block.getFieldValue('documentId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelopeDocument__documentIdGuid'] = block =>
         `documentIdGuid: ${JSON.stringify(block.getFieldValue('documentIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelopeDocument__includeInDownload'] = block =>
         `includeInDownload: ${JSON.stringify(block.getFieldValue('includeInDownload'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['envelopeDocument__includeInDownloadMetadata'] = block => {
         let out = `includeInDownloadMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['envelopeDocument__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['envelopeDocument__nameMetadata'] = block => {
         let out = `nameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['envelopeDocument__order'] = block =>
         `order: ${JSON.stringify(block.getFieldValue('order'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelopeDocument__signerMustAcknowledge'] = block =>
         `signerMustAcknowledge: ${JSON.stringify(block.getFieldValue('signerMustAcknowledge'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['envelopeDocument__signerMustAcknowledgeMetadata'] = block => {
         let out = `signerMustAcknowledgeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['envelopeDocument__sizeBytes'] = block =>
         `sizeBytes: ${JSON.stringify(block.getFieldValue('sizeBytes'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelopeDocument__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelopeDocument__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelopeDocument__type'] = block =>
         `type: ${JSON.stringify(block.getFieldValue('type'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelopeDocument__uri'] = block =>
         `uri: ${JSON.stringify(block.getFieldValue('uri'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['envelopeEvent'] = block => 
  `\n.add_object("envelopeEvent", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['envelopeEvent__envelopeEventStatusCode'] = block =>
         `envelopeEventStatusCode: ${JSON.stringify(block.getFieldValue('envelopeEventStatusCode'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelopeEvent__includeDocuments'] = block =>
         `includeDocuments: ${JSON.stringify(block.getFieldValue('includeDocuments'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['envelopeId'] = block => 
  `\n.add_object("envelopeId", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['envelopeId__anchorAllowWhiteSpaceInCharacters'] = block =>
         `anchorAllowWhiteSpaceInCharacters: ${JSON.stringify(block.getFieldValue('anchorAllowWhiteSpaceInCharacters'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['envelopeId__anchorAllowWhiteSpaceInCharactersMetadata'] = block => {
         let out = `anchorAllowWhiteSpaceInCharactersMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['envelopeId__anchorCaseSensitive'] = block =>
         `anchorCaseSensitive: ${JSON.stringify(block.getFieldValue('anchorCaseSensitive'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['envelopeId__anchorCaseSensitiveMetadata'] = block => {
         let out = `anchorCaseSensitiveMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['envelopeId__anchorHorizontalAlignment'] = block =>
         `anchorHorizontalAlignment: ${JSON.stringify(block.getFieldValue('anchorHorizontalAlignment'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['envelopeId__anchorHorizontalAlignmentMetadata'] = block => {
         let out = `anchorHorizontalAlignmentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['envelopeId__anchorIgnoreIfNotPresent'] = block =>
         `anchorIgnoreIfNotPresent: ${JSON.stringify(block.getFieldValue('anchorIgnoreIfNotPresent'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['envelopeId__anchorIgnoreIfNotPresentMetadata'] = block => {
         let out = `anchorIgnoreIfNotPresentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['envelopeId__anchorMatchWholeWord'] = block =>
         `anchorMatchWholeWord: ${JSON.stringify(block.getFieldValue('anchorMatchWholeWord'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['envelopeId__anchorMatchWholeWordMetadata'] = block => {
         let out = `anchorMatchWholeWordMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['envelopeId__anchorString'] = block =>
         `anchorString: ${JSON.stringify(block.getFieldValue('anchorString'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['envelopeId__anchorStringMetadata'] = block => {
         let out = `anchorStringMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['envelopeId__anchorTabProcessorVersion'] = block =>
         `anchorTabProcessorVersion: ${JSON.stringify(block.getFieldValue('anchorTabProcessorVersion'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['envelopeId__anchorTabProcessorVersionMetadata'] = block => {
         let out = `anchorTabProcessorVersionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['envelopeId__anchorUnits'] = block =>
         `anchorUnits: ${JSON.stringify(block.getFieldValue('anchorUnits'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['envelopeId__anchorUnitsMetadata'] = block => {
         let out = `anchorUnitsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['envelopeId__anchorXOffset'] = block =>
         `anchorXOffset: ${JSON.stringify(block.getFieldValue('anchorXOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['envelopeId__anchorXOffsetMetadata'] = block => {
         let out = `anchorXOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['envelopeId__anchorYOffset'] = block =>
         `anchorYOffset: ${JSON.stringify(block.getFieldValue('anchorYOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['envelopeId__anchorYOffsetMetadata'] = block => {
         let out = `anchorYOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['envelopeId__bold'] = block =>
         `bold: ${JSON.stringify(block.getFieldValue('bold'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['envelopeId__boldMetadata'] = block => {
         let out = `boldMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['envelopeId__conditionalParentLabel'] = block =>
         `conditionalParentLabel: ${JSON.stringify(block.getFieldValue('conditionalParentLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['envelopeId__conditionalParentLabelMetadata'] = block => {
         let out = `conditionalParentLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['envelopeId__conditionalParentValue'] = block =>
         `conditionalParentValue: ${JSON.stringify(block.getFieldValue('conditionalParentValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['envelopeId__conditionalParentValueMetadata'] = block => {
         let out = `conditionalParentValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['envelopeId__customTabId'] = block =>
         `customTabId: ${JSON.stringify(block.getFieldValue('customTabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['envelopeId__customTabIdMetadata'] = block => {
         let out = `customTabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['envelopeId__documentId'] = block =>
         `documentId: ${JSON.stringify(block.getFieldValue('documentId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['envelopeId__documentIdMetadata'] = block => {
         let out = `documentIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['envelopeId__font'] = block =>
         `font: ${JSON.stringify(block.getFieldValue('font'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelopeId__fontColor'] = block =>
         `fontColor: ${JSON.stringify(block.getFieldValue('fontColor'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['envelopeId__fontColorMetadata'] = block => {
         let out = `fontColorMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['envelopeId__fontMetadata'] = block => {
         let out = `fontMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['envelopeId__fontSize'] = block =>
         `fontSize: ${JSON.stringify(block.getFieldValue('fontSize'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['envelopeId__fontSizeMetadata'] = block => {
         let out = `fontSizeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['envelopeId__formOrder'] = block =>
         `formOrder: ${JSON.stringify(block.getFieldValue('formOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['envelopeId__formOrderMetadata'] = block => {
         let out = `formOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['envelopeId__formPageLabel'] = block =>
         `formPageLabel: ${JSON.stringify(block.getFieldValue('formPageLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['envelopeId__formPageLabelMetadata'] = block => {
         let out = `formPageLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['envelopeId__formPageNumber'] = block =>
         `formPageNumber: ${JSON.stringify(block.getFieldValue('formPageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['envelopeId__formPageNumberMetadata'] = block => {
         let out = `formPageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['envelopeId__height'] = block =>
         `height: ${JSON.stringify(block.getFieldValue('height'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['envelopeId__heightMetadata'] = block => {
         let out = `heightMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['envelopeId__italic'] = block =>
         `italic: ${JSON.stringify(block.getFieldValue('italic'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['envelopeId__italicMetadata'] = block => {
         let out = `italicMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['envelopeId__mergeFieldXml'] = block =>
         `mergeFieldXml: ${JSON.stringify(block.getFieldValue('mergeFieldXml'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelopeId__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['envelopeId__nameMetadata'] = block => {
         let out = `nameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['envelopeId__pageNumber'] = block =>
         `pageNumber: ${JSON.stringify(block.getFieldValue('pageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['envelopeId__pageNumberMetadata'] = block => {
         let out = `pageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['envelopeId__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelopeId__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['envelopeId__recipientIdGuidMetadata'] = block => {
         let out = `recipientIdGuidMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['envelopeId__recipientIdMetadata'] = block => {
         let out = `recipientIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['envelopeId__source'] = block =>
         `source: ${JSON.stringify(block.getFieldValue('source'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelopeId__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['envelopeId__statusMetadata'] = block => {
         let out = `statusMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['envelopeId__tabGroupLabels'] = block => {
         let out = `    tabGroupLabels: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'tabGroupLabel', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['envelopeId_tabGroupLabels__tabGroupLabel'] = block =>
         `    ${JSON.stringify(block.getFieldValue('tabGroupLabel'))}${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['envelopeId__tabGroupLabelsMetadata'] = block => {
         let out = `tabGroupLabelsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['envelopeId__tabId'] = block =>
         `tabId: ${JSON.stringify(block.getFieldValue('tabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['envelopeId__tabIdMetadata'] = block => {
         let out = `tabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['envelopeId__tabLabel'] = block =>
         `tabLabel: ${JSON.stringify(block.getFieldValue('tabLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['envelopeId__tabLabelMetadata'] = block => {
         let out = `tabLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['envelopeId__tabOrder'] = block =>
         `tabOrder: ${JSON.stringify(block.getFieldValue('tabOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['envelopeId__tabOrderMetadata'] = block => {
         let out = `tabOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['envelopeId__tabType'] = block =>
         `tabType: ${JSON.stringify(block.getFieldValue('tabType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['envelopeId__tabTypeMetadata'] = block => {
         let out = `tabTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['envelopeId__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['envelopeId__templateLockedMetadata'] = block => {
         let out = `templateLockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['envelopeId__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['envelopeId__templateRequiredMetadata'] = block => {
         let out = `templateRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['envelopeId__toolTipMetadata'] = block => {
         let out = `toolTipMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['envelopeId__tooltip'] = block =>
         `tooltip: ${JSON.stringify(block.getFieldValue('tooltip'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelopeId__underline'] = block =>
         `underline: ${JSON.stringify(block.getFieldValue('underline'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['envelopeId__underlineMetadata'] = block => {
         let out = `underlineMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['envelopeId__width'] = block =>
         `width: ${JSON.stringify(block.getFieldValue('width'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['envelopeId__widthMetadata'] = block => {
         let out = `widthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['envelopeId__xPosition'] = block =>
         `xPosition: ${JSON.stringify(block.getFieldValue('xPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['envelopeId__xPositionMetadata'] = block => {
         let out = `xPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['envelopeId__yPosition'] = block =>
         `yPosition: ${JSON.stringify(block.getFieldValue('yPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['envelopeId__yPositionMetadata'] = block => {
         let out = `yPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

Blockly.JavaScript['envelopeMetadata'] = block => 
  `\n.add_object("envelopeMetadata", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['envelopeMetadata__allowAdvancedCorrect'] = block =>
         `allowAdvancedCorrect: ${JSON.stringify(block.getFieldValue('allowAdvancedCorrect'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelopeMetadata__allowCorrect'] = block =>
         `allowCorrect: ${JSON.stringify(block.getFieldValue('allowCorrect'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['envelopeMetadata__enableSignWithNotary'] = block =>
         `enableSignWithNotary: ${JSON.stringify(block.getFieldValue('enableSignWithNotary'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['errorDetails'] = block => 
  `\n.add_object("errorDetails", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['errorDetails__errorCode'] = block =>
         `errorCode: ${JSON.stringify(block.getFieldValue('errorCode'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['errorDetails__message'] = block =>
         `message: ${JSON.stringify(block.getFieldValue('message'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['eventNotification'] = block => 
  `\n.add_object("eventNotification", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['eventNotification__deliveryMode'] = block =>
         `deliveryMode: ${JSON.stringify(block.getFieldValue('deliveryMode'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['eventNotification__events'] = block => {
         let out = `    events: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'event', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['eventNotification_events__event'] = block =>
         `    ${JSON.stringify(block.getFieldValue('event'))}${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['eventNotification__includeCertificateOfCompletion'] = block =>
         `includeCertificateOfCompletion: ${JSON.stringify(block.getFieldValue('includeCertificateOfCompletion'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['eventNotification__includeCertificateWithSoap'] = block =>
         `includeCertificateWithSoap: ${JSON.stringify(block.getFieldValue('includeCertificateWithSoap'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['eventNotification__includeDocumentFields'] = block =>
         `includeDocumentFields: ${JSON.stringify(block.getFieldValue('includeDocumentFields'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['eventNotification__includeDocuments'] = block =>
         `includeDocuments: ${JSON.stringify(block.getFieldValue('includeDocuments'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['eventNotification__includeEnvelopeVoidReason'] = block =>
         `includeEnvelopeVoidReason: ${JSON.stringify(block.getFieldValue('includeEnvelopeVoidReason'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['eventNotification__includeHMAC'] = block =>
         `includeHMAC: ${JSON.stringify(block.getFieldValue('includeHMAC'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['eventNotification__includeSenderAccountAsCustomField'] = block =>
         `includeSenderAccountAsCustomField: ${JSON.stringify(block.getFieldValue('includeSenderAccountAsCustomField'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['eventNotification__includeTimeZone'] = block =>
         `includeTimeZone: ${JSON.stringify(block.getFieldValue('includeTimeZone'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['eventNotification__loggingEnabled'] = block =>
         `loggingEnabled: ${JSON.stringify(block.getFieldValue('loggingEnabled'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['eventNotification__requireAcknowledgment'] = block =>
         `requireAcknowledgment: ${JSON.stringify(block.getFieldValue('requireAcknowledgment'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['eventNotification__signMessageWithX509Cert'] = block =>
         `signMessageWithX509Cert: ${JSON.stringify(block.getFieldValue('signMessageWithX509Cert'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['eventNotification__soapNameSpace'] = block =>
         `soapNameSpace: ${JSON.stringify(block.getFieldValue('soapNameSpace'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['eventNotification__url'] = block =>
         `url: ${JSON.stringify(block.getFieldValue('url'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['eventNotification__useSoapInterface'] = block =>
         `useSoapInterface: ${JSON.stringify(block.getFieldValue('useSoapInterface'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['expirations'] = block => 
  `\n.add_object("expirations", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['expirations__expireAfter'] = block =>
         `expireAfter: ${JSON.stringify(block.getFieldValue('expireAfter'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['expirations__expireEnabled'] = block =>
         `expireEnabled: ${JSON.stringify(block.getFieldValue('expireEnabled'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['expirations__expireWarn'] = block =>
         `expireWarn: ${JSON.stringify(block.getFieldValue('expireWarn'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['featureAvailableMetadata'] = block => 
  `\n.add_object("featureAvailableMetadata", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['featureAvailableMetadata__availabilty'] = block =>
         `availabilty: ${JSON.stringify(block.getFieldValue('availabilty'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['featureAvailableMetadata__featureName'] = block =>
         `featureName: ${JSON.stringify(block.getFieldValue('featureName'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['filter'] = block => 
  `\n.add_object("filter", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['filter__actionRequired'] = block =>
         `actionRequired: ${JSON.stringify(block.getFieldValue('actionRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['filter__expires'] = block =>
         `expires: ${JSON.stringify(block.getFieldValue('expires'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['filter__folderIds'] = block =>
         `folderIds: ${JSON.stringify(block.getFieldValue('folderIds'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['filter__fromDateTime'] = block =>
         `fromDateTime: ${JSON.stringify(block.getFieldValue('fromDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['filter__isTemplate'] = block =>
         `isTemplate: ${JSON.stringify(block.getFieldValue('isTemplate'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['filter__order'] = block =>
         `order: ${JSON.stringify(block.getFieldValue('order'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['filter__orderBy'] = block =>
         `orderBy: ${JSON.stringify(block.getFieldValue('orderBy'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['filter__searchTarget'] = block =>
         `searchTarget: ${JSON.stringify(block.getFieldValue('searchTarget'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['filter__searchText'] = block =>
         `searchText: ${JSON.stringify(block.getFieldValue('searchText'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['filter__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['filter__toDateTime'] = block =>
         `toDateTime: ${JSON.stringify(block.getFieldValue('toDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['firstName'] = block => 
  `\n.add_object("firstName", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['firstName__anchorAllowWhiteSpaceInCharacters'] = block =>
         `anchorAllowWhiteSpaceInCharacters: ${JSON.stringify(block.getFieldValue('anchorAllowWhiteSpaceInCharacters'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['firstName__anchorAllowWhiteSpaceInCharactersMetadata'] = block => {
         let out = `anchorAllowWhiteSpaceInCharactersMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['firstName__anchorCaseSensitive'] = block =>
         `anchorCaseSensitive: ${JSON.stringify(block.getFieldValue('anchorCaseSensitive'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['firstName__anchorCaseSensitiveMetadata'] = block => {
         let out = `anchorCaseSensitiveMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['firstName__anchorHorizontalAlignment'] = block =>
         `anchorHorizontalAlignment: ${JSON.stringify(block.getFieldValue('anchorHorizontalAlignment'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['firstName__anchorHorizontalAlignmentMetadata'] = block => {
         let out = `anchorHorizontalAlignmentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['firstName__anchorIgnoreIfNotPresent'] = block =>
         `anchorIgnoreIfNotPresent: ${JSON.stringify(block.getFieldValue('anchorIgnoreIfNotPresent'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['firstName__anchorIgnoreIfNotPresentMetadata'] = block => {
         let out = `anchorIgnoreIfNotPresentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['firstName__anchorMatchWholeWord'] = block =>
         `anchorMatchWholeWord: ${JSON.stringify(block.getFieldValue('anchorMatchWholeWord'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['firstName__anchorMatchWholeWordMetadata'] = block => {
         let out = `anchorMatchWholeWordMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['firstName__anchorString'] = block =>
         `anchorString: ${JSON.stringify(block.getFieldValue('anchorString'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['firstName__anchorStringMetadata'] = block => {
         let out = `anchorStringMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['firstName__anchorTabProcessorVersion'] = block =>
         `anchorTabProcessorVersion: ${JSON.stringify(block.getFieldValue('anchorTabProcessorVersion'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['firstName__anchorTabProcessorVersionMetadata'] = block => {
         let out = `anchorTabProcessorVersionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['firstName__anchorUnits'] = block =>
         `anchorUnits: ${JSON.stringify(block.getFieldValue('anchorUnits'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['firstName__anchorUnitsMetadata'] = block => {
         let out = `anchorUnitsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['firstName__anchorXOffset'] = block =>
         `anchorXOffset: ${JSON.stringify(block.getFieldValue('anchorXOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['firstName__anchorXOffsetMetadata'] = block => {
         let out = `anchorXOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['firstName__anchorYOffset'] = block =>
         `anchorYOffset: ${JSON.stringify(block.getFieldValue('anchorYOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['firstName__anchorYOffsetMetadata'] = block => {
         let out = `anchorYOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['firstName__bold'] = block =>
         `bold: ${JSON.stringify(block.getFieldValue('bold'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['firstName__boldMetadata'] = block => {
         let out = `boldMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['firstName__conditionalParentLabel'] = block =>
         `conditionalParentLabel: ${JSON.stringify(block.getFieldValue('conditionalParentLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['firstName__conditionalParentLabelMetadata'] = block => {
         let out = `conditionalParentLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['firstName__conditionalParentValue'] = block =>
         `conditionalParentValue: ${JSON.stringify(block.getFieldValue('conditionalParentValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['firstName__conditionalParentValueMetadata'] = block => {
         let out = `conditionalParentValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['firstName__customTabId'] = block =>
         `customTabId: ${JSON.stringify(block.getFieldValue('customTabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['firstName__customTabIdMetadata'] = block => {
         let out = `customTabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['firstName__documentId'] = block =>
         `documentId: ${JSON.stringify(block.getFieldValue('documentId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['firstName__documentIdMetadata'] = block => {
         let out = `documentIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['firstName__font'] = block =>
         `font: ${JSON.stringify(block.getFieldValue('font'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['firstName__fontColor'] = block =>
         `fontColor: ${JSON.stringify(block.getFieldValue('fontColor'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['firstName__fontColorMetadata'] = block => {
         let out = `fontColorMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['firstName__fontMetadata'] = block => {
         let out = `fontMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['firstName__fontSize'] = block =>
         `fontSize: ${JSON.stringify(block.getFieldValue('fontSize'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['firstName__fontSizeMetadata'] = block => {
         let out = `fontSizeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['firstName__formOrder'] = block =>
         `formOrder: ${JSON.stringify(block.getFieldValue('formOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['firstName__formOrderMetadata'] = block => {
         let out = `formOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['firstName__formPageLabel'] = block =>
         `formPageLabel: ${JSON.stringify(block.getFieldValue('formPageLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['firstName__formPageLabelMetadata'] = block => {
         let out = `formPageLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['firstName__formPageNumber'] = block =>
         `formPageNumber: ${JSON.stringify(block.getFieldValue('formPageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['firstName__formPageNumberMetadata'] = block => {
         let out = `formPageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['firstName__height'] = block =>
         `height: ${JSON.stringify(block.getFieldValue('height'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['firstName__heightMetadata'] = block => {
         let out = `heightMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['firstName__italic'] = block =>
         `italic: ${JSON.stringify(block.getFieldValue('italic'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['firstName__italicMetadata'] = block => {
         let out = `italicMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['firstName__mergeFieldXml'] = block =>
         `mergeFieldXml: ${JSON.stringify(block.getFieldValue('mergeFieldXml'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['firstName__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['firstName__nameMetadata'] = block => {
         let out = `nameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['firstName__pageNumber'] = block =>
         `pageNumber: ${JSON.stringify(block.getFieldValue('pageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['firstName__pageNumberMetadata'] = block => {
         let out = `pageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['firstName__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['firstName__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['firstName__recipientIdGuidMetadata'] = block => {
         let out = `recipientIdGuidMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['firstName__recipientIdMetadata'] = block => {
         let out = `recipientIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['firstName__source'] = block =>
         `source: ${JSON.stringify(block.getFieldValue('source'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['firstName__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['firstName__statusMetadata'] = block => {
         let out = `statusMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['firstName__tabGroupLabels'] = block => {
         let out = `    tabGroupLabels: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'tabGroupLabel', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['firstName_tabGroupLabels__tabGroupLabel'] = block =>
         `    ${JSON.stringify(block.getFieldValue('tabGroupLabel'))}${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['firstName__tabGroupLabelsMetadata'] = block => {
         let out = `tabGroupLabelsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['firstName__tabId'] = block =>
         `tabId: ${JSON.stringify(block.getFieldValue('tabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['firstName__tabIdMetadata'] = block => {
         let out = `tabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['firstName__tabLabel'] = block =>
         `tabLabel: ${JSON.stringify(block.getFieldValue('tabLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['firstName__tabLabelMetadata'] = block => {
         let out = `tabLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['firstName__tabOrder'] = block =>
         `tabOrder: ${JSON.stringify(block.getFieldValue('tabOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['firstName__tabOrderMetadata'] = block => {
         let out = `tabOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['firstName__tabType'] = block =>
         `tabType: ${JSON.stringify(block.getFieldValue('tabType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['firstName__tabTypeMetadata'] = block => {
         let out = `tabTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['firstName__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['firstName__templateLockedMetadata'] = block => {
         let out = `templateLockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['firstName__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['firstName__templateRequiredMetadata'] = block => {
         let out = `templateRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['firstName__toolTipMetadata'] = block => {
         let out = `toolTipMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['firstName__tooltip'] = block =>
         `tooltip: ${JSON.stringify(block.getFieldValue('tooltip'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['firstName__underline'] = block =>
         `underline: ${JSON.stringify(block.getFieldValue('underline'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['firstName__underlineMetadata'] = block => {
         let out = `underlineMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['firstName__value'] = block =>
         `value: ${JSON.stringify(block.getFieldValue('value'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['firstName__valueMetadata'] = block => {
         let out = `valueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['firstName__width'] = block =>
         `width: ${JSON.stringify(block.getFieldValue('width'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['firstName__widthMetadata'] = block => {
         let out = `widthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['firstName__xPosition'] = block =>
         `xPosition: ${JSON.stringify(block.getFieldValue('xPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['firstName__xPositionMetadata'] = block => {
         let out = `xPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['firstName__yPosition'] = block =>
         `yPosition: ${JSON.stringify(block.getFieldValue('yPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['firstName__yPositionMetadata'] = block => {
         let out = `yPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

Blockly.JavaScript['folder'] = block => 
  `\n.add_object("folder", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['folder__folderId'] = block =>
         `folderId: ${JSON.stringify(block.getFieldValue('folderId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['folder__hasAccess'] = block =>
         `hasAccess: ${JSON.stringify(block.getFieldValue('hasAccess'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['folder__hasSubFolders'] = block =>
         `hasSubFolders: ${JSON.stringify(block.getFieldValue('hasSubFolders'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['folder__itemCount'] = block =>
         `itemCount: ${JSON.stringify(block.getFieldValue('itemCount'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['folder__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['folder__parentFolderId'] = block =>
         `parentFolderId: ${JSON.stringify(block.getFieldValue('parentFolderId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['folder__parentFolderUri'] = block =>
         `parentFolderUri: ${JSON.stringify(block.getFieldValue('parentFolderUri'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['folder__subFolderCount'] = block =>
         `subFolderCount: ${JSON.stringify(block.getFieldValue('subFolderCount'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['folder__type'] = block =>
         `type: ${JSON.stringify(block.getFieldValue('type'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['folder__uri'] = block =>
         `uri: ${JSON.stringify(block.getFieldValue('uri'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['folderItem_v2'] = block => 
  `\n.add_object("folderItem_v2", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['folderItem_v2__completedDateTime'] = block =>
         `completedDateTime: ${JSON.stringify(block.getFieldValue('completedDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['folderItem_v2__createdDateTime'] = block =>
         `createdDateTime: ${JSON.stringify(block.getFieldValue('createdDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['folderItem_v2__envelopeId'] = block =>
         `envelopeId: ${JSON.stringify(block.getFieldValue('envelopeId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['folderItem_v2__envelopeUri'] = block =>
         `envelopeUri: ${JSON.stringify(block.getFieldValue('envelopeUri'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['folderItem_v2__expireDateTime'] = block =>
         `expireDateTime: ${JSON.stringify(block.getFieldValue('expireDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['folderItem_v2__folderId'] = block =>
         `folderId: ${JSON.stringify(block.getFieldValue('folderId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['folderItem_v2__folderUri'] = block =>
         `folderUri: ${JSON.stringify(block.getFieldValue('folderUri'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['folderItem_v2__is21CFRPart11'] = block =>
         `is21CFRPart11: ${JSON.stringify(block.getFieldValue('is21CFRPart11'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['folderItem_v2__lastModifiedDateTime'] = block =>
         `lastModifiedDateTime: ${JSON.stringify(block.getFieldValue('lastModifiedDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['folderItem_v2__ownerName'] = block =>
         `ownerName: ${JSON.stringify(block.getFieldValue('ownerName'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['folderItem_v2__recipientsUri'] = block =>
         `recipientsUri: ${JSON.stringify(block.getFieldValue('recipientsUri'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['folderItem_v2__senderCompany'] = block =>
         `senderCompany: ${JSON.stringify(block.getFieldValue('senderCompany'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['folderItem_v2__senderEmail'] = block =>
         `senderEmail: ${JSON.stringify(block.getFieldValue('senderEmail'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['folderItem_v2__senderName'] = block =>
         `senderName: ${JSON.stringify(block.getFieldValue('senderName'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['folderItem_v2__senderUserId'] = block =>
         `senderUserId: ${JSON.stringify(block.getFieldValue('senderUserId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['folderItem_v2__sentDateTime'] = block =>
         `sentDateTime: ${JSON.stringify(block.getFieldValue('sentDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['folderItem_v2__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['folderItem_v2__subject'] = block =>
         `subject: ${JSON.stringify(block.getFieldValue('subject'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['folderItem_v2__templateId'] = block =>
         `templateId: ${JSON.stringify(block.getFieldValue('templateId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['folderItem_v2__templateUri'] = block =>
         `templateUri: ${JSON.stringify(block.getFieldValue('templateUri'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['formulaTab'] = block => 
  `\n.add_object("formulaTab", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['formulaTab__anchorAllowWhiteSpaceInCharacters'] = block =>
         `anchorAllowWhiteSpaceInCharacters: ${JSON.stringify(block.getFieldValue('anchorAllowWhiteSpaceInCharacters'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__anchorAllowWhiteSpaceInCharactersMetadata'] = block => {
         let out = `anchorAllowWhiteSpaceInCharactersMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__anchorCaseSensitive'] = block =>
         `anchorCaseSensitive: ${JSON.stringify(block.getFieldValue('anchorCaseSensitive'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__anchorCaseSensitiveMetadata'] = block => {
         let out = `anchorCaseSensitiveMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__anchorHorizontalAlignment'] = block =>
         `anchorHorizontalAlignment: ${JSON.stringify(block.getFieldValue('anchorHorizontalAlignment'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__anchorHorizontalAlignmentMetadata'] = block => {
         let out = `anchorHorizontalAlignmentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__anchorIgnoreIfNotPresent'] = block =>
         `anchorIgnoreIfNotPresent: ${JSON.stringify(block.getFieldValue('anchorIgnoreIfNotPresent'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__anchorIgnoreIfNotPresentMetadata'] = block => {
         let out = `anchorIgnoreIfNotPresentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__anchorMatchWholeWord'] = block =>
         `anchorMatchWholeWord: ${JSON.stringify(block.getFieldValue('anchorMatchWholeWord'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__anchorMatchWholeWordMetadata'] = block => {
         let out = `anchorMatchWholeWordMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__anchorString'] = block =>
         `anchorString: ${JSON.stringify(block.getFieldValue('anchorString'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__anchorStringMetadata'] = block => {
         let out = `anchorStringMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__anchorTabProcessorVersion'] = block =>
         `anchorTabProcessorVersion: ${JSON.stringify(block.getFieldValue('anchorTabProcessorVersion'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__anchorTabProcessorVersionMetadata'] = block => {
         let out = `anchorTabProcessorVersionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__anchorUnits'] = block =>
         `anchorUnits: ${JSON.stringify(block.getFieldValue('anchorUnits'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__anchorUnitsMetadata'] = block => {
         let out = `anchorUnitsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__anchorXOffset'] = block =>
         `anchorXOffset: ${JSON.stringify(block.getFieldValue('anchorXOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__anchorXOffsetMetadata'] = block => {
         let out = `anchorXOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__anchorYOffset'] = block =>
         `anchorYOffset: ${JSON.stringify(block.getFieldValue('anchorYOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__anchorYOffsetMetadata'] = block => {
         let out = `anchorYOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__bold'] = block =>
         `bold: ${JSON.stringify(block.getFieldValue('bold'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__boldMetadata'] = block => {
         let out = `boldMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__concealValueOnDocument'] = block =>
         `concealValueOnDocument: ${JSON.stringify(block.getFieldValue('concealValueOnDocument'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__concealValueOnDocumentMetadata'] = block => {
         let out = `concealValueOnDocumentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__conditionalParentLabel'] = block =>
         `conditionalParentLabel: ${JSON.stringify(block.getFieldValue('conditionalParentLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__conditionalParentLabelMetadata'] = block => {
         let out = `conditionalParentLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__conditionalParentValue'] = block =>
         `conditionalParentValue: ${JSON.stringify(block.getFieldValue('conditionalParentValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__conditionalParentValueMetadata'] = block => {
         let out = `conditionalParentValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__customTabId'] = block =>
         `customTabId: ${JSON.stringify(block.getFieldValue('customTabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__customTabIdMetadata'] = block => {
         let out = `customTabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__disableAutoSize'] = block =>
         `disableAutoSize: ${JSON.stringify(block.getFieldValue('disableAutoSize'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__disableAutoSizeMetadata'] = block => {
         let out = `disableAutoSizeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__documentId'] = block =>
         `documentId: ${JSON.stringify(block.getFieldValue('documentId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__documentIdMetadata'] = block => {
         let out = `documentIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__font'] = block =>
         `font: ${JSON.stringify(block.getFieldValue('font'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['formulaTab__fontColor'] = block =>
         `fontColor: ${JSON.stringify(block.getFieldValue('fontColor'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__fontColorMetadata'] = block => {
         let out = `fontColorMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['formulaTab__fontMetadata'] = block => {
         let out = `fontMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__fontSize'] = block =>
         `fontSize: ${JSON.stringify(block.getFieldValue('fontSize'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__fontSizeMetadata'] = block => {
         let out = `fontSizeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__formOrder'] = block =>
         `formOrder: ${JSON.stringify(block.getFieldValue('formOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__formOrderMetadata'] = block => {
         let out = `formOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__formPageLabel'] = block =>
         `formPageLabel: ${JSON.stringify(block.getFieldValue('formPageLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__formPageLabelMetadata'] = block => {
         let out = `formPageLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__formPageNumber'] = block =>
         `formPageNumber: ${JSON.stringify(block.getFieldValue('formPageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__formPageNumberMetadata'] = block => {
         let out = `formPageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__formula'] = block =>
         `formula: ${JSON.stringify(block.getFieldValue('formula'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__formulaMetadata'] = block => {
         let out = `formulaMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__height'] = block =>
         `height: ${JSON.stringify(block.getFieldValue('height'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__heightMetadata'] = block => {
         let out = `heightMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__hidden'] = block =>
         `hidden: ${JSON.stringify(block.getFieldValue('hidden'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__hiddenMetadata'] = block => {
         let out = `hiddenMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['formulaTab__isPaymentAmountMetadata'] = block => {
         let out = `isPaymentAmountMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__italic'] = block =>
         `italic: ${JSON.stringify(block.getFieldValue('italic'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__italicMetadata'] = block => {
         let out = `italicMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__locked'] = block =>
         `locked: ${JSON.stringify(block.getFieldValue('locked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__lockedMetadata'] = block => {
         let out = `lockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__maxLength'] = block =>
         `maxLength: ${JSON.stringify(block.getFieldValue('maxLength'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__maxLengthMetadata'] = block => {
         let out = `maxLengthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__mergeFieldXml'] = block =>
         `mergeFieldXml: ${JSON.stringify(block.getFieldValue('mergeFieldXml'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['formulaTab__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__nameMetadata'] = block => {
         let out = `nameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__originalValue'] = block =>
         `originalValue: ${JSON.stringify(block.getFieldValue('originalValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__originalValueMetadata'] = block => {
         let out = `originalValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__pageNumber'] = block =>
         `pageNumber: ${JSON.stringify(block.getFieldValue('pageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__pageNumberMetadata'] = block => {
         let out = `pageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['formulaTab__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__recipientIdGuidMetadata'] = block => {
         let out = `recipientIdGuidMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['formulaTab__recipientIdMetadata'] = block => {
         let out = `recipientIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__requireAll'] = block =>
         `requireAll: ${JSON.stringify(block.getFieldValue('requireAll'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__requireAllMetadata'] = block => {
         let out = `requireAllMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__requireInitialOnSharedChange'] = block =>
         `requireInitialOnSharedChange: ${JSON.stringify(block.getFieldValue('requireInitialOnSharedChange'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__requireInitialOnSharedChangeMetadata'] = block => {
         let out = `requireInitialOnSharedChangeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__required'] = block =>
         `required: ${JSON.stringify(block.getFieldValue('required'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__requiredMetadata'] = block => {
         let out = `requiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__roundDecimalPlaces'] = block =>
         `roundDecimalPlaces: ${JSON.stringify(block.getFieldValue('roundDecimalPlaces'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__roundDecimalPlacesMetadata'] = block => {
         let out = `roundDecimalPlacesMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__senderRequired'] = block =>
         `senderRequired: ${JSON.stringify(block.getFieldValue('senderRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__senderRequiredMetadata'] = block => {
         let out = `senderRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__shareToRecipients'] = block =>
         `shareToRecipients: ${JSON.stringify(block.getFieldValue('shareToRecipients'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__shareToRecipientsMetadata'] = block => {
         let out = `shareToRecipientsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__shared'] = block =>
         `shared: ${JSON.stringify(block.getFieldValue('shared'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__sharedMetadata'] = block => {
         let out = `sharedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__source'] = block =>
         `source: ${JSON.stringify(block.getFieldValue('source'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['formulaTab__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__statusMetadata'] = block => {
         let out = `statusMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['formulaTab__tabGroupLabels'] = block => {
         let out = `    tabGroupLabels: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'tabGroupLabel', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['formulaTab_tabGroupLabels__tabGroupLabel'] = block =>
         `    ${JSON.stringify(block.getFieldValue('tabGroupLabel'))}${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['formulaTab__tabGroupLabelsMetadata'] = block => {
         let out = `tabGroupLabelsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__tabId'] = block =>
         `tabId: ${JSON.stringify(block.getFieldValue('tabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__tabIdMetadata'] = block => {
         let out = `tabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__tabLabel'] = block =>
         `tabLabel: ${JSON.stringify(block.getFieldValue('tabLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__tabLabelMetadata'] = block => {
         let out = `tabLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__tabOrder'] = block =>
         `tabOrder: ${JSON.stringify(block.getFieldValue('tabOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__tabOrderMetadata'] = block => {
         let out = `tabOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__tabType'] = block =>
         `tabType: ${JSON.stringify(block.getFieldValue('tabType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__tabTypeMetadata'] = block => {
         let out = `tabTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__templateLockedMetadata'] = block => {
         let out = `templateLockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__templateRequiredMetadata'] = block => {
         let out = `templateRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['formulaTab__toolTipMetadata'] = block => {
         let out = `toolTipMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__tooltip'] = block =>
         `tooltip: ${JSON.stringify(block.getFieldValue('tooltip'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['formulaTab__underline'] = block =>
         `underline: ${JSON.stringify(block.getFieldValue('underline'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__underlineMetadata'] = block => {
         let out = `underlineMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__validationMessage'] = block =>
         `validationMessage: ${JSON.stringify(block.getFieldValue('validationMessage'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__validationMessageMetadata'] = block => {
         let out = `validationMessageMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__validationPattern'] = block =>
         `validationPattern: ${JSON.stringify(block.getFieldValue('validationPattern'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__validationPatternMetadata'] = block => {
         let out = `validationPatternMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__value'] = block =>
         `value: ${JSON.stringify(block.getFieldValue('value'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__valueMetadata'] = block => {
         let out = `valueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__width'] = block =>
         `width: ${JSON.stringify(block.getFieldValue('width'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__widthMetadata'] = block => {
         let out = `widthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__xPosition'] = block =>
         `xPosition: ${JSON.stringify(block.getFieldValue('xPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__xPositionMetadata'] = block => {
         let out = `xPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['formulaTab__yPosition'] = block =>
         `yPosition: ${JSON.stringify(block.getFieldValue('yPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['formulaTab__yPositionMetadata'] = block => {
         let out = `yPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

Blockly.JavaScript['fullName'] = block => 
  `\n.add_object("fullName", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['fullName__anchorAllowWhiteSpaceInCharacters'] = block =>
         `anchorAllowWhiteSpaceInCharacters: ${JSON.stringify(block.getFieldValue('anchorAllowWhiteSpaceInCharacters'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['fullName__anchorAllowWhiteSpaceInCharactersMetadata'] = block => {
         let out = `anchorAllowWhiteSpaceInCharactersMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['fullName__anchorCaseSensitive'] = block =>
         `anchorCaseSensitive: ${JSON.stringify(block.getFieldValue('anchorCaseSensitive'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['fullName__anchorCaseSensitiveMetadata'] = block => {
         let out = `anchorCaseSensitiveMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['fullName__anchorHorizontalAlignment'] = block =>
         `anchorHorizontalAlignment: ${JSON.stringify(block.getFieldValue('anchorHorizontalAlignment'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['fullName__anchorHorizontalAlignmentMetadata'] = block => {
         let out = `anchorHorizontalAlignmentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['fullName__anchorIgnoreIfNotPresent'] = block =>
         `anchorIgnoreIfNotPresent: ${JSON.stringify(block.getFieldValue('anchorIgnoreIfNotPresent'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['fullName__anchorIgnoreIfNotPresentMetadata'] = block => {
         let out = `anchorIgnoreIfNotPresentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['fullName__anchorMatchWholeWord'] = block =>
         `anchorMatchWholeWord: ${JSON.stringify(block.getFieldValue('anchorMatchWholeWord'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['fullName__anchorMatchWholeWordMetadata'] = block => {
         let out = `anchorMatchWholeWordMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['fullName__anchorString'] = block =>
         `anchorString: ${JSON.stringify(block.getFieldValue('anchorString'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['fullName__anchorStringMetadata'] = block => {
         let out = `anchorStringMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['fullName__anchorTabProcessorVersion'] = block =>
         `anchorTabProcessorVersion: ${JSON.stringify(block.getFieldValue('anchorTabProcessorVersion'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['fullName__anchorTabProcessorVersionMetadata'] = block => {
         let out = `anchorTabProcessorVersionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['fullName__anchorUnits'] = block =>
         `anchorUnits: ${JSON.stringify(block.getFieldValue('anchorUnits'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['fullName__anchorUnitsMetadata'] = block => {
         let out = `anchorUnitsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['fullName__anchorXOffset'] = block =>
         `anchorXOffset: ${JSON.stringify(block.getFieldValue('anchorXOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['fullName__anchorXOffsetMetadata'] = block => {
         let out = `anchorXOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['fullName__anchorYOffset'] = block =>
         `anchorYOffset: ${JSON.stringify(block.getFieldValue('anchorYOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['fullName__anchorYOffsetMetadata'] = block => {
         let out = `anchorYOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['fullName__bold'] = block =>
         `bold: ${JSON.stringify(block.getFieldValue('bold'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['fullName__boldMetadata'] = block => {
         let out = `boldMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['fullName__conditionalParentLabel'] = block =>
         `conditionalParentLabel: ${JSON.stringify(block.getFieldValue('conditionalParentLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['fullName__conditionalParentLabelMetadata'] = block => {
         let out = `conditionalParentLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['fullName__conditionalParentValue'] = block =>
         `conditionalParentValue: ${JSON.stringify(block.getFieldValue('conditionalParentValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['fullName__conditionalParentValueMetadata'] = block => {
         let out = `conditionalParentValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['fullName__customTabId'] = block =>
         `customTabId: ${JSON.stringify(block.getFieldValue('customTabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['fullName__customTabIdMetadata'] = block => {
         let out = `customTabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['fullName__documentId'] = block =>
         `documentId: ${JSON.stringify(block.getFieldValue('documentId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['fullName__documentIdMetadata'] = block => {
         let out = `documentIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['fullName__font'] = block =>
         `font: ${JSON.stringify(block.getFieldValue('font'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['fullName__fontColor'] = block =>
         `fontColor: ${JSON.stringify(block.getFieldValue('fontColor'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['fullName__fontColorMetadata'] = block => {
         let out = `fontColorMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['fullName__fontMetadata'] = block => {
         let out = `fontMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['fullName__fontSize'] = block =>
         `fontSize: ${JSON.stringify(block.getFieldValue('fontSize'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['fullName__fontSizeMetadata'] = block => {
         let out = `fontSizeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['fullName__formOrder'] = block =>
         `formOrder: ${JSON.stringify(block.getFieldValue('formOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['fullName__formOrderMetadata'] = block => {
         let out = `formOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['fullName__formPageLabel'] = block =>
         `formPageLabel: ${JSON.stringify(block.getFieldValue('formPageLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['fullName__formPageLabelMetadata'] = block => {
         let out = `formPageLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['fullName__formPageNumber'] = block =>
         `formPageNumber: ${JSON.stringify(block.getFieldValue('formPageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['fullName__formPageNumberMetadata'] = block => {
         let out = `formPageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['fullName__height'] = block =>
         `height: ${JSON.stringify(block.getFieldValue('height'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['fullName__heightMetadata'] = block => {
         let out = `heightMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['fullName__italic'] = block =>
         `italic: ${JSON.stringify(block.getFieldValue('italic'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['fullName__italicMetadata'] = block => {
         let out = `italicMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['fullName__mergeFieldXml'] = block =>
         `mergeFieldXml: ${JSON.stringify(block.getFieldValue('mergeFieldXml'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['fullName__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['fullName__nameMetadata'] = block => {
         let out = `nameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['fullName__pageNumber'] = block =>
         `pageNumber: ${JSON.stringify(block.getFieldValue('pageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['fullName__pageNumberMetadata'] = block => {
         let out = `pageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['fullName__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['fullName__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['fullName__recipientIdGuidMetadata'] = block => {
         let out = `recipientIdGuidMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['fullName__recipientIdMetadata'] = block => {
         let out = `recipientIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['fullName__source'] = block =>
         `source: ${JSON.stringify(block.getFieldValue('source'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['fullName__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['fullName__statusMetadata'] = block => {
         let out = `statusMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['fullName__tabGroupLabels'] = block => {
         let out = `    tabGroupLabels: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'tabGroupLabel', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['fullName_tabGroupLabels__tabGroupLabel'] = block =>
         `    ${JSON.stringify(block.getFieldValue('tabGroupLabel'))}${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['fullName__tabGroupLabelsMetadata'] = block => {
         let out = `tabGroupLabelsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['fullName__tabId'] = block =>
         `tabId: ${JSON.stringify(block.getFieldValue('tabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['fullName__tabIdMetadata'] = block => {
         let out = `tabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['fullName__tabLabel'] = block =>
         `tabLabel: ${JSON.stringify(block.getFieldValue('tabLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['fullName__tabLabelMetadata'] = block => {
         let out = `tabLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['fullName__tabOrder'] = block =>
         `tabOrder: ${JSON.stringify(block.getFieldValue('tabOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['fullName__tabOrderMetadata'] = block => {
         let out = `tabOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['fullName__tabType'] = block =>
         `tabType: ${JSON.stringify(block.getFieldValue('tabType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['fullName__tabTypeMetadata'] = block => {
         let out = `tabTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['fullName__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['fullName__templateLockedMetadata'] = block => {
         let out = `templateLockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['fullName__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['fullName__templateRequiredMetadata'] = block => {
         let out = `templateRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['fullName__toolTipMetadata'] = block => {
         let out = `toolTipMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['fullName__tooltip'] = block =>
         `tooltip: ${JSON.stringify(block.getFieldValue('tooltip'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['fullName__underline'] = block =>
         `underline: ${JSON.stringify(block.getFieldValue('underline'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['fullName__underlineMetadata'] = block => {
         let out = `underlineMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['fullName__value'] = block =>
         `value: ${JSON.stringify(block.getFieldValue('value'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['fullName__valueMetadata'] = block => {
         let out = `valueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['fullName__width'] = block =>
         `width: ${JSON.stringify(block.getFieldValue('width'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['fullName__widthMetadata'] = block => {
         let out = `widthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['fullName__xPosition'] = block =>
         `xPosition: ${JSON.stringify(block.getFieldValue('xPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['fullName__xPositionMetadata'] = block => {
         let out = `xPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['fullName__yPosition'] = block =>
         `yPosition: ${JSON.stringify(block.getFieldValue('yPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['fullName__yPositionMetadata'] = block => {
         let out = `yPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

Blockly.JavaScript['graphicsContext'] = block => 
  `\n.add_object("graphicsContext", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['graphicsContext__fillColor'] = block =>
         `fillColor: ${JSON.stringify(block.getFieldValue('fillColor'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['graphicsContext__lineColor'] = block =>
         `lineColor: ${JSON.stringify(block.getFieldValue('lineColor'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['graphicsContext__lineWeight'] = block =>
         `lineWeight: ${JSON.stringify(block.getFieldValue('lineWeight'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['inPersonSigner'] = block => 
  `\n.add_object("inPersonSigner", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['inPersonSigner__accessCode'] = block =>
         `accessCode: ${JSON.stringify(block.getFieldValue('accessCode'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['inPersonSigner__accessCodeMetadata'] = block => {
         let out = `accessCodeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['inPersonSigner__addAccessCodeToEmail'] = block =>
         `addAccessCodeToEmail: ${JSON.stringify(block.getFieldValue('addAccessCodeToEmail'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['inPersonSigner__allowSystemOverrideForLockedRecipient'] = block =>
         `allowSystemOverrideForLockedRecipient: ${JSON.stringify(block.getFieldValue('allowSystemOverrideForLockedRecipient'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['inPersonSigner__autoNavigation'] = block =>
         `autoNavigation: ${JSON.stringify(block.getFieldValue('autoNavigation'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['inPersonSigner__autoRespondedReason'] = block =>
         `autoRespondedReason: ${JSON.stringify(block.getFieldValue('autoRespondedReason'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['inPersonSigner__canSignOffline'] = block =>
         `canSignOffline: ${JSON.stringify(block.getFieldValue('canSignOffline'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['inPersonSigner__clientUserId'] = block =>
         `clientUserId: ${JSON.stringify(block.getFieldValue('clientUserId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['inPersonSigner__completedCount'] = block =>
         `completedCount: ${JSON.stringify(block.getFieldValue('completedCount'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['inPersonSigner__creationReason'] = block =>
         `creationReason: ${JSON.stringify(block.getFieldValue('creationReason'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['inPersonSigner__customFields'] = block => {
         let out = `    customFields: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'customField', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['inPersonSigner_customFields__customField'] = block =>
         `    ${JSON.stringify(block.getFieldValue('customField'))}${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['inPersonSigner__declinedDateTime'] = block =>
         `declinedDateTime: ${JSON.stringify(block.getFieldValue('declinedDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['inPersonSigner__declinedReason'] = block =>
         `declinedReason: ${JSON.stringify(block.getFieldValue('declinedReason'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['inPersonSigner__defaultRecipient'] = block =>
         `defaultRecipient: ${JSON.stringify(block.getFieldValue('defaultRecipient'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['inPersonSigner__deliveredDateTime'] = block =>
         `deliveredDateTime: ${JSON.stringify(block.getFieldValue('deliveredDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['inPersonSigner__deliveryMethod'] = block =>
         `deliveryMethod: ${JSON.stringify(block.getFieldValue('deliveryMethod'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['inPersonSigner__deliveryMethodMetadata'] = block => {
         let out = `deliveryMethodMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['inPersonSigner__designatorId'] = block =>
         `designatorId: ${JSON.stringify(block.getFieldValue('designatorId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['inPersonSigner__designatorIdGuid'] = block =>
         `designatorIdGuid: ${JSON.stringify(block.getFieldValue('designatorIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['inPersonSigner__email'] = block =>
         `email: ${JSON.stringify(block.getFieldValue('email'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['inPersonSigner__emailMetadata'] = block => {
         let out = `emailMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['inPersonSigner__embeddedRecipientStartURL'] = block =>
         `embeddedRecipientStartURL: ${JSON.stringify(block.getFieldValue('embeddedRecipientStartURL'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['inPersonSigner__excludedDocuments'] = block => {
         let out = `    excludedDocuments: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'excludedDocument', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['inPersonSigner_excludedDocuments__excludedDocument'] = block =>
         `    ${JSON.stringify(block.getFieldValue('excludedDocument'))}${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['inPersonSigner__faxNumber'] = block =>
         `faxNumber: ${JSON.stringify(block.getFieldValue('faxNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['inPersonSigner__faxNumberMetadata'] = block => {
         let out = `faxNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['inPersonSigner__hostEmail'] = block =>
         `hostEmail: ${JSON.stringify(block.getFieldValue('hostEmail'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['inPersonSigner__hostEmailMetadata'] = block => {
         let out = `hostEmailMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['inPersonSigner__hostName'] = block =>
         `hostName: ${JSON.stringify(block.getFieldValue('hostName'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['inPersonSigner__hostNameMetadata'] = block => {
         let out = `hostNameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['inPersonSigner__idCheckConfigurationName'] = block =>
         `idCheckConfigurationName: ${JSON.stringify(block.getFieldValue('idCheckConfigurationName'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['inPersonSigner__idCheckConfigurationNameMetadata'] = block => {
         let out = `idCheckConfigurationNameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['inPersonSigner__inPersonSigningType'] = block =>
         `inPersonSigningType: ${JSON.stringify(block.getFieldValue('inPersonSigningType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['inPersonSigner__inPersonSigningTypeMetadata'] = block => {
         let out = `inPersonSigningTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['inPersonSigner__inheritEmailNotificationConfiguration'] = block =>
         `inheritEmailNotificationConfiguration: ${JSON.stringify(block.getFieldValue('inheritEmailNotificationConfiguration'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['inPersonSigner__lockedRecipientPhoneAuthEditable'] = block =>
         `lockedRecipientPhoneAuthEditable: ${JSON.stringify(block.getFieldValue('lockedRecipientPhoneAuthEditable'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['inPersonSigner__lockedRecipientSmsEditable'] = block =>
         `lockedRecipientSmsEditable: ${JSON.stringify(block.getFieldValue('lockedRecipientSmsEditable'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['inPersonSigner__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['inPersonSigner__nameMetadata'] = block => {
         let out = `nameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['inPersonSigner__notaryId'] = block =>
         `notaryId: ${JSON.stringify(block.getFieldValue('notaryId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['inPersonSigner__note'] = block =>
         `note: ${JSON.stringify(block.getFieldValue('note'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['inPersonSigner__noteMetadata'] = block => {
         let out = `noteMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['inPersonSigner__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['inPersonSigner__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['inPersonSigner__recipientSuppliesTabs'] = block =>
         `recipientSuppliesTabs: ${JSON.stringify(block.getFieldValue('recipientSuppliesTabs'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['inPersonSigner__recipientType'] = block =>
         `recipientType: ${JSON.stringify(block.getFieldValue('recipientType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['inPersonSigner__recipientTypeMetadata'] = block => {
         let out = `recipientTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['inPersonSigner__requireIdLookup'] = block =>
         `requireIdLookup: ${JSON.stringify(block.getFieldValue('requireIdLookup'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['inPersonSigner__requireIdLookupMetadata'] = block => {
         let out = `requireIdLookupMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['inPersonSigner__requireSignOnPaper'] = block =>
         `requireSignOnPaper: ${JSON.stringify(block.getFieldValue('requireSignOnPaper'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['inPersonSigner__requireSignerCertificate'] = block =>
         `requireSignerCertificate: ${JSON.stringify(block.getFieldValue('requireSignerCertificate'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['inPersonSigner__requireUploadSignature'] = block =>
         `requireUploadSignature: ${JSON.stringify(block.getFieldValue('requireUploadSignature'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['inPersonSigner__roleName'] = block =>
         `roleName: ${JSON.stringify(block.getFieldValue('roleName'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['inPersonSigner__routingOrder'] = block =>
         `routingOrder: ${JSON.stringify(block.getFieldValue('routingOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['inPersonSigner__routingOrderMetadata'] = block => {
         let out = `routingOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['inPersonSigner__sentDateTime'] = block =>
         `sentDateTime: ${JSON.stringify(block.getFieldValue('sentDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['inPersonSigner__signInEachLocation'] = block =>
         `signInEachLocation: ${JSON.stringify(block.getFieldValue('signInEachLocation'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['inPersonSigner__signInEachLocationMetadata'] = block => {
         let out = `signInEachLocationMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['inPersonSigner__signedDateTime'] = block =>
         `signedDateTime: ${JSON.stringify(block.getFieldValue('signedDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['inPersonSigner__signerEmail'] = block =>
         `signerEmail: ${JSON.stringify(block.getFieldValue('signerEmail'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['inPersonSigner__signerEmailMetadata'] = block => {
         let out = `signerEmailMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['inPersonSigner__signerFirstName'] = block =>
         `signerFirstName: ${JSON.stringify(block.getFieldValue('signerFirstName'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['inPersonSigner__signerFirstNameMetadata'] = block => {
         let out = `signerFirstNameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['inPersonSigner__signerLastName'] = block =>
         `signerLastName: ${JSON.stringify(block.getFieldValue('signerLastName'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['inPersonSigner__signerLastNameMetadata'] = block => {
         let out = `signerLastNameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['inPersonSigner__signerName'] = block =>
         `signerName: ${JSON.stringify(block.getFieldValue('signerName'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['inPersonSigner__signerNameMetadata'] = block => {
         let out = `signerNameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['inPersonSigner__signingGroupId'] = block =>
         `signingGroupId: ${JSON.stringify(block.getFieldValue('signingGroupId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['inPersonSigner__signingGroupIdMetadata'] = block => {
         let out = `signingGroupIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['inPersonSigner__signingGroupName'] = block =>
         `signingGroupName: ${JSON.stringify(block.getFieldValue('signingGroupName'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['inPersonSigner__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['inPersonSigner__statusCode'] = block =>
         `statusCode: ${JSON.stringify(block.getFieldValue('statusCode'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['inPersonSigner__suppressEmails'] = block =>
         `suppressEmails: ${JSON.stringify(block.getFieldValue('suppressEmails'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['inPersonSigner__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['inPersonSigner__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['inPersonSigner__totalTabCount'] = block =>
         `totalTabCount: ${JSON.stringify(block.getFieldValue('totalTabCount'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['inPersonSigner__userId'] = block =>
         `userId: ${JSON.stringify(block.getFieldValue('userId'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['initialHere'] = block => 
  `\n.add_object("initialHere", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['initialHere__anchorAllowWhiteSpaceInCharacters'] = block =>
         `anchorAllowWhiteSpaceInCharacters: ${JSON.stringify(block.getFieldValue('anchorAllowWhiteSpaceInCharacters'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['initialHere__anchorAllowWhiteSpaceInCharactersMetadata'] = block => {
         let out = `anchorAllowWhiteSpaceInCharactersMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['initialHere__anchorCaseSensitive'] = block =>
         `anchorCaseSensitive: ${JSON.stringify(block.getFieldValue('anchorCaseSensitive'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['initialHere__anchorCaseSensitiveMetadata'] = block => {
         let out = `anchorCaseSensitiveMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['initialHere__anchorHorizontalAlignment'] = block =>
         `anchorHorizontalAlignment: ${JSON.stringify(block.getFieldValue('anchorHorizontalAlignment'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['initialHere__anchorHorizontalAlignmentMetadata'] = block => {
         let out = `anchorHorizontalAlignmentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['initialHere__anchorIgnoreIfNotPresent'] = block =>
         `anchorIgnoreIfNotPresent: ${JSON.stringify(block.getFieldValue('anchorIgnoreIfNotPresent'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['initialHere__anchorIgnoreIfNotPresentMetadata'] = block => {
         let out = `anchorIgnoreIfNotPresentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['initialHere__anchorMatchWholeWord'] = block =>
         `anchorMatchWholeWord: ${JSON.stringify(block.getFieldValue('anchorMatchWholeWord'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['initialHere__anchorMatchWholeWordMetadata'] = block => {
         let out = `anchorMatchWholeWordMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['initialHere__anchorString'] = block =>
         `anchorString: ${JSON.stringify(block.getFieldValue('anchorString'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['initialHere__anchorStringMetadata'] = block => {
         let out = `anchorStringMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['initialHere__anchorTabProcessorVersion'] = block =>
         `anchorTabProcessorVersion: ${JSON.stringify(block.getFieldValue('anchorTabProcessorVersion'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['initialHere__anchorTabProcessorVersionMetadata'] = block => {
         let out = `anchorTabProcessorVersionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['initialHere__anchorUnits'] = block =>
         `anchorUnits: ${JSON.stringify(block.getFieldValue('anchorUnits'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['initialHere__anchorUnitsMetadata'] = block => {
         let out = `anchorUnitsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['initialHere__anchorXOffset'] = block =>
         `anchorXOffset: ${JSON.stringify(block.getFieldValue('anchorXOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['initialHere__anchorXOffsetMetadata'] = block => {
         let out = `anchorXOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['initialHere__anchorYOffset'] = block =>
         `anchorYOffset: ${JSON.stringify(block.getFieldValue('anchorYOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['initialHere__anchorYOffsetMetadata'] = block => {
         let out = `anchorYOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['initialHere__conditionalParentLabel'] = block =>
         `conditionalParentLabel: ${JSON.stringify(block.getFieldValue('conditionalParentLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['initialHere__conditionalParentLabelMetadata'] = block => {
         let out = `conditionalParentLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['initialHere__conditionalParentValue'] = block =>
         `conditionalParentValue: ${JSON.stringify(block.getFieldValue('conditionalParentValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['initialHere__conditionalParentValueMetadata'] = block => {
         let out = `conditionalParentValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['initialHere__customTabId'] = block =>
         `customTabId: ${JSON.stringify(block.getFieldValue('customTabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['initialHere__customTabIdMetadata'] = block => {
         let out = `customTabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['initialHere__documentId'] = block =>
         `documentId: ${JSON.stringify(block.getFieldValue('documentId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['initialHere__documentIdMetadata'] = block => {
         let out = `documentIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['initialHere__formOrder'] = block =>
         `formOrder: ${JSON.stringify(block.getFieldValue('formOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['initialHere__formOrderMetadata'] = block => {
         let out = `formOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['initialHere__formPageLabel'] = block =>
         `formPageLabel: ${JSON.stringify(block.getFieldValue('formPageLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['initialHere__formPageLabelMetadata'] = block => {
         let out = `formPageLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['initialHere__formPageNumber'] = block =>
         `formPageNumber: ${JSON.stringify(block.getFieldValue('formPageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['initialHere__formPageNumberMetadata'] = block => {
         let out = `formPageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['initialHere__handDrawRequired'] = block =>
         `handDrawRequired: ${JSON.stringify(block.getFieldValue('handDrawRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['initialHere__height'] = block =>
         `height: ${JSON.stringify(block.getFieldValue('height'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['initialHere__heightMetadata'] = block => {
         let out = `heightMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['initialHere__mergeFieldXml'] = block =>
         `mergeFieldXml: ${JSON.stringify(block.getFieldValue('mergeFieldXml'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['initialHere__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['initialHere__nameMetadata'] = block => {
         let out = `nameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['initialHere__optional'] = block =>
         `optional: ${JSON.stringify(block.getFieldValue('optional'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['initialHere__optionalMetadata'] = block => {
         let out = `optionalMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['initialHere__pageNumber'] = block =>
         `pageNumber: ${JSON.stringify(block.getFieldValue('pageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['initialHere__pageNumberMetadata'] = block => {
         let out = `pageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['initialHere__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['initialHere__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['initialHere__recipientIdGuidMetadata'] = block => {
         let out = `recipientIdGuidMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['initialHere__recipientIdMetadata'] = block => {
         let out = `recipientIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['initialHere__scaleValue'] = block =>
         `scaleValue: ${JSON.stringify(block.getFieldValue('scaleValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['initialHere__scaleValueMetadata'] = block => {
         let out = `scaleValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['initialHere__source'] = block =>
         `source: ${JSON.stringify(block.getFieldValue('source'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['initialHere__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['initialHere__statusMetadata'] = block => {
         let out = `statusMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['initialHere__tabGroupLabels'] = block => {
         let out = `    tabGroupLabels: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'tabGroupLabel', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['initialHere_tabGroupLabels__tabGroupLabel'] = block =>
         `    ${JSON.stringify(block.getFieldValue('tabGroupLabel'))}${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['initialHere__tabGroupLabelsMetadata'] = block => {
         let out = `tabGroupLabelsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['initialHere__tabId'] = block =>
         `tabId: ${JSON.stringify(block.getFieldValue('tabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['initialHere__tabIdMetadata'] = block => {
         let out = `tabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['initialHere__tabLabel'] = block =>
         `tabLabel: ${JSON.stringify(block.getFieldValue('tabLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['initialHere__tabLabelMetadata'] = block => {
         let out = `tabLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['initialHere__tabOrder'] = block =>
         `tabOrder: ${JSON.stringify(block.getFieldValue('tabOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['initialHere__tabOrderMetadata'] = block => {
         let out = `tabOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['initialHere__tabType'] = block =>
         `tabType: ${JSON.stringify(block.getFieldValue('tabType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['initialHere__tabTypeMetadata'] = block => {
         let out = `tabTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['initialHere__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['initialHere__templateLockedMetadata'] = block => {
         let out = `templateLockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['initialHere__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['initialHere__templateRequiredMetadata'] = block => {
         let out = `templateRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['initialHere__toolTipMetadata'] = block => {
         let out = `toolTipMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['initialHere__tooltip'] = block =>
         `tooltip: ${JSON.stringify(block.getFieldValue('tooltip'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['initialHere__width'] = block =>
         `width: ${JSON.stringify(block.getFieldValue('width'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['initialHere__widthMetadata'] = block => {
         let out = `widthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['initialHere__xPosition'] = block =>
         `xPosition: ${JSON.stringify(block.getFieldValue('xPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['initialHere__xPositionMetadata'] = block => {
         let out = `xPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['initialHere__yPosition'] = block =>
         `yPosition: ${JSON.stringify(block.getFieldValue('yPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['initialHere__yPositionMetadata'] = block => {
         let out = `yPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

Blockly.JavaScript['inlineTemplate'] = block => 
  `\n.add_object("inlineTemplate", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['inlineTemplate__sequence'] = block =>
         `sequence: ${JSON.stringify(block.getFieldValue('sequence'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['intermediary'] = block => 
  `\n.add_object("intermediary", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['intermediary__accessCode'] = block =>
         `accessCode: ${JSON.stringify(block.getFieldValue('accessCode'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['intermediary__accessCodeMetadata'] = block => {
         let out = `accessCodeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['intermediary__addAccessCodeToEmail'] = block =>
         `addAccessCodeToEmail: ${JSON.stringify(block.getFieldValue('addAccessCodeToEmail'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['intermediary__allowSystemOverrideForLockedRecipient'] = block =>
         `allowSystemOverrideForLockedRecipient: ${JSON.stringify(block.getFieldValue('allowSystemOverrideForLockedRecipient'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['intermediary__autoRespondedReason'] = block =>
         `autoRespondedReason: ${JSON.stringify(block.getFieldValue('autoRespondedReason'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['intermediary__clientUserId'] = block =>
         `clientUserId: ${JSON.stringify(block.getFieldValue('clientUserId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['intermediary__completedCount'] = block =>
         `completedCount: ${JSON.stringify(block.getFieldValue('completedCount'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['intermediary__customFields'] = block => {
         let out = `    customFields: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'customField', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['intermediary_customFields__customField'] = block =>
         `    ${JSON.stringify(block.getFieldValue('customField'))}${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['intermediary__declinedDateTime'] = block =>
         `declinedDateTime: ${JSON.stringify(block.getFieldValue('declinedDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['intermediary__declinedReason'] = block =>
         `declinedReason: ${JSON.stringify(block.getFieldValue('declinedReason'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['intermediary__deliveredDateTime'] = block =>
         `deliveredDateTime: ${JSON.stringify(block.getFieldValue('deliveredDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['intermediary__deliveryMethod'] = block =>
         `deliveryMethod: ${JSON.stringify(block.getFieldValue('deliveryMethod'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['intermediary__deliveryMethodMetadata'] = block => {
         let out = `deliveryMethodMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['intermediary__designatorId'] = block =>
         `designatorId: ${JSON.stringify(block.getFieldValue('designatorId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['intermediary__designatorIdGuid'] = block =>
         `designatorIdGuid: ${JSON.stringify(block.getFieldValue('designatorIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['intermediary__email'] = block =>
         `email: ${JSON.stringify(block.getFieldValue('email'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['intermediary__emailMetadata'] = block => {
         let out = `emailMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['intermediary__emailRecipientPostSigningURL'] = block =>
         `emailRecipientPostSigningURL: ${JSON.stringify(block.getFieldValue('emailRecipientPostSigningURL'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['intermediary__embeddedRecipientStartURL'] = block =>
         `embeddedRecipientStartURL: ${JSON.stringify(block.getFieldValue('embeddedRecipientStartURL'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['intermediary__excludedDocuments'] = block => {
         let out = `    excludedDocuments: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'excludedDocument', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['intermediary_excludedDocuments__excludedDocument'] = block =>
         `    ${JSON.stringify(block.getFieldValue('excludedDocument'))}${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['intermediary__faxNumber'] = block =>
         `faxNumber: ${JSON.stringify(block.getFieldValue('faxNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['intermediary__faxNumberMetadata'] = block => {
         let out = `faxNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['intermediary__firstName'] = block =>
         `firstName: ${JSON.stringify(block.getFieldValue('firstName'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['intermediary__firstNameMetadata'] = block => {
         let out = `firstNameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['intermediary__fullName'] = block =>
         `fullName: ${JSON.stringify(block.getFieldValue('fullName'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['intermediary__fullNameMetadata'] = block => {
         let out = `fullNameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['intermediary__idCheckConfigurationName'] = block =>
         `idCheckConfigurationName: ${JSON.stringify(block.getFieldValue('idCheckConfigurationName'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['intermediary__idCheckConfigurationNameMetadata'] = block => {
         let out = `idCheckConfigurationNameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['intermediary__inheritEmailNotificationConfiguration'] = block =>
         `inheritEmailNotificationConfiguration: ${JSON.stringify(block.getFieldValue('inheritEmailNotificationConfiguration'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['intermediary__lastName'] = block =>
         `lastName: ${JSON.stringify(block.getFieldValue('lastName'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['intermediary__lastNameMetadata'] = block => {
         let out = `lastNameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['intermediary__lockedRecipientPhoneAuthEditable'] = block =>
         `lockedRecipientPhoneAuthEditable: ${JSON.stringify(block.getFieldValue('lockedRecipientPhoneAuthEditable'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['intermediary__lockedRecipientSmsEditable'] = block =>
         `lockedRecipientSmsEditable: ${JSON.stringify(block.getFieldValue('lockedRecipientSmsEditable'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['intermediary__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['intermediary__nameMetadata'] = block => {
         let out = `nameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['intermediary__note'] = block =>
         `note: ${JSON.stringify(block.getFieldValue('note'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['intermediary__noteMetadata'] = block => {
         let out = `noteMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['intermediary__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['intermediary__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['intermediary__recipientType'] = block =>
         `recipientType: ${JSON.stringify(block.getFieldValue('recipientType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['intermediary__recipientTypeMetadata'] = block => {
         let out = `recipientTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['intermediary__requireIdLookup'] = block =>
         `requireIdLookup: ${JSON.stringify(block.getFieldValue('requireIdLookup'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['intermediary__requireIdLookupMetadata'] = block => {
         let out = `requireIdLookupMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['intermediary__roleName'] = block =>
         `roleName: ${JSON.stringify(block.getFieldValue('roleName'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['intermediary__routingOrder'] = block =>
         `routingOrder: ${JSON.stringify(block.getFieldValue('routingOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['intermediary__routingOrderMetadata'] = block => {
         let out = `routingOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['intermediary__sentDateTime'] = block =>
         `sentDateTime: ${JSON.stringify(block.getFieldValue('sentDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['intermediary__signedDateTime'] = block =>
         `signedDateTime: ${JSON.stringify(block.getFieldValue('signedDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['intermediary__signingGroupId'] = block =>
         `signingGroupId: ${JSON.stringify(block.getFieldValue('signingGroupId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['intermediary__signingGroupIdMetadata'] = block => {
         let out = `signingGroupIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['intermediary__signingGroupName'] = block =>
         `signingGroupName: ${JSON.stringify(block.getFieldValue('signingGroupName'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['intermediary__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['intermediary__statusCode'] = block =>
         `statusCode: ${JSON.stringify(block.getFieldValue('statusCode'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['intermediary__suppressEmails'] = block =>
         `suppressEmails: ${JSON.stringify(block.getFieldValue('suppressEmails'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['intermediary__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['intermediary__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['intermediary__totalTabCount'] = block =>
         `totalTabCount: ${JSON.stringify(block.getFieldValue('totalTabCount'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['intermediary__userId'] = block =>
         `userId: ${JSON.stringify(block.getFieldValue('userId'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['lastName'] = block => 
  `\n.add_object("lastName", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['lastName__anchorAllowWhiteSpaceInCharacters'] = block =>
         `anchorAllowWhiteSpaceInCharacters: ${JSON.stringify(block.getFieldValue('anchorAllowWhiteSpaceInCharacters'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['lastName__anchorAllowWhiteSpaceInCharactersMetadata'] = block => {
         let out = `anchorAllowWhiteSpaceInCharactersMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['lastName__anchorCaseSensitive'] = block =>
         `anchorCaseSensitive: ${JSON.stringify(block.getFieldValue('anchorCaseSensitive'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['lastName__anchorCaseSensitiveMetadata'] = block => {
         let out = `anchorCaseSensitiveMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['lastName__anchorHorizontalAlignment'] = block =>
         `anchorHorizontalAlignment: ${JSON.stringify(block.getFieldValue('anchorHorizontalAlignment'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['lastName__anchorHorizontalAlignmentMetadata'] = block => {
         let out = `anchorHorizontalAlignmentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['lastName__anchorIgnoreIfNotPresent'] = block =>
         `anchorIgnoreIfNotPresent: ${JSON.stringify(block.getFieldValue('anchorIgnoreIfNotPresent'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['lastName__anchorIgnoreIfNotPresentMetadata'] = block => {
         let out = `anchorIgnoreIfNotPresentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['lastName__anchorMatchWholeWord'] = block =>
         `anchorMatchWholeWord: ${JSON.stringify(block.getFieldValue('anchorMatchWholeWord'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['lastName__anchorMatchWholeWordMetadata'] = block => {
         let out = `anchorMatchWholeWordMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['lastName__anchorString'] = block =>
         `anchorString: ${JSON.stringify(block.getFieldValue('anchorString'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['lastName__anchorStringMetadata'] = block => {
         let out = `anchorStringMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['lastName__anchorTabProcessorVersion'] = block =>
         `anchorTabProcessorVersion: ${JSON.stringify(block.getFieldValue('anchorTabProcessorVersion'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['lastName__anchorTabProcessorVersionMetadata'] = block => {
         let out = `anchorTabProcessorVersionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['lastName__anchorUnits'] = block =>
         `anchorUnits: ${JSON.stringify(block.getFieldValue('anchorUnits'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['lastName__anchorUnitsMetadata'] = block => {
         let out = `anchorUnitsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['lastName__anchorXOffset'] = block =>
         `anchorXOffset: ${JSON.stringify(block.getFieldValue('anchorXOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['lastName__anchorXOffsetMetadata'] = block => {
         let out = `anchorXOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['lastName__anchorYOffset'] = block =>
         `anchorYOffset: ${JSON.stringify(block.getFieldValue('anchorYOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['lastName__anchorYOffsetMetadata'] = block => {
         let out = `anchorYOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['lastName__bold'] = block =>
         `bold: ${JSON.stringify(block.getFieldValue('bold'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['lastName__boldMetadata'] = block => {
         let out = `boldMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['lastName__conditionalParentLabel'] = block =>
         `conditionalParentLabel: ${JSON.stringify(block.getFieldValue('conditionalParentLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['lastName__conditionalParentLabelMetadata'] = block => {
         let out = `conditionalParentLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['lastName__conditionalParentValue'] = block =>
         `conditionalParentValue: ${JSON.stringify(block.getFieldValue('conditionalParentValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['lastName__conditionalParentValueMetadata'] = block => {
         let out = `conditionalParentValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['lastName__customTabId'] = block =>
         `customTabId: ${JSON.stringify(block.getFieldValue('customTabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['lastName__customTabIdMetadata'] = block => {
         let out = `customTabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['lastName__documentId'] = block =>
         `documentId: ${JSON.stringify(block.getFieldValue('documentId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['lastName__documentIdMetadata'] = block => {
         let out = `documentIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['lastName__font'] = block =>
         `font: ${JSON.stringify(block.getFieldValue('font'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['lastName__fontColor'] = block =>
         `fontColor: ${JSON.stringify(block.getFieldValue('fontColor'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['lastName__fontColorMetadata'] = block => {
         let out = `fontColorMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['lastName__fontMetadata'] = block => {
         let out = `fontMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['lastName__fontSize'] = block =>
         `fontSize: ${JSON.stringify(block.getFieldValue('fontSize'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['lastName__fontSizeMetadata'] = block => {
         let out = `fontSizeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['lastName__formOrder'] = block =>
         `formOrder: ${JSON.stringify(block.getFieldValue('formOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['lastName__formOrderMetadata'] = block => {
         let out = `formOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['lastName__formPageLabel'] = block =>
         `formPageLabel: ${JSON.stringify(block.getFieldValue('formPageLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['lastName__formPageLabelMetadata'] = block => {
         let out = `formPageLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['lastName__formPageNumber'] = block =>
         `formPageNumber: ${JSON.stringify(block.getFieldValue('formPageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['lastName__formPageNumberMetadata'] = block => {
         let out = `formPageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['lastName__height'] = block =>
         `height: ${JSON.stringify(block.getFieldValue('height'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['lastName__heightMetadata'] = block => {
         let out = `heightMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['lastName__italic'] = block =>
         `italic: ${JSON.stringify(block.getFieldValue('italic'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['lastName__italicMetadata'] = block => {
         let out = `italicMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['lastName__mergeFieldXml'] = block =>
         `mergeFieldXml: ${JSON.stringify(block.getFieldValue('mergeFieldXml'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['lastName__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['lastName__nameMetadata'] = block => {
         let out = `nameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['lastName__pageNumber'] = block =>
         `pageNumber: ${JSON.stringify(block.getFieldValue('pageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['lastName__pageNumberMetadata'] = block => {
         let out = `pageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['lastName__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['lastName__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['lastName__recipientIdGuidMetadata'] = block => {
         let out = `recipientIdGuidMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['lastName__recipientIdMetadata'] = block => {
         let out = `recipientIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['lastName__source'] = block =>
         `source: ${JSON.stringify(block.getFieldValue('source'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['lastName__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['lastName__statusMetadata'] = block => {
         let out = `statusMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['lastName__tabGroupLabels'] = block => {
         let out = `    tabGroupLabels: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'tabGroupLabel', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['lastName_tabGroupLabels__tabGroupLabel'] = block =>
         `    ${JSON.stringify(block.getFieldValue('tabGroupLabel'))}${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['lastName__tabGroupLabelsMetadata'] = block => {
         let out = `tabGroupLabelsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['lastName__tabId'] = block =>
         `tabId: ${JSON.stringify(block.getFieldValue('tabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['lastName__tabIdMetadata'] = block => {
         let out = `tabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['lastName__tabLabel'] = block =>
         `tabLabel: ${JSON.stringify(block.getFieldValue('tabLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['lastName__tabLabelMetadata'] = block => {
         let out = `tabLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['lastName__tabOrder'] = block =>
         `tabOrder: ${JSON.stringify(block.getFieldValue('tabOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['lastName__tabOrderMetadata'] = block => {
         let out = `tabOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['lastName__tabType'] = block =>
         `tabType: ${JSON.stringify(block.getFieldValue('tabType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['lastName__tabTypeMetadata'] = block => {
         let out = `tabTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['lastName__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['lastName__templateLockedMetadata'] = block => {
         let out = `templateLockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['lastName__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['lastName__templateRequiredMetadata'] = block => {
         let out = `templateRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['lastName__toolTipMetadata'] = block => {
         let out = `toolTipMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['lastName__tooltip'] = block =>
         `tooltip: ${JSON.stringify(block.getFieldValue('tooltip'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['lastName__underline'] = block =>
         `underline: ${JSON.stringify(block.getFieldValue('underline'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['lastName__underlineMetadata'] = block => {
         let out = `underlineMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['lastName__value'] = block =>
         `value: ${JSON.stringify(block.getFieldValue('value'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['lastName__valueMetadata'] = block => {
         let out = `valueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['lastName__width'] = block =>
         `width: ${JSON.stringify(block.getFieldValue('width'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['lastName__widthMetadata'] = block => {
         let out = `widthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['lastName__xPosition'] = block =>
         `xPosition: ${JSON.stringify(block.getFieldValue('xPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['lastName__xPositionMetadata'] = block => {
         let out = `xPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['lastName__yPosition'] = block =>
         `yPosition: ${JSON.stringify(block.getFieldValue('yPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['lastName__yPositionMetadata'] = block => {
         let out = `yPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

Blockly.JavaScript['list'] = block => 
  `\n.add_object("list", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['list__anchorAllowWhiteSpaceInCharacters'] = block =>
         `anchorAllowWhiteSpaceInCharacters: ${JSON.stringify(block.getFieldValue('anchorAllowWhiteSpaceInCharacters'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['list__anchorAllowWhiteSpaceInCharactersMetadata'] = block => {
         let out = `anchorAllowWhiteSpaceInCharactersMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['list__anchorCaseSensitive'] = block =>
         `anchorCaseSensitive: ${JSON.stringify(block.getFieldValue('anchorCaseSensitive'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['list__anchorCaseSensitiveMetadata'] = block => {
         let out = `anchorCaseSensitiveMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['list__anchorHorizontalAlignment'] = block =>
         `anchorHorizontalAlignment: ${JSON.stringify(block.getFieldValue('anchorHorizontalAlignment'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['list__anchorHorizontalAlignmentMetadata'] = block => {
         let out = `anchorHorizontalAlignmentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['list__anchorIgnoreIfNotPresent'] = block =>
         `anchorIgnoreIfNotPresent: ${JSON.stringify(block.getFieldValue('anchorIgnoreIfNotPresent'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['list__anchorIgnoreIfNotPresentMetadata'] = block => {
         let out = `anchorIgnoreIfNotPresentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['list__anchorMatchWholeWord'] = block =>
         `anchorMatchWholeWord: ${JSON.stringify(block.getFieldValue('anchorMatchWholeWord'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['list__anchorMatchWholeWordMetadata'] = block => {
         let out = `anchorMatchWholeWordMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['list__anchorString'] = block =>
         `anchorString: ${JSON.stringify(block.getFieldValue('anchorString'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['list__anchorStringMetadata'] = block => {
         let out = `anchorStringMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['list__anchorTabProcessorVersion'] = block =>
         `anchorTabProcessorVersion: ${JSON.stringify(block.getFieldValue('anchorTabProcessorVersion'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['list__anchorTabProcessorVersionMetadata'] = block => {
         let out = `anchorTabProcessorVersionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['list__anchorUnits'] = block =>
         `anchorUnits: ${JSON.stringify(block.getFieldValue('anchorUnits'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['list__anchorUnitsMetadata'] = block => {
         let out = `anchorUnitsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['list__anchorXOffset'] = block =>
         `anchorXOffset: ${JSON.stringify(block.getFieldValue('anchorXOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['list__anchorXOffsetMetadata'] = block => {
         let out = `anchorXOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['list__anchorYOffset'] = block =>
         `anchorYOffset: ${JSON.stringify(block.getFieldValue('anchorYOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['list__anchorYOffsetMetadata'] = block => {
         let out = `anchorYOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['list__bold'] = block =>
         `bold: ${JSON.stringify(block.getFieldValue('bold'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['list__boldMetadata'] = block => {
         let out = `boldMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['list__conditionalParentLabel'] = block =>
         `conditionalParentLabel: ${JSON.stringify(block.getFieldValue('conditionalParentLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['list__conditionalParentLabelMetadata'] = block => {
         let out = `conditionalParentLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['list__conditionalParentValue'] = block =>
         `conditionalParentValue: ${JSON.stringify(block.getFieldValue('conditionalParentValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['list__conditionalParentValueMetadata'] = block => {
         let out = `conditionalParentValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['list__customTabId'] = block =>
         `customTabId: ${JSON.stringify(block.getFieldValue('customTabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['list__customTabIdMetadata'] = block => {
         let out = `customTabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['list__documentId'] = block =>
         `documentId: ${JSON.stringify(block.getFieldValue('documentId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['list__documentIdMetadata'] = block => {
         let out = `documentIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['list__font'] = block =>
         `font: ${JSON.stringify(block.getFieldValue('font'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['list__fontColor'] = block =>
         `fontColor: ${JSON.stringify(block.getFieldValue('fontColor'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['list__fontColorMetadata'] = block => {
         let out = `fontColorMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['list__fontMetadata'] = block => {
         let out = `fontMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['list__fontSize'] = block =>
         `fontSize: ${JSON.stringify(block.getFieldValue('fontSize'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['list__fontSizeMetadata'] = block => {
         let out = `fontSizeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['list__formOrder'] = block =>
         `formOrder: ${JSON.stringify(block.getFieldValue('formOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['list__formOrderMetadata'] = block => {
         let out = `formOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['list__formPageLabel'] = block =>
         `formPageLabel: ${JSON.stringify(block.getFieldValue('formPageLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['list__formPageLabelMetadata'] = block => {
         let out = `formPageLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['list__formPageNumber'] = block =>
         `formPageNumber: ${JSON.stringify(block.getFieldValue('formPageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['list__formPageNumberMetadata'] = block => {
         let out = `formPageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['list__height'] = block =>
         `height: ${JSON.stringify(block.getFieldValue('height'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['list__heightMetadata'] = block => {
         let out = `heightMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['list__italic'] = block =>
         `italic: ${JSON.stringify(block.getFieldValue('italic'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['list__italicMetadata'] = block => {
         let out = `italicMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['list__listSelectedValue'] = block =>
         `listSelectedValue: ${JSON.stringify(block.getFieldValue('listSelectedValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['list__listSelectedValueMetadata'] = block => {
         let out = `listSelectedValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['list__locked'] = block =>
         `locked: ${JSON.stringify(block.getFieldValue('locked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['list__lockedMetadata'] = block => {
         let out = `lockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['list__mergeFieldXml'] = block =>
         `mergeFieldXml: ${JSON.stringify(block.getFieldValue('mergeFieldXml'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['list__originalValue'] = block =>
         `originalValue: ${JSON.stringify(block.getFieldValue('originalValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['list__originalValueMetadata'] = block => {
         let out = `originalValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['list__pageNumber'] = block =>
         `pageNumber: ${JSON.stringify(block.getFieldValue('pageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['list__pageNumberMetadata'] = block => {
         let out = `pageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['list__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['list__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['list__recipientIdGuidMetadata'] = block => {
         let out = `recipientIdGuidMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['list__recipientIdMetadata'] = block => {
         let out = `recipientIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['list__requireAll'] = block =>
         `requireAll: ${JSON.stringify(block.getFieldValue('requireAll'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['list__requireAllMetadata'] = block => {
         let out = `requireAllMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['list__requireInitialOnSharedChange'] = block =>
         `requireInitialOnSharedChange: ${JSON.stringify(block.getFieldValue('requireInitialOnSharedChange'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['list__requireInitialOnSharedChangeMetadata'] = block => {
         let out = `requireInitialOnSharedChangeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['list__required'] = block =>
         `required: ${JSON.stringify(block.getFieldValue('required'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['list__requiredMetadata'] = block => {
         let out = `requiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['list__senderRequired'] = block =>
         `senderRequired: ${JSON.stringify(block.getFieldValue('senderRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['list__senderRequiredMetadata'] = block => {
         let out = `senderRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['list__shareToRecipients'] = block =>
         `shareToRecipients: ${JSON.stringify(block.getFieldValue('shareToRecipients'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['list__shareToRecipientsMetadata'] = block => {
         let out = `shareToRecipientsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['list__shared'] = block =>
         `shared: ${JSON.stringify(block.getFieldValue('shared'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['list__sharedMetadata'] = block => {
         let out = `sharedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['list__source'] = block =>
         `source: ${JSON.stringify(block.getFieldValue('source'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['list__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['list__statusMetadata'] = block => {
         let out = `statusMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['list__tabGroupLabels'] = block => {
         let out = `    tabGroupLabels: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'tabGroupLabel', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['list_tabGroupLabels__tabGroupLabel'] = block =>
         `    ${JSON.stringify(block.getFieldValue('tabGroupLabel'))}${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['list__tabGroupLabelsMetadata'] = block => {
         let out = `tabGroupLabelsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['list__tabId'] = block =>
         `tabId: ${JSON.stringify(block.getFieldValue('tabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['list__tabIdMetadata'] = block => {
         let out = `tabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['list__tabLabel'] = block =>
         `tabLabel: ${JSON.stringify(block.getFieldValue('tabLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['list__tabLabelMetadata'] = block => {
         let out = `tabLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['list__tabOrder'] = block =>
         `tabOrder: ${JSON.stringify(block.getFieldValue('tabOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['list__tabOrderMetadata'] = block => {
         let out = `tabOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['list__tabType'] = block =>
         `tabType: ${JSON.stringify(block.getFieldValue('tabType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['list__tabTypeMetadata'] = block => {
         let out = `tabTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['list__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['list__templateLockedMetadata'] = block => {
         let out = `templateLockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['list__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['list__templateRequiredMetadata'] = block => {
         let out = `templateRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['list__toolTipMetadata'] = block => {
         let out = `toolTipMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['list__tooltip'] = block =>
         `tooltip: ${JSON.stringify(block.getFieldValue('tooltip'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['list__underline'] = block =>
         `underline: ${JSON.stringify(block.getFieldValue('underline'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['list__underlineMetadata'] = block => {
         let out = `underlineMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['list__value'] = block =>
         `value: ${JSON.stringify(block.getFieldValue('value'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['list__valueMetadata'] = block => {
         let out = `valueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['list__width'] = block =>
         `width: ${JSON.stringify(block.getFieldValue('width'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['list__widthMetadata'] = block => {
         let out = `widthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['list__xPosition'] = block =>
         `xPosition: ${JSON.stringify(block.getFieldValue('xPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['list__xPositionMetadata'] = block => {
         let out = `xPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['list__yPosition'] = block =>
         `yPosition: ${JSON.stringify(block.getFieldValue('yPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['list__yPositionMetadata'] = block => {
         let out = `yPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

Blockly.JavaScript['listCustomField'] = block => 
  `\n.add_object("listCustomField", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['listCustomField__configurationType'] = block =>
         `configurationType: ${JSON.stringify(block.getFieldValue('configurationType'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['listCustomField__fieldId'] = block =>
         `fieldId: ${JSON.stringify(block.getFieldValue('fieldId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['listCustomField__listItems'] = block => {
         let out = `    listItems: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'listItem', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['listCustomField_listItems__listItem'] = block =>
         `    ${JSON.stringify(block.getFieldValue('listItem'))}${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['listCustomField__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['listCustomField__required'] = block =>
         `required: ${JSON.stringify(block.getFieldValue('required'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['listCustomField__show'] = block =>
         `show: ${JSON.stringify(block.getFieldValue('show'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['listCustomField__value'] = block =>
         `value: ${JSON.stringify(block.getFieldValue('value'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['listItem'] = block => 
  `\n.add_object("listItem", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['listItem__selected'] = block =>
         `selected: ${JSON.stringify(block.getFieldValue('selected'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['listItem__selectedMetadata'] = block => {
         let out = `selectedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['listItem__text'] = block =>
         `text: ${JSON.stringify(block.getFieldValue('text'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['listItem__textMetadata'] = block => {
         let out = `textMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['listItem__value'] = block =>
         `value: ${JSON.stringify(block.getFieldValue('value'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['listItem__valueMetadata'] = block => {
         let out = `valueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

Blockly.JavaScript['localePolicyTab'] = block => 
  `\n.add_object("localePolicyTab", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['localePolicyTab__addressFormat'] = block =>
         `addressFormat: ${JSON.stringify(block.getFieldValue('addressFormat'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['localePolicyTab__calendarType'] = block =>
         `calendarType: ${JSON.stringify(block.getFieldValue('calendarType'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['localePolicyTab__cultureName'] = block =>
         `cultureName: ${JSON.stringify(block.getFieldValue('cultureName'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['localePolicyTab__currencyCode'] = block =>
         `currencyCode: ${JSON.stringify(block.getFieldValue('currencyCode'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['localePolicyTab__currencyNegativeFormat'] = block =>
         `currencyNegativeFormat: ${JSON.stringify(block.getFieldValue('currencyNegativeFormat'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['localePolicyTab__currencyPositiveFormat'] = block =>
         `currencyPositiveFormat: ${JSON.stringify(block.getFieldValue('currencyPositiveFormat'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['localePolicyTab__customDateFormat'] = block =>
         `customDateFormat: ${JSON.stringify(block.getFieldValue('customDateFormat'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['localePolicyTab__customTimeFormat'] = block =>
         `customTimeFormat: ${JSON.stringify(block.getFieldValue('customTimeFormat'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['localePolicyTab__dateFormat'] = block =>
         `dateFormat: ${JSON.stringify(block.getFieldValue('dateFormat'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['localePolicyTab__initialFormat'] = block =>
         `initialFormat: ${JSON.stringify(block.getFieldValue('initialFormat'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['localePolicyTab__nameFormat'] = block =>
         `nameFormat: ${JSON.stringify(block.getFieldValue('nameFormat'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['localePolicyTab__timeFormat'] = block =>
         `timeFormat: ${JSON.stringify(block.getFieldValue('timeFormat'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['localePolicyTab__timeZone'] = block =>
         `timeZone: ${JSON.stringify(block.getFieldValue('timeZone'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['lockInformation'] = block => 
  `\n.add_object("lockInformation", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['lockInformation__lockDurationInSeconds'] = block =>
         `lockDurationInSeconds: ${JSON.stringify(block.getFieldValue('lockDurationInSeconds'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['lockInformation__lockToken'] = block =>
         `lockToken: ${JSON.stringify(block.getFieldValue('lockToken'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['lockInformation__lockType'] = block =>
         `lockType: ${JSON.stringify(block.getFieldValue('lockType'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['lockInformation__lockedByApp'] = block =>
         `lockedByApp: ${JSON.stringify(block.getFieldValue('lockedByApp'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['lockInformation__lockedUntilDateTime'] = block =>
         `lockedUntilDateTime: ${JSON.stringify(block.getFieldValue('lockedUntilDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['lockInformation__useScratchPad'] = block =>
         `useScratchPad: ${JSON.stringify(block.getFieldValue('useScratchPad'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['matchBox'] = block => 
  `\n.add_object("matchBox", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['matchBox__height'] = block =>
         `height: ${JSON.stringify(block.getFieldValue('height'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['matchBox__pageNumber'] = block =>
         `pageNumber: ${JSON.stringify(block.getFieldValue('pageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['matchBox__width'] = block =>
         `width: ${JSON.stringify(block.getFieldValue('width'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['matchBox__xPosition'] = block =>
         `xPosition: ${JSON.stringify(block.getFieldValue('xPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['matchBox__yPosition'] = block =>
         `yPosition: ${JSON.stringify(block.getFieldValue('yPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['mergeField'] = block => 
  `\n.add_object("mergeField", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['mergeField__allowSenderToEdit'] = block =>
         `allowSenderToEdit: ${JSON.stringify(block.getFieldValue('allowSenderToEdit'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['mergeField__allowSenderToEditMetadata'] = block => {
         let out = `allowSenderToEditMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['mergeField__configurationType'] = block =>
         `configurationType: ${JSON.stringify(block.getFieldValue('configurationType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['mergeField__configurationTypeMetadata'] = block => {
         let out = `configurationTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['mergeField__path'] = block =>
         `path: ${JSON.stringify(block.getFieldValue('path'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['mergeField__pathExtendedMetadata'] = block => {
         let out = `pathExtendedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['mergeField__pathMetadata'] = block => {
         let out = `pathMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['mergeField__row'] = block =>
         `row: ${JSON.stringify(block.getFieldValue('row'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['mergeField__rowMetadata'] = block => {
         let out = `rowMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['mergeField__writeBack'] = block =>
         `writeBack: ${JSON.stringify(block.getFieldValue('writeBack'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['mergeField__writeBackMetadata'] = block => {
         let out = `writeBackMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

Blockly.JavaScript['money'] = block => 
  `\n.add_object("money", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['money__amountInBaseUnit'] = block =>
         `amountInBaseUnit: ${JSON.stringify(block.getFieldValue('amountInBaseUnit'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['money__currency'] = block =>
         `currency: ${JSON.stringify(block.getFieldValue('currency'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['money__displayAmount'] = block =>
         `displayAmount: ${JSON.stringify(block.getFieldValue('displayAmount'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['nameValue'] = block => 
  `\n.add_object("nameValue", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['nameValue__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['nameValue__originalValue'] = block =>
         `originalValue: ${JSON.stringify(block.getFieldValue('originalValue'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['nameValue__value'] = block =>
         `value: ${JSON.stringify(block.getFieldValue('value'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['notarize'] = block => 
  `\n.add_object("notarize", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['notarize__anchorAllowWhiteSpaceInCharacters'] = block =>
         `anchorAllowWhiteSpaceInCharacters: ${JSON.stringify(block.getFieldValue('anchorAllowWhiteSpaceInCharacters'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarize__anchorAllowWhiteSpaceInCharactersMetadata'] = block => {
         let out = `anchorAllowWhiteSpaceInCharactersMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarize__anchorCaseSensitive'] = block =>
         `anchorCaseSensitive: ${JSON.stringify(block.getFieldValue('anchorCaseSensitive'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarize__anchorCaseSensitiveMetadata'] = block => {
         let out = `anchorCaseSensitiveMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarize__anchorHorizontalAlignment'] = block =>
         `anchorHorizontalAlignment: ${JSON.stringify(block.getFieldValue('anchorHorizontalAlignment'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarize__anchorHorizontalAlignmentMetadata'] = block => {
         let out = `anchorHorizontalAlignmentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarize__anchorIgnoreIfNotPresent'] = block =>
         `anchorIgnoreIfNotPresent: ${JSON.stringify(block.getFieldValue('anchorIgnoreIfNotPresent'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarize__anchorIgnoreIfNotPresentMetadata'] = block => {
         let out = `anchorIgnoreIfNotPresentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarize__anchorMatchWholeWord'] = block =>
         `anchorMatchWholeWord: ${JSON.stringify(block.getFieldValue('anchorMatchWholeWord'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarize__anchorMatchWholeWordMetadata'] = block => {
         let out = `anchorMatchWholeWordMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarize__anchorString'] = block =>
         `anchorString: ${JSON.stringify(block.getFieldValue('anchorString'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarize__anchorStringMetadata'] = block => {
         let out = `anchorStringMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarize__anchorTabProcessorVersion'] = block =>
         `anchorTabProcessorVersion: ${JSON.stringify(block.getFieldValue('anchorTabProcessorVersion'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarize__anchorTabProcessorVersionMetadata'] = block => {
         let out = `anchorTabProcessorVersionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarize__anchorUnits'] = block =>
         `anchorUnits: ${JSON.stringify(block.getFieldValue('anchorUnits'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarize__anchorUnitsMetadata'] = block => {
         let out = `anchorUnitsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarize__anchorXOffset'] = block =>
         `anchorXOffset: ${JSON.stringify(block.getFieldValue('anchorXOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarize__anchorXOffsetMetadata'] = block => {
         let out = `anchorXOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarize__anchorYOffset'] = block =>
         `anchorYOffset: ${JSON.stringify(block.getFieldValue('anchorYOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarize__anchorYOffsetMetadata'] = block => {
         let out = `anchorYOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarize__conditionalParentLabel'] = block =>
         `conditionalParentLabel: ${JSON.stringify(block.getFieldValue('conditionalParentLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarize__conditionalParentLabelMetadata'] = block => {
         let out = `conditionalParentLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarize__conditionalParentValue'] = block =>
         `conditionalParentValue: ${JSON.stringify(block.getFieldValue('conditionalParentValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarize__conditionalParentValueMetadata'] = block => {
         let out = `conditionalParentValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarize__customTabId'] = block =>
         `customTabId: ${JSON.stringify(block.getFieldValue('customTabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarize__customTabIdMetadata'] = block => {
         let out = `customTabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarize__documentId'] = block =>
         `documentId: ${JSON.stringify(block.getFieldValue('documentId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarize__documentIdMetadata'] = block => {
         let out = `documentIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarize__formOrder'] = block =>
         `formOrder: ${JSON.stringify(block.getFieldValue('formOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarize__formOrderMetadata'] = block => {
         let out = `formOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarize__formPageLabel'] = block =>
         `formPageLabel: ${JSON.stringify(block.getFieldValue('formPageLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarize__formPageLabelMetadata'] = block => {
         let out = `formPageLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarize__formPageNumber'] = block =>
         `formPageNumber: ${JSON.stringify(block.getFieldValue('formPageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarize__formPageNumberMetadata'] = block => {
         let out = `formPageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarize__height'] = block =>
         `height: ${JSON.stringify(block.getFieldValue('height'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarize__heightMetadata'] = block => {
         let out = `heightMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarize__locked'] = block =>
         `locked: ${JSON.stringify(block.getFieldValue('locked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarize__lockedMetadata'] = block => {
         let out = `lockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarize__mergeFieldXml'] = block =>
         `mergeFieldXml: ${JSON.stringify(block.getFieldValue('mergeFieldXml'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notarize__pageNumber'] = block =>
         `pageNumber: ${JSON.stringify(block.getFieldValue('pageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarize__pageNumberMetadata'] = block => {
         let out = `pageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarize__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notarize__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarize__recipientIdGuidMetadata'] = block => {
         let out = `recipientIdGuidMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['notarize__recipientIdMetadata'] = block => {
         let out = `recipientIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarize__required'] = block =>
         `required: ${JSON.stringify(block.getFieldValue('required'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarize__requiredMetadata'] = block => {
         let out = `requiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarize__source'] = block =>
         `source: ${JSON.stringify(block.getFieldValue('source'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notarize__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarize__statusMetadata'] = block => {
         let out = `statusMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['notarize__tabGroupLabels'] = block => {
         let out = `    tabGroupLabels: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'tabGroupLabel', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['notarize_tabGroupLabels__tabGroupLabel'] = block =>
         `    ${JSON.stringify(block.getFieldValue('tabGroupLabel'))}${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['notarize__tabGroupLabelsMetadata'] = block => {
         let out = `tabGroupLabelsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarize__tabId'] = block =>
         `tabId: ${JSON.stringify(block.getFieldValue('tabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarize__tabIdMetadata'] = block => {
         let out = `tabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarize__tabOrder'] = block =>
         `tabOrder: ${JSON.stringify(block.getFieldValue('tabOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarize__tabOrderMetadata'] = block => {
         let out = `tabOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarize__tabType'] = block =>
         `tabType: ${JSON.stringify(block.getFieldValue('tabType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarize__tabTypeMetadata'] = block => {
         let out = `tabTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarize__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarize__templateLockedMetadata'] = block => {
         let out = `templateLockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarize__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarize__templateRequiredMetadata'] = block => {
         let out = `templateRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['notarize__toolTipMetadata'] = block => {
         let out = `toolTipMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarize__tooltip'] = block =>
         `tooltip: ${JSON.stringify(block.getFieldValue('tooltip'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notarize__width'] = block =>
         `width: ${JSON.stringify(block.getFieldValue('width'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarize__widthMetadata'] = block => {
         let out = `widthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarize__xPosition'] = block =>
         `xPosition: ${JSON.stringify(block.getFieldValue('xPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarize__xPositionMetadata'] = block => {
         let out = `xPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarize__yPosition'] = block =>
         `yPosition: ${JSON.stringify(block.getFieldValue('yPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarize__yPositionMetadata'] = block => {
         let out = `yPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

Blockly.JavaScript['notaryHost'] = block => 
  `\n.add_object("notaryHost", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['notaryHost__accessCode'] = block =>
         `accessCode: ${JSON.stringify(block.getFieldValue('accessCode'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notaryHost__accessCodeMetadata'] = block => {
         let out = `accessCodeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notaryHost__addAccessCodeToEmail'] = block =>
         `addAccessCodeToEmail: ${JSON.stringify(block.getFieldValue('addAccessCodeToEmail'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryHost__allowSystemOverrideForLockedRecipient'] = block =>
         `allowSystemOverrideForLockedRecipient: ${JSON.stringify(block.getFieldValue('allowSystemOverrideForLockedRecipient'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryHost__autoRespondedReason'] = block =>
         `autoRespondedReason: ${JSON.stringify(block.getFieldValue('autoRespondedReason'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryHost__clientUserId'] = block =>
         `clientUserId: ${JSON.stringify(block.getFieldValue('clientUserId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryHost__completedCount'] = block =>
         `completedCount: ${JSON.stringify(block.getFieldValue('completedCount'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notaryHost__customFields'] = block => {
         let out = `    customFields: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'customField', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['notaryHost_customFields__customField'] = block =>
         `    ${JSON.stringify(block.getFieldValue('customField'))}${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notaryHost__declinedDateTime'] = block =>
         `declinedDateTime: ${JSON.stringify(block.getFieldValue('declinedDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryHost__declinedReason'] = block =>
         `declinedReason: ${JSON.stringify(block.getFieldValue('declinedReason'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryHost__deliveredDateTime'] = block =>
         `deliveredDateTime: ${JSON.stringify(block.getFieldValue('deliveredDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryHost__deliveryMethod'] = block =>
         `deliveryMethod: ${JSON.stringify(block.getFieldValue('deliveryMethod'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notaryHost__deliveryMethodMetadata'] = block => {
         let out = `deliveryMethodMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notaryHost__designatorId'] = block =>
         `designatorId: ${JSON.stringify(block.getFieldValue('designatorId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryHost__designatorIdGuid'] = block =>
         `designatorIdGuid: ${JSON.stringify(block.getFieldValue('designatorIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryHost__email'] = block =>
         `email: ${JSON.stringify(block.getFieldValue('email'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notaryHost__emailMetadata'] = block => {
         let out = `emailMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notaryHost__embeddedRecipientStartURL'] = block =>
         `embeddedRecipientStartURL: ${JSON.stringify(block.getFieldValue('embeddedRecipientStartURL'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryHost__faxNumber'] = block =>
         `faxNumber: ${JSON.stringify(block.getFieldValue('faxNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notaryHost__faxNumberMetadata'] = block => {
         let out = `faxNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notaryHost__hostRecipientId'] = block =>
         `hostRecipientId: ${JSON.stringify(block.getFieldValue('hostRecipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryHost__idCheckConfigurationName'] = block =>
         `idCheckConfigurationName: ${JSON.stringify(block.getFieldValue('idCheckConfigurationName'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notaryHost__idCheckConfigurationNameMetadata'] = block => {
         let out = `idCheckConfigurationNameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notaryHost__inheritEmailNotificationConfiguration'] = block =>
         `inheritEmailNotificationConfiguration: ${JSON.stringify(block.getFieldValue('inheritEmailNotificationConfiguration'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryHost__lockedRecipientPhoneAuthEditable'] = block =>
         `lockedRecipientPhoneAuthEditable: ${JSON.stringify(block.getFieldValue('lockedRecipientPhoneAuthEditable'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryHost__lockedRecipientSmsEditable'] = block =>
         `lockedRecipientSmsEditable: ${JSON.stringify(block.getFieldValue('lockedRecipientSmsEditable'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryHost__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notaryHost__nameMetadata'] = block => {
         let out = `nameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notaryHost__note'] = block =>
         `note: ${JSON.stringify(block.getFieldValue('note'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notaryHost__noteMetadata'] = block => {
         let out = `noteMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notaryHost__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryHost__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryHost__recipientType'] = block =>
         `recipientType: ${JSON.stringify(block.getFieldValue('recipientType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notaryHost__recipientTypeMetadata'] = block => {
         let out = `recipientTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notaryHost__requireIdLookup'] = block =>
         `requireIdLookup: ${JSON.stringify(block.getFieldValue('requireIdLookup'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notaryHost__requireIdLookupMetadata'] = block => {
         let out = `requireIdLookupMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notaryHost__roleName'] = block =>
         `roleName: ${JSON.stringify(block.getFieldValue('roleName'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryHost__routingOrder'] = block =>
         `routingOrder: ${JSON.stringify(block.getFieldValue('routingOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notaryHost__routingOrderMetadata'] = block => {
         let out = `routingOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notaryHost__sentDateTime'] = block =>
         `sentDateTime: ${JSON.stringify(block.getFieldValue('sentDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryHost__signedDateTime'] = block =>
         `signedDateTime: ${JSON.stringify(block.getFieldValue('signedDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryHost__signingGroupId'] = block =>
         `signingGroupId: ${JSON.stringify(block.getFieldValue('signingGroupId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notaryHost__signingGroupIdMetadata'] = block => {
         let out = `signingGroupIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notaryHost__signingGroupName'] = block =>
         `signingGroupName: ${JSON.stringify(block.getFieldValue('signingGroupName'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryHost__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryHost__statusCode'] = block =>
         `statusCode: ${JSON.stringify(block.getFieldValue('statusCode'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryHost__suppressEmails'] = block =>
         `suppressEmails: ${JSON.stringify(block.getFieldValue('suppressEmails'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryHost__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryHost__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryHost__totalTabCount'] = block =>
         `totalTabCount: ${JSON.stringify(block.getFieldValue('totalTabCount'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryHost__userId'] = block =>
         `userId: ${JSON.stringify(block.getFieldValue('userId'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['notaryRecipient'] = block => 
  `\n.add_object("notaryRecipient", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['notaryRecipient__accessCode'] = block =>
         `accessCode: ${JSON.stringify(block.getFieldValue('accessCode'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notaryRecipient__accessCodeMetadata'] = block => {
         let out = `accessCodeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notaryRecipient__addAccessCodeToEmail'] = block =>
         `addAccessCodeToEmail: ${JSON.stringify(block.getFieldValue('addAccessCodeToEmail'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryRecipient__agentCanEditEmail'] = block =>
         `agentCanEditEmail: ${JSON.stringify(block.getFieldValue('agentCanEditEmail'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryRecipient__agentCanEditName'] = block =>
         `agentCanEditName: ${JSON.stringify(block.getFieldValue('agentCanEditName'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryRecipient__allowSystemOverrideForLockedRecipient'] = block =>
         `allowSystemOverrideForLockedRecipient: ${JSON.stringify(block.getFieldValue('allowSystemOverrideForLockedRecipient'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryRecipient__autoNavigation'] = block =>
         `autoNavigation: ${JSON.stringify(block.getFieldValue('autoNavigation'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryRecipient__autoRespondedReason'] = block =>
         `autoRespondedReason: ${JSON.stringify(block.getFieldValue('autoRespondedReason'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryRecipient__bulkRecipientsUri'] = block =>
         `bulkRecipientsUri: ${JSON.stringify(block.getFieldValue('bulkRecipientsUri'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryRecipient__canSignOffline'] = block =>
         `canSignOffline: ${JSON.stringify(block.getFieldValue('canSignOffline'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryRecipient__clientUserId'] = block =>
         `clientUserId: ${JSON.stringify(block.getFieldValue('clientUserId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryRecipient__completedCount'] = block =>
         `completedCount: ${JSON.stringify(block.getFieldValue('completedCount'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryRecipient__creationReason'] = block =>
         `creationReason: ${JSON.stringify(block.getFieldValue('creationReason'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notaryRecipient__customFields'] = block => {
         let out = `    customFields: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'customField', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['notaryRecipient_customFields__customField'] = block =>
         `    ${JSON.stringify(block.getFieldValue('customField'))}${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notaryRecipient__declinedDateTime'] = block =>
         `declinedDateTime: ${JSON.stringify(block.getFieldValue('declinedDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryRecipient__declinedReason'] = block =>
         `declinedReason: ${JSON.stringify(block.getFieldValue('declinedReason'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryRecipient__defaultRecipient'] = block =>
         `defaultRecipient: ${JSON.stringify(block.getFieldValue('defaultRecipient'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryRecipient__deliveredDateTime'] = block =>
         `deliveredDateTime: ${JSON.stringify(block.getFieldValue('deliveredDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryRecipient__deliveryMethod'] = block =>
         `deliveryMethod: ${JSON.stringify(block.getFieldValue('deliveryMethod'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notaryRecipient__deliveryMethodMetadata'] = block => {
         let out = `deliveryMethodMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notaryRecipient__designatorId'] = block =>
         `designatorId: ${JSON.stringify(block.getFieldValue('designatorId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryRecipient__designatorIdGuid'] = block =>
         `designatorIdGuid: ${JSON.stringify(block.getFieldValue('designatorIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryRecipient__email'] = block =>
         `email: ${JSON.stringify(block.getFieldValue('email'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notaryRecipient__emailMetadata'] = block => {
         let out = `emailMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notaryRecipient__emailRecipientPostSigningURL'] = block =>
         `emailRecipientPostSigningURL: ${JSON.stringify(block.getFieldValue('emailRecipientPostSigningURL'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryRecipient__embeddedRecipientStartURL'] = block =>
         `embeddedRecipientStartURL: ${JSON.stringify(block.getFieldValue('embeddedRecipientStartURL'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notaryRecipient__excludedDocuments'] = block => {
         let out = `    excludedDocuments: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'excludedDocument', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['notaryRecipient_excludedDocuments__excludedDocument'] = block =>
         `    ${JSON.stringify(block.getFieldValue('excludedDocument'))}${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notaryRecipient__faxNumber'] = block =>
         `faxNumber: ${JSON.stringify(block.getFieldValue('faxNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notaryRecipient__faxNumberMetadata'] = block => {
         let out = `faxNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notaryRecipient__firstName'] = block =>
         `firstName: ${JSON.stringify(block.getFieldValue('firstName'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notaryRecipient__firstNameMetadata'] = block => {
         let out = `firstNameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notaryRecipient__fullName'] = block =>
         `fullName: ${JSON.stringify(block.getFieldValue('fullName'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notaryRecipient__fullNameMetadata'] = block => {
         let out = `fullNameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notaryRecipient__idCheckConfigurationName'] = block =>
         `idCheckConfigurationName: ${JSON.stringify(block.getFieldValue('idCheckConfigurationName'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notaryRecipient__idCheckConfigurationNameMetadata'] = block => {
         let out = `idCheckConfigurationNameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notaryRecipient__inheritEmailNotificationConfiguration'] = block =>
         `inheritEmailNotificationConfiguration: ${JSON.stringify(block.getFieldValue('inheritEmailNotificationConfiguration'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryRecipient__isBulkRecipient'] = block =>
         `isBulkRecipient: ${JSON.stringify(block.getFieldValue('isBulkRecipient'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notaryRecipient__isBulkRecipientMetadata'] = block => {
         let out = `isBulkRecipientMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notaryRecipient__lastName'] = block =>
         `lastName: ${JSON.stringify(block.getFieldValue('lastName'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notaryRecipient__lastNameMetadata'] = block => {
         let out = `lastNameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notaryRecipient__liveOakStartURL'] = block =>
         `liveOakStartURL: ${JSON.stringify(block.getFieldValue('liveOakStartURL'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryRecipient__lockedRecipientPhoneAuthEditable'] = block =>
         `lockedRecipientPhoneAuthEditable: ${JSON.stringify(block.getFieldValue('lockedRecipientPhoneAuthEditable'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryRecipient__lockedRecipientSmsEditable'] = block =>
         `lockedRecipientSmsEditable: ${JSON.stringify(block.getFieldValue('lockedRecipientSmsEditable'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryRecipient__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notaryRecipient__nameMetadata'] = block => {
         let out = `nameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notaryRecipient__notaryId'] = block =>
         `notaryId: ${JSON.stringify(block.getFieldValue('notaryId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryRecipient__notarySignerEmailSent'] = block =>
         `notarySignerEmailSent: ${JSON.stringify(block.getFieldValue('notarySignerEmailSent'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notaryRecipient__notarySigners'] = block => {
         let out = `    notarySigners: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'notarySigner', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['notaryRecipient_notarySigners__notarySigner'] = block =>
         `    ${JSON.stringify(block.getFieldValue('notarySigner'))}${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notaryRecipient__notaryType'] = block =>
         `notaryType: ${JSON.stringify(block.getFieldValue('notaryType'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryRecipient__note'] = block =>
         `note: ${JSON.stringify(block.getFieldValue('note'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notaryRecipient__noteMetadata'] = block => {
         let out = `noteMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notaryRecipient__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryRecipient__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryRecipient__recipientSuppliesTabs'] = block =>
         `recipientSuppliesTabs: ${JSON.stringify(block.getFieldValue('recipientSuppliesTabs'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryRecipient__recipientType'] = block =>
         `recipientType: ${JSON.stringify(block.getFieldValue('recipientType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notaryRecipient__recipientTypeMetadata'] = block => {
         let out = `recipientTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notaryRecipient__requireIdLookup'] = block =>
         `requireIdLookup: ${JSON.stringify(block.getFieldValue('requireIdLookup'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notaryRecipient__requireIdLookupMetadata'] = block => {
         let out = `requireIdLookupMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notaryRecipient__requireSignOnPaper'] = block =>
         `requireSignOnPaper: ${JSON.stringify(block.getFieldValue('requireSignOnPaper'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryRecipient__requireSignerCertificate'] = block =>
         `requireSignerCertificate: ${JSON.stringify(block.getFieldValue('requireSignerCertificate'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryRecipient__requireUploadSignature'] = block =>
         `requireUploadSignature: ${JSON.stringify(block.getFieldValue('requireUploadSignature'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryRecipient__roleName'] = block =>
         `roleName: ${JSON.stringify(block.getFieldValue('roleName'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryRecipient__routingOrder'] = block =>
         `routingOrder: ${JSON.stringify(block.getFieldValue('routingOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notaryRecipient__routingOrderMetadata'] = block => {
         let out = `routingOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notaryRecipient__sentDateTime'] = block =>
         `sentDateTime: ${JSON.stringify(block.getFieldValue('sentDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryRecipient__signInEachLocation'] = block =>
         `signInEachLocation: ${JSON.stringify(block.getFieldValue('signInEachLocation'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notaryRecipient__signInEachLocationMetadata'] = block => {
         let out = `signInEachLocationMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notaryRecipient__signedDateTime'] = block =>
         `signedDateTime: ${JSON.stringify(block.getFieldValue('signedDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryRecipient__signingGroupId'] = block =>
         `signingGroupId: ${JSON.stringify(block.getFieldValue('signingGroupId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notaryRecipient__signingGroupIdMetadata'] = block => {
         let out = `signingGroupIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notaryRecipient__signingGroupName'] = block =>
         `signingGroupName: ${JSON.stringify(block.getFieldValue('signingGroupName'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryRecipient__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryRecipient__statusCode'] = block =>
         `statusCode: ${JSON.stringify(block.getFieldValue('statusCode'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryRecipient__suppressEmails'] = block =>
         `suppressEmails: ${JSON.stringify(block.getFieldValue('suppressEmails'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryRecipient__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryRecipient__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryRecipient__totalTabCount'] = block =>
         `totalTabCount: ${JSON.stringify(block.getFieldValue('totalTabCount'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notaryRecipient__userId'] = block =>
         `userId: ${JSON.stringify(block.getFieldValue('userId'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['notarySeal'] = block => 
  `\n.add_object("notarySeal", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['notarySeal__anchorAllowWhiteSpaceInCharacters'] = block =>
         `anchorAllowWhiteSpaceInCharacters: ${JSON.stringify(block.getFieldValue('anchorAllowWhiteSpaceInCharacters'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarySeal__anchorAllowWhiteSpaceInCharactersMetadata'] = block => {
         let out = `anchorAllowWhiteSpaceInCharactersMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarySeal__anchorCaseSensitive'] = block =>
         `anchorCaseSensitive: ${JSON.stringify(block.getFieldValue('anchorCaseSensitive'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarySeal__anchorCaseSensitiveMetadata'] = block => {
         let out = `anchorCaseSensitiveMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarySeal__anchorHorizontalAlignment'] = block =>
         `anchorHorizontalAlignment: ${JSON.stringify(block.getFieldValue('anchorHorizontalAlignment'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarySeal__anchorHorizontalAlignmentMetadata'] = block => {
         let out = `anchorHorizontalAlignmentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarySeal__anchorIgnoreIfNotPresent'] = block =>
         `anchorIgnoreIfNotPresent: ${JSON.stringify(block.getFieldValue('anchorIgnoreIfNotPresent'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarySeal__anchorIgnoreIfNotPresentMetadata'] = block => {
         let out = `anchorIgnoreIfNotPresentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarySeal__anchorMatchWholeWord'] = block =>
         `anchorMatchWholeWord: ${JSON.stringify(block.getFieldValue('anchorMatchWholeWord'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarySeal__anchorMatchWholeWordMetadata'] = block => {
         let out = `anchorMatchWholeWordMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarySeal__anchorString'] = block =>
         `anchorString: ${JSON.stringify(block.getFieldValue('anchorString'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarySeal__anchorStringMetadata'] = block => {
         let out = `anchorStringMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarySeal__anchorTabProcessorVersion'] = block =>
         `anchorTabProcessorVersion: ${JSON.stringify(block.getFieldValue('anchorTabProcessorVersion'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarySeal__anchorTabProcessorVersionMetadata'] = block => {
         let out = `anchorTabProcessorVersionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarySeal__anchorUnits'] = block =>
         `anchorUnits: ${JSON.stringify(block.getFieldValue('anchorUnits'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarySeal__anchorUnitsMetadata'] = block => {
         let out = `anchorUnitsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarySeal__anchorXOffset'] = block =>
         `anchorXOffset: ${JSON.stringify(block.getFieldValue('anchorXOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarySeal__anchorXOffsetMetadata'] = block => {
         let out = `anchorXOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarySeal__anchorYOffset'] = block =>
         `anchorYOffset: ${JSON.stringify(block.getFieldValue('anchorYOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarySeal__anchorYOffsetMetadata'] = block => {
         let out = `anchorYOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarySeal__conditionalParentLabel'] = block =>
         `conditionalParentLabel: ${JSON.stringify(block.getFieldValue('conditionalParentLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarySeal__conditionalParentLabelMetadata'] = block => {
         let out = `conditionalParentLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarySeal__conditionalParentValue'] = block =>
         `conditionalParentValue: ${JSON.stringify(block.getFieldValue('conditionalParentValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarySeal__conditionalParentValueMetadata'] = block => {
         let out = `conditionalParentValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarySeal__customTabId'] = block =>
         `customTabId: ${JSON.stringify(block.getFieldValue('customTabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarySeal__customTabIdMetadata'] = block => {
         let out = `customTabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarySeal__documentId'] = block =>
         `documentId: ${JSON.stringify(block.getFieldValue('documentId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarySeal__documentIdMetadata'] = block => {
         let out = `documentIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarySeal__formOrder'] = block =>
         `formOrder: ${JSON.stringify(block.getFieldValue('formOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarySeal__formOrderMetadata'] = block => {
         let out = `formOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarySeal__formPageLabel'] = block =>
         `formPageLabel: ${JSON.stringify(block.getFieldValue('formPageLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarySeal__formPageLabelMetadata'] = block => {
         let out = `formPageLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarySeal__formPageNumber'] = block =>
         `formPageNumber: ${JSON.stringify(block.getFieldValue('formPageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarySeal__formPageNumberMetadata'] = block => {
         let out = `formPageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarySeal__height'] = block =>
         `height: ${JSON.stringify(block.getFieldValue('height'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarySeal__heightMetadata'] = block => {
         let out = `heightMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarySeal__mergeFieldXml'] = block =>
         `mergeFieldXml: ${JSON.stringify(block.getFieldValue('mergeFieldXml'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notarySeal__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarySeal__nameMetadata'] = block => {
         let out = `nameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarySeal__pageNumber'] = block =>
         `pageNumber: ${JSON.stringify(block.getFieldValue('pageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarySeal__pageNumberMetadata'] = block => {
         let out = `pageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarySeal__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notarySeal__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarySeal__recipientIdGuidMetadata'] = block => {
         let out = `recipientIdGuidMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['notarySeal__recipientIdMetadata'] = block => {
         let out = `recipientIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarySeal__scaleValue'] = block =>
         `scaleValue: ${JSON.stringify(block.getFieldValue('scaleValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarySeal__scaleValueMetadata'] = block => {
         let out = `scaleValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarySeal__source'] = block =>
         `source: ${JSON.stringify(block.getFieldValue('source'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notarySeal__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarySeal__statusMetadata'] = block => {
         let out = `statusMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['notarySeal__tabGroupLabels'] = block => {
         let out = `    tabGroupLabels: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'tabGroupLabel', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['notarySeal_tabGroupLabels__tabGroupLabel'] = block =>
         `    ${JSON.stringify(block.getFieldValue('tabGroupLabel'))}${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['notarySeal__tabGroupLabelsMetadata'] = block => {
         let out = `tabGroupLabelsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarySeal__tabId'] = block =>
         `tabId: ${JSON.stringify(block.getFieldValue('tabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarySeal__tabIdMetadata'] = block => {
         let out = `tabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarySeal__tabLabel'] = block =>
         `tabLabel: ${JSON.stringify(block.getFieldValue('tabLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarySeal__tabLabelMetadata'] = block => {
         let out = `tabLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarySeal__tabOrder'] = block =>
         `tabOrder: ${JSON.stringify(block.getFieldValue('tabOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarySeal__tabOrderMetadata'] = block => {
         let out = `tabOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarySeal__tabType'] = block =>
         `tabType: ${JSON.stringify(block.getFieldValue('tabType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarySeal__tabTypeMetadata'] = block => {
         let out = `tabTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarySeal__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarySeal__templateLockedMetadata'] = block => {
         let out = `templateLockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarySeal__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarySeal__templateRequiredMetadata'] = block => {
         let out = `templateRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['notarySeal__toolTipMetadata'] = block => {
         let out = `toolTipMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarySeal__tooltip'] = block =>
         `tooltip: ${JSON.stringify(block.getFieldValue('tooltip'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['notarySeal__width'] = block =>
         `width: ${JSON.stringify(block.getFieldValue('width'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarySeal__widthMetadata'] = block => {
         let out = `widthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarySeal__xPosition'] = block =>
         `xPosition: ${JSON.stringify(block.getFieldValue('xPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarySeal__xPositionMetadata'] = block => {
         let out = `xPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['notarySeal__yPosition'] = block =>
         `yPosition: ${JSON.stringify(block.getFieldValue('yPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['notarySeal__yPositionMetadata'] = block => {
         let out = `yPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

Blockly.JavaScript['note'] = block => 
  `\n.add_object("note", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['note__anchorAllowWhiteSpaceInCharacters'] = block =>
         `anchorAllowWhiteSpaceInCharacters: ${JSON.stringify(block.getFieldValue('anchorAllowWhiteSpaceInCharacters'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['note__anchorAllowWhiteSpaceInCharactersMetadata'] = block => {
         let out = `anchorAllowWhiteSpaceInCharactersMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['note__anchorCaseSensitive'] = block =>
         `anchorCaseSensitive: ${JSON.stringify(block.getFieldValue('anchorCaseSensitive'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['note__anchorCaseSensitiveMetadata'] = block => {
         let out = `anchorCaseSensitiveMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['note__anchorHorizontalAlignment'] = block =>
         `anchorHorizontalAlignment: ${JSON.stringify(block.getFieldValue('anchorHorizontalAlignment'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['note__anchorHorizontalAlignmentMetadata'] = block => {
         let out = `anchorHorizontalAlignmentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['note__anchorIgnoreIfNotPresent'] = block =>
         `anchorIgnoreIfNotPresent: ${JSON.stringify(block.getFieldValue('anchorIgnoreIfNotPresent'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['note__anchorIgnoreIfNotPresentMetadata'] = block => {
         let out = `anchorIgnoreIfNotPresentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['note__anchorMatchWholeWord'] = block =>
         `anchorMatchWholeWord: ${JSON.stringify(block.getFieldValue('anchorMatchWholeWord'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['note__anchorMatchWholeWordMetadata'] = block => {
         let out = `anchorMatchWholeWordMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['note__anchorString'] = block =>
         `anchorString: ${JSON.stringify(block.getFieldValue('anchorString'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['note__anchorStringMetadata'] = block => {
         let out = `anchorStringMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['note__anchorTabProcessorVersion'] = block =>
         `anchorTabProcessorVersion: ${JSON.stringify(block.getFieldValue('anchorTabProcessorVersion'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['note__anchorTabProcessorVersionMetadata'] = block => {
         let out = `anchorTabProcessorVersionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['note__anchorUnits'] = block =>
         `anchorUnits: ${JSON.stringify(block.getFieldValue('anchorUnits'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['note__anchorUnitsMetadata'] = block => {
         let out = `anchorUnitsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['note__anchorXOffset'] = block =>
         `anchorXOffset: ${JSON.stringify(block.getFieldValue('anchorXOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['note__anchorXOffsetMetadata'] = block => {
         let out = `anchorXOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['note__anchorYOffset'] = block =>
         `anchorYOffset: ${JSON.stringify(block.getFieldValue('anchorYOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['note__anchorYOffsetMetadata'] = block => {
         let out = `anchorYOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['note__bold'] = block =>
         `bold: ${JSON.stringify(block.getFieldValue('bold'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['note__boldMetadata'] = block => {
         let out = `boldMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['note__conditionalParentLabel'] = block =>
         `conditionalParentLabel: ${JSON.stringify(block.getFieldValue('conditionalParentLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['note__conditionalParentLabelMetadata'] = block => {
         let out = `conditionalParentLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['note__conditionalParentValue'] = block =>
         `conditionalParentValue: ${JSON.stringify(block.getFieldValue('conditionalParentValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['note__conditionalParentValueMetadata'] = block => {
         let out = `conditionalParentValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['note__customTabId'] = block =>
         `customTabId: ${JSON.stringify(block.getFieldValue('customTabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['note__customTabIdMetadata'] = block => {
         let out = `customTabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['note__documentId'] = block =>
         `documentId: ${JSON.stringify(block.getFieldValue('documentId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['note__documentIdMetadata'] = block => {
         let out = `documentIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['note__font'] = block =>
         `font: ${JSON.stringify(block.getFieldValue('font'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['note__fontColor'] = block =>
         `fontColor: ${JSON.stringify(block.getFieldValue('fontColor'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['note__fontColorMetadata'] = block => {
         let out = `fontColorMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['note__fontMetadata'] = block => {
         let out = `fontMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['note__fontSize'] = block =>
         `fontSize: ${JSON.stringify(block.getFieldValue('fontSize'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['note__fontSizeMetadata'] = block => {
         let out = `fontSizeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['note__formOrder'] = block =>
         `formOrder: ${JSON.stringify(block.getFieldValue('formOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['note__formOrderMetadata'] = block => {
         let out = `formOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['note__formPageLabel'] = block =>
         `formPageLabel: ${JSON.stringify(block.getFieldValue('formPageLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['note__formPageLabelMetadata'] = block => {
         let out = `formPageLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['note__formPageNumber'] = block =>
         `formPageNumber: ${JSON.stringify(block.getFieldValue('formPageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['note__formPageNumberMetadata'] = block => {
         let out = `formPageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['note__height'] = block =>
         `height: ${JSON.stringify(block.getFieldValue('height'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['note__heightMetadata'] = block => {
         let out = `heightMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['note__italic'] = block =>
         `italic: ${JSON.stringify(block.getFieldValue('italic'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['note__italicMetadata'] = block => {
         let out = `italicMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['note__mergeFieldXml'] = block =>
         `mergeFieldXml: ${JSON.stringify(block.getFieldValue('mergeFieldXml'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['note__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['note__nameMetadata'] = block => {
         let out = `nameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['note__pageNumber'] = block =>
         `pageNumber: ${JSON.stringify(block.getFieldValue('pageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['note__pageNumberMetadata'] = block => {
         let out = `pageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['note__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['note__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['note__recipientIdGuidMetadata'] = block => {
         let out = `recipientIdGuidMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['note__recipientIdMetadata'] = block => {
         let out = `recipientIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['note__shared'] = block =>
         `shared: ${JSON.stringify(block.getFieldValue('shared'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['note__sharedMetadata'] = block => {
         let out = `sharedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['note__source'] = block =>
         `source: ${JSON.stringify(block.getFieldValue('source'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['note__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['note__statusMetadata'] = block => {
         let out = `statusMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['note__tabGroupLabels'] = block => {
         let out = `    tabGroupLabels: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'tabGroupLabel', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['note_tabGroupLabels__tabGroupLabel'] = block =>
         `    ${JSON.stringify(block.getFieldValue('tabGroupLabel'))}${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['note__tabGroupLabelsMetadata'] = block => {
         let out = `tabGroupLabelsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['note__tabId'] = block =>
         `tabId: ${JSON.stringify(block.getFieldValue('tabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['note__tabIdMetadata'] = block => {
         let out = `tabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['note__tabLabel'] = block =>
         `tabLabel: ${JSON.stringify(block.getFieldValue('tabLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['note__tabLabelMetadata'] = block => {
         let out = `tabLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['note__tabOrder'] = block =>
         `tabOrder: ${JSON.stringify(block.getFieldValue('tabOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['note__tabOrderMetadata'] = block => {
         let out = `tabOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['note__tabType'] = block =>
         `tabType: ${JSON.stringify(block.getFieldValue('tabType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['note__tabTypeMetadata'] = block => {
         let out = `tabTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['note__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['note__templateLockedMetadata'] = block => {
         let out = `templateLockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['note__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['note__templateRequiredMetadata'] = block => {
         let out = `templateRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['note__toolTipMetadata'] = block => {
         let out = `toolTipMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['note__tooltip'] = block =>
         `tooltip: ${JSON.stringify(block.getFieldValue('tooltip'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['note__underline'] = block =>
         `underline: ${JSON.stringify(block.getFieldValue('underline'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['note__underlineMetadata'] = block => {
         let out = `underlineMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['note__value'] = block =>
         `value: ${JSON.stringify(block.getFieldValue('value'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['note__valueMetadata'] = block => {
         let out = `valueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['note__width'] = block =>
         `width: ${JSON.stringify(block.getFieldValue('width'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['note__widthMetadata'] = block => {
         let out = `widthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['note__xPosition'] = block =>
         `xPosition: ${JSON.stringify(block.getFieldValue('xPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['note__xPositionMetadata'] = block => {
         let out = `xPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['note__yPosition'] = block =>
         `yPosition: ${JSON.stringify(block.getFieldValue('yPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['note__yPositionMetadata'] = block => {
         let out = `yPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

Blockly.JavaScript['notification'] = block => 
  `\n.add_object("notification", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['notification__useAccountDefaults'] = block =>
         `useAccountDefaults: ${JSON.stringify(block.getFieldValue('useAccountDefaults'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['number'] = block => 
  `\n.add_object("number", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['number__anchorAllowWhiteSpaceInCharacters'] = block =>
         `anchorAllowWhiteSpaceInCharacters: ${JSON.stringify(block.getFieldValue('anchorAllowWhiteSpaceInCharacters'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__anchorAllowWhiteSpaceInCharactersMetadata'] = block => {
         let out = `anchorAllowWhiteSpaceInCharactersMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__anchorCaseSensitive'] = block =>
         `anchorCaseSensitive: ${JSON.stringify(block.getFieldValue('anchorCaseSensitive'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__anchorCaseSensitiveMetadata'] = block => {
         let out = `anchorCaseSensitiveMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__anchorHorizontalAlignment'] = block =>
         `anchorHorizontalAlignment: ${JSON.stringify(block.getFieldValue('anchorHorizontalAlignment'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__anchorHorizontalAlignmentMetadata'] = block => {
         let out = `anchorHorizontalAlignmentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__anchorIgnoreIfNotPresent'] = block =>
         `anchorIgnoreIfNotPresent: ${JSON.stringify(block.getFieldValue('anchorIgnoreIfNotPresent'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__anchorIgnoreIfNotPresentMetadata'] = block => {
         let out = `anchorIgnoreIfNotPresentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__anchorMatchWholeWord'] = block =>
         `anchorMatchWholeWord: ${JSON.stringify(block.getFieldValue('anchorMatchWholeWord'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__anchorMatchWholeWordMetadata'] = block => {
         let out = `anchorMatchWholeWordMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__anchorString'] = block =>
         `anchorString: ${JSON.stringify(block.getFieldValue('anchorString'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__anchorStringMetadata'] = block => {
         let out = `anchorStringMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__anchorTabProcessorVersion'] = block =>
         `anchorTabProcessorVersion: ${JSON.stringify(block.getFieldValue('anchorTabProcessorVersion'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__anchorTabProcessorVersionMetadata'] = block => {
         let out = `anchorTabProcessorVersionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__anchorUnits'] = block =>
         `anchorUnits: ${JSON.stringify(block.getFieldValue('anchorUnits'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__anchorUnitsMetadata'] = block => {
         let out = `anchorUnitsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__anchorXOffset'] = block =>
         `anchorXOffset: ${JSON.stringify(block.getFieldValue('anchorXOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__anchorXOffsetMetadata'] = block => {
         let out = `anchorXOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__anchorYOffset'] = block =>
         `anchorYOffset: ${JSON.stringify(block.getFieldValue('anchorYOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__anchorYOffsetMetadata'] = block => {
         let out = `anchorYOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__bold'] = block =>
         `bold: ${JSON.stringify(block.getFieldValue('bold'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__boldMetadata'] = block => {
         let out = `boldMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__concealValueOnDocument'] = block =>
         `concealValueOnDocument: ${JSON.stringify(block.getFieldValue('concealValueOnDocument'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__concealValueOnDocumentMetadata'] = block => {
         let out = `concealValueOnDocumentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__conditionalParentLabel'] = block =>
         `conditionalParentLabel: ${JSON.stringify(block.getFieldValue('conditionalParentLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__conditionalParentLabelMetadata'] = block => {
         let out = `conditionalParentLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__conditionalParentValue'] = block =>
         `conditionalParentValue: ${JSON.stringify(block.getFieldValue('conditionalParentValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__conditionalParentValueMetadata'] = block => {
         let out = `conditionalParentValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__customTabId'] = block =>
         `customTabId: ${JSON.stringify(block.getFieldValue('customTabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__customTabIdMetadata'] = block => {
         let out = `customTabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__disableAutoSize'] = block =>
         `disableAutoSize: ${JSON.stringify(block.getFieldValue('disableAutoSize'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__disableAutoSizeMetadata'] = block => {
         let out = `disableAutoSizeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__documentId'] = block =>
         `documentId: ${JSON.stringify(block.getFieldValue('documentId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__documentIdMetadata'] = block => {
         let out = `documentIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__font'] = block =>
         `font: ${JSON.stringify(block.getFieldValue('font'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['number__fontColor'] = block =>
         `fontColor: ${JSON.stringify(block.getFieldValue('fontColor'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__fontColorMetadata'] = block => {
         let out = `fontColorMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['number__fontMetadata'] = block => {
         let out = `fontMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__fontSize'] = block =>
         `fontSize: ${JSON.stringify(block.getFieldValue('fontSize'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__fontSizeMetadata'] = block => {
         let out = `fontSizeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__formOrder'] = block =>
         `formOrder: ${JSON.stringify(block.getFieldValue('formOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__formOrderMetadata'] = block => {
         let out = `formOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__formPageLabel'] = block =>
         `formPageLabel: ${JSON.stringify(block.getFieldValue('formPageLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__formPageLabelMetadata'] = block => {
         let out = `formPageLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__formPageNumber'] = block =>
         `formPageNumber: ${JSON.stringify(block.getFieldValue('formPageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__formPageNumberMetadata'] = block => {
         let out = `formPageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__formula'] = block =>
         `formula: ${JSON.stringify(block.getFieldValue('formula'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__formulaMetadata'] = block => {
         let out = `formulaMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__height'] = block =>
         `height: ${JSON.stringify(block.getFieldValue('height'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__heightMetadata'] = block => {
         let out = `heightMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__italic'] = block =>
         `italic: ${JSON.stringify(block.getFieldValue('italic'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__italicMetadata'] = block => {
         let out = `italicMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__locked'] = block =>
         `locked: ${JSON.stringify(block.getFieldValue('locked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__lockedMetadata'] = block => {
         let out = `lockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__maxLength'] = block =>
         `maxLength: ${JSON.stringify(block.getFieldValue('maxLength'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__maxLengthMetadata'] = block => {
         let out = `maxLengthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__mergeFieldXml'] = block =>
         `mergeFieldXml: ${JSON.stringify(block.getFieldValue('mergeFieldXml'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['number__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__nameMetadata'] = block => {
         let out = `nameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__originalValue'] = block =>
         `originalValue: ${JSON.stringify(block.getFieldValue('originalValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__originalValueMetadata'] = block => {
         let out = `originalValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__pageNumber'] = block =>
         `pageNumber: ${JSON.stringify(block.getFieldValue('pageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__pageNumberMetadata'] = block => {
         let out = `pageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['number__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__recipientIdGuidMetadata'] = block => {
         let out = `recipientIdGuidMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['number__recipientIdMetadata'] = block => {
         let out = `recipientIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__requireAll'] = block =>
         `requireAll: ${JSON.stringify(block.getFieldValue('requireAll'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__requireAllMetadata'] = block => {
         let out = `requireAllMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__requireInitialOnSharedChange'] = block =>
         `requireInitialOnSharedChange: ${JSON.stringify(block.getFieldValue('requireInitialOnSharedChange'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__requireInitialOnSharedChangeMetadata'] = block => {
         let out = `requireInitialOnSharedChangeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__required'] = block =>
         `required: ${JSON.stringify(block.getFieldValue('required'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__requiredMetadata'] = block => {
         let out = `requiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__senderRequired'] = block =>
         `senderRequired: ${JSON.stringify(block.getFieldValue('senderRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__senderRequiredMetadata'] = block => {
         let out = `senderRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__shareToRecipients'] = block =>
         `shareToRecipients: ${JSON.stringify(block.getFieldValue('shareToRecipients'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__shareToRecipientsMetadata'] = block => {
         let out = `shareToRecipientsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__shared'] = block =>
         `shared: ${JSON.stringify(block.getFieldValue('shared'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__sharedMetadata'] = block => {
         let out = `sharedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__source'] = block =>
         `source: ${JSON.stringify(block.getFieldValue('source'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['number__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__statusMetadata'] = block => {
         let out = `statusMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['number__tabGroupLabels'] = block => {
         let out = `    tabGroupLabels: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'tabGroupLabel', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['number_tabGroupLabels__tabGroupLabel'] = block =>
         `    ${JSON.stringify(block.getFieldValue('tabGroupLabel'))}${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['number__tabGroupLabelsMetadata'] = block => {
         let out = `tabGroupLabelsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__tabId'] = block =>
         `tabId: ${JSON.stringify(block.getFieldValue('tabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__tabIdMetadata'] = block => {
         let out = `tabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__tabLabel'] = block =>
         `tabLabel: ${JSON.stringify(block.getFieldValue('tabLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__tabLabelMetadata'] = block => {
         let out = `tabLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__tabOrder'] = block =>
         `tabOrder: ${JSON.stringify(block.getFieldValue('tabOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__tabOrderMetadata'] = block => {
         let out = `tabOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__tabType'] = block =>
         `tabType: ${JSON.stringify(block.getFieldValue('tabType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__tabTypeMetadata'] = block => {
         let out = `tabTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__templateLockedMetadata'] = block => {
         let out = `templateLockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__templateRequiredMetadata'] = block => {
         let out = `templateRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['number__toolTipMetadata'] = block => {
         let out = `toolTipMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__tooltip'] = block =>
         `tooltip: ${JSON.stringify(block.getFieldValue('tooltip'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['number__underline'] = block =>
         `underline: ${JSON.stringify(block.getFieldValue('underline'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__underlineMetadata'] = block => {
         let out = `underlineMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__validationMessage'] = block =>
         `validationMessage: ${JSON.stringify(block.getFieldValue('validationMessage'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__validationMessageMetadata'] = block => {
         let out = `validationMessageMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__validationPattern'] = block =>
         `validationPattern: ${JSON.stringify(block.getFieldValue('validationPattern'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__validationPatternMetadata'] = block => {
         let out = `validationPatternMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__value'] = block =>
         `value: ${JSON.stringify(block.getFieldValue('value'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__valueMetadata'] = block => {
         let out = `valueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__width'] = block =>
         `width: ${JSON.stringify(block.getFieldValue('width'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__widthMetadata'] = block => {
         let out = `widthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__xPosition'] = block =>
         `xPosition: ${JSON.stringify(block.getFieldValue('xPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__xPositionMetadata'] = block => {
         let out = `xPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['number__yPosition'] = block =>
         `yPosition: ${JSON.stringify(block.getFieldValue('yPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['number__yPositionMetadata'] = block => {
         let out = `yPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

Blockly.JavaScript['page'] = block => 
  `\n.add_object("page", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['page__dpi'] = block =>
         `dpi: ${JSON.stringify(block.getFieldValue('dpi'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['page__height'] = block =>
         `height: ${JSON.stringify(block.getFieldValue('height'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['page__imageBytes'] = block =>
         `imageBytes: ${JSON.stringify(block.getFieldValue('imageBytes'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['page__mimeType'] = block =>
         `mimeType: ${JSON.stringify(block.getFieldValue('mimeType'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['page__pageId'] = block =>
         `pageId: ${JSON.stringify(block.getFieldValue('pageId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['page__sequence'] = block =>
         `sequence: ${JSON.stringify(block.getFieldValue('sequence'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['page__width'] = block =>
         `width: ${JSON.stringify(block.getFieldValue('width'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['pathExtendedElement'] = block => 
  `\n.add_object("pathExtendedElement", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['pathExtendedElement__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['pathExtendedElement__type'] = block =>
         `type: ${JSON.stringify(block.getFieldValue('type'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['pathExtendedElement__typeName'] = block =>
         `typeName: ${JSON.stringify(block.getFieldValue('typeName'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['paymentDetails'] = block => 
  `\n.add_object("paymentDetails", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
      Blockly.JavaScript['paymentDetails__allowedPaymentMethods'] = block => {
         let out = `    allowedPaymentMethods: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'allowedPaymentMethod', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['paymentDetails_allowedPaymentMethods__allowedPaymentMethod'] = block =>
         `    ${JSON.stringify(block.getFieldValue('allowedPaymentMethod'))}${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['paymentDetails__chargeId'] = block =>
         `chargeId: ${JSON.stringify(block.getFieldValue('chargeId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['paymentDetails__currencyCode'] = block =>
         `currencyCode: ${JSON.stringify(block.getFieldValue('currencyCode'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['paymentDetails__currencyCodeMetadata'] = block => {
         let out = `currencyCodeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['paymentDetails__customMetadata'] = block =>
         `customMetadata: ${JSON.stringify(block.getFieldValue('customMetadata'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['paymentDetails__customMetadataRequired'] = block =>
         `customMetadataRequired: ${block.getFieldValue('customMetadataRequired')}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['paymentDetails__customerId'] = block =>
         `customerId: ${JSON.stringify(block.getFieldValue('customerId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['paymentDetails__gatewayAccountId'] = block =>
         `gatewayAccountId: ${JSON.stringify(block.getFieldValue('gatewayAccountId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['paymentDetails__gatewayAccountIdMetadata'] = block => {
         let out = `gatewayAccountIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['paymentDetails__gatewayDisplayName'] = block =>
         `gatewayDisplayName: ${JSON.stringify(block.getFieldValue('gatewayDisplayName'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['paymentDetails__gatewayName'] = block =>
         `gatewayName: ${JSON.stringify(block.getFieldValue('gatewayName'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['paymentDetails__paymentOption'] = block =>
         `paymentOption: ${JSON.stringify(block.getFieldValue('paymentOption'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['paymentDetails__paymentSourceId'] = block =>
         `paymentSourceId: ${JSON.stringify(block.getFieldValue('paymentSourceId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['paymentDetails__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['paymentDetails__subGatewayName'] = block =>
         `subGatewayName: ${JSON.stringify(block.getFieldValue('subGatewayName'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['paymentLineItem'] = block => 
  `\n.add_object("paymentLineItem", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['paymentLineItem__amountReference'] = block =>
         `amountReference: ${JSON.stringify(block.getFieldValue('amountReference'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['paymentLineItem__description'] = block =>
         `description: ${JSON.stringify(block.getFieldValue('description'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['paymentLineItem__itemCode'] = block =>
         `itemCode: ${JSON.stringify(block.getFieldValue('itemCode'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['paymentLineItem__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['paymentSignerValues'] = block => 
  `\n.add_object("paymentSignerValues", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['paymentSignerValues__paymentOption'] = block =>
         `paymentOption: ${JSON.stringify(block.getFieldValue('paymentOption'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['phoneNumber'] = block => 
  `\n.add_object("phoneNumber", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['phoneNumber__anchorAllowWhiteSpaceInCharacters'] = block =>
         `anchorAllowWhiteSpaceInCharacters: ${JSON.stringify(block.getFieldValue('anchorAllowWhiteSpaceInCharacters'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['phoneNumber__anchorAllowWhiteSpaceInCharactersMetadata'] = block => {
         let out = `anchorAllowWhiteSpaceInCharactersMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['phoneNumber__anchorCaseSensitive'] = block =>
         `anchorCaseSensitive: ${JSON.stringify(block.getFieldValue('anchorCaseSensitive'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['phoneNumber__anchorCaseSensitiveMetadata'] = block => {
         let out = `anchorCaseSensitiveMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['phoneNumber__anchorHorizontalAlignment'] = block =>
         `anchorHorizontalAlignment: ${JSON.stringify(block.getFieldValue('anchorHorizontalAlignment'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['phoneNumber__anchorHorizontalAlignmentMetadata'] = block => {
         let out = `anchorHorizontalAlignmentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['phoneNumber__anchorIgnoreIfNotPresent'] = block =>
         `anchorIgnoreIfNotPresent: ${JSON.stringify(block.getFieldValue('anchorIgnoreIfNotPresent'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['phoneNumber__anchorIgnoreIfNotPresentMetadata'] = block => {
         let out = `anchorIgnoreIfNotPresentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['phoneNumber__anchorMatchWholeWord'] = block =>
         `anchorMatchWholeWord: ${JSON.stringify(block.getFieldValue('anchorMatchWholeWord'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['phoneNumber__anchorMatchWholeWordMetadata'] = block => {
         let out = `anchorMatchWholeWordMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['phoneNumber__anchorString'] = block =>
         `anchorString: ${JSON.stringify(block.getFieldValue('anchorString'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['phoneNumber__anchorStringMetadata'] = block => {
         let out = `anchorStringMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['phoneNumber__anchorTabProcessorVersion'] = block =>
         `anchorTabProcessorVersion: ${JSON.stringify(block.getFieldValue('anchorTabProcessorVersion'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['phoneNumber__anchorTabProcessorVersionMetadata'] = block => {
         let out = `anchorTabProcessorVersionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['phoneNumber__anchorUnits'] = block =>
         `anchorUnits: ${JSON.stringify(block.getFieldValue('anchorUnits'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['phoneNumber__anchorUnitsMetadata'] = block => {
         let out = `anchorUnitsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['phoneNumber__anchorXOffset'] = block =>
         `anchorXOffset: ${JSON.stringify(block.getFieldValue('anchorXOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['phoneNumber__anchorXOffsetMetadata'] = block => {
         let out = `anchorXOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['phoneNumber__anchorYOffset'] = block =>
         `anchorYOffset: ${JSON.stringify(block.getFieldValue('anchorYOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['phoneNumber__anchorYOffsetMetadata'] = block => {
         let out = `anchorYOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['phoneNumber__bold'] = block =>
         `bold: ${JSON.stringify(block.getFieldValue('bold'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['phoneNumber__boldMetadata'] = block => {
         let out = `boldMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['phoneNumber__concealValueOnDocument'] = block =>
         `concealValueOnDocument: ${JSON.stringify(block.getFieldValue('concealValueOnDocument'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['phoneNumber__concealValueOnDocumentMetadata'] = block => {
         let out = `concealValueOnDocumentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['phoneNumber__conditionalParentLabel'] = block =>
         `conditionalParentLabel: ${JSON.stringify(block.getFieldValue('conditionalParentLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['phoneNumber__conditionalParentLabelMetadata'] = block => {
         let out = `conditionalParentLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['phoneNumber__conditionalParentValue'] = block =>
         `conditionalParentValue: ${JSON.stringify(block.getFieldValue('conditionalParentValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['phoneNumber__conditionalParentValueMetadata'] = block => {
         let out = `conditionalParentValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['phoneNumber__customTabId'] = block =>
         `customTabId: ${JSON.stringify(block.getFieldValue('customTabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['phoneNumber__customTabIdMetadata'] = block => {
         let out = `customTabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['phoneNumber__disableAutoSize'] = block =>
         `disableAutoSize: ${JSON.stringify(block.getFieldValue('disableAutoSize'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['phoneNumber__disableAutoSizeMetadata'] = block => {
         let out = `disableAutoSizeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['phoneNumber__documentId'] = block =>
         `documentId: ${JSON.stringify(block.getFieldValue('documentId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['phoneNumber__documentIdMetadata'] = block => {
         let out = `documentIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['phoneNumber__font'] = block =>
         `font: ${JSON.stringify(block.getFieldValue('font'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['phoneNumber__fontColor'] = block =>
         `fontColor: ${JSON.stringify(block.getFieldValue('fontColor'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['phoneNumber__fontColorMetadata'] = block => {
         let out = `fontColorMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['phoneNumber__fontMetadata'] = block => {
         let out = `fontMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['phoneNumber__fontSize'] = block =>
         `fontSize: ${JSON.stringify(block.getFieldValue('fontSize'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['phoneNumber__fontSizeMetadata'] = block => {
         let out = `fontSizeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['phoneNumber__formOrder'] = block =>
         `formOrder: ${JSON.stringify(block.getFieldValue('formOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['phoneNumber__formOrderMetadata'] = block => {
         let out = `formOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['phoneNumber__formPageLabel'] = block =>
         `formPageLabel: ${JSON.stringify(block.getFieldValue('formPageLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['phoneNumber__formPageLabelMetadata'] = block => {
         let out = `formPageLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['phoneNumber__formPageNumber'] = block =>
         `formPageNumber: ${JSON.stringify(block.getFieldValue('formPageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['phoneNumber__formPageNumberMetadata'] = block => {
         let out = `formPageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['phoneNumber__height'] = block =>
         `height: ${JSON.stringify(block.getFieldValue('height'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['phoneNumber__heightMetadata'] = block => {
         let out = `heightMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['phoneNumber__italic'] = block =>
         `italic: ${JSON.stringify(block.getFieldValue('italic'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['phoneNumber__italicMetadata'] = block => {
         let out = `italicMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['phoneNumber__locked'] = block =>
         `locked: ${JSON.stringify(block.getFieldValue('locked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['phoneNumber__lockedMetadata'] = block => {
         let out = `lockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['phoneNumber__maxLength'] = block =>
         `maxLength: ${JSON.stringify(block.getFieldValue('maxLength'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['phoneNumber__maxLengthMetadata'] = block => {
         let out = `maxLengthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['phoneNumber__mergeFieldXml'] = block =>
         `mergeFieldXml: ${JSON.stringify(block.getFieldValue('mergeFieldXml'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['phoneNumber__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['phoneNumber__nameMetadata'] = block => {
         let out = `nameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['phoneNumber__originalValue'] = block =>
         `originalValue: ${JSON.stringify(block.getFieldValue('originalValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['phoneNumber__originalValueMetadata'] = block => {
         let out = `originalValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['phoneNumber__pageNumber'] = block =>
         `pageNumber: ${JSON.stringify(block.getFieldValue('pageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['phoneNumber__pageNumberMetadata'] = block => {
         let out = `pageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['phoneNumber__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['phoneNumber__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['phoneNumber__recipientIdGuidMetadata'] = block => {
         let out = `recipientIdGuidMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['phoneNumber__recipientIdMetadata'] = block => {
         let out = `recipientIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['phoneNumber__required'] = block =>
         `required: ${JSON.stringify(block.getFieldValue('required'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['phoneNumber__requiredMetadata'] = block => {
         let out = `requiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['phoneNumber__source'] = block =>
         `source: ${JSON.stringify(block.getFieldValue('source'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['phoneNumber__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['phoneNumber__statusMetadata'] = block => {
         let out = `statusMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['phoneNumber__tabGroupLabels'] = block => {
         let out = `    tabGroupLabels: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'tabGroupLabel', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['phoneNumber_tabGroupLabels__tabGroupLabel'] = block =>
         `    ${JSON.stringify(block.getFieldValue('tabGroupLabel'))}${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['phoneNumber__tabGroupLabelsMetadata'] = block => {
         let out = `tabGroupLabelsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['phoneNumber__tabId'] = block =>
         `tabId: ${JSON.stringify(block.getFieldValue('tabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['phoneNumber__tabIdMetadata'] = block => {
         let out = `tabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['phoneNumber__tabLabel'] = block =>
         `tabLabel: ${JSON.stringify(block.getFieldValue('tabLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['phoneNumber__tabLabelMetadata'] = block => {
         let out = `tabLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['phoneNumber__tabOrder'] = block =>
         `tabOrder: ${JSON.stringify(block.getFieldValue('tabOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['phoneNumber__tabOrderMetadata'] = block => {
         let out = `tabOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['phoneNumber__tabType'] = block =>
         `tabType: ${JSON.stringify(block.getFieldValue('tabType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['phoneNumber__tabTypeMetadata'] = block => {
         let out = `tabTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['phoneNumber__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['phoneNumber__templateLockedMetadata'] = block => {
         let out = `templateLockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['phoneNumber__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['phoneNumber__templateRequiredMetadata'] = block => {
         let out = `templateRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['phoneNumber__toolTipMetadata'] = block => {
         let out = `toolTipMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['phoneNumber__tooltip'] = block =>
         `tooltip: ${JSON.stringify(block.getFieldValue('tooltip'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['phoneNumber__underline'] = block =>
         `underline: ${JSON.stringify(block.getFieldValue('underline'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['phoneNumber__underlineMetadata'] = block => {
         let out = `underlineMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['phoneNumber__value'] = block =>
         `value: ${JSON.stringify(block.getFieldValue('value'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['phoneNumber__valueMetadata'] = block => {
         let out = `valueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['phoneNumber__width'] = block =>
         `width: ${JSON.stringify(block.getFieldValue('width'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['phoneNumber__widthMetadata'] = block => {
         let out = `widthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['phoneNumber__xPosition'] = block =>
         `xPosition: ${JSON.stringify(block.getFieldValue('xPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['phoneNumber__xPositionMetadata'] = block => {
         let out = `xPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['phoneNumber__yPosition'] = block =>
         `yPosition: ${JSON.stringify(block.getFieldValue('yPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['phoneNumber__yPositionMetadata'] = block => {
         let out = `yPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

Blockly.JavaScript['polyLine'] = block => 
  `\n.add_object("polyLine", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['polyLine__x1'] = block =>
         `x1: ${JSON.stringify(block.getFieldValue('x1'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['polyLine__x2'] = block =>
         `x2: ${JSON.stringify(block.getFieldValue('x2'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['polyLine__y1'] = block =>
         `y1: ${JSON.stringify(block.getFieldValue('y1'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['polyLine__y2'] = block =>
         `y2: ${JSON.stringify(block.getFieldValue('y2'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['polyLineOverlay'] = block => 
  `\n.add_object("polyLineOverlay", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['polyLineOverlay__anchorAllowWhiteSpaceInCharacters'] = block =>
         `anchorAllowWhiteSpaceInCharacters: ${JSON.stringify(block.getFieldValue('anchorAllowWhiteSpaceInCharacters'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['polyLineOverlay__anchorAllowWhiteSpaceInCharactersMetadata'] = block => {
         let out = `anchorAllowWhiteSpaceInCharactersMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['polyLineOverlay__anchorCaseSensitive'] = block =>
         `anchorCaseSensitive: ${JSON.stringify(block.getFieldValue('anchorCaseSensitive'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['polyLineOverlay__anchorCaseSensitiveMetadata'] = block => {
         let out = `anchorCaseSensitiveMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['polyLineOverlay__anchorHorizontalAlignment'] = block =>
         `anchorHorizontalAlignment: ${JSON.stringify(block.getFieldValue('anchorHorizontalAlignment'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['polyLineOverlay__anchorHorizontalAlignmentMetadata'] = block => {
         let out = `anchorHorizontalAlignmentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['polyLineOverlay__anchorIgnoreIfNotPresent'] = block =>
         `anchorIgnoreIfNotPresent: ${JSON.stringify(block.getFieldValue('anchorIgnoreIfNotPresent'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['polyLineOverlay__anchorIgnoreIfNotPresentMetadata'] = block => {
         let out = `anchorIgnoreIfNotPresentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['polyLineOverlay__anchorMatchWholeWord'] = block =>
         `anchorMatchWholeWord: ${JSON.stringify(block.getFieldValue('anchorMatchWholeWord'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['polyLineOverlay__anchorMatchWholeWordMetadata'] = block => {
         let out = `anchorMatchWholeWordMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['polyLineOverlay__anchorString'] = block =>
         `anchorString: ${JSON.stringify(block.getFieldValue('anchorString'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['polyLineOverlay__anchorStringMetadata'] = block => {
         let out = `anchorStringMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['polyLineOverlay__anchorTabProcessorVersion'] = block =>
         `anchorTabProcessorVersion: ${JSON.stringify(block.getFieldValue('anchorTabProcessorVersion'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['polyLineOverlay__anchorTabProcessorVersionMetadata'] = block => {
         let out = `anchorTabProcessorVersionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['polyLineOverlay__anchorUnits'] = block =>
         `anchorUnits: ${JSON.stringify(block.getFieldValue('anchorUnits'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['polyLineOverlay__anchorUnitsMetadata'] = block => {
         let out = `anchorUnitsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['polyLineOverlay__anchorXOffset'] = block =>
         `anchorXOffset: ${JSON.stringify(block.getFieldValue('anchorXOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['polyLineOverlay__anchorXOffsetMetadata'] = block => {
         let out = `anchorXOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['polyLineOverlay__anchorYOffset'] = block =>
         `anchorYOffset: ${JSON.stringify(block.getFieldValue('anchorYOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['polyLineOverlay__anchorYOffsetMetadata'] = block => {
         let out = `anchorYOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['polyLineOverlay__conditionalParentLabel'] = block =>
         `conditionalParentLabel: ${JSON.stringify(block.getFieldValue('conditionalParentLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['polyLineOverlay__conditionalParentLabelMetadata'] = block => {
         let out = `conditionalParentLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['polyLineOverlay__conditionalParentValue'] = block =>
         `conditionalParentValue: ${JSON.stringify(block.getFieldValue('conditionalParentValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['polyLineOverlay__conditionalParentValueMetadata'] = block => {
         let out = `conditionalParentValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['polyLineOverlay__customTabId'] = block =>
         `customTabId: ${JSON.stringify(block.getFieldValue('customTabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['polyLineOverlay__customTabIdMetadata'] = block => {
         let out = `customTabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['polyLineOverlay__documentId'] = block =>
         `documentId: ${JSON.stringify(block.getFieldValue('documentId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['polyLineOverlay__documentIdMetadata'] = block => {
         let out = `documentIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['polyLineOverlay__formOrder'] = block =>
         `formOrder: ${JSON.stringify(block.getFieldValue('formOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['polyLineOverlay__formOrderMetadata'] = block => {
         let out = `formOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['polyLineOverlay__formPageLabel'] = block =>
         `formPageLabel: ${JSON.stringify(block.getFieldValue('formPageLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['polyLineOverlay__formPageLabelMetadata'] = block => {
         let out = `formPageLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['polyLineOverlay__formPageNumber'] = block =>
         `formPageNumber: ${JSON.stringify(block.getFieldValue('formPageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['polyLineOverlay__formPageNumberMetadata'] = block => {
         let out = `formPageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['polyLineOverlay__height'] = block =>
         `height: ${JSON.stringify(block.getFieldValue('height'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['polyLineOverlay__heightMetadata'] = block => {
         let out = `heightMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['polyLineOverlay__locked'] = block =>
         `locked: ${JSON.stringify(block.getFieldValue('locked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['polyLineOverlay__lockedMetadata'] = block => {
         let out = `lockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['polyLineOverlay__mergeFieldXml'] = block =>
         `mergeFieldXml: ${JSON.stringify(block.getFieldValue('mergeFieldXml'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['polyLineOverlay__overlayType'] = block =>
         `overlayType: ${JSON.stringify(block.getFieldValue('overlayType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['polyLineOverlay__overlayTypeMetadata'] = block => {
         let out = `overlayTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['polyLineOverlay__pageNumber'] = block =>
         `pageNumber: ${JSON.stringify(block.getFieldValue('pageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['polyLineOverlay__pageNumberMetadata'] = block => {
         let out = `pageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['polyLineOverlay__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['polyLineOverlay__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['polyLineOverlay__recipientIdGuidMetadata'] = block => {
         let out = `recipientIdGuidMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['polyLineOverlay__recipientIdMetadata'] = block => {
         let out = `recipientIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['polyLineOverlay__shared'] = block =>
         `shared: ${JSON.stringify(block.getFieldValue('shared'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['polyLineOverlay__sharedMetadata'] = block => {
         let out = `sharedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['polyLineOverlay__source'] = block =>
         `source: ${JSON.stringify(block.getFieldValue('source'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['polyLineOverlay__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['polyLineOverlay__statusMetadata'] = block => {
         let out = `statusMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['polyLineOverlay__tabGroupLabels'] = block => {
         let out = `    tabGroupLabels: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'tabGroupLabel', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['polyLineOverlay_tabGroupLabels__tabGroupLabel'] = block =>
         `    ${JSON.stringify(block.getFieldValue('tabGroupLabel'))}${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['polyLineOverlay__tabGroupLabelsMetadata'] = block => {
         let out = `tabGroupLabelsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['polyLineOverlay__tabId'] = block =>
         `tabId: ${JSON.stringify(block.getFieldValue('tabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['polyLineOverlay__tabIdMetadata'] = block => {
         let out = `tabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['polyLineOverlay__tabLabel'] = block =>
         `tabLabel: ${JSON.stringify(block.getFieldValue('tabLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['polyLineOverlay__tabOrder'] = block =>
         `tabOrder: ${JSON.stringify(block.getFieldValue('tabOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['polyLineOverlay__tabOrderMetadata'] = block => {
         let out = `tabOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['polyLineOverlay__tabType'] = block =>
         `tabType: ${JSON.stringify(block.getFieldValue('tabType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['polyLineOverlay__tabTypeMetadata'] = block => {
         let out = `tabTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['polyLineOverlay__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['polyLineOverlay__templateLockedMetadata'] = block => {
         let out = `templateLockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['polyLineOverlay__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['polyLineOverlay__templateRequiredMetadata'] = block => {
         let out = `templateRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['polyLineOverlay__toolTipMetadata'] = block => {
         let out = `toolTipMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['polyLineOverlay__tooltip'] = block =>
         `tooltip: ${JSON.stringify(block.getFieldValue('tooltip'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['polyLineOverlay__width'] = block =>
         `width: ${JSON.stringify(block.getFieldValue('width'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['polyLineOverlay__widthMetadata'] = block => {
         let out = `widthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['polyLineOverlay__xPosition'] = block =>
         `xPosition: ${JSON.stringify(block.getFieldValue('xPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['polyLineOverlay__xPositionMetadata'] = block => {
         let out = `xPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['polyLineOverlay__yPosition'] = block =>
         `yPosition: ${JSON.stringify(block.getFieldValue('yPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['polyLineOverlay__yPositionMetadata'] = block => {
         let out = `yPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

Blockly.JavaScript['powerForm'] = block => 
  `\n.add_object("powerForm", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['powerForm__createdBy'] = block =>
         `createdBy: ${JSON.stringify(block.getFieldValue('createdBy'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['powerForm__createdDateTime'] = block =>
         `createdDateTime: ${JSON.stringify(block.getFieldValue('createdDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['powerForm__emailBody'] = block =>
         `emailBody: ${JSON.stringify(block.getFieldValue('emailBody'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['powerForm__emailSubject'] = block =>
         `emailSubject: ${JSON.stringify(block.getFieldValue('emailSubject'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['powerForm__instructions'] = block =>
         `instructions: ${JSON.stringify(block.getFieldValue('instructions'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['powerForm__isActive'] = block =>
         `isActive: ${JSON.stringify(block.getFieldValue('isActive'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['powerForm__lastUsed'] = block =>
         `lastUsed: ${JSON.stringify(block.getFieldValue('lastUsed'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['powerForm__limitUseInterval'] = block =>
         `limitUseInterval: ${JSON.stringify(block.getFieldValue('limitUseInterval'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['powerForm__limitUseIntervalEnabled'] = block =>
         `limitUseIntervalEnabled: ${JSON.stringify(block.getFieldValue('limitUseIntervalEnabled'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['powerForm__limitUseIntervalUnits'] = block =>
         `limitUseIntervalUnits: ${JSON.stringify(block.getFieldValue('limitUseIntervalUnits'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['powerForm__maxUseEnabled'] = block =>
         `maxUseEnabled: ${JSON.stringify(block.getFieldValue('maxUseEnabled'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['powerForm__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['powerForm__powerFormId'] = block =>
         `powerFormId: ${JSON.stringify(block.getFieldValue('powerFormId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['powerForm__powerFormUrl'] = block =>
         `powerFormUrl: ${JSON.stringify(block.getFieldValue('powerFormUrl'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['powerForm__senderName'] = block =>
         `senderName: ${JSON.stringify(block.getFieldValue('senderName'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['powerForm__senderUserId'] = block =>
         `senderUserId: ${JSON.stringify(block.getFieldValue('senderUserId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['powerForm__signingMode'] = block =>
         `signingMode: ${JSON.stringify(block.getFieldValue('signingMode'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['powerForm__templateId'] = block =>
         `templateId: ${JSON.stringify(block.getFieldValue('templateId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['powerForm__templateName'] = block =>
         `templateName: ${JSON.stringify(block.getFieldValue('templateName'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['powerForm__timesUsed'] = block =>
         `timesUsed: ${JSON.stringify(block.getFieldValue('timesUsed'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['powerForm__uri'] = block =>
         `uri: ${JSON.stringify(block.getFieldValue('uri'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['powerForm__usesRemaining'] = block =>
         `usesRemaining: ${JSON.stringify(block.getFieldValue('usesRemaining'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['powerFormRecipient'] = block => 
  `\n.add_object("powerFormRecipient", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['powerFormRecipient__accessCode'] = block =>
         `accessCode: ${JSON.stringify(block.getFieldValue('accessCode'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['powerFormRecipient__accessCodeLocked'] = block =>
         `accessCodeLocked: ${JSON.stringify(block.getFieldValue('accessCodeLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['powerFormRecipient__accessCodeRequired'] = block =>
         `accessCodeRequired: ${JSON.stringify(block.getFieldValue('accessCodeRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['powerFormRecipient__email'] = block =>
         `email: ${JSON.stringify(block.getFieldValue('email'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['powerFormRecipient__emailLocked'] = block =>
         `emailLocked: ${JSON.stringify(block.getFieldValue('emailLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['powerFormRecipient__idCheckConfigurationName'] = block =>
         `idCheckConfigurationName: ${JSON.stringify(block.getFieldValue('idCheckConfigurationName'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['powerFormRecipient__idCheckRequired'] = block =>
         `idCheckRequired: ${JSON.stringify(block.getFieldValue('idCheckRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['powerFormRecipient__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['powerFormRecipient__recipientType'] = block =>
         `recipientType: ${JSON.stringify(block.getFieldValue('recipientType'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['powerFormRecipient__roleName'] = block =>
         `roleName: ${JSON.stringify(block.getFieldValue('roleName'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['powerFormRecipient__routingOrder'] = block =>
         `routingOrder: ${JSON.stringify(block.getFieldValue('routingOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['powerFormRecipient__templateRequiresIdLookup'] = block =>
         `templateRequiresIdLookup: ${JSON.stringify(block.getFieldValue('templateRequiresIdLookup'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['powerFormRecipient__userNameLocked'] = block =>
         `userNameLocked: ${JSON.stringify(block.getFieldValue('userNameLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['prefillTabs'] = block => 
  `\n.add_object("prefillTabs", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
Blockly.JavaScript['radio'] = block => 
  `\n.add_object("radio", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['radio__anchorAllowWhiteSpaceInCharacters'] = block =>
         `anchorAllowWhiteSpaceInCharacters: ${JSON.stringify(block.getFieldValue('anchorAllowWhiteSpaceInCharacters'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['radio__anchorAllowWhiteSpaceInCharactersMetadata'] = block => {
         let out = `anchorAllowWhiteSpaceInCharactersMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['radio__anchorCaseSensitive'] = block =>
         `anchorCaseSensitive: ${JSON.stringify(block.getFieldValue('anchorCaseSensitive'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['radio__anchorCaseSensitiveMetadata'] = block => {
         let out = `anchorCaseSensitiveMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['radio__anchorHorizontalAlignment'] = block =>
         `anchorHorizontalAlignment: ${JSON.stringify(block.getFieldValue('anchorHorizontalAlignment'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['radio__anchorHorizontalAlignmentMetadata'] = block => {
         let out = `anchorHorizontalAlignmentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['radio__anchorIgnoreIfNotPresent'] = block =>
         `anchorIgnoreIfNotPresent: ${JSON.stringify(block.getFieldValue('anchorIgnoreIfNotPresent'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['radio__anchorIgnoreIfNotPresentMetadata'] = block => {
         let out = `anchorIgnoreIfNotPresentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['radio__anchorMatchWholeWord'] = block =>
         `anchorMatchWholeWord: ${JSON.stringify(block.getFieldValue('anchorMatchWholeWord'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['radio__anchorMatchWholeWordMetadata'] = block => {
         let out = `anchorMatchWholeWordMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['radio__anchorString'] = block =>
         `anchorString: ${JSON.stringify(block.getFieldValue('anchorString'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['radio__anchorStringMetadata'] = block => {
         let out = `anchorStringMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['radio__anchorTabProcessorVersion'] = block =>
         `anchorTabProcessorVersion: ${JSON.stringify(block.getFieldValue('anchorTabProcessorVersion'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['radio__anchorTabProcessorVersionMetadata'] = block => {
         let out = `anchorTabProcessorVersionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['radio__anchorUnits'] = block =>
         `anchorUnits: ${JSON.stringify(block.getFieldValue('anchorUnits'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['radio__anchorUnitsMetadata'] = block => {
         let out = `anchorUnitsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['radio__anchorXOffset'] = block =>
         `anchorXOffset: ${JSON.stringify(block.getFieldValue('anchorXOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['radio__anchorXOffsetMetadata'] = block => {
         let out = `anchorXOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['radio__anchorYOffset'] = block =>
         `anchorYOffset: ${JSON.stringify(block.getFieldValue('anchorYOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['radio__anchorYOffsetMetadata'] = block => {
         let out = `anchorYOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['radio__bold'] = block =>
         `bold: ${JSON.stringify(block.getFieldValue('bold'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['radio__boldMetadata'] = block => {
         let out = `boldMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['radio__font'] = block =>
         `font: ${JSON.stringify(block.getFieldValue('font'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['radio__fontColor'] = block =>
         `fontColor: ${JSON.stringify(block.getFieldValue('fontColor'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['radio__fontColorMetadata'] = block => {
         let out = `fontColorMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['radio__fontMetadata'] = block => {
         let out = `fontMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['radio__fontSize'] = block =>
         `fontSize: ${JSON.stringify(block.getFieldValue('fontSize'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['radio__fontSizeMetadata'] = block => {
         let out = `fontSizeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['radio__italic'] = block =>
         `italic: ${JSON.stringify(block.getFieldValue('italic'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['radio__italicMetadata'] = block => {
         let out = `italicMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['radio__locked'] = block =>
         `locked: ${JSON.stringify(block.getFieldValue('locked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['radio__lockedMetadata'] = block => {
         let out = `lockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['radio__pageNumber'] = block =>
         `pageNumber: ${JSON.stringify(block.getFieldValue('pageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['radio__pageNumberMetadata'] = block => {
         let out = `pageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['radio__required'] = block =>
         `required: ${JSON.stringify(block.getFieldValue('required'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['radio__requiredMetadata'] = block => {
         let out = `requiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['radio__selected'] = block =>
         `selected: ${JSON.stringify(block.getFieldValue('selected'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['radio__selectedMetadata'] = block => {
         let out = `selectedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['radio__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['radio__statusMetadata'] = block => {
         let out = `statusMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['radio__tabId'] = block =>
         `tabId: ${JSON.stringify(block.getFieldValue('tabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['radio__tabIdMetadata'] = block => {
         let out = `tabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['radio__tabOrder'] = block =>
         `tabOrder: ${JSON.stringify(block.getFieldValue('tabOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['radio__tabOrderMetadata'] = block => {
         let out = `tabOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['radio__underline'] = block =>
         `underline: ${JSON.stringify(block.getFieldValue('underline'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['radio__underlineMetadata'] = block => {
         let out = `underlineMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['radio__value'] = block =>
         `value: ${JSON.stringify(block.getFieldValue('value'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['radio__valueMetadata'] = block => {
         let out = `valueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['radio__xPosition'] = block =>
         `xPosition: ${JSON.stringify(block.getFieldValue('xPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['radio__xPositionMetadata'] = block => {
         let out = `xPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['radio__yPosition'] = block =>
         `yPosition: ${JSON.stringify(block.getFieldValue('yPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['radio__yPositionMetadata'] = block => {
         let out = `yPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

Blockly.JavaScript['radioGroup'] = block => 
  `\n.add_object("radioGroup", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['radioGroup__conditionalParentLabel'] = block =>
         `conditionalParentLabel: ${JSON.stringify(block.getFieldValue('conditionalParentLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['radioGroup__conditionalParentLabelMetadata'] = block => {
         let out = `conditionalParentLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['radioGroup__conditionalParentValue'] = block =>
         `conditionalParentValue: ${JSON.stringify(block.getFieldValue('conditionalParentValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['radioGroup__conditionalParentValueMetadata'] = block => {
         let out = `conditionalParentValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['radioGroup__documentId'] = block =>
         `documentId: ${JSON.stringify(block.getFieldValue('documentId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['radioGroup__documentIdMetadata'] = block => {
         let out = `documentIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['radioGroup__groupName'] = block =>
         `groupName: ${JSON.stringify(block.getFieldValue('groupName'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['radioGroup__groupNameMetadata'] = block => {
         let out = `groupNameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['radioGroup__originalValue'] = block =>
         `originalValue: ${JSON.stringify(block.getFieldValue('originalValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['radioGroup__originalValueMetadata'] = block => {
         let out = `originalValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['radioGroup__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['radioGroup__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['radioGroup__recipientIdGuidMetadata'] = block => {
         let out = `recipientIdGuidMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['radioGroup__recipientIdMetadata'] = block => {
         let out = `recipientIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['radioGroup__requireAll'] = block =>
         `requireAll: ${JSON.stringify(block.getFieldValue('requireAll'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['radioGroup__requireAllMetadata'] = block => {
         let out = `requireAllMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['radioGroup__requireInitialOnSharedChange'] = block =>
         `requireInitialOnSharedChange: ${JSON.stringify(block.getFieldValue('requireInitialOnSharedChange'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['radioGroup__requireInitialOnSharedChangeMetadata'] = block => {
         let out = `requireInitialOnSharedChangeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['radioGroup__shareToRecipients'] = block =>
         `shareToRecipients: ${JSON.stringify(block.getFieldValue('shareToRecipients'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['radioGroup__shareToRecipientsMetadata'] = block => {
         let out = `shareToRecipientsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['radioGroup__shared'] = block =>
         `shared: ${JSON.stringify(block.getFieldValue('shared'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['radioGroup__sharedMetadata'] = block => {
         let out = `sharedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['radioGroup__tabType'] = block =>
         `tabType: ${JSON.stringify(block.getFieldValue('tabType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['radioGroup__tabTypeMetadata'] = block => {
         let out = `tabTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['radioGroup__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['radioGroup__templateLockedMetadata'] = block => {
         let out = `templateLockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['radioGroup__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['radioGroup__templateRequiredMetadata'] = block => {
         let out = `templateRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['radioGroup__tooltip'] = block =>
         `tooltip: ${JSON.stringify(block.getFieldValue('tooltip'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['radioGroup__tooltipMetadata'] = block => {
         let out = `tooltipMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['radioGroup__value'] = block =>
         `value: ${JSON.stringify(block.getFieldValue('value'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['radioGroup__valueMetadata'] = block => {
         let out = `valueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

Blockly.JavaScript['recipientAdditionalNotification'] = block => 
  `\n.add_object("recipientAdditionalNotification", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['recipientAdditionalNotification__secondaryDeliveryMethod'] = block =>
         `secondaryDeliveryMethod: ${JSON.stringify(block.getFieldValue('secondaryDeliveryMethod'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['recipientAdditionalNotification__secondaryDeliveryStatus'] = block =>
         `secondaryDeliveryStatus: ${JSON.stringify(block.getFieldValue('secondaryDeliveryStatus'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['recipientAttachment'] = block => 
  `\n.add_object("recipientAttachment", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['recipientAttachment__attachmentId'] = block =>
         `attachmentId: ${JSON.stringify(block.getFieldValue('attachmentId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['recipientAttachment__attachmentType'] = block =>
         `attachmentType: ${JSON.stringify(block.getFieldValue('attachmentType'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['recipientAttachment__data'] = block =>
         `data: ${JSON.stringify(block.getFieldValue('data'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['recipientAttachment__label'] = block =>
         `label: ${JSON.stringify(block.getFieldValue('label'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['recipientAttachment__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['recipientAttachment__remoteUrl'] = block =>
         `remoteUrl: ${JSON.stringify(block.getFieldValue('remoteUrl'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['recipientEmailNotification'] = block => 
  `\n.add_object("recipientEmailNotification", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['recipientEmailNotification__emailBody'] = block =>
         `emailBody: ${JSON.stringify(block.getFieldValue('emailBody'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['recipientEmailNotification__emailBodyMetadata'] = block => {
         let out = `emailBodyMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['recipientEmailNotification__emailSubject'] = block =>
         `emailSubject: ${JSON.stringify(block.getFieldValue('emailSubject'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['recipientEmailNotification__emailSubjectMetadata'] = block => {
         let out = `emailSubjectMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['recipientEmailNotification__supportedLanguage'] = block =>
         `supportedLanguage: ${JSON.stringify(block.getFieldValue('supportedLanguage'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['recipientEmailNotification__supportedLanguageMetadata'] = block => {
         let out = `supportedLanguageMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

Blockly.JavaScript['recipientEvent'] = block => 
  `\n.add_object("recipientEvent", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['recipientEvent__includeDocuments'] = block =>
         `includeDocuments: ${JSON.stringify(block.getFieldValue('includeDocuments'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['recipientEvent__recipientEventStatusCode'] = block =>
         `recipientEventStatusCode: ${JSON.stringify(block.getFieldValue('recipientEventStatusCode'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['recipientGroup'] = block => 
  `\n.add_object("recipientGroup", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['recipientGroup__groupMessage'] = block =>
         `groupMessage: ${JSON.stringify(block.getFieldValue('groupMessage'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['recipientGroup__groupName'] = block =>
         `groupName: ${JSON.stringify(block.getFieldValue('groupName'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['recipientIdentityInputOption'] = block => 
  `\n.add_object("recipientIdentityInputOption", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['recipientIdentityInputOption__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['recipientIdentityInputOption__valueType'] = block =>
         `valueType: ${JSON.stringify(block.getFieldValue('valueType'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['recipientIdentityPhoneNumber'] = block => 
  `\n.add_object("recipientIdentityPhoneNumber", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['recipientIdentityPhoneNumber__countryCode'] = block =>
         `countryCode: ${JSON.stringify(block.getFieldValue('countryCode'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['recipientIdentityPhoneNumber__countryCodeLock'] = block =>
         `countryCodeLock: ${JSON.stringify(block.getFieldValue('countryCodeLock'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['recipientIdentityPhoneNumber__countryCodeMetadata'] = block => {
         let out = `countryCodeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['recipientIdentityPhoneNumber__extension'] = block =>
         `extension: ${JSON.stringify(block.getFieldValue('extension'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['recipientIdentityPhoneNumber__extensionMetadata'] = block => {
         let out = `extensionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['recipientIdentityPhoneNumber__number'] = block =>
         `number: ${JSON.stringify(block.getFieldValue('number'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['recipientIdentityPhoneNumber__numberMetadata'] = block => {
         let out = `numberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

Blockly.JavaScript['recipientIdentityVerification'] = block => 
  `\n.add_object("recipientIdentityVerification", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['recipientIdentityVerification__workflowId'] = block =>
         `workflowId: ${JSON.stringify(block.getFieldValue('workflowId'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['recipientOption'] = block => 
  `\n.add_object("recipientOption", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['recipientOption__email'] = block =>
         `email: ${JSON.stringify(block.getFieldValue('email'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['recipientOption__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['recipientOption__recipientLabel'] = block =>
         `recipientLabel: ${JSON.stringify(block.getFieldValue('recipientLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['recipientOption__roleName'] = block =>
         `roleName: ${JSON.stringify(block.getFieldValue('roleName'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['recipientOption__signingGroupId'] = block =>
         `signingGroupId: ${JSON.stringify(block.getFieldValue('signingGroupId'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['recipientPhoneAuthentication'] = block => 
  `\n.add_object("recipientPhoneAuthentication", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['recipientPhoneAuthentication__recipMayProvideNumber'] = block =>
         `recipMayProvideNumber: ${JSON.stringify(block.getFieldValue('recipMayProvideNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['recipientPhoneAuthentication__recipMayProvideNumberMetadata'] = block => {
         let out = `recipMayProvideNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['recipientPhoneAuthentication__recordVoicePrint'] = block =>
         `recordVoicePrint: ${JSON.stringify(block.getFieldValue('recordVoicePrint'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['recipientPhoneAuthentication__recordVoicePrintMetadata'] = block => {
         let out = `recordVoicePrintMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['recipientPhoneAuthentication__senderProvidedNumbers'] = block => {
         let out = `    senderProvidedNumbers: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'senderProvidedNumber', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['recipientPhoneAuthentication_senderProvidedNumbers__senderProvidedNumber'] = block =>
         `    ${JSON.stringify(block.getFieldValue('senderProvidedNumber'))}${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['recipientPhoneAuthentication__senderProvidedNumbersMetadata'] = block => {
         let out = `senderProvidedNumbersMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['recipientPhoneAuthentication__validateRecipProvidedNumber'] = block =>
         `validateRecipProvidedNumber: ${JSON.stringify(block.getFieldValue('validateRecipProvidedNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['recipientPhoneAuthentication__validateRecipProvidedNumberMetadata'] = block => {
         let out = `validateRecipProvidedNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

Blockly.JavaScript['recipientPhoneNumber'] = block => 
  `\n.add_object("recipientPhoneNumber", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['recipientPhoneNumber__countryCode'] = block =>
         `countryCode: ${JSON.stringify(block.getFieldValue('countryCode'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['recipientPhoneNumber__countryCodeMetadata'] = block => {
         let out = `countryCodeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['recipientPhoneNumber__number'] = block =>
         `number: ${JSON.stringify(block.getFieldValue('number'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['recipientPhoneNumber__numberMetadata'] = block => {
         let out = `numberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

Blockly.JavaScript['recipientProofFile'] = block => 
  `\n.add_object("recipientProofFile", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['recipientProofFile__hasIdentityAttempts'] = block =>
         `hasIdentityAttempts: ${JSON.stringify(block.getFieldValue('hasIdentityAttempts'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['recipientProofFile__isInProofFile'] = block =>
         `isInProofFile: ${JSON.stringify(block.getFieldValue('isInProofFile'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['recipientSignatureInformation'] = block => 
  `\n.add_object("recipientSignatureInformation", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['recipientSignatureInformation__fontStyle'] = block =>
         `fontStyle: ${JSON.stringify(block.getFieldValue('fontStyle'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['recipientSignatureInformation__signatureInitials'] = block =>
         `signatureInitials: ${JSON.stringify(block.getFieldValue('signatureInitials'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['recipientSignatureInformation__signatureName'] = block =>
         `signatureName: ${JSON.stringify(block.getFieldValue('signatureName'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['recipientSignatureProvider'] = block => 
  `\n.add_object("recipientSignatureProvider", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['recipientSignatureProvider__sealDocumentsWithTabsOnly'] = block =>
         `sealDocumentsWithTabsOnly: ${JSON.stringify(block.getFieldValue('sealDocumentsWithTabsOnly'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['recipientSignatureProvider__sealName'] = block =>
         `sealName: ${JSON.stringify(block.getFieldValue('sealName'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['recipientSignatureProvider__signatureProviderName'] = block =>
         `signatureProviderName: ${JSON.stringify(block.getFieldValue('signatureProviderName'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['recipientSignatureProviderOptions'] = block => 
  `\n.add_object("recipientSignatureProviderOptions", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['recipientSignatureProviderOptions__cpfNumber'] = block =>
         `cpfNumber: ${JSON.stringify(block.getFieldValue('cpfNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['recipientSignatureProviderOptions__cpfNumberMetadata'] = block => {
         let out = `cpfNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['recipientSignatureProviderOptions__oneTimePassword'] = block =>
         `oneTimePassword: ${JSON.stringify(block.getFieldValue('oneTimePassword'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['recipientSignatureProviderOptions__oneTimePasswordMetadata'] = block => {
         let out = `oneTimePasswordMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['recipientSignatureProviderOptions__signerRole'] = block =>
         `signerRole: ${JSON.stringify(block.getFieldValue('signerRole'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['recipientSignatureProviderOptions__signerRoleMetadata'] = block => {
         let out = `signerRoleMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['recipientSignatureProviderOptions__sms'] = block =>
         `sms: ${JSON.stringify(block.getFieldValue('sms'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['recipientSignatureProviderOptions__smsMetadata'] = block => {
         let out = `smsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

Blockly.JavaScript['recipientSMSAuthentication'] = block => 
  `\n.add_object("recipientSMSAuthentication", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
      Blockly.JavaScript['recipientSMSAuthentication__senderProvidedNumbers'] = block => {
         let out = `    senderProvidedNumbers: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'senderProvidedNumber', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['recipientSMSAuthentication_senderProvidedNumbers__senderProvidedNumber'] = block =>
         `    ${JSON.stringify(block.getFieldValue('senderProvidedNumber'))}${block.getNextBlock() ? ', ': ''}\n`;

Blockly.JavaScript['reminders'] = block => 
  `\n.add_object("reminders", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['reminders__reminderDelay'] = block =>
         `reminderDelay: ${JSON.stringify(block.getFieldValue('reminderDelay'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['reminders__reminderEnabled'] = block =>
         `reminderEnabled: ${JSON.stringify(block.getFieldValue('reminderEnabled'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['reminders__reminderFrequency'] = block =>
         `reminderFrequency: ${JSON.stringify(block.getFieldValue('reminderFrequency'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['scheduledSendingApiModel'] = block => 
  `\n.add_object("scheduledSendingApiModel", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['scheduledSendingApiModel__resumeDate'] = block =>
         `resumeDate: ${JSON.stringify(block.getFieldValue('resumeDate'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['scheduledSendingApiModel__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['sealSign'] = block => 
  `\n.add_object("sealSign", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['sealSign__accessCode'] = block =>
         `accessCode: ${JSON.stringify(block.getFieldValue('accessCode'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['sealSign__accessCodeMetadata'] = block => {
         let out = `accessCodeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['sealSign__addAccessCodeToEmail'] = block =>
         `addAccessCodeToEmail: ${JSON.stringify(block.getFieldValue('addAccessCodeToEmail'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['sealSign__allowSystemOverrideForLockedRecipient'] = block =>
         `allowSystemOverrideForLockedRecipient: ${JSON.stringify(block.getFieldValue('allowSystemOverrideForLockedRecipient'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['sealSign__autoRespondedReason'] = block =>
         `autoRespondedReason: ${JSON.stringify(block.getFieldValue('autoRespondedReason'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['sealSign__clientUserId'] = block =>
         `clientUserId: ${JSON.stringify(block.getFieldValue('clientUserId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['sealSign__completedCount'] = block =>
         `completedCount: ${JSON.stringify(block.getFieldValue('completedCount'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['sealSign__customFields'] = block => {
         let out = `    customFields: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'customField', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['sealSign_customFields__customField'] = block =>
         `    ${JSON.stringify(block.getFieldValue('customField'))}${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['sealSign__declinedDateTime'] = block =>
         `declinedDateTime: ${JSON.stringify(block.getFieldValue('declinedDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['sealSign__declinedReason'] = block =>
         `declinedReason: ${JSON.stringify(block.getFieldValue('declinedReason'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['sealSign__deliveredDateTime'] = block =>
         `deliveredDateTime: ${JSON.stringify(block.getFieldValue('deliveredDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['sealSign__deliveryMethod'] = block =>
         `deliveryMethod: ${JSON.stringify(block.getFieldValue('deliveryMethod'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['sealSign__deliveryMethodMetadata'] = block => {
         let out = `deliveryMethodMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['sealSign__designatorId'] = block =>
         `designatorId: ${JSON.stringify(block.getFieldValue('designatorId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['sealSign__designatorIdGuid'] = block =>
         `designatorIdGuid: ${JSON.stringify(block.getFieldValue('designatorIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['sealSign__embeddedRecipientStartURL'] = block =>
         `embeddedRecipientStartURL: ${JSON.stringify(block.getFieldValue('embeddedRecipientStartURL'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['sealSign__faxNumber'] = block =>
         `faxNumber: ${JSON.stringify(block.getFieldValue('faxNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['sealSign__faxNumberMetadata'] = block => {
         let out = `faxNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['sealSign__idCheckConfigurationName'] = block =>
         `idCheckConfigurationName: ${JSON.stringify(block.getFieldValue('idCheckConfigurationName'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['sealSign__idCheckConfigurationNameMetadata'] = block => {
         let out = `idCheckConfigurationNameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['sealSign__inheritEmailNotificationConfiguration'] = block =>
         `inheritEmailNotificationConfiguration: ${JSON.stringify(block.getFieldValue('inheritEmailNotificationConfiguration'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['sealSign__lockedRecipientPhoneAuthEditable'] = block =>
         `lockedRecipientPhoneAuthEditable: ${JSON.stringify(block.getFieldValue('lockedRecipientPhoneAuthEditable'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['sealSign__lockedRecipientSmsEditable'] = block =>
         `lockedRecipientSmsEditable: ${JSON.stringify(block.getFieldValue('lockedRecipientSmsEditable'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['sealSign__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['sealSign__note'] = block =>
         `note: ${JSON.stringify(block.getFieldValue('note'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['sealSign__noteMetadata'] = block => {
         let out = `noteMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['sealSign__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['sealSign__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['sealSign__recipientType'] = block =>
         `recipientType: ${JSON.stringify(block.getFieldValue('recipientType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['sealSign__recipientTypeMetadata'] = block => {
         let out = `recipientTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['sealSign__requireIdLookup'] = block =>
         `requireIdLookup: ${JSON.stringify(block.getFieldValue('requireIdLookup'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['sealSign__requireIdLookupMetadata'] = block => {
         let out = `requireIdLookupMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['sealSign__roleName'] = block =>
         `roleName: ${JSON.stringify(block.getFieldValue('roleName'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['sealSign__routingOrder'] = block =>
         `routingOrder: ${JSON.stringify(block.getFieldValue('routingOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['sealSign__routingOrderMetadata'] = block => {
         let out = `routingOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['sealSign__sentDateTime'] = block =>
         `sentDateTime: ${JSON.stringify(block.getFieldValue('sentDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['sealSign__signedDateTime'] = block =>
         `signedDateTime: ${JSON.stringify(block.getFieldValue('signedDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['sealSign__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['sealSign__statusCode'] = block =>
         `statusCode: ${JSON.stringify(block.getFieldValue('statusCode'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['sealSign__suppressEmails'] = block =>
         `suppressEmails: ${JSON.stringify(block.getFieldValue('suppressEmails'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['sealSign__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['sealSign__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['sealSign__totalTabCount'] = block =>
         `totalTabCount: ${JSON.stringify(block.getFieldValue('totalTabCount'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['sealSign__userId'] = block =>
         `userId: ${JSON.stringify(block.getFieldValue('userId'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['senderCompany'] = block => 
  `\n.add_object("senderCompany", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['senderCompany__anchorAllowWhiteSpaceInCharacters'] = block =>
         `anchorAllowWhiteSpaceInCharacters: ${JSON.stringify(block.getFieldValue('anchorAllowWhiteSpaceInCharacters'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderCompany__anchorAllowWhiteSpaceInCharactersMetadata'] = block => {
         let out = `anchorAllowWhiteSpaceInCharactersMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderCompany__anchorCaseSensitive'] = block =>
         `anchorCaseSensitive: ${JSON.stringify(block.getFieldValue('anchorCaseSensitive'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderCompany__anchorCaseSensitiveMetadata'] = block => {
         let out = `anchorCaseSensitiveMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderCompany__anchorHorizontalAlignment'] = block =>
         `anchorHorizontalAlignment: ${JSON.stringify(block.getFieldValue('anchorHorizontalAlignment'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderCompany__anchorHorizontalAlignmentMetadata'] = block => {
         let out = `anchorHorizontalAlignmentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderCompany__anchorIgnoreIfNotPresent'] = block =>
         `anchorIgnoreIfNotPresent: ${JSON.stringify(block.getFieldValue('anchorIgnoreIfNotPresent'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderCompany__anchorIgnoreIfNotPresentMetadata'] = block => {
         let out = `anchorIgnoreIfNotPresentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderCompany__anchorMatchWholeWord'] = block =>
         `anchorMatchWholeWord: ${JSON.stringify(block.getFieldValue('anchorMatchWholeWord'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderCompany__anchorMatchWholeWordMetadata'] = block => {
         let out = `anchorMatchWholeWordMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderCompany__anchorString'] = block =>
         `anchorString: ${JSON.stringify(block.getFieldValue('anchorString'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderCompany__anchorStringMetadata'] = block => {
         let out = `anchorStringMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderCompany__anchorTabProcessorVersion'] = block =>
         `anchorTabProcessorVersion: ${JSON.stringify(block.getFieldValue('anchorTabProcessorVersion'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderCompany__anchorTabProcessorVersionMetadata'] = block => {
         let out = `anchorTabProcessorVersionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderCompany__anchorUnits'] = block =>
         `anchorUnits: ${JSON.stringify(block.getFieldValue('anchorUnits'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderCompany__anchorUnitsMetadata'] = block => {
         let out = `anchorUnitsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderCompany__anchorXOffset'] = block =>
         `anchorXOffset: ${JSON.stringify(block.getFieldValue('anchorXOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderCompany__anchorXOffsetMetadata'] = block => {
         let out = `anchorXOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderCompany__anchorYOffset'] = block =>
         `anchorYOffset: ${JSON.stringify(block.getFieldValue('anchorYOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderCompany__anchorYOffsetMetadata'] = block => {
         let out = `anchorYOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderCompany__bold'] = block =>
         `bold: ${JSON.stringify(block.getFieldValue('bold'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderCompany__boldMetadata'] = block => {
         let out = `boldMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderCompany__conditionalParentLabel'] = block =>
         `conditionalParentLabel: ${JSON.stringify(block.getFieldValue('conditionalParentLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderCompany__conditionalParentLabelMetadata'] = block => {
         let out = `conditionalParentLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderCompany__conditionalParentValue'] = block =>
         `conditionalParentValue: ${JSON.stringify(block.getFieldValue('conditionalParentValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderCompany__conditionalParentValueMetadata'] = block => {
         let out = `conditionalParentValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderCompany__customTabId'] = block =>
         `customTabId: ${JSON.stringify(block.getFieldValue('customTabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderCompany__customTabIdMetadata'] = block => {
         let out = `customTabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderCompany__documentId'] = block =>
         `documentId: ${JSON.stringify(block.getFieldValue('documentId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderCompany__documentIdMetadata'] = block => {
         let out = `documentIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderCompany__font'] = block =>
         `font: ${JSON.stringify(block.getFieldValue('font'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['senderCompany__fontColor'] = block =>
         `fontColor: ${JSON.stringify(block.getFieldValue('fontColor'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderCompany__fontColorMetadata'] = block => {
         let out = `fontColorMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['senderCompany__fontMetadata'] = block => {
         let out = `fontMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderCompany__fontSize'] = block =>
         `fontSize: ${JSON.stringify(block.getFieldValue('fontSize'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderCompany__fontSizeMetadata'] = block => {
         let out = `fontSizeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderCompany__formOrder'] = block =>
         `formOrder: ${JSON.stringify(block.getFieldValue('formOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderCompany__formOrderMetadata'] = block => {
         let out = `formOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderCompany__formPageLabel'] = block =>
         `formPageLabel: ${JSON.stringify(block.getFieldValue('formPageLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderCompany__formPageLabelMetadata'] = block => {
         let out = `formPageLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderCompany__formPageNumber'] = block =>
         `formPageNumber: ${JSON.stringify(block.getFieldValue('formPageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderCompany__formPageNumberMetadata'] = block => {
         let out = `formPageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderCompany__height'] = block =>
         `height: ${JSON.stringify(block.getFieldValue('height'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderCompany__heightMetadata'] = block => {
         let out = `heightMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderCompany__italic'] = block =>
         `italic: ${JSON.stringify(block.getFieldValue('italic'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderCompany__italicMetadata'] = block => {
         let out = `italicMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderCompany__mergeFieldXml'] = block =>
         `mergeFieldXml: ${JSON.stringify(block.getFieldValue('mergeFieldXml'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['senderCompany__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderCompany__nameMetadata'] = block => {
         let out = `nameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderCompany__pageNumber'] = block =>
         `pageNumber: ${JSON.stringify(block.getFieldValue('pageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderCompany__pageNumberMetadata'] = block => {
         let out = `pageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderCompany__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['senderCompany__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderCompany__recipientIdGuidMetadata'] = block => {
         let out = `recipientIdGuidMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['senderCompany__recipientIdMetadata'] = block => {
         let out = `recipientIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderCompany__source'] = block =>
         `source: ${JSON.stringify(block.getFieldValue('source'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['senderCompany__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderCompany__statusMetadata'] = block => {
         let out = `statusMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['senderCompany__tabGroupLabels'] = block => {
         let out = `    tabGroupLabels: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'tabGroupLabel', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['senderCompany_tabGroupLabels__tabGroupLabel'] = block =>
         `    ${JSON.stringify(block.getFieldValue('tabGroupLabel'))}${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['senderCompany__tabGroupLabelsMetadata'] = block => {
         let out = `tabGroupLabelsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderCompany__tabId'] = block =>
         `tabId: ${JSON.stringify(block.getFieldValue('tabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderCompany__tabIdMetadata'] = block => {
         let out = `tabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderCompany__tabLabel'] = block =>
         `tabLabel: ${JSON.stringify(block.getFieldValue('tabLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderCompany__tabLabelMetadata'] = block => {
         let out = `tabLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderCompany__tabOrder'] = block =>
         `tabOrder: ${JSON.stringify(block.getFieldValue('tabOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderCompany__tabOrderMetadata'] = block => {
         let out = `tabOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderCompany__tabType'] = block =>
         `tabType: ${JSON.stringify(block.getFieldValue('tabType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderCompany__tabTypeMetadata'] = block => {
         let out = `tabTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderCompany__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderCompany__templateLockedMetadata'] = block => {
         let out = `templateLockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderCompany__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderCompany__templateRequiredMetadata'] = block => {
         let out = `templateRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['senderCompany__toolTipMetadata'] = block => {
         let out = `toolTipMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderCompany__tooltip'] = block =>
         `tooltip: ${JSON.stringify(block.getFieldValue('tooltip'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['senderCompany__underline'] = block =>
         `underline: ${JSON.stringify(block.getFieldValue('underline'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderCompany__underlineMetadata'] = block => {
         let out = `underlineMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderCompany__value'] = block =>
         `value: ${JSON.stringify(block.getFieldValue('value'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderCompany__valueMetadata'] = block => {
         let out = `valueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderCompany__width'] = block =>
         `width: ${JSON.stringify(block.getFieldValue('width'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderCompany__widthMetadata'] = block => {
         let out = `widthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderCompany__xPosition'] = block =>
         `xPosition: ${JSON.stringify(block.getFieldValue('xPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderCompany__xPositionMetadata'] = block => {
         let out = `xPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderCompany__yPosition'] = block =>
         `yPosition: ${JSON.stringify(block.getFieldValue('yPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderCompany__yPositionMetadata'] = block => {
         let out = `yPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

Blockly.JavaScript['senderName'] = block => 
  `\n.add_object("senderName", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['senderName__anchorAllowWhiteSpaceInCharacters'] = block =>
         `anchorAllowWhiteSpaceInCharacters: ${JSON.stringify(block.getFieldValue('anchorAllowWhiteSpaceInCharacters'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderName__anchorAllowWhiteSpaceInCharactersMetadata'] = block => {
         let out = `anchorAllowWhiteSpaceInCharactersMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderName__anchorCaseSensitive'] = block =>
         `anchorCaseSensitive: ${JSON.stringify(block.getFieldValue('anchorCaseSensitive'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderName__anchorCaseSensitiveMetadata'] = block => {
         let out = `anchorCaseSensitiveMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderName__anchorHorizontalAlignment'] = block =>
         `anchorHorizontalAlignment: ${JSON.stringify(block.getFieldValue('anchorHorizontalAlignment'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderName__anchorHorizontalAlignmentMetadata'] = block => {
         let out = `anchorHorizontalAlignmentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderName__anchorIgnoreIfNotPresent'] = block =>
         `anchorIgnoreIfNotPresent: ${JSON.stringify(block.getFieldValue('anchorIgnoreIfNotPresent'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderName__anchorIgnoreIfNotPresentMetadata'] = block => {
         let out = `anchorIgnoreIfNotPresentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderName__anchorMatchWholeWord'] = block =>
         `anchorMatchWholeWord: ${JSON.stringify(block.getFieldValue('anchorMatchWholeWord'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderName__anchorMatchWholeWordMetadata'] = block => {
         let out = `anchorMatchWholeWordMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderName__anchorString'] = block =>
         `anchorString: ${JSON.stringify(block.getFieldValue('anchorString'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderName__anchorStringMetadata'] = block => {
         let out = `anchorStringMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderName__anchorTabProcessorVersion'] = block =>
         `anchorTabProcessorVersion: ${JSON.stringify(block.getFieldValue('anchorTabProcessorVersion'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderName__anchorTabProcessorVersionMetadata'] = block => {
         let out = `anchorTabProcessorVersionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderName__anchorUnits'] = block =>
         `anchorUnits: ${JSON.stringify(block.getFieldValue('anchorUnits'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderName__anchorUnitsMetadata'] = block => {
         let out = `anchorUnitsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderName__anchorXOffset'] = block =>
         `anchorXOffset: ${JSON.stringify(block.getFieldValue('anchorXOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderName__anchorXOffsetMetadata'] = block => {
         let out = `anchorXOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderName__anchorYOffset'] = block =>
         `anchorYOffset: ${JSON.stringify(block.getFieldValue('anchorYOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderName__anchorYOffsetMetadata'] = block => {
         let out = `anchorYOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderName__bold'] = block =>
         `bold: ${JSON.stringify(block.getFieldValue('bold'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderName__boldMetadata'] = block => {
         let out = `boldMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderName__conditionalParentLabel'] = block =>
         `conditionalParentLabel: ${JSON.stringify(block.getFieldValue('conditionalParentLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderName__conditionalParentLabelMetadata'] = block => {
         let out = `conditionalParentLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderName__conditionalParentValue'] = block =>
         `conditionalParentValue: ${JSON.stringify(block.getFieldValue('conditionalParentValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderName__conditionalParentValueMetadata'] = block => {
         let out = `conditionalParentValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderName__customTabId'] = block =>
         `customTabId: ${JSON.stringify(block.getFieldValue('customTabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderName__customTabIdMetadata'] = block => {
         let out = `customTabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderName__documentId'] = block =>
         `documentId: ${JSON.stringify(block.getFieldValue('documentId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderName__documentIdMetadata'] = block => {
         let out = `documentIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderName__font'] = block =>
         `font: ${JSON.stringify(block.getFieldValue('font'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['senderName__fontColor'] = block =>
         `fontColor: ${JSON.stringify(block.getFieldValue('fontColor'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderName__fontColorMetadata'] = block => {
         let out = `fontColorMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['senderName__fontMetadata'] = block => {
         let out = `fontMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderName__fontSize'] = block =>
         `fontSize: ${JSON.stringify(block.getFieldValue('fontSize'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderName__fontSizeMetadata'] = block => {
         let out = `fontSizeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderName__formOrder'] = block =>
         `formOrder: ${JSON.stringify(block.getFieldValue('formOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderName__formOrderMetadata'] = block => {
         let out = `formOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderName__formPageLabel'] = block =>
         `formPageLabel: ${JSON.stringify(block.getFieldValue('formPageLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderName__formPageLabelMetadata'] = block => {
         let out = `formPageLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderName__formPageNumber'] = block =>
         `formPageNumber: ${JSON.stringify(block.getFieldValue('formPageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderName__formPageNumberMetadata'] = block => {
         let out = `formPageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderName__height'] = block =>
         `height: ${JSON.stringify(block.getFieldValue('height'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderName__heightMetadata'] = block => {
         let out = `heightMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderName__italic'] = block =>
         `italic: ${JSON.stringify(block.getFieldValue('italic'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderName__italicMetadata'] = block => {
         let out = `italicMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderName__mergeFieldXml'] = block =>
         `mergeFieldXml: ${JSON.stringify(block.getFieldValue('mergeFieldXml'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['senderName__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderName__nameMetadata'] = block => {
         let out = `nameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderName__pageNumber'] = block =>
         `pageNumber: ${JSON.stringify(block.getFieldValue('pageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderName__pageNumberMetadata'] = block => {
         let out = `pageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderName__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['senderName__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderName__recipientIdGuidMetadata'] = block => {
         let out = `recipientIdGuidMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['senderName__recipientIdMetadata'] = block => {
         let out = `recipientIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderName__source'] = block =>
         `source: ${JSON.stringify(block.getFieldValue('source'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['senderName__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderName__statusMetadata'] = block => {
         let out = `statusMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['senderName__tabGroupLabels'] = block => {
         let out = `    tabGroupLabels: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'tabGroupLabel', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['senderName_tabGroupLabels__tabGroupLabel'] = block =>
         `    ${JSON.stringify(block.getFieldValue('tabGroupLabel'))}${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['senderName__tabGroupLabelsMetadata'] = block => {
         let out = `tabGroupLabelsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderName__tabId'] = block =>
         `tabId: ${JSON.stringify(block.getFieldValue('tabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderName__tabIdMetadata'] = block => {
         let out = `tabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderName__tabLabel'] = block =>
         `tabLabel: ${JSON.stringify(block.getFieldValue('tabLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderName__tabLabelMetadata'] = block => {
         let out = `tabLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderName__tabOrder'] = block =>
         `tabOrder: ${JSON.stringify(block.getFieldValue('tabOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderName__tabOrderMetadata'] = block => {
         let out = `tabOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderName__tabType'] = block =>
         `tabType: ${JSON.stringify(block.getFieldValue('tabType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderName__tabTypeMetadata'] = block => {
         let out = `tabTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderName__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderName__templateLockedMetadata'] = block => {
         let out = `templateLockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderName__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderName__templateRequiredMetadata'] = block => {
         let out = `templateRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['senderName__toolTipMetadata'] = block => {
         let out = `toolTipMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderName__tooltip'] = block =>
         `tooltip: ${JSON.stringify(block.getFieldValue('tooltip'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['senderName__underline'] = block =>
         `underline: ${JSON.stringify(block.getFieldValue('underline'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderName__underlineMetadata'] = block => {
         let out = `underlineMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderName__value'] = block =>
         `value: ${JSON.stringify(block.getFieldValue('value'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderName__valueMetadata'] = block => {
         let out = `valueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderName__width'] = block =>
         `width: ${JSON.stringify(block.getFieldValue('width'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderName__widthMetadata'] = block => {
         let out = `widthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderName__xPosition'] = block =>
         `xPosition: ${JSON.stringify(block.getFieldValue('xPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderName__xPositionMetadata'] = block => {
         let out = `xPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['senderName__yPosition'] = block =>
         `yPosition: ${JSON.stringify(block.getFieldValue('yPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['senderName__yPositionMetadata'] = block => {
         let out = `yPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

Blockly.JavaScript['serverTemplate'] = block => 
  `\n.add_object("serverTemplate", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['serverTemplate__sequence'] = block =>
         `sequence: ${JSON.stringify(block.getFieldValue('sequence'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['serverTemplate__templateId'] = block =>
         `templateId: ${JSON.stringify(block.getFieldValue('templateId'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['signHere'] = block => 
  `\n.add_object("signHere", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['signHere__anchorAllowWhiteSpaceInCharacters'] = block =>
         `anchorAllowWhiteSpaceInCharacters: ${JSON.stringify(block.getFieldValue('anchorAllowWhiteSpaceInCharacters'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signHere__anchorAllowWhiteSpaceInCharactersMetadata'] = block => {
         let out = `anchorAllowWhiteSpaceInCharactersMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signHere__anchorCaseSensitive'] = block =>
         `anchorCaseSensitive: ${JSON.stringify(block.getFieldValue('anchorCaseSensitive'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signHere__anchorCaseSensitiveMetadata'] = block => {
         let out = `anchorCaseSensitiveMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signHere__anchorHorizontalAlignment'] = block =>
         `anchorHorizontalAlignment: ${JSON.stringify(block.getFieldValue('anchorHorizontalAlignment'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signHere__anchorHorizontalAlignmentMetadata'] = block => {
         let out = `anchorHorizontalAlignmentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signHere__anchorIgnoreIfNotPresent'] = block =>
         `anchorIgnoreIfNotPresent: ${JSON.stringify(block.getFieldValue('anchorIgnoreIfNotPresent'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signHere__anchorIgnoreIfNotPresentMetadata'] = block => {
         let out = `anchorIgnoreIfNotPresentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signHere__anchorMatchWholeWord'] = block =>
         `anchorMatchWholeWord: ${JSON.stringify(block.getFieldValue('anchorMatchWholeWord'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signHere__anchorMatchWholeWordMetadata'] = block => {
         let out = `anchorMatchWholeWordMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signHere__anchorString'] = block =>
         `anchorString: ${JSON.stringify(block.getFieldValue('anchorString'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signHere__anchorStringMetadata'] = block => {
         let out = `anchorStringMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signHere__anchorTabProcessorVersion'] = block =>
         `anchorTabProcessorVersion: ${JSON.stringify(block.getFieldValue('anchorTabProcessorVersion'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signHere__anchorTabProcessorVersionMetadata'] = block => {
         let out = `anchorTabProcessorVersionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signHere__anchorUnits'] = block =>
         `anchorUnits: ${JSON.stringify(block.getFieldValue('anchorUnits'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signHere__anchorUnitsMetadata'] = block => {
         let out = `anchorUnitsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signHere__anchorXOffset'] = block =>
         `anchorXOffset: ${JSON.stringify(block.getFieldValue('anchorXOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signHere__anchorXOffsetMetadata'] = block => {
         let out = `anchorXOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signHere__anchorYOffset'] = block =>
         `anchorYOffset: ${JSON.stringify(block.getFieldValue('anchorYOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signHere__anchorYOffsetMetadata'] = block => {
         let out = `anchorYOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signHere__conditionalParentLabel'] = block =>
         `conditionalParentLabel: ${JSON.stringify(block.getFieldValue('conditionalParentLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signHere__conditionalParentLabelMetadata'] = block => {
         let out = `conditionalParentLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signHere__conditionalParentValue'] = block =>
         `conditionalParentValue: ${JSON.stringify(block.getFieldValue('conditionalParentValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signHere__conditionalParentValueMetadata'] = block => {
         let out = `conditionalParentValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signHere__customTabId'] = block =>
         `customTabId: ${JSON.stringify(block.getFieldValue('customTabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signHere__customTabIdMetadata'] = block => {
         let out = `customTabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signHere__documentId'] = block =>
         `documentId: ${JSON.stringify(block.getFieldValue('documentId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signHere__documentIdMetadata'] = block => {
         let out = `documentIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signHere__formOrder'] = block =>
         `formOrder: ${JSON.stringify(block.getFieldValue('formOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signHere__formOrderMetadata'] = block => {
         let out = `formOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signHere__formPageLabel'] = block =>
         `formPageLabel: ${JSON.stringify(block.getFieldValue('formPageLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signHere__formPageLabelMetadata'] = block => {
         let out = `formPageLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signHere__formPageNumber'] = block =>
         `formPageNumber: ${JSON.stringify(block.getFieldValue('formPageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signHere__formPageNumberMetadata'] = block => {
         let out = `formPageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signHere__handDrawRequired'] = block =>
         `handDrawRequired: ${JSON.stringify(block.getFieldValue('handDrawRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signHere__height'] = block =>
         `height: ${JSON.stringify(block.getFieldValue('height'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signHere__heightMetadata'] = block => {
         let out = `heightMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signHere__isSealSignTab'] = block =>
         `isSealSignTab: ${JSON.stringify(block.getFieldValue('isSealSignTab'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signHere__mergeFieldXml'] = block =>
         `mergeFieldXml: ${JSON.stringify(block.getFieldValue('mergeFieldXml'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signHere__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signHere__nameMetadata'] = block => {
         let out = `nameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signHere__optional'] = block =>
         `optional: ${JSON.stringify(block.getFieldValue('optional'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signHere__optionalMetadata'] = block => {
         let out = `optionalMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signHere__pageNumber'] = block =>
         `pageNumber: ${JSON.stringify(block.getFieldValue('pageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signHere__pageNumberMetadata'] = block => {
         let out = `pageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signHere__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signHere__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signHere__recipientIdGuidMetadata'] = block => {
         let out = `recipientIdGuidMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['signHere__recipientIdMetadata'] = block => {
         let out = `recipientIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signHere__scaleValue'] = block =>
         `scaleValue: ${JSON.stringify(block.getFieldValue('scaleValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signHere__scaleValueMetadata'] = block => {
         let out = `scaleValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signHere__source'] = block =>
         `source: ${JSON.stringify(block.getFieldValue('source'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signHere__stampType'] = block =>
         `stampType: ${JSON.stringify(block.getFieldValue('stampType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signHere__stampTypeMetadata'] = block => {
         let out = `stampTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signHere__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signHere__statusMetadata'] = block => {
         let out = `statusMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['signHere__tabGroupLabels'] = block => {
         let out = `    tabGroupLabels: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'tabGroupLabel', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['signHere_tabGroupLabels__tabGroupLabel'] = block =>
         `    ${JSON.stringify(block.getFieldValue('tabGroupLabel'))}${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['signHere__tabGroupLabelsMetadata'] = block => {
         let out = `tabGroupLabelsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signHere__tabId'] = block =>
         `tabId: ${JSON.stringify(block.getFieldValue('tabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signHere__tabIdMetadata'] = block => {
         let out = `tabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signHere__tabLabel'] = block =>
         `tabLabel: ${JSON.stringify(block.getFieldValue('tabLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signHere__tabLabelMetadata'] = block => {
         let out = `tabLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signHere__tabOrder'] = block =>
         `tabOrder: ${JSON.stringify(block.getFieldValue('tabOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signHere__tabOrderMetadata'] = block => {
         let out = `tabOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signHere__tabType'] = block =>
         `tabType: ${JSON.stringify(block.getFieldValue('tabType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signHere__tabTypeMetadata'] = block => {
         let out = `tabTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signHere__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signHere__templateLockedMetadata'] = block => {
         let out = `templateLockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signHere__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signHere__templateRequiredMetadata'] = block => {
         let out = `templateRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['signHere__toolTipMetadata'] = block => {
         let out = `toolTipMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signHere__tooltip'] = block =>
         `tooltip: ${JSON.stringify(block.getFieldValue('tooltip'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signHere__width'] = block =>
         `width: ${JSON.stringify(block.getFieldValue('width'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signHere__widthMetadata'] = block => {
         let out = `widthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signHere__xPosition'] = block =>
         `xPosition: ${JSON.stringify(block.getFieldValue('xPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signHere__xPositionMetadata'] = block => {
         let out = `xPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signHere__yPosition'] = block =>
         `yPosition: ${JSON.stringify(block.getFieldValue('yPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signHere__yPositionMetadata'] = block => {
         let out = `yPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

Blockly.JavaScript['signatureType'] = block => 
  `\n.add_object("signatureType", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['signatureType__isDefault'] = block =>
         `isDefault: ${JSON.stringify(block.getFieldValue('isDefault'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signatureType__type'] = block =>
         `type: ${JSON.stringify(block.getFieldValue('type'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['signer'] = block => 
  `\n.add_object("signer", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['signer__accessCode'] = block =>
         `accessCode: ${JSON.stringify(block.getFieldValue('accessCode'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signer__accessCodeMetadata'] = block => {
         let out = `accessCodeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signer__addAccessCodeToEmail'] = block =>
         `addAccessCodeToEmail: ${JSON.stringify(block.getFieldValue('addAccessCodeToEmail'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signer__agentCanEditEmail'] = block =>
         `agentCanEditEmail: ${JSON.stringify(block.getFieldValue('agentCanEditEmail'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signer__agentCanEditName'] = block =>
         `agentCanEditName: ${JSON.stringify(block.getFieldValue('agentCanEditName'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signer__allowSystemOverrideForLockedRecipient'] = block =>
         `allowSystemOverrideForLockedRecipient: ${JSON.stringify(block.getFieldValue('allowSystemOverrideForLockedRecipient'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signer__autoNavigation'] = block =>
         `autoNavigation: ${JSON.stringify(block.getFieldValue('autoNavigation'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signer__autoRespondedReason'] = block =>
         `autoRespondedReason: ${JSON.stringify(block.getFieldValue('autoRespondedReason'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signer__bulkRecipientsUri'] = block =>
         `bulkRecipientsUri: ${JSON.stringify(block.getFieldValue('bulkRecipientsUri'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signer__canSignOffline'] = block =>
         `canSignOffline: ${JSON.stringify(block.getFieldValue('canSignOffline'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signer__clientUserId'] = block =>
         `clientUserId: ${JSON.stringify(block.getFieldValue('clientUserId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signer__completedCount'] = block =>
         `completedCount: ${JSON.stringify(block.getFieldValue('completedCount'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signer__creationReason'] = block =>
         `creationReason: ${JSON.stringify(block.getFieldValue('creationReason'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signer__customFields'] = block => {
         let out = `    customFields: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'customField', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['signer_customFields__customField'] = block =>
         `    ${JSON.stringify(block.getFieldValue('customField'))}${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signer__declinedDateTime'] = block =>
         `declinedDateTime: ${JSON.stringify(block.getFieldValue('declinedDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signer__declinedReason'] = block =>
         `declinedReason: ${JSON.stringify(block.getFieldValue('declinedReason'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signer__defaultRecipient'] = block =>
         `defaultRecipient: ${JSON.stringify(block.getFieldValue('defaultRecipient'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signer__deliveredDateTime'] = block =>
         `deliveredDateTime: ${JSON.stringify(block.getFieldValue('deliveredDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signer__deliveryMethod'] = block =>
         `deliveryMethod: ${JSON.stringify(block.getFieldValue('deliveryMethod'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signer__deliveryMethodMetadata'] = block => {
         let out = `deliveryMethodMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signer__designatorId'] = block =>
         `designatorId: ${JSON.stringify(block.getFieldValue('designatorId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signer__designatorIdGuid'] = block =>
         `designatorIdGuid: ${JSON.stringify(block.getFieldValue('designatorIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signer__email'] = block =>
         `email: ${JSON.stringify(block.getFieldValue('email'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signer__emailMetadata'] = block => {
         let out = `emailMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signer__emailRecipientPostSigningURL'] = block =>
         `emailRecipientPostSigningURL: ${JSON.stringify(block.getFieldValue('emailRecipientPostSigningURL'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signer__embeddedRecipientStartURL'] = block =>
         `embeddedRecipientStartURL: ${JSON.stringify(block.getFieldValue('embeddedRecipientStartURL'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signer__excludedDocuments'] = block => {
         let out = `    excludedDocuments: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'excludedDocument', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['signer_excludedDocuments__excludedDocument'] = block =>
         `    ${JSON.stringify(block.getFieldValue('excludedDocument'))}${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signer__faxNumber'] = block =>
         `faxNumber: ${JSON.stringify(block.getFieldValue('faxNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signer__faxNumberMetadata'] = block => {
         let out = `faxNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signer__firstName'] = block =>
         `firstName: ${JSON.stringify(block.getFieldValue('firstName'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signer__firstNameMetadata'] = block => {
         let out = `firstNameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signer__fullName'] = block =>
         `fullName: ${JSON.stringify(block.getFieldValue('fullName'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signer__fullNameMetadata'] = block => {
         let out = `fullNameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signer__idCheckConfigurationName'] = block =>
         `idCheckConfigurationName: ${JSON.stringify(block.getFieldValue('idCheckConfigurationName'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signer__idCheckConfigurationNameMetadata'] = block => {
         let out = `idCheckConfigurationNameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signer__inheritEmailNotificationConfiguration'] = block =>
         `inheritEmailNotificationConfiguration: ${JSON.stringify(block.getFieldValue('inheritEmailNotificationConfiguration'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signer__isBulkRecipient'] = block =>
         `isBulkRecipient: ${JSON.stringify(block.getFieldValue('isBulkRecipient'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signer__isBulkRecipientMetadata'] = block => {
         let out = `isBulkRecipientMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signer__lastName'] = block =>
         `lastName: ${JSON.stringify(block.getFieldValue('lastName'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signer__lastNameMetadata'] = block => {
         let out = `lastNameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signer__lockedRecipientPhoneAuthEditable'] = block =>
         `lockedRecipientPhoneAuthEditable: ${JSON.stringify(block.getFieldValue('lockedRecipientPhoneAuthEditable'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signer__lockedRecipientSmsEditable'] = block =>
         `lockedRecipientSmsEditable: ${JSON.stringify(block.getFieldValue('lockedRecipientSmsEditable'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signer__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signer__nameMetadata'] = block => {
         let out = `nameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signer__notaryId'] = block =>
         `notaryId: ${JSON.stringify(block.getFieldValue('notaryId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signer__notarySignerEmailSent'] = block =>
         `notarySignerEmailSent: ${JSON.stringify(block.getFieldValue('notarySignerEmailSent'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signer__note'] = block =>
         `note: ${JSON.stringify(block.getFieldValue('note'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signer__noteMetadata'] = block => {
         let out = `noteMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signer__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signer__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signer__recipientSuppliesTabs'] = block =>
         `recipientSuppliesTabs: ${JSON.stringify(block.getFieldValue('recipientSuppliesTabs'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signer__recipientType'] = block =>
         `recipientType: ${JSON.stringify(block.getFieldValue('recipientType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signer__recipientTypeMetadata'] = block => {
         let out = `recipientTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signer__requireIdLookup'] = block =>
         `requireIdLookup: ${JSON.stringify(block.getFieldValue('requireIdLookup'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signer__requireIdLookupMetadata'] = block => {
         let out = `requireIdLookupMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signer__requireSignOnPaper'] = block =>
         `requireSignOnPaper: ${JSON.stringify(block.getFieldValue('requireSignOnPaper'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signer__requireSignerCertificate'] = block =>
         `requireSignerCertificate: ${JSON.stringify(block.getFieldValue('requireSignerCertificate'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signer__requireUploadSignature'] = block =>
         `requireUploadSignature: ${JSON.stringify(block.getFieldValue('requireUploadSignature'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signer__roleName'] = block =>
         `roleName: ${JSON.stringify(block.getFieldValue('roleName'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signer__routingOrder'] = block =>
         `routingOrder: ${JSON.stringify(block.getFieldValue('routingOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signer__routingOrderMetadata'] = block => {
         let out = `routingOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signer__sentDateTime'] = block =>
         `sentDateTime: ${JSON.stringify(block.getFieldValue('sentDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signer__signInEachLocation'] = block =>
         `signInEachLocation: ${JSON.stringify(block.getFieldValue('signInEachLocation'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signer__signInEachLocationMetadata'] = block => {
         let out = `signInEachLocationMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signer__signedDateTime'] = block =>
         `signedDateTime: ${JSON.stringify(block.getFieldValue('signedDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signer__signingGroupId'] = block =>
         `signingGroupId: ${JSON.stringify(block.getFieldValue('signingGroupId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signer__signingGroupIdMetadata'] = block => {
         let out = `signingGroupIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signer__signingGroupName'] = block =>
         `signingGroupName: ${JSON.stringify(block.getFieldValue('signingGroupName'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signer__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signer__statusCode'] = block =>
         `statusCode: ${JSON.stringify(block.getFieldValue('statusCode'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signer__suppressEmails'] = block =>
         `suppressEmails: ${JSON.stringify(block.getFieldValue('suppressEmails'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signer__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signer__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signer__totalTabCount'] = block =>
         `totalTabCount: ${JSON.stringify(block.getFieldValue('totalTabCount'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signer__userId'] = block =>
         `userId: ${JSON.stringify(block.getFieldValue('userId'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['signerAttachment'] = block => 
  `\n.add_object("signerAttachment", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['signerAttachment__anchorAllowWhiteSpaceInCharacters'] = block =>
         `anchorAllowWhiteSpaceInCharacters: ${JSON.stringify(block.getFieldValue('anchorAllowWhiteSpaceInCharacters'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signerAttachment__anchorAllowWhiteSpaceInCharactersMetadata'] = block => {
         let out = `anchorAllowWhiteSpaceInCharactersMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signerAttachment__anchorCaseSensitive'] = block =>
         `anchorCaseSensitive: ${JSON.stringify(block.getFieldValue('anchorCaseSensitive'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signerAttachment__anchorCaseSensitiveMetadata'] = block => {
         let out = `anchorCaseSensitiveMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signerAttachment__anchorHorizontalAlignment'] = block =>
         `anchorHorizontalAlignment: ${JSON.stringify(block.getFieldValue('anchorHorizontalAlignment'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signerAttachment__anchorHorizontalAlignmentMetadata'] = block => {
         let out = `anchorHorizontalAlignmentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signerAttachment__anchorIgnoreIfNotPresent'] = block =>
         `anchorIgnoreIfNotPresent: ${JSON.stringify(block.getFieldValue('anchorIgnoreIfNotPresent'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signerAttachment__anchorIgnoreIfNotPresentMetadata'] = block => {
         let out = `anchorIgnoreIfNotPresentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signerAttachment__anchorMatchWholeWord'] = block =>
         `anchorMatchWholeWord: ${JSON.stringify(block.getFieldValue('anchorMatchWholeWord'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signerAttachment__anchorMatchWholeWordMetadata'] = block => {
         let out = `anchorMatchWholeWordMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signerAttachment__anchorString'] = block =>
         `anchorString: ${JSON.stringify(block.getFieldValue('anchorString'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signerAttachment__anchorStringMetadata'] = block => {
         let out = `anchorStringMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signerAttachment__anchorTabProcessorVersion'] = block =>
         `anchorTabProcessorVersion: ${JSON.stringify(block.getFieldValue('anchorTabProcessorVersion'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signerAttachment__anchorTabProcessorVersionMetadata'] = block => {
         let out = `anchorTabProcessorVersionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signerAttachment__anchorUnits'] = block =>
         `anchorUnits: ${JSON.stringify(block.getFieldValue('anchorUnits'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signerAttachment__anchorUnitsMetadata'] = block => {
         let out = `anchorUnitsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signerAttachment__anchorXOffset'] = block =>
         `anchorXOffset: ${JSON.stringify(block.getFieldValue('anchorXOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signerAttachment__anchorXOffsetMetadata'] = block => {
         let out = `anchorXOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signerAttachment__anchorYOffset'] = block =>
         `anchorYOffset: ${JSON.stringify(block.getFieldValue('anchorYOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signerAttachment__anchorYOffsetMetadata'] = block => {
         let out = `anchorYOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signerAttachment__conditionalParentLabel'] = block =>
         `conditionalParentLabel: ${JSON.stringify(block.getFieldValue('conditionalParentLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signerAttachment__conditionalParentLabelMetadata'] = block => {
         let out = `conditionalParentLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signerAttachment__conditionalParentValue'] = block =>
         `conditionalParentValue: ${JSON.stringify(block.getFieldValue('conditionalParentValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signerAttachment__conditionalParentValueMetadata'] = block => {
         let out = `conditionalParentValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signerAttachment__customTabId'] = block =>
         `customTabId: ${JSON.stringify(block.getFieldValue('customTabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signerAttachment__customTabIdMetadata'] = block => {
         let out = `customTabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signerAttachment__documentId'] = block =>
         `documentId: ${JSON.stringify(block.getFieldValue('documentId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signerAttachment__documentIdMetadata'] = block => {
         let out = `documentIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signerAttachment__formOrder'] = block =>
         `formOrder: ${JSON.stringify(block.getFieldValue('formOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signerAttachment__formOrderMetadata'] = block => {
         let out = `formOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signerAttachment__formPageLabel'] = block =>
         `formPageLabel: ${JSON.stringify(block.getFieldValue('formPageLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signerAttachment__formPageLabelMetadata'] = block => {
         let out = `formPageLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signerAttachment__formPageNumber'] = block =>
         `formPageNumber: ${JSON.stringify(block.getFieldValue('formPageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signerAttachment__formPageNumberMetadata'] = block => {
         let out = `formPageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signerAttachment__handDrawRequired'] = block =>
         `handDrawRequired: ${JSON.stringify(block.getFieldValue('handDrawRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signerAttachment__height'] = block =>
         `height: ${JSON.stringify(block.getFieldValue('height'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signerAttachment__heightMetadata'] = block => {
         let out = `heightMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signerAttachment__mergeFieldXml'] = block =>
         `mergeFieldXml: ${JSON.stringify(block.getFieldValue('mergeFieldXml'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signerAttachment__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signerAttachment__nameMetadata'] = block => {
         let out = `nameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signerAttachment__optional'] = block =>
         `optional: ${JSON.stringify(block.getFieldValue('optional'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signerAttachment__optionalMetadata'] = block => {
         let out = `optionalMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signerAttachment__pageNumber'] = block =>
         `pageNumber: ${JSON.stringify(block.getFieldValue('pageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signerAttachment__pageNumberMetadata'] = block => {
         let out = `pageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signerAttachment__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signerAttachment__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signerAttachment__recipientIdGuidMetadata'] = block => {
         let out = `recipientIdGuidMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['signerAttachment__recipientIdMetadata'] = block => {
         let out = `recipientIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signerAttachment__scaleValue'] = block =>
         `scaleValue: ${JSON.stringify(block.getFieldValue('scaleValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signerAttachment__scaleValueMetadata'] = block => {
         let out = `scaleValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signerAttachment__source'] = block =>
         `source: ${JSON.stringify(block.getFieldValue('source'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signerAttachment__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signerAttachment__statusMetadata'] = block => {
         let out = `statusMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['signerAttachment__tabGroupLabels'] = block => {
         let out = `    tabGroupLabels: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'tabGroupLabel', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['signerAttachment_tabGroupLabels__tabGroupLabel'] = block =>
         `    ${JSON.stringify(block.getFieldValue('tabGroupLabel'))}${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['signerAttachment__tabGroupLabelsMetadata'] = block => {
         let out = `tabGroupLabelsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signerAttachment__tabId'] = block =>
         `tabId: ${JSON.stringify(block.getFieldValue('tabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signerAttachment__tabIdMetadata'] = block => {
         let out = `tabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signerAttachment__tabLabel'] = block =>
         `tabLabel: ${JSON.stringify(block.getFieldValue('tabLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signerAttachment__tabLabelMetadata'] = block => {
         let out = `tabLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signerAttachment__tabOrder'] = block =>
         `tabOrder: ${JSON.stringify(block.getFieldValue('tabOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signerAttachment__tabOrderMetadata'] = block => {
         let out = `tabOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signerAttachment__tabType'] = block =>
         `tabType: ${JSON.stringify(block.getFieldValue('tabType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signerAttachment__tabTypeMetadata'] = block => {
         let out = `tabTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signerAttachment__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signerAttachment__templateLockedMetadata'] = block => {
         let out = `templateLockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signerAttachment__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signerAttachment__templateRequiredMetadata'] = block => {
         let out = `templateRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['signerAttachment__toolTipMetadata'] = block => {
         let out = `toolTipMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signerAttachment__tooltip'] = block =>
         `tooltip: ${JSON.stringify(block.getFieldValue('tooltip'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['signerAttachment__width'] = block =>
         `width: ${JSON.stringify(block.getFieldValue('width'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signerAttachment__widthMetadata'] = block => {
         let out = `widthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signerAttachment__xPosition'] = block =>
         `xPosition: ${JSON.stringify(block.getFieldValue('xPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signerAttachment__xPositionMetadata'] = block => {
         let out = `xPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['signerAttachment__yPosition'] = block =>
         `yPosition: ${JSON.stringify(block.getFieldValue('yPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['signerAttachment__yPositionMetadata'] = block => {
         let out = `yPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

Blockly.JavaScript['smartContractInformation'] = block => 
  `\n.add_object("smartContractInformation", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['smartContractInformation__code'] = block =>
         `code: ${JSON.stringify(block.getFieldValue('code'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['smartContractInformation__uri'] = block =>
         `uri: ${JSON.stringify(block.getFieldValue('uri'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['smartSection'] = block => 
  `\n.add_object("smartSection", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['smartSection__anchorAllowWhiteSpaceInCharacters'] = block =>
         `anchorAllowWhiteSpaceInCharacters: ${JSON.stringify(block.getFieldValue('anchorAllowWhiteSpaceInCharacters'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['smartSection__anchorAllowWhiteSpaceInCharactersMetadata'] = block => {
         let out = `anchorAllowWhiteSpaceInCharactersMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['smartSection__anchorCaseSensitive'] = block =>
         `anchorCaseSensitive: ${JSON.stringify(block.getFieldValue('anchorCaseSensitive'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['smartSection__anchorCaseSensitiveMetadata'] = block => {
         let out = `anchorCaseSensitiveMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['smartSection__anchorHorizontalAlignment'] = block =>
         `anchorHorizontalAlignment: ${JSON.stringify(block.getFieldValue('anchorHorizontalAlignment'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['smartSection__anchorHorizontalAlignmentMetadata'] = block => {
         let out = `anchorHorizontalAlignmentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['smartSection__anchorIgnoreIfNotPresent'] = block =>
         `anchorIgnoreIfNotPresent: ${JSON.stringify(block.getFieldValue('anchorIgnoreIfNotPresent'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['smartSection__anchorIgnoreIfNotPresentMetadata'] = block => {
         let out = `anchorIgnoreIfNotPresentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['smartSection__anchorMatchWholeWord'] = block =>
         `anchorMatchWholeWord: ${JSON.stringify(block.getFieldValue('anchorMatchWholeWord'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['smartSection__anchorMatchWholeWordMetadata'] = block => {
         let out = `anchorMatchWholeWordMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['smartSection__anchorString'] = block =>
         `anchorString: ${JSON.stringify(block.getFieldValue('anchorString'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['smartSection__anchorStringMetadata'] = block => {
         let out = `anchorStringMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['smartSection__anchorTabProcessorVersion'] = block =>
         `anchorTabProcessorVersion: ${JSON.stringify(block.getFieldValue('anchorTabProcessorVersion'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['smartSection__anchorTabProcessorVersionMetadata'] = block => {
         let out = `anchorTabProcessorVersionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['smartSection__anchorUnits'] = block =>
         `anchorUnits: ${JSON.stringify(block.getFieldValue('anchorUnits'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['smartSection__anchorUnitsMetadata'] = block => {
         let out = `anchorUnitsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['smartSection__anchorXOffset'] = block =>
         `anchorXOffset: ${JSON.stringify(block.getFieldValue('anchorXOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['smartSection__anchorXOffsetMetadata'] = block => {
         let out = `anchorXOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['smartSection__anchorYOffset'] = block =>
         `anchorYOffset: ${JSON.stringify(block.getFieldValue('anchorYOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['smartSection__anchorYOffsetMetadata'] = block => {
         let out = `anchorYOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['smartSection__caseSensitive'] = block =>
         `caseSensitive: ${block.getFieldValue('caseSensitive')}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['smartSection__conditionalParentLabel'] = block =>
         `conditionalParentLabel: ${JSON.stringify(block.getFieldValue('conditionalParentLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['smartSection__conditionalParentLabelMetadata'] = block => {
         let out = `conditionalParentLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['smartSection__conditionalParentValue'] = block =>
         `conditionalParentValue: ${JSON.stringify(block.getFieldValue('conditionalParentValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['smartSection__conditionalParentValueMetadata'] = block => {
         let out = `conditionalParentValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['smartSection__customTabId'] = block =>
         `customTabId: ${JSON.stringify(block.getFieldValue('customTabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['smartSection__customTabIdMetadata'] = block => {
         let out = `customTabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['smartSection__documentId'] = block =>
         `documentId: ${JSON.stringify(block.getFieldValue('documentId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['smartSection__documentIdMetadata'] = block => {
         let out = `documentIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['smartSection__endAnchor'] = block =>
         `endAnchor: ${JSON.stringify(block.getFieldValue('endAnchor'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['smartSection__endPosition'] = block => {
         let out = `endPosition: {\n`;
         out += `    pageNumber: ${block.getFieldValue('pageNumber')},\n`;
         out += `    xPosition: ${block.getFieldValue('xPosition')},\n`;
         out += `    yPosition: ${block.getFieldValue('yPosition')},\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };

   Blockly.JavaScript['smartSection__formOrder'] = block =>
         `formOrder: ${JSON.stringify(block.getFieldValue('formOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['smartSection__formOrderMetadata'] = block => {
         let out = `formOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['smartSection__formPageLabel'] = block =>
         `formPageLabel: ${JSON.stringify(block.getFieldValue('formPageLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['smartSection__formPageLabelMetadata'] = block => {
         let out = `formPageLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['smartSection__formPageNumber'] = block =>
         `formPageNumber: ${JSON.stringify(block.getFieldValue('formPageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['smartSection__formPageNumberMetadata'] = block => {
         let out = `formPageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['smartSection__height'] = block =>
         `height: ${JSON.stringify(block.getFieldValue('height'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['smartSection__heightMetadata'] = block => {
         let out = `heightMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['smartSection__locked'] = block =>
         `locked: ${JSON.stringify(block.getFieldValue('locked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['smartSection__lockedMetadata'] = block => {
         let out = `lockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['smartSection__mergeFieldXml'] = block =>
         `mergeFieldXml: ${JSON.stringify(block.getFieldValue('mergeFieldXml'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['smartSection__overlayType'] = block =>
         `overlayType: ${JSON.stringify(block.getFieldValue('overlayType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['smartSection__overlayTypeMetadata'] = block => {
         let out = `overlayTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['smartSection__pageNumber'] = block =>
         `pageNumber: ${JSON.stringify(block.getFieldValue('pageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['smartSection__pageNumberMetadata'] = block => {
         let out = `pageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['smartSection__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['smartSection__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['smartSection__recipientIdGuidMetadata'] = block => {
         let out = `recipientIdGuidMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['smartSection__recipientIdMetadata'] = block => {
         let out = `recipientIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['smartSection__removeEndAnchor'] = block =>
         `removeEndAnchor: ${block.getFieldValue('removeEndAnchor')}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['smartSection__removeStartAnchor'] = block =>
         `removeStartAnchor: ${block.getFieldValue('removeStartAnchor')}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['smartSection__shared'] = block =>
         `shared: ${JSON.stringify(block.getFieldValue('shared'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['smartSection__sharedMetadata'] = block => {
         let out = `sharedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['smartSection__source'] = block =>
         `source: ${JSON.stringify(block.getFieldValue('source'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['smartSection__startAnchor'] = block =>
         `startAnchor: ${JSON.stringify(block.getFieldValue('startAnchor'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['smartSection__startPosition'] = block => {
         let out = `startPosition: {\n`;
         out += `    pageNumber: ${block.getFieldValue('pageNumber')},\n`;
         out += `    xPosition: ${block.getFieldValue('xPosition')},\n`;
         out += `    yPosition: ${block.getFieldValue('yPosition')},\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };

   Blockly.JavaScript['smartSection__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['smartSection__statusMetadata'] = block => {
         let out = `statusMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['smartSection__tabGroupLabels'] = block => {
         let out = `    tabGroupLabels: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'tabGroupLabel', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['smartSection_tabGroupLabels__tabGroupLabel'] = block =>
         `    ${JSON.stringify(block.getFieldValue('tabGroupLabel'))}${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['smartSection__tabGroupLabelsMetadata'] = block => {
         let out = `tabGroupLabelsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['smartSection__tabId'] = block =>
         `tabId: ${JSON.stringify(block.getFieldValue('tabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['smartSection__tabIdMetadata'] = block => {
         let out = `tabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['smartSection__tabLabel'] = block =>
         `tabLabel: ${JSON.stringify(block.getFieldValue('tabLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['smartSection__tabOrder'] = block =>
         `tabOrder: ${JSON.stringify(block.getFieldValue('tabOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['smartSection__tabOrderMetadata'] = block => {
         let out = `tabOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['smartSection__tabType'] = block =>
         `tabType: ${JSON.stringify(block.getFieldValue('tabType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['smartSection__tabTypeMetadata'] = block => {
         let out = `tabTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['smartSection__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['smartSection__templateLockedMetadata'] = block => {
         let out = `templateLockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['smartSection__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['smartSection__templateRequiredMetadata'] = block => {
         let out = `templateRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['smartSection__toolTipMetadata'] = block => {
         let out = `toolTipMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['smartSection__tooltip'] = block =>
         `tooltip: ${JSON.stringify(block.getFieldValue('tooltip'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['smartSection__width'] = block =>
         `width: ${JSON.stringify(block.getFieldValue('width'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['smartSection__widthMetadata'] = block => {
         let out = `widthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['smartSection__xPosition'] = block =>
         `xPosition: ${JSON.stringify(block.getFieldValue('xPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['smartSection__xPositionMetadata'] = block => {
         let out = `xPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['smartSection__yPosition'] = block =>
         `yPosition: ${JSON.stringify(block.getFieldValue('yPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['smartSection__yPositionMetadata'] = block => {
         let out = `yPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

Blockly.JavaScript['smartSectionCollapsibleDisplaySettings'] = block => 
  `\n.add_object("smartSectionCollapsibleDisplaySettings", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['smartSectionCollapsibleDisplaySettings__arrowClosed'] = block =>
         `arrowClosed: ${JSON.stringify(block.getFieldValue('arrowClosed'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['smartSectionCollapsibleDisplaySettings__arrowColor'] = block =>
         `arrowColor: ${JSON.stringify(block.getFieldValue('arrowColor'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['smartSectionCollapsibleDisplaySettings__arrowLocation'] = block =>
         `arrowLocation: ${JSON.stringify(block.getFieldValue('arrowLocation'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['smartSectionCollapsibleDisplaySettings__arrowOpen'] = block =>
         `arrowOpen: ${JSON.stringify(block.getFieldValue('arrowOpen'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['smartSectionCollapsibleDisplaySettings__arrowSize'] = block =>
         `arrowSize: ${JSON.stringify(block.getFieldValue('arrowSize'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['smartSectionCollapsibleDisplaySettings__arrowStyle'] = block =>
         `arrowStyle: ${JSON.stringify(block.getFieldValue('arrowStyle'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['smartSectionCollapsibleDisplaySettings__containerStyle'] = block =>
         `containerStyle: ${JSON.stringify(block.getFieldValue('containerStyle'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['smartSectionCollapsibleDisplaySettings__labelStyle'] = block =>
         `labelStyle: ${JSON.stringify(block.getFieldValue('labelStyle'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['smartSectionCollapsibleDisplaySettings__onlyArrowIsClickable'] = block =>
         `onlyArrowIsClickable: ${block.getFieldValue('onlyArrowIsClickable')}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['smartSectionCollapsibleDisplaySettings__outerLabelAndArrowStyle'] = block =>
         `outerLabelAndArrowStyle: ${JSON.stringify(block.getFieldValue('outerLabelAndArrowStyle'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['smartSectionDisplaySettings'] = block => 
  `\n.add_object("smartSectionDisplaySettings", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['smartSectionDisplaySettings__cellStyle'] = block =>
         `cellStyle: ${JSON.stringify(block.getFieldValue('cellStyle'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['smartSectionDisplaySettings__display'] = block =>
         `display: ${JSON.stringify(block.getFieldValue('display'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['smartSectionDisplaySettings__displayLabel'] = block =>
         `displayLabel: ${JSON.stringify(block.getFieldValue('displayLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['smartSectionDisplaySettings__displayOrder'] = block =>
         `displayOrder: ${block.getFieldValue('displayOrder')}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['smartSectionDisplaySettings__displayPageNumber'] = block =>
         `displayPageNumber: ${block.getFieldValue('displayPageNumber')}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['smartSectionDisplaySettings__hideLabelWhenOpened'] = block =>
         `hideLabelWhenOpened: ${block.getFieldValue('hideLabelWhenOpened')}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['smartSectionDisplaySettings__inlineOuterStyle'] = block =>
         `inlineOuterStyle: ${JSON.stringify(block.getFieldValue('inlineOuterStyle'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['smartSectionDisplaySettings__labelWhenOpened'] = block =>
         `labelWhenOpened: ${JSON.stringify(block.getFieldValue('labelWhenOpened'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['smartSectionDisplaySettings__preLabel'] = block =>
         `preLabel: ${JSON.stringify(block.getFieldValue('preLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['smartSectionDisplaySettings__scrollToTopWhenOpened'] = block =>
         `scrollToTopWhenOpened: ${block.getFieldValue('scrollToTopWhenOpened')}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['smartSectionDisplaySettings__tableStyle'] = block =>
         `tableStyle: ${JSON.stringify(block.getFieldValue('tableStyle'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['socialAuthentication'] = block => 
  `\n.add_object("socialAuthentication", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['socialAuthentication__authentication'] = block =>
         `authentication: ${JSON.stringify(block.getFieldValue('authentication'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['ssn'] = block => 
  `\n.add_object("ssn", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['ssn__anchorAllowWhiteSpaceInCharacters'] = block =>
         `anchorAllowWhiteSpaceInCharacters: ${JSON.stringify(block.getFieldValue('anchorAllowWhiteSpaceInCharacters'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__anchorAllowWhiteSpaceInCharactersMetadata'] = block => {
         let out = `anchorAllowWhiteSpaceInCharactersMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['ssn__anchorCaseSensitive'] = block =>
         `anchorCaseSensitive: ${JSON.stringify(block.getFieldValue('anchorCaseSensitive'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__anchorCaseSensitiveMetadata'] = block => {
         let out = `anchorCaseSensitiveMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['ssn__anchorHorizontalAlignment'] = block =>
         `anchorHorizontalAlignment: ${JSON.stringify(block.getFieldValue('anchorHorizontalAlignment'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__anchorHorizontalAlignmentMetadata'] = block => {
         let out = `anchorHorizontalAlignmentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['ssn__anchorIgnoreIfNotPresent'] = block =>
         `anchorIgnoreIfNotPresent: ${JSON.stringify(block.getFieldValue('anchorIgnoreIfNotPresent'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__anchorIgnoreIfNotPresentMetadata'] = block => {
         let out = `anchorIgnoreIfNotPresentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['ssn__anchorMatchWholeWord'] = block =>
         `anchorMatchWholeWord: ${JSON.stringify(block.getFieldValue('anchorMatchWholeWord'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__anchorMatchWholeWordMetadata'] = block => {
         let out = `anchorMatchWholeWordMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['ssn__anchorString'] = block =>
         `anchorString: ${JSON.stringify(block.getFieldValue('anchorString'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__anchorStringMetadata'] = block => {
         let out = `anchorStringMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['ssn__anchorTabProcessorVersion'] = block =>
         `anchorTabProcessorVersion: ${JSON.stringify(block.getFieldValue('anchorTabProcessorVersion'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__anchorTabProcessorVersionMetadata'] = block => {
         let out = `anchorTabProcessorVersionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['ssn__anchorUnits'] = block =>
         `anchorUnits: ${JSON.stringify(block.getFieldValue('anchorUnits'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__anchorUnitsMetadata'] = block => {
         let out = `anchorUnitsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['ssn__anchorXOffset'] = block =>
         `anchorXOffset: ${JSON.stringify(block.getFieldValue('anchorXOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__anchorXOffsetMetadata'] = block => {
         let out = `anchorXOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['ssn__anchorYOffset'] = block =>
         `anchorYOffset: ${JSON.stringify(block.getFieldValue('anchorYOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__anchorYOffsetMetadata'] = block => {
         let out = `anchorYOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['ssn__bold'] = block =>
         `bold: ${JSON.stringify(block.getFieldValue('bold'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__boldMetadata'] = block => {
         let out = `boldMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['ssn__concealValueOnDocument'] = block =>
         `concealValueOnDocument: ${JSON.stringify(block.getFieldValue('concealValueOnDocument'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__concealValueOnDocumentMetadata'] = block => {
         let out = `concealValueOnDocumentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['ssn__conditionalParentLabel'] = block =>
         `conditionalParentLabel: ${JSON.stringify(block.getFieldValue('conditionalParentLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__conditionalParentLabelMetadata'] = block => {
         let out = `conditionalParentLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['ssn__conditionalParentValue'] = block =>
         `conditionalParentValue: ${JSON.stringify(block.getFieldValue('conditionalParentValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__conditionalParentValueMetadata'] = block => {
         let out = `conditionalParentValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['ssn__customTabId'] = block =>
         `customTabId: ${JSON.stringify(block.getFieldValue('customTabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__customTabIdMetadata'] = block => {
         let out = `customTabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['ssn__disableAutoSize'] = block =>
         `disableAutoSize: ${JSON.stringify(block.getFieldValue('disableAutoSize'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__disableAutoSizeMetadata'] = block => {
         let out = `disableAutoSizeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['ssn__documentId'] = block =>
         `documentId: ${JSON.stringify(block.getFieldValue('documentId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__documentIdMetadata'] = block => {
         let out = `documentIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['ssn__font'] = block =>
         `font: ${JSON.stringify(block.getFieldValue('font'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['ssn__fontColor'] = block =>
         `fontColor: ${JSON.stringify(block.getFieldValue('fontColor'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__fontColorMetadata'] = block => {
         let out = `fontColorMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['ssn__fontMetadata'] = block => {
         let out = `fontMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['ssn__fontSize'] = block =>
         `fontSize: ${JSON.stringify(block.getFieldValue('fontSize'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__fontSizeMetadata'] = block => {
         let out = `fontSizeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['ssn__formOrder'] = block =>
         `formOrder: ${JSON.stringify(block.getFieldValue('formOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__formOrderMetadata'] = block => {
         let out = `formOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['ssn__formPageLabel'] = block =>
         `formPageLabel: ${JSON.stringify(block.getFieldValue('formPageLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__formPageLabelMetadata'] = block => {
         let out = `formPageLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['ssn__formPageNumber'] = block =>
         `formPageNumber: ${JSON.stringify(block.getFieldValue('formPageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__formPageNumberMetadata'] = block => {
         let out = `formPageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['ssn__height'] = block =>
         `height: ${JSON.stringify(block.getFieldValue('height'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__heightMetadata'] = block => {
         let out = `heightMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['ssn__italic'] = block =>
         `italic: ${JSON.stringify(block.getFieldValue('italic'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__italicMetadata'] = block => {
         let out = `italicMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['ssn__locked'] = block =>
         `locked: ${JSON.stringify(block.getFieldValue('locked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__lockedMetadata'] = block => {
         let out = `lockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['ssn__maxLength'] = block =>
         `maxLength: ${JSON.stringify(block.getFieldValue('maxLength'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__maxLengthMetadata'] = block => {
         let out = `maxLengthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['ssn__mergeFieldXml'] = block =>
         `mergeFieldXml: ${JSON.stringify(block.getFieldValue('mergeFieldXml'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['ssn__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__nameMetadata'] = block => {
         let out = `nameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['ssn__originalValue'] = block =>
         `originalValue: ${JSON.stringify(block.getFieldValue('originalValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__originalValueMetadata'] = block => {
         let out = `originalValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['ssn__pageNumber'] = block =>
         `pageNumber: ${JSON.stringify(block.getFieldValue('pageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__pageNumberMetadata'] = block => {
         let out = `pageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['ssn__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['ssn__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__recipientIdGuidMetadata'] = block => {
         let out = `recipientIdGuidMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['ssn__recipientIdMetadata'] = block => {
         let out = `recipientIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['ssn__requireAll'] = block =>
         `requireAll: ${JSON.stringify(block.getFieldValue('requireAll'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__requireAllMetadata'] = block => {
         let out = `requireAllMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['ssn__requireInitialOnSharedChange'] = block =>
         `requireInitialOnSharedChange: ${JSON.stringify(block.getFieldValue('requireInitialOnSharedChange'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__requireInitialOnSharedChangeMetadata'] = block => {
         let out = `requireInitialOnSharedChangeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['ssn__required'] = block =>
         `required: ${JSON.stringify(block.getFieldValue('required'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__requiredMetadata'] = block => {
         let out = `requiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['ssn__senderRequired'] = block =>
         `senderRequired: ${JSON.stringify(block.getFieldValue('senderRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__senderRequiredMetadata'] = block => {
         let out = `senderRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['ssn__shareToRecipients'] = block =>
         `shareToRecipients: ${JSON.stringify(block.getFieldValue('shareToRecipients'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__shareToRecipientsMetadata'] = block => {
         let out = `shareToRecipientsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['ssn__shared'] = block =>
         `shared: ${JSON.stringify(block.getFieldValue('shared'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__sharedMetadata'] = block => {
         let out = `sharedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['ssn__source'] = block =>
         `source: ${JSON.stringify(block.getFieldValue('source'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['ssn__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__statusMetadata'] = block => {
         let out = `statusMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['ssn__tabGroupLabels'] = block => {
         let out = `    tabGroupLabels: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'tabGroupLabel', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['ssn_tabGroupLabels__tabGroupLabel'] = block =>
         `    ${JSON.stringify(block.getFieldValue('tabGroupLabel'))}${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['ssn__tabGroupLabelsMetadata'] = block => {
         let out = `tabGroupLabelsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['ssn__tabId'] = block =>
         `tabId: ${JSON.stringify(block.getFieldValue('tabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__tabIdMetadata'] = block => {
         let out = `tabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['ssn__tabLabel'] = block =>
         `tabLabel: ${JSON.stringify(block.getFieldValue('tabLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__tabLabelMetadata'] = block => {
         let out = `tabLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['ssn__tabOrder'] = block =>
         `tabOrder: ${JSON.stringify(block.getFieldValue('tabOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__tabOrderMetadata'] = block => {
         let out = `tabOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['ssn__tabType'] = block =>
         `tabType: ${JSON.stringify(block.getFieldValue('tabType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__tabTypeMetadata'] = block => {
         let out = `tabTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['ssn__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__templateLockedMetadata'] = block => {
         let out = `templateLockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['ssn__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__templateRequiredMetadata'] = block => {
         let out = `templateRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['ssn__toolTipMetadata'] = block => {
         let out = `toolTipMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['ssn__tooltip'] = block =>
         `tooltip: ${JSON.stringify(block.getFieldValue('tooltip'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['ssn__underline'] = block =>
         `underline: ${JSON.stringify(block.getFieldValue('underline'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__underlineMetadata'] = block => {
         let out = `underlineMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['ssn__validationMessage'] = block =>
         `validationMessage: ${JSON.stringify(block.getFieldValue('validationMessage'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__validationMessageMetadata'] = block => {
         let out = `validationMessageMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['ssn__validationPattern'] = block =>
         `validationPattern: ${JSON.stringify(block.getFieldValue('validationPattern'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__validationPatternMetadata'] = block => {
         let out = `validationPatternMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['ssn__value'] = block =>
         `value: ${JSON.stringify(block.getFieldValue('value'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__valueMetadata'] = block => {
         let out = `valueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['ssn__width'] = block =>
         `width: ${JSON.stringify(block.getFieldValue('width'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__widthMetadata'] = block => {
         let out = `widthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['ssn__xPosition'] = block =>
         `xPosition: ${JSON.stringify(block.getFieldValue('xPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__xPositionMetadata'] = block => {
         let out = `xPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['ssn__yPosition'] = block =>
         `yPosition: ${JSON.stringify(block.getFieldValue('yPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['ssn__yPositionMetadata'] = block => {
         let out = `yPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

Blockly.JavaScript['ssn4InformationInput'] = block => 
  `\n.add_object("ssn4InformationInput", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['ssn4InformationInput__displayLevelCode'] = block =>
         `displayLevelCode: ${JSON.stringify(block.getFieldValue('displayLevelCode'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['ssn4InformationInput__receiveInResponse'] = block =>
         `receiveInResponse: ${JSON.stringify(block.getFieldValue('receiveInResponse'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['ssn4InformationInput__ssn4'] = block =>
         `ssn4: ${JSON.stringify(block.getFieldValue('ssn4'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['ssn9InformationInput'] = block => 
  `\n.add_object("ssn9InformationInput", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['ssn9InformationInput__displayLevelCode'] = block =>
         `displayLevelCode: ${JSON.stringify(block.getFieldValue('displayLevelCode'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['ssn9InformationInput__ssn9'] = block =>
         `ssn9: ${JSON.stringify(block.getFieldValue('ssn9'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['stamp'] = block => 
  `\n.add_object("stamp", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['stamp__adoptedDateTime'] = block =>
         `adoptedDateTime: ${JSON.stringify(block.getFieldValue('adoptedDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['stamp__createdDateTime'] = block =>
         `createdDateTime: ${JSON.stringify(block.getFieldValue('createdDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['stamp__customField'] = block =>
         `customField: ${JSON.stringify(block.getFieldValue('customField'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['stamp__disallowUserResizeStamp'] = block =>
         `disallowUserResizeStamp: ${JSON.stringify(block.getFieldValue('disallowUserResizeStamp'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['stamp__externalID'] = block =>
         `externalID: ${JSON.stringify(block.getFieldValue('externalID'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['stamp__imageBase64'] = block =>
         `imageBase64: ${JSON.stringify(block.getFieldValue('imageBase64'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['stamp__imageType'] = block =>
         `imageType: ${JSON.stringify(block.getFieldValue('imageType'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['stamp__lastModifiedDateTime'] = block =>
         `lastModifiedDateTime: ${JSON.stringify(block.getFieldValue('lastModifiedDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['stamp__phoneticName'] = block =>
         `phoneticName: ${JSON.stringify(block.getFieldValue('phoneticName'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['stamp__signatureName'] = block =>
         `signatureName: ${JSON.stringify(block.getFieldValue('signatureName'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['stamp__stampFormat'] = block =>
         `stampFormat: ${JSON.stringify(block.getFieldValue('stampFormat'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['stamp__stampImageUri'] = block =>
         `stampImageUri: ${JSON.stringify(block.getFieldValue('stampImageUri'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['stamp__stampSizeMM'] = block =>
         `stampSizeMM: ${JSON.stringify(block.getFieldValue('stampSizeMM'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['stamp__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['tabGroup'] = block => 
  `\n.add_object("tabGroup", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['tabGroup__anchorAllowWhiteSpaceInCharacters'] = block =>
         `anchorAllowWhiteSpaceInCharacters: ${JSON.stringify(block.getFieldValue('anchorAllowWhiteSpaceInCharacters'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['tabGroup__anchorAllowWhiteSpaceInCharactersMetadata'] = block => {
         let out = `anchorAllowWhiteSpaceInCharactersMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['tabGroup__anchorCaseSensitive'] = block =>
         `anchorCaseSensitive: ${JSON.stringify(block.getFieldValue('anchorCaseSensitive'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['tabGroup__anchorCaseSensitiveMetadata'] = block => {
         let out = `anchorCaseSensitiveMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['tabGroup__anchorHorizontalAlignment'] = block =>
         `anchorHorizontalAlignment: ${JSON.stringify(block.getFieldValue('anchorHorizontalAlignment'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['tabGroup__anchorHorizontalAlignmentMetadata'] = block => {
         let out = `anchorHorizontalAlignmentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['tabGroup__anchorIgnoreIfNotPresent'] = block =>
         `anchorIgnoreIfNotPresent: ${JSON.stringify(block.getFieldValue('anchorIgnoreIfNotPresent'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['tabGroup__anchorIgnoreIfNotPresentMetadata'] = block => {
         let out = `anchorIgnoreIfNotPresentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['tabGroup__anchorMatchWholeWord'] = block =>
         `anchorMatchWholeWord: ${JSON.stringify(block.getFieldValue('anchorMatchWholeWord'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['tabGroup__anchorMatchWholeWordMetadata'] = block => {
         let out = `anchorMatchWholeWordMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['tabGroup__anchorString'] = block =>
         `anchorString: ${JSON.stringify(block.getFieldValue('anchorString'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['tabGroup__anchorStringMetadata'] = block => {
         let out = `anchorStringMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['tabGroup__anchorTabProcessorVersion'] = block =>
         `anchorTabProcessorVersion: ${JSON.stringify(block.getFieldValue('anchorTabProcessorVersion'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['tabGroup__anchorTabProcessorVersionMetadata'] = block => {
         let out = `anchorTabProcessorVersionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['tabGroup__anchorUnits'] = block =>
         `anchorUnits: ${JSON.stringify(block.getFieldValue('anchorUnits'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['tabGroup__anchorUnitsMetadata'] = block => {
         let out = `anchorUnitsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['tabGroup__anchorXOffset'] = block =>
         `anchorXOffset: ${JSON.stringify(block.getFieldValue('anchorXOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['tabGroup__anchorXOffsetMetadata'] = block => {
         let out = `anchorXOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['tabGroup__anchorYOffset'] = block =>
         `anchorYOffset: ${JSON.stringify(block.getFieldValue('anchorYOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['tabGroup__anchorYOffsetMetadata'] = block => {
         let out = `anchorYOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['tabGroup__conditionalParentLabel'] = block =>
         `conditionalParentLabel: ${JSON.stringify(block.getFieldValue('conditionalParentLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['tabGroup__conditionalParentLabelMetadata'] = block => {
         let out = `conditionalParentLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['tabGroup__conditionalParentValue'] = block =>
         `conditionalParentValue: ${JSON.stringify(block.getFieldValue('conditionalParentValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['tabGroup__conditionalParentValueMetadata'] = block => {
         let out = `conditionalParentValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['tabGroup__customTabId'] = block =>
         `customTabId: ${JSON.stringify(block.getFieldValue('customTabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['tabGroup__customTabIdMetadata'] = block => {
         let out = `customTabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['tabGroup__documentId'] = block =>
         `documentId: ${JSON.stringify(block.getFieldValue('documentId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['tabGroup__documentIdMetadata'] = block => {
         let out = `documentIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['tabGroup__formOrder'] = block =>
         `formOrder: ${JSON.stringify(block.getFieldValue('formOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['tabGroup__formOrderMetadata'] = block => {
         let out = `formOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['tabGroup__formPageLabel'] = block =>
         `formPageLabel: ${JSON.stringify(block.getFieldValue('formPageLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['tabGroup__formPageLabelMetadata'] = block => {
         let out = `formPageLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['tabGroup__formPageNumber'] = block =>
         `formPageNumber: ${JSON.stringify(block.getFieldValue('formPageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['tabGroup__formPageNumberMetadata'] = block => {
         let out = `formPageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['tabGroup__groupLabel'] = block =>
         `groupLabel: ${JSON.stringify(block.getFieldValue('groupLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['tabGroup__groupLabelMetadata'] = block => {
         let out = `groupLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['tabGroup__groupRule'] = block =>
         `groupRule: ${JSON.stringify(block.getFieldValue('groupRule'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['tabGroup__groupRuleMetadata'] = block => {
         let out = `groupRuleMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['tabGroup__height'] = block =>
         `height: ${JSON.stringify(block.getFieldValue('height'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['tabGroup__heightMetadata'] = block => {
         let out = `heightMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['tabGroup__maximumAllowed'] = block =>
         `maximumAllowed: ${JSON.stringify(block.getFieldValue('maximumAllowed'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['tabGroup__maximumAllowedMetadata'] = block => {
         let out = `maximumAllowedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['tabGroup__mergeFieldXml'] = block =>
         `mergeFieldXml: ${JSON.stringify(block.getFieldValue('mergeFieldXml'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['tabGroup__minimumRequired'] = block =>
         `minimumRequired: ${JSON.stringify(block.getFieldValue('minimumRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['tabGroup__minimumRequiredMetadata'] = block => {
         let out = `minimumRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['tabGroup__pageNumber'] = block =>
         `pageNumber: ${JSON.stringify(block.getFieldValue('pageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['tabGroup__pageNumberMetadata'] = block => {
         let out = `pageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['tabGroup__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['tabGroup__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['tabGroup__recipientIdGuidMetadata'] = block => {
         let out = `recipientIdGuidMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['tabGroup__recipientIdMetadata'] = block => {
         let out = `recipientIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['tabGroup__source'] = block =>
         `source: ${JSON.stringify(block.getFieldValue('source'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['tabGroup__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['tabGroup__statusMetadata'] = block => {
         let out = `statusMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['tabGroup__tabGroupLabels'] = block => {
         let out = `    tabGroupLabels: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'tabGroupLabel', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['tabGroup_tabGroupLabels__tabGroupLabel'] = block =>
         `    ${JSON.stringify(block.getFieldValue('tabGroupLabel'))}${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['tabGroup__tabGroupLabelsMetadata'] = block => {
         let out = `tabGroupLabelsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['tabGroup__tabId'] = block =>
         `tabId: ${JSON.stringify(block.getFieldValue('tabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['tabGroup__tabIdMetadata'] = block => {
         let out = `tabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['tabGroup__tabOrder'] = block =>
         `tabOrder: ${JSON.stringify(block.getFieldValue('tabOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['tabGroup__tabOrderMetadata'] = block => {
         let out = `tabOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['tabGroup__tabScope'] = block =>
         `tabScope: ${JSON.stringify(block.getFieldValue('tabScope'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['tabGroup__tabScopeMetadata'] = block => {
         let out = `tabScopeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['tabGroup__tabType'] = block =>
         `tabType: ${JSON.stringify(block.getFieldValue('tabType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['tabGroup__tabTypeMetadata'] = block => {
         let out = `tabTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['tabGroup__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['tabGroup__templateLockedMetadata'] = block => {
         let out = `templateLockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['tabGroup__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['tabGroup__templateRequiredMetadata'] = block => {
         let out = `templateRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['tabGroup__toolTipMetadata'] = block => {
         let out = `toolTipMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['tabGroup__tooltip'] = block =>
         `tooltip: ${JSON.stringify(block.getFieldValue('tooltip'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['tabGroup__validationMessage'] = block =>
         `validationMessage: ${JSON.stringify(block.getFieldValue('validationMessage'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['tabGroup__validationMessageMetadata'] = block => {
         let out = `validationMessageMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['tabGroup__width'] = block =>
         `width: ${JSON.stringify(block.getFieldValue('width'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['tabGroup__widthMetadata'] = block => {
         let out = `widthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['tabGroup__xPosition'] = block =>
         `xPosition: ${JSON.stringify(block.getFieldValue('xPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['tabGroup__xPositionMetadata'] = block => {
         let out = `xPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['tabGroup__yPosition'] = block =>
         `yPosition: ${JSON.stringify(block.getFieldValue('yPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['tabGroup__yPositionMetadata'] = block => {
         let out = `yPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

Blockly.JavaScript['templateRole'] = block => 
  `\n.add_object("templateRole", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['templateRole__accessCode'] = block =>
         `accessCode: ${JSON.stringify(block.getFieldValue('accessCode'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['templateRole__clientUserId'] = block =>
         `clientUserId: ${JSON.stringify(block.getFieldValue('clientUserId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['templateRole__defaultRecipient'] = block =>
         `defaultRecipient: ${JSON.stringify(block.getFieldValue('defaultRecipient'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['templateRole__email'] = block =>
         `email: ${JSON.stringify(block.getFieldValue('email'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['templateRole__embeddedRecipientStartURL'] = block =>
         `embeddedRecipientStartURL: ${JSON.stringify(block.getFieldValue('embeddedRecipientStartURL'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['templateRole__inPersonSignerName'] = block =>
         `inPersonSignerName: ${JSON.stringify(block.getFieldValue('inPersonSignerName'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['templateRole__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['templateRole__roleName'] = block =>
         `roleName: ${JSON.stringify(block.getFieldValue('roleName'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['templateRole__routingOrder'] = block =>
         `routingOrder: ${JSON.stringify(block.getFieldValue('routingOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['templateRole__signingGroupId'] = block =>
         `signingGroupId: ${JSON.stringify(block.getFieldValue('signingGroupId'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['text'] = block => 
  `\n.add_object("text", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['text__anchorAllowWhiteSpaceInCharacters'] = block =>
         `anchorAllowWhiteSpaceInCharacters: ${JSON.stringify(block.getFieldValue('anchorAllowWhiteSpaceInCharacters'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__anchorAllowWhiteSpaceInCharactersMetadata'] = block => {
         let out = `anchorAllowWhiteSpaceInCharactersMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__anchorCaseSensitive'] = block =>
         `anchorCaseSensitive: ${JSON.stringify(block.getFieldValue('anchorCaseSensitive'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__anchorCaseSensitiveMetadata'] = block => {
         let out = `anchorCaseSensitiveMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__anchorHorizontalAlignment'] = block =>
         `anchorHorizontalAlignment: ${JSON.stringify(block.getFieldValue('anchorHorizontalAlignment'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__anchorHorizontalAlignmentMetadata'] = block => {
         let out = `anchorHorizontalAlignmentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__anchorIgnoreIfNotPresent'] = block =>
         `anchorIgnoreIfNotPresent: ${JSON.stringify(block.getFieldValue('anchorIgnoreIfNotPresent'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__anchorIgnoreIfNotPresentMetadata'] = block => {
         let out = `anchorIgnoreIfNotPresentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__anchorMatchWholeWord'] = block =>
         `anchorMatchWholeWord: ${JSON.stringify(block.getFieldValue('anchorMatchWholeWord'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__anchorMatchWholeWordMetadata'] = block => {
         let out = `anchorMatchWholeWordMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__anchorString'] = block =>
         `anchorString: ${JSON.stringify(block.getFieldValue('anchorString'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__anchorStringMetadata'] = block => {
         let out = `anchorStringMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__anchorTabProcessorVersion'] = block =>
         `anchorTabProcessorVersion: ${JSON.stringify(block.getFieldValue('anchorTabProcessorVersion'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__anchorTabProcessorVersionMetadata'] = block => {
         let out = `anchorTabProcessorVersionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__anchorUnits'] = block =>
         `anchorUnits: ${JSON.stringify(block.getFieldValue('anchorUnits'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__anchorUnitsMetadata'] = block => {
         let out = `anchorUnitsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__anchorXOffset'] = block =>
         `anchorXOffset: ${JSON.stringify(block.getFieldValue('anchorXOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__anchorXOffsetMetadata'] = block => {
         let out = `anchorXOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__anchorYOffset'] = block =>
         `anchorYOffset: ${JSON.stringify(block.getFieldValue('anchorYOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__anchorYOffsetMetadata'] = block => {
         let out = `anchorYOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__bold'] = block =>
         `bold: ${JSON.stringify(block.getFieldValue('bold'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__boldMetadata'] = block => {
         let out = `boldMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__concealValueOnDocument'] = block =>
         `concealValueOnDocument: ${JSON.stringify(block.getFieldValue('concealValueOnDocument'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__concealValueOnDocumentMetadata'] = block => {
         let out = `concealValueOnDocumentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__conditionalParentLabel'] = block =>
         `conditionalParentLabel: ${JSON.stringify(block.getFieldValue('conditionalParentLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__conditionalParentLabelMetadata'] = block => {
         let out = `conditionalParentLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__conditionalParentValue'] = block =>
         `conditionalParentValue: ${JSON.stringify(block.getFieldValue('conditionalParentValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__conditionalParentValueMetadata'] = block => {
         let out = `conditionalParentValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__customTabId'] = block =>
         `customTabId: ${JSON.stringify(block.getFieldValue('customTabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__customTabIdMetadata'] = block => {
         let out = `customTabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__disableAutoSize'] = block =>
         `disableAutoSize: ${JSON.stringify(block.getFieldValue('disableAutoSize'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__disableAutoSizeMetadata'] = block => {
         let out = `disableAutoSizeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__documentId'] = block =>
         `documentId: ${JSON.stringify(block.getFieldValue('documentId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__documentIdMetadata'] = block => {
         let out = `documentIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__font'] = block =>
         `font: ${JSON.stringify(block.getFieldValue('font'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['text__fontColor'] = block =>
         `fontColor: ${JSON.stringify(block.getFieldValue('fontColor'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__fontColorMetadata'] = block => {
         let out = `fontColorMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['text__fontMetadata'] = block => {
         let out = `fontMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__fontSize'] = block =>
         `fontSize: ${JSON.stringify(block.getFieldValue('fontSize'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__fontSizeMetadata'] = block => {
         let out = `fontSizeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__formOrder'] = block =>
         `formOrder: ${JSON.stringify(block.getFieldValue('formOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__formOrderMetadata'] = block => {
         let out = `formOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__formPageLabel'] = block =>
         `formPageLabel: ${JSON.stringify(block.getFieldValue('formPageLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__formPageLabelMetadata'] = block => {
         let out = `formPageLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__formPageNumber'] = block =>
         `formPageNumber: ${JSON.stringify(block.getFieldValue('formPageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__formPageNumberMetadata'] = block => {
         let out = `formPageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__formula'] = block =>
         `formula: ${JSON.stringify(block.getFieldValue('formula'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__formulaMetadata'] = block => {
         let out = `formulaMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__height'] = block =>
         `height: ${JSON.stringify(block.getFieldValue('height'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__heightMetadata'] = block => {
         let out = `heightMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__italic'] = block =>
         `italic: ${JSON.stringify(block.getFieldValue('italic'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__italicMetadata'] = block => {
         let out = `italicMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__locked'] = block =>
         `locked: ${JSON.stringify(block.getFieldValue('locked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__lockedMetadata'] = block => {
         let out = `lockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__maxLength'] = block =>
         `maxLength: ${JSON.stringify(block.getFieldValue('maxLength'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__maxLengthMetadata'] = block => {
         let out = `maxLengthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__mergeFieldXml'] = block =>
         `mergeFieldXml: ${JSON.stringify(block.getFieldValue('mergeFieldXml'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['text__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__nameMetadata'] = block => {
         let out = `nameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__originalValue'] = block =>
         `originalValue: ${JSON.stringify(block.getFieldValue('originalValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__originalValueMetadata'] = block => {
         let out = `originalValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__pageNumber'] = block =>
         `pageNumber: ${JSON.stringify(block.getFieldValue('pageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__pageNumberMetadata'] = block => {
         let out = `pageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['text__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__recipientIdGuidMetadata'] = block => {
         let out = `recipientIdGuidMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['text__recipientIdMetadata'] = block => {
         let out = `recipientIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__requireAll'] = block =>
         `requireAll: ${JSON.stringify(block.getFieldValue('requireAll'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__requireAllMetadata'] = block => {
         let out = `requireAllMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__requireInitialOnSharedChange'] = block =>
         `requireInitialOnSharedChange: ${JSON.stringify(block.getFieldValue('requireInitialOnSharedChange'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__requireInitialOnSharedChangeMetadata'] = block => {
         let out = `requireInitialOnSharedChangeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__required'] = block =>
         `required: ${JSON.stringify(block.getFieldValue('required'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__requiredMetadata'] = block => {
         let out = `requiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__senderRequired'] = block =>
         `senderRequired: ${JSON.stringify(block.getFieldValue('senderRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__senderRequiredMetadata'] = block => {
         let out = `senderRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__shareToRecipients'] = block =>
         `shareToRecipients: ${JSON.stringify(block.getFieldValue('shareToRecipients'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__shareToRecipientsMetadata'] = block => {
         let out = `shareToRecipientsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__shared'] = block =>
         `shared: ${JSON.stringify(block.getFieldValue('shared'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__sharedMetadata'] = block => {
         let out = `sharedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__source'] = block =>
         `source: ${JSON.stringify(block.getFieldValue('source'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['text__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__statusMetadata'] = block => {
         let out = `statusMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['text__tabGroupLabels'] = block => {
         let out = `    tabGroupLabels: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'tabGroupLabel', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['text_tabGroupLabels__tabGroupLabel'] = block =>
         `    ${JSON.stringify(block.getFieldValue('tabGroupLabel'))}${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['text__tabGroupLabelsMetadata'] = block => {
         let out = `tabGroupLabelsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__tabId'] = block =>
         `tabId: ${JSON.stringify(block.getFieldValue('tabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__tabIdMetadata'] = block => {
         let out = `tabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__tabLabel'] = block =>
         `tabLabel: ${JSON.stringify(block.getFieldValue('tabLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__tabLabelMetadata'] = block => {
         let out = `tabLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__tabOrder'] = block =>
         `tabOrder: ${JSON.stringify(block.getFieldValue('tabOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__tabOrderMetadata'] = block => {
         let out = `tabOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__tabType'] = block =>
         `tabType: ${JSON.stringify(block.getFieldValue('tabType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__tabTypeMetadata'] = block => {
         let out = `tabTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__templateLockedMetadata'] = block => {
         let out = `templateLockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__templateRequiredMetadata'] = block => {
         let out = `templateRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['text__toolTipMetadata'] = block => {
         let out = `toolTipMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__tooltip'] = block =>
         `tooltip: ${JSON.stringify(block.getFieldValue('tooltip'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['text__underline'] = block =>
         `underline: ${JSON.stringify(block.getFieldValue('underline'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__underlineMetadata'] = block => {
         let out = `underlineMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__validationMessage'] = block =>
         `validationMessage: ${JSON.stringify(block.getFieldValue('validationMessage'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__validationMessageMetadata'] = block => {
         let out = `validationMessageMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__validationPattern'] = block =>
         `validationPattern: ${JSON.stringify(block.getFieldValue('validationPattern'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__validationPatternMetadata'] = block => {
         let out = `validationPatternMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__value'] = block =>
         `value: ${JSON.stringify(block.getFieldValue('value'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__valueMetadata'] = block => {
         let out = `valueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__width'] = block =>
         `width: ${JSON.stringify(block.getFieldValue('width'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__widthMetadata'] = block => {
         let out = `widthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__xPosition'] = block =>
         `xPosition: ${JSON.stringify(block.getFieldValue('xPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__xPositionMetadata'] = block => {
         let out = `xPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['text__yPosition'] = block =>
         `yPosition: ${JSON.stringify(block.getFieldValue('yPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['text__yPositionMetadata'] = block => {
         let out = `yPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

Blockly.JavaScript['textCustomField'] = block => 
  `\n.add_object("textCustomField", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['textCustomField__configurationType'] = block =>
         `configurationType: ${JSON.stringify(block.getFieldValue('configurationType'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['textCustomField__fieldId'] = block =>
         `fieldId: ${JSON.stringify(block.getFieldValue('fieldId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['textCustomField__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['textCustomField__required'] = block =>
         `required: ${JSON.stringify(block.getFieldValue('required'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['textCustomField__show'] = block =>
         `show: ${JSON.stringify(block.getFieldValue('show'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['textCustomField__value'] = block =>
         `value: ${JSON.stringify(block.getFieldValue('value'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['title'] = block => 
  `\n.add_object("title", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['title__anchorAllowWhiteSpaceInCharacters'] = block =>
         `anchorAllowWhiteSpaceInCharacters: ${JSON.stringify(block.getFieldValue('anchorAllowWhiteSpaceInCharacters'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['title__anchorAllowWhiteSpaceInCharactersMetadata'] = block => {
         let out = `anchorAllowWhiteSpaceInCharactersMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['title__anchorCaseSensitive'] = block =>
         `anchorCaseSensitive: ${JSON.stringify(block.getFieldValue('anchorCaseSensitive'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['title__anchorCaseSensitiveMetadata'] = block => {
         let out = `anchorCaseSensitiveMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['title__anchorHorizontalAlignment'] = block =>
         `anchorHorizontalAlignment: ${JSON.stringify(block.getFieldValue('anchorHorizontalAlignment'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['title__anchorHorizontalAlignmentMetadata'] = block => {
         let out = `anchorHorizontalAlignmentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['title__anchorIgnoreIfNotPresent'] = block =>
         `anchorIgnoreIfNotPresent: ${JSON.stringify(block.getFieldValue('anchorIgnoreIfNotPresent'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['title__anchorIgnoreIfNotPresentMetadata'] = block => {
         let out = `anchorIgnoreIfNotPresentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['title__anchorMatchWholeWord'] = block =>
         `anchorMatchWholeWord: ${JSON.stringify(block.getFieldValue('anchorMatchWholeWord'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['title__anchorMatchWholeWordMetadata'] = block => {
         let out = `anchorMatchWholeWordMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['title__anchorString'] = block =>
         `anchorString: ${JSON.stringify(block.getFieldValue('anchorString'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['title__anchorStringMetadata'] = block => {
         let out = `anchorStringMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['title__anchorTabProcessorVersion'] = block =>
         `anchorTabProcessorVersion: ${JSON.stringify(block.getFieldValue('anchorTabProcessorVersion'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['title__anchorTabProcessorVersionMetadata'] = block => {
         let out = `anchorTabProcessorVersionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['title__anchorUnits'] = block =>
         `anchorUnits: ${JSON.stringify(block.getFieldValue('anchorUnits'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['title__anchorUnitsMetadata'] = block => {
         let out = `anchorUnitsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['title__anchorXOffset'] = block =>
         `anchorXOffset: ${JSON.stringify(block.getFieldValue('anchorXOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['title__anchorXOffsetMetadata'] = block => {
         let out = `anchorXOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['title__anchorYOffset'] = block =>
         `anchorYOffset: ${JSON.stringify(block.getFieldValue('anchorYOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['title__anchorYOffsetMetadata'] = block => {
         let out = `anchorYOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['title__bold'] = block =>
         `bold: ${JSON.stringify(block.getFieldValue('bold'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['title__boldMetadata'] = block => {
         let out = `boldMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['title__concealValueOnDocument'] = block =>
         `concealValueOnDocument: ${JSON.stringify(block.getFieldValue('concealValueOnDocument'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['title__concealValueOnDocumentMetadata'] = block => {
         let out = `concealValueOnDocumentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['title__conditionalParentLabel'] = block =>
         `conditionalParentLabel: ${JSON.stringify(block.getFieldValue('conditionalParentLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['title__conditionalParentLabelMetadata'] = block => {
         let out = `conditionalParentLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['title__conditionalParentValue'] = block =>
         `conditionalParentValue: ${JSON.stringify(block.getFieldValue('conditionalParentValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['title__conditionalParentValueMetadata'] = block => {
         let out = `conditionalParentValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['title__customTabId'] = block =>
         `customTabId: ${JSON.stringify(block.getFieldValue('customTabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['title__customTabIdMetadata'] = block => {
         let out = `customTabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['title__disableAutoSize'] = block =>
         `disableAutoSize: ${JSON.stringify(block.getFieldValue('disableAutoSize'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['title__disableAutoSizeMetadata'] = block => {
         let out = `disableAutoSizeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['title__documentId'] = block =>
         `documentId: ${JSON.stringify(block.getFieldValue('documentId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['title__documentIdMetadata'] = block => {
         let out = `documentIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['title__font'] = block =>
         `font: ${JSON.stringify(block.getFieldValue('font'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['title__fontColor'] = block =>
         `fontColor: ${JSON.stringify(block.getFieldValue('fontColor'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['title__fontColorMetadata'] = block => {
         let out = `fontColorMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['title__fontMetadata'] = block => {
         let out = `fontMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['title__fontSize'] = block =>
         `fontSize: ${JSON.stringify(block.getFieldValue('fontSize'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['title__fontSizeMetadata'] = block => {
         let out = `fontSizeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['title__formOrder'] = block =>
         `formOrder: ${JSON.stringify(block.getFieldValue('formOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['title__formOrderMetadata'] = block => {
         let out = `formOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['title__formPageLabel'] = block =>
         `formPageLabel: ${JSON.stringify(block.getFieldValue('formPageLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['title__formPageLabelMetadata'] = block => {
         let out = `formPageLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['title__formPageNumber'] = block =>
         `formPageNumber: ${JSON.stringify(block.getFieldValue('formPageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['title__formPageNumberMetadata'] = block => {
         let out = `formPageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['title__height'] = block =>
         `height: ${JSON.stringify(block.getFieldValue('height'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['title__heightMetadata'] = block => {
         let out = `heightMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['title__italic'] = block =>
         `italic: ${JSON.stringify(block.getFieldValue('italic'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['title__italicMetadata'] = block => {
         let out = `italicMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['title__locked'] = block =>
         `locked: ${JSON.stringify(block.getFieldValue('locked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['title__lockedMetadata'] = block => {
         let out = `lockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['title__maxLength'] = block =>
         `maxLength: ${JSON.stringify(block.getFieldValue('maxLength'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['title__maxLengthMetadata'] = block => {
         let out = `maxLengthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['title__mergeFieldXml'] = block =>
         `mergeFieldXml: ${JSON.stringify(block.getFieldValue('mergeFieldXml'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['title__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['title__nameMetadata'] = block => {
         let out = `nameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['title__originalValue'] = block =>
         `originalValue: ${JSON.stringify(block.getFieldValue('originalValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['title__originalValueMetadata'] = block => {
         let out = `originalValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['title__pageNumber'] = block =>
         `pageNumber: ${JSON.stringify(block.getFieldValue('pageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['title__pageNumberMetadata'] = block => {
         let out = `pageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['title__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['title__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['title__recipientIdGuidMetadata'] = block => {
         let out = `recipientIdGuidMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['title__recipientIdMetadata'] = block => {
         let out = `recipientIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['title__required'] = block =>
         `required: ${JSON.stringify(block.getFieldValue('required'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['title__requiredMetadata'] = block => {
         let out = `requiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['title__source'] = block =>
         `source: ${JSON.stringify(block.getFieldValue('source'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['title__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['title__statusMetadata'] = block => {
         let out = `statusMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['title__tabGroupLabels'] = block => {
         let out = `    tabGroupLabels: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'tabGroupLabel', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['title_tabGroupLabels__tabGroupLabel'] = block =>
         `    ${JSON.stringify(block.getFieldValue('tabGroupLabel'))}${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['title__tabGroupLabelsMetadata'] = block => {
         let out = `tabGroupLabelsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['title__tabId'] = block =>
         `tabId: ${JSON.stringify(block.getFieldValue('tabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['title__tabIdMetadata'] = block => {
         let out = `tabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['title__tabLabel'] = block =>
         `tabLabel: ${JSON.stringify(block.getFieldValue('tabLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['title__tabLabelMetadata'] = block => {
         let out = `tabLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['title__tabOrder'] = block =>
         `tabOrder: ${JSON.stringify(block.getFieldValue('tabOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['title__tabOrderMetadata'] = block => {
         let out = `tabOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['title__tabType'] = block =>
         `tabType: ${JSON.stringify(block.getFieldValue('tabType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['title__tabTypeMetadata'] = block => {
         let out = `tabTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['title__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['title__templateLockedMetadata'] = block => {
         let out = `templateLockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['title__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['title__templateRequiredMetadata'] = block => {
         let out = `templateRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['title__toolTipMetadata'] = block => {
         let out = `toolTipMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['title__tooltip'] = block =>
         `tooltip: ${JSON.stringify(block.getFieldValue('tooltip'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['title__underline'] = block =>
         `underline: ${JSON.stringify(block.getFieldValue('underline'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['title__underlineMetadata'] = block => {
         let out = `underlineMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['title__value'] = block =>
         `value: ${JSON.stringify(block.getFieldValue('value'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['title__valueMetadata'] = block => {
         let out = `valueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['title__width'] = block =>
         `width: ${JSON.stringify(block.getFieldValue('width'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['title__widthMetadata'] = block => {
         let out = `widthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['title__xPosition'] = block =>
         `xPosition: ${JSON.stringify(block.getFieldValue('xPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['title__xPositionMetadata'] = block => {
         let out = `xPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['title__yPosition'] = block =>
         `yPosition: ${JSON.stringify(block.getFieldValue('yPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['title__yPositionMetadata'] = block => {
         let out = `yPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

Blockly.JavaScript['userInfo'] = block => 
  `\n.add_object("userInfo", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['userInfo__accountId'] = block =>
         `accountId: ${JSON.stringify(block.getFieldValue('accountId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['userInfo__accountName'] = block =>
         `accountName: ${JSON.stringify(block.getFieldValue('accountName'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['userInfo__activationAccessCode'] = block =>
         `activationAccessCode: ${JSON.stringify(block.getFieldValue('activationAccessCode'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['userInfo__email'] = block =>
         `email: ${JSON.stringify(block.getFieldValue('email'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['userInfo__loginStatus'] = block =>
         `loginStatus: ${JSON.stringify(block.getFieldValue('loginStatus'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['userInfo__membershipId'] = block =>
         `membershipId: ${JSON.stringify(block.getFieldValue('membershipId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['userInfo__sendActivationEmail'] = block =>
         `sendActivationEmail: ${JSON.stringify(block.getFieldValue('sendActivationEmail'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['userInfo__uri'] = block =>
         `uri: ${JSON.stringify(block.getFieldValue('uri'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['userInfo__userId'] = block =>
         `userId: ${JSON.stringify(block.getFieldValue('userId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['userInfo__userName'] = block =>
         `userName: ${JSON.stringify(block.getFieldValue('userName'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['userInfo__userStatus'] = block =>
         `userStatus: ${JSON.stringify(block.getFieldValue('userStatus'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['userInfo__userType'] = block =>
         `userType: ${JSON.stringify(block.getFieldValue('userType'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['view'] = block => 
  `\n.add_object("view", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['view__anchorAllowWhiteSpaceInCharacters'] = block =>
         `anchorAllowWhiteSpaceInCharacters: ${JSON.stringify(block.getFieldValue('anchorAllowWhiteSpaceInCharacters'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['view__anchorAllowWhiteSpaceInCharactersMetadata'] = block => {
         let out = `anchorAllowWhiteSpaceInCharactersMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['view__anchorCaseSensitive'] = block =>
         `anchorCaseSensitive: ${JSON.stringify(block.getFieldValue('anchorCaseSensitive'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['view__anchorCaseSensitiveMetadata'] = block => {
         let out = `anchorCaseSensitiveMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['view__anchorHorizontalAlignment'] = block =>
         `anchorHorizontalAlignment: ${JSON.stringify(block.getFieldValue('anchorHorizontalAlignment'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['view__anchorHorizontalAlignmentMetadata'] = block => {
         let out = `anchorHorizontalAlignmentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['view__anchorIgnoreIfNotPresent'] = block =>
         `anchorIgnoreIfNotPresent: ${JSON.stringify(block.getFieldValue('anchorIgnoreIfNotPresent'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['view__anchorIgnoreIfNotPresentMetadata'] = block => {
         let out = `anchorIgnoreIfNotPresentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['view__anchorMatchWholeWord'] = block =>
         `anchorMatchWholeWord: ${JSON.stringify(block.getFieldValue('anchorMatchWholeWord'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['view__anchorMatchWholeWordMetadata'] = block => {
         let out = `anchorMatchWholeWordMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['view__anchorString'] = block =>
         `anchorString: ${JSON.stringify(block.getFieldValue('anchorString'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['view__anchorStringMetadata'] = block => {
         let out = `anchorStringMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['view__anchorTabProcessorVersion'] = block =>
         `anchorTabProcessorVersion: ${JSON.stringify(block.getFieldValue('anchorTabProcessorVersion'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['view__anchorTabProcessorVersionMetadata'] = block => {
         let out = `anchorTabProcessorVersionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['view__anchorUnits'] = block =>
         `anchorUnits: ${JSON.stringify(block.getFieldValue('anchorUnits'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['view__anchorUnitsMetadata'] = block => {
         let out = `anchorUnitsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['view__anchorXOffset'] = block =>
         `anchorXOffset: ${JSON.stringify(block.getFieldValue('anchorXOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['view__anchorXOffsetMetadata'] = block => {
         let out = `anchorXOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['view__anchorYOffset'] = block =>
         `anchorYOffset: ${JSON.stringify(block.getFieldValue('anchorYOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['view__anchorYOffsetMetadata'] = block => {
         let out = `anchorYOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['view__bold'] = block =>
         `bold: ${JSON.stringify(block.getFieldValue('bold'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['view__boldMetadata'] = block => {
         let out = `boldMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['view__buttonText'] = block =>
         `buttonText: ${JSON.stringify(block.getFieldValue('buttonText'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['view__buttonTextMetadata'] = block => {
         let out = `buttonTextMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['view__conditionalParentLabel'] = block =>
         `conditionalParentLabel: ${JSON.stringify(block.getFieldValue('conditionalParentLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['view__conditionalParentLabelMetadata'] = block => {
         let out = `conditionalParentLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['view__conditionalParentValue'] = block =>
         `conditionalParentValue: ${JSON.stringify(block.getFieldValue('conditionalParentValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['view__conditionalParentValueMetadata'] = block => {
         let out = `conditionalParentValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['view__customTabId'] = block =>
         `customTabId: ${JSON.stringify(block.getFieldValue('customTabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['view__customTabIdMetadata'] = block => {
         let out = `customTabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['view__documentId'] = block =>
         `documentId: ${JSON.stringify(block.getFieldValue('documentId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['view__documentIdMetadata'] = block => {
         let out = `documentIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['view__font'] = block =>
         `font: ${JSON.stringify(block.getFieldValue('font'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['view__fontColor'] = block =>
         `fontColor: ${JSON.stringify(block.getFieldValue('fontColor'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['view__fontColorMetadata'] = block => {
         let out = `fontColorMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['view__fontMetadata'] = block => {
         let out = `fontMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['view__fontSize'] = block =>
         `fontSize: ${JSON.stringify(block.getFieldValue('fontSize'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['view__fontSizeMetadata'] = block => {
         let out = `fontSizeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['view__formOrder'] = block =>
         `formOrder: ${JSON.stringify(block.getFieldValue('formOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['view__formOrderMetadata'] = block => {
         let out = `formOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['view__formPageLabel'] = block =>
         `formPageLabel: ${JSON.stringify(block.getFieldValue('formPageLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['view__formPageLabelMetadata'] = block => {
         let out = `formPageLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['view__formPageNumber'] = block =>
         `formPageNumber: ${JSON.stringify(block.getFieldValue('formPageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['view__formPageNumberMetadata'] = block => {
         let out = `formPageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['view__height'] = block =>
         `height: ${JSON.stringify(block.getFieldValue('height'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['view__heightMetadata'] = block => {
         let out = `heightMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['view__italic'] = block =>
         `italic: ${JSON.stringify(block.getFieldValue('italic'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['view__italicMetadata'] = block => {
         let out = `italicMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['view__mergeFieldXml'] = block =>
         `mergeFieldXml: ${JSON.stringify(block.getFieldValue('mergeFieldXml'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['view__pageNumber'] = block =>
         `pageNumber: ${JSON.stringify(block.getFieldValue('pageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['view__pageNumberMetadata'] = block => {
         let out = `pageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['view__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['view__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['view__recipientIdGuidMetadata'] = block => {
         let out = `recipientIdGuidMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['view__recipientIdMetadata'] = block => {
         let out = `recipientIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['view__required'] = block =>
         `required: ${JSON.stringify(block.getFieldValue('required'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['view__requiredMetadata'] = block => {
         let out = `requiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['view__requiredRead'] = block =>
         `requiredRead: ${JSON.stringify(block.getFieldValue('requiredRead'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['view__source'] = block =>
         `source: ${JSON.stringify(block.getFieldValue('source'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['view__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['view__statusMetadata'] = block => {
         let out = `statusMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['view__tabGroupLabels'] = block => {
         let out = `    tabGroupLabels: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'tabGroupLabel', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['view_tabGroupLabels__tabGroupLabel'] = block =>
         `    ${JSON.stringify(block.getFieldValue('tabGroupLabel'))}${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['view__tabGroupLabelsMetadata'] = block => {
         let out = `tabGroupLabelsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['view__tabId'] = block =>
         `tabId: ${JSON.stringify(block.getFieldValue('tabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['view__tabIdMetadata'] = block => {
         let out = `tabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['view__tabLabel'] = block =>
         `tabLabel: ${JSON.stringify(block.getFieldValue('tabLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['view__tabLabelMetadata'] = block => {
         let out = `tabLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['view__tabOrder'] = block =>
         `tabOrder: ${JSON.stringify(block.getFieldValue('tabOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['view__tabOrderMetadata'] = block => {
         let out = `tabOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['view__tabType'] = block =>
         `tabType: ${JSON.stringify(block.getFieldValue('tabType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['view__tabTypeMetadata'] = block => {
         let out = `tabTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['view__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['view__templateLockedMetadata'] = block => {
         let out = `templateLockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['view__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['view__templateRequiredMetadata'] = block => {
         let out = `templateRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['view__toolTipMetadata'] = block => {
         let out = `toolTipMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['view__tooltip'] = block =>
         `tooltip: ${JSON.stringify(block.getFieldValue('tooltip'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['view__underline'] = block =>
         `underline: ${JSON.stringify(block.getFieldValue('underline'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['view__underlineMetadata'] = block => {
         let out = `underlineMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['view__width'] = block =>
         `width: ${JSON.stringify(block.getFieldValue('width'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['view__widthMetadata'] = block => {
         let out = `widthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['view__xPosition'] = block =>
         `xPosition: ${JSON.stringify(block.getFieldValue('xPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['view__xPositionMetadata'] = block => {
         let out = `xPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['view__yPosition'] = block =>
         `yPosition: ${JSON.stringify(block.getFieldValue('yPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['view__yPositionMetadata'] = block => {
         let out = `yPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

Blockly.JavaScript['witness'] = block => 
  `\n.add_object("witness", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['witness__accessCode'] = block =>
         `accessCode: ${JSON.stringify(block.getFieldValue('accessCode'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['witness__accessCodeMetadata'] = block => {
         let out = `accessCodeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['witness__addAccessCodeToEmail'] = block =>
         `addAccessCodeToEmail: ${JSON.stringify(block.getFieldValue('addAccessCodeToEmail'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['witness__agentCanEditEmail'] = block =>
         `agentCanEditEmail: ${JSON.stringify(block.getFieldValue('agentCanEditEmail'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['witness__agentCanEditName'] = block =>
         `agentCanEditName: ${JSON.stringify(block.getFieldValue('agentCanEditName'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['witness__allowSystemOverrideForLockedRecipient'] = block =>
         `allowSystemOverrideForLockedRecipient: ${JSON.stringify(block.getFieldValue('allowSystemOverrideForLockedRecipient'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['witness__autoNavigation'] = block =>
         `autoNavigation: ${JSON.stringify(block.getFieldValue('autoNavigation'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['witness__autoRespondedReason'] = block =>
         `autoRespondedReason: ${JSON.stringify(block.getFieldValue('autoRespondedReason'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['witness__bulkRecipientsUri'] = block =>
         `bulkRecipientsUri: ${JSON.stringify(block.getFieldValue('bulkRecipientsUri'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['witness__canSignOffline'] = block =>
         `canSignOffline: ${JSON.stringify(block.getFieldValue('canSignOffline'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['witness__clientUserId'] = block =>
         `clientUserId: ${JSON.stringify(block.getFieldValue('clientUserId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['witness__completedCount'] = block =>
         `completedCount: ${JSON.stringify(block.getFieldValue('completedCount'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['witness__creationReason'] = block =>
         `creationReason: ${JSON.stringify(block.getFieldValue('creationReason'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['witness__customFields'] = block => {
         let out = `    customFields: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'customField', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['witness_customFields__customField'] = block =>
         `    ${JSON.stringify(block.getFieldValue('customField'))}${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['witness__declinedDateTime'] = block =>
         `declinedDateTime: ${JSON.stringify(block.getFieldValue('declinedDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['witness__declinedReason'] = block =>
         `declinedReason: ${JSON.stringify(block.getFieldValue('declinedReason'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['witness__defaultRecipient'] = block =>
         `defaultRecipient: ${JSON.stringify(block.getFieldValue('defaultRecipient'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['witness__deliveredDateTime'] = block =>
         `deliveredDateTime: ${JSON.stringify(block.getFieldValue('deliveredDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['witness__deliveryMethod'] = block =>
         `deliveryMethod: ${JSON.stringify(block.getFieldValue('deliveryMethod'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['witness__deliveryMethodMetadata'] = block => {
         let out = `deliveryMethodMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['witness__designatorId'] = block =>
         `designatorId: ${JSON.stringify(block.getFieldValue('designatorId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['witness__designatorIdGuid'] = block =>
         `designatorIdGuid: ${JSON.stringify(block.getFieldValue('designatorIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['witness__email'] = block =>
         `email: ${JSON.stringify(block.getFieldValue('email'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['witness__emailMetadata'] = block => {
         let out = `emailMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['witness__emailRecipientPostSigningURL'] = block =>
         `emailRecipientPostSigningURL: ${JSON.stringify(block.getFieldValue('emailRecipientPostSigningURL'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['witness__embeddedRecipientStartURL'] = block =>
         `embeddedRecipientStartURL: ${JSON.stringify(block.getFieldValue('embeddedRecipientStartURL'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['witness__excludedDocuments'] = block => {
         let out = `    excludedDocuments: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'excludedDocument', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['witness_excludedDocuments__excludedDocument'] = block =>
         `    ${JSON.stringify(block.getFieldValue('excludedDocument'))}${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['witness__faxNumber'] = block =>
         `faxNumber: ${JSON.stringify(block.getFieldValue('faxNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['witness__faxNumberMetadata'] = block => {
         let out = `faxNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['witness__firstName'] = block =>
         `firstName: ${JSON.stringify(block.getFieldValue('firstName'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['witness__firstNameMetadata'] = block => {
         let out = `firstNameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['witness__fullName'] = block =>
         `fullName: ${JSON.stringify(block.getFieldValue('fullName'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['witness__fullNameMetadata'] = block => {
         let out = `fullNameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['witness__idCheckConfigurationName'] = block =>
         `idCheckConfigurationName: ${JSON.stringify(block.getFieldValue('idCheckConfigurationName'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['witness__idCheckConfigurationNameMetadata'] = block => {
         let out = `idCheckConfigurationNameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['witness__inheritEmailNotificationConfiguration'] = block =>
         `inheritEmailNotificationConfiguration: ${JSON.stringify(block.getFieldValue('inheritEmailNotificationConfiguration'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['witness__isBulkRecipient'] = block =>
         `isBulkRecipient: ${JSON.stringify(block.getFieldValue('isBulkRecipient'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['witness__isBulkRecipientMetadata'] = block => {
         let out = `isBulkRecipientMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['witness__lastName'] = block =>
         `lastName: ${JSON.stringify(block.getFieldValue('lastName'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['witness__lastNameMetadata'] = block => {
         let out = `lastNameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['witness__lockedRecipientPhoneAuthEditable'] = block =>
         `lockedRecipientPhoneAuthEditable: ${JSON.stringify(block.getFieldValue('lockedRecipientPhoneAuthEditable'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['witness__lockedRecipientSmsEditable'] = block =>
         `lockedRecipientSmsEditable: ${JSON.stringify(block.getFieldValue('lockedRecipientSmsEditable'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['witness__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['witness__nameMetadata'] = block => {
         let out = `nameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['witness__notaryId'] = block =>
         `notaryId: ${JSON.stringify(block.getFieldValue('notaryId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['witness__notarySignerEmailSent'] = block =>
         `notarySignerEmailSent: ${JSON.stringify(block.getFieldValue('notarySignerEmailSent'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['witness__note'] = block =>
         `note: ${JSON.stringify(block.getFieldValue('note'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['witness__noteMetadata'] = block => {
         let out = `noteMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['witness__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['witness__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['witness__recipientSuppliesTabs'] = block =>
         `recipientSuppliesTabs: ${JSON.stringify(block.getFieldValue('recipientSuppliesTabs'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['witness__recipientType'] = block =>
         `recipientType: ${JSON.stringify(block.getFieldValue('recipientType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['witness__recipientTypeMetadata'] = block => {
         let out = `recipientTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['witness__requireIdLookup'] = block =>
         `requireIdLookup: ${JSON.stringify(block.getFieldValue('requireIdLookup'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['witness__requireIdLookupMetadata'] = block => {
         let out = `requireIdLookupMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['witness__requireSignOnPaper'] = block =>
         `requireSignOnPaper: ${JSON.stringify(block.getFieldValue('requireSignOnPaper'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['witness__requireSignerCertificate'] = block =>
         `requireSignerCertificate: ${JSON.stringify(block.getFieldValue('requireSignerCertificate'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['witness__requireUploadSignature'] = block =>
         `requireUploadSignature: ${JSON.stringify(block.getFieldValue('requireUploadSignature'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['witness__roleName'] = block =>
         `roleName: ${JSON.stringify(block.getFieldValue('roleName'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['witness__routingOrder'] = block =>
         `routingOrder: ${JSON.stringify(block.getFieldValue('routingOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['witness__routingOrderMetadata'] = block => {
         let out = `routingOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['witness__sentDateTime'] = block =>
         `sentDateTime: ${JSON.stringify(block.getFieldValue('sentDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['witness__signInEachLocation'] = block =>
         `signInEachLocation: ${JSON.stringify(block.getFieldValue('signInEachLocation'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['witness__signInEachLocationMetadata'] = block => {
         let out = `signInEachLocationMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['witness__signedDateTime'] = block =>
         `signedDateTime: ${JSON.stringify(block.getFieldValue('signedDateTime'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['witness__signingGroupId'] = block =>
         `signingGroupId: ${JSON.stringify(block.getFieldValue('signingGroupId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['witness__signingGroupIdMetadata'] = block => {
         let out = `signingGroupIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['witness__signingGroupName'] = block =>
         `signingGroupName: ${JSON.stringify(block.getFieldValue('signingGroupName'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['witness__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['witness__statusCode'] = block =>
         `statusCode: ${JSON.stringify(block.getFieldValue('statusCode'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['witness__suppressEmails'] = block =>
         `suppressEmails: ${JSON.stringify(block.getFieldValue('suppressEmails'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['witness__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['witness__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['witness__totalTabCount'] = block =>
         `totalTabCount: ${JSON.stringify(block.getFieldValue('totalTabCount'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['witness__userId'] = block =>
         `userId: ${JSON.stringify(block.getFieldValue('userId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['witness__witnessFor'] = block =>
         `witnessFor: ${JSON.stringify(block.getFieldValue('witnessFor'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['witness__witnessForGuid'] = block =>
         `witnessForGuid: ${JSON.stringify(block.getFieldValue('witnessForGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['workflow'] = block => 
  `\n.add_object("workflow", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['workflow__currentWorkflowStepId'] = block =>
         `currentWorkflowStepId: ${JSON.stringify(block.getFieldValue('currentWorkflowStepId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['workflow__resumeDate'] = block =>
         `resumeDate: ${JSON.stringify(block.getFieldValue('resumeDate'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['workflow__workflowStatus'] = block =>
         `workflowStatus: ${JSON.stringify(block.getFieldValue('workflowStatus'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['workflowStep'] = block => 
  `\n.add_object("workflowStep", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['workflowStep__action'] = block =>
         `action: ${JSON.stringify(block.getFieldValue('action'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['workflowStep__completedDate'] = block =>
         `completedDate: ${JSON.stringify(block.getFieldValue('completedDate'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['workflowStep__itemId'] = block =>
         `itemId: ${JSON.stringify(block.getFieldValue('itemId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['workflowStep__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['workflowStep__triggerOnItem'] = block =>
         `triggerOnItem: ${JSON.stringify(block.getFieldValue('triggerOnItem'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['workflowStep__triggeredDate'] = block =>
         `triggeredDate: ${JSON.stringify(block.getFieldValue('triggeredDate'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['workflowStep__workflowStepId'] = block =>
         `workflowStepId: ${JSON.stringify(block.getFieldValue('workflowStepId'))}${block.getNextBlock() ? ', ': ''}\n`;
      Blockly.JavaScript['zip'] = block => 
  `\n.add_object("zip", {
${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}})`;
   Blockly.JavaScript['zip__anchorAllowWhiteSpaceInCharacters'] = block =>
         `anchorAllowWhiteSpaceInCharacters: ${JSON.stringify(block.getFieldValue('anchorAllowWhiteSpaceInCharacters'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__anchorAllowWhiteSpaceInCharactersMetadata'] = block => {
         let out = `anchorAllowWhiteSpaceInCharactersMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__anchorCaseSensitive'] = block =>
         `anchorCaseSensitive: ${JSON.stringify(block.getFieldValue('anchorCaseSensitive'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__anchorCaseSensitiveMetadata'] = block => {
         let out = `anchorCaseSensitiveMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__anchorHorizontalAlignment'] = block =>
         `anchorHorizontalAlignment: ${JSON.stringify(block.getFieldValue('anchorHorizontalAlignment'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__anchorHorizontalAlignmentMetadata'] = block => {
         let out = `anchorHorizontalAlignmentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__anchorIgnoreIfNotPresent'] = block =>
         `anchorIgnoreIfNotPresent: ${JSON.stringify(block.getFieldValue('anchorIgnoreIfNotPresent'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__anchorIgnoreIfNotPresentMetadata'] = block => {
         let out = `anchorIgnoreIfNotPresentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__anchorMatchWholeWord'] = block =>
         `anchorMatchWholeWord: ${JSON.stringify(block.getFieldValue('anchorMatchWholeWord'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__anchorMatchWholeWordMetadata'] = block => {
         let out = `anchorMatchWholeWordMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__anchorString'] = block =>
         `anchorString: ${JSON.stringify(block.getFieldValue('anchorString'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__anchorStringMetadata'] = block => {
         let out = `anchorStringMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__anchorTabProcessorVersion'] = block =>
         `anchorTabProcessorVersion: ${JSON.stringify(block.getFieldValue('anchorTabProcessorVersion'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__anchorTabProcessorVersionMetadata'] = block => {
         let out = `anchorTabProcessorVersionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__anchorUnits'] = block =>
         `anchorUnits: ${JSON.stringify(block.getFieldValue('anchorUnits'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__anchorUnitsMetadata'] = block => {
         let out = `anchorUnitsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__anchorXOffset'] = block =>
         `anchorXOffset: ${JSON.stringify(block.getFieldValue('anchorXOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__anchorXOffsetMetadata'] = block => {
         let out = `anchorXOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__anchorYOffset'] = block =>
         `anchorYOffset: ${JSON.stringify(block.getFieldValue('anchorYOffset'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__anchorYOffsetMetadata'] = block => {
         let out = `anchorYOffsetMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__bold'] = block =>
         `bold: ${JSON.stringify(block.getFieldValue('bold'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__boldMetadata'] = block => {
         let out = `boldMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__concealValueOnDocument'] = block =>
         `concealValueOnDocument: ${JSON.stringify(block.getFieldValue('concealValueOnDocument'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__concealValueOnDocumentMetadata'] = block => {
         let out = `concealValueOnDocumentMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__conditionalParentLabel'] = block =>
         `conditionalParentLabel: ${JSON.stringify(block.getFieldValue('conditionalParentLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__conditionalParentLabelMetadata'] = block => {
         let out = `conditionalParentLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__conditionalParentValue'] = block =>
         `conditionalParentValue: ${JSON.stringify(block.getFieldValue('conditionalParentValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__conditionalParentValueMetadata'] = block => {
         let out = `conditionalParentValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__customTabId'] = block =>
         `customTabId: ${JSON.stringify(block.getFieldValue('customTabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__customTabIdMetadata'] = block => {
         let out = `customTabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__disableAutoSize'] = block =>
         `disableAutoSize: ${JSON.stringify(block.getFieldValue('disableAutoSize'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__disableAutoSizeMetadata'] = block => {
         let out = `disableAutoSizeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__documentId'] = block =>
         `documentId: ${JSON.stringify(block.getFieldValue('documentId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__documentIdMetadata'] = block => {
         let out = `documentIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__font'] = block =>
         `font: ${JSON.stringify(block.getFieldValue('font'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['zip__fontColor'] = block =>
         `fontColor: ${JSON.stringify(block.getFieldValue('fontColor'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__fontColorMetadata'] = block => {
         let out = `fontColorMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['zip__fontMetadata'] = block => {
         let out = `fontMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__fontSize'] = block =>
         `fontSize: ${JSON.stringify(block.getFieldValue('fontSize'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__fontSizeMetadata'] = block => {
         let out = `fontSizeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__formOrder'] = block =>
         `formOrder: ${JSON.stringify(block.getFieldValue('formOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__formOrderMetadata'] = block => {
         let out = `formOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__formPageLabel'] = block =>
         `formPageLabel: ${JSON.stringify(block.getFieldValue('formPageLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__formPageLabelMetadata'] = block => {
         let out = `formPageLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__formPageNumber'] = block =>
         `formPageNumber: ${JSON.stringify(block.getFieldValue('formPageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__formPageNumberMetadata'] = block => {
         let out = `formPageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__height'] = block =>
         `height: ${JSON.stringify(block.getFieldValue('height'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__heightMetadata'] = block => {
         let out = `heightMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__italic'] = block =>
         `italic: ${JSON.stringify(block.getFieldValue('italic'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__italicMetadata'] = block => {
         let out = `italicMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__locked'] = block =>
         `locked: ${JSON.stringify(block.getFieldValue('locked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__lockedMetadata'] = block => {
         let out = `lockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__maxLength'] = block =>
         `maxLength: ${JSON.stringify(block.getFieldValue('maxLength'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__maxLengthMetadata'] = block => {
         let out = `maxLengthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__mergeFieldXml'] = block =>
         `mergeFieldXml: ${JSON.stringify(block.getFieldValue('mergeFieldXml'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['zip__name'] = block =>
         `name: ${JSON.stringify(block.getFieldValue('name'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__nameMetadata'] = block => {
         let out = `nameMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__originalValue'] = block =>
         `originalValue: ${JSON.stringify(block.getFieldValue('originalValue'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__originalValueMetadata'] = block => {
         let out = `originalValueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__pageNumber'] = block =>
         `pageNumber: ${JSON.stringify(block.getFieldValue('pageNumber'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__pageNumberMetadata'] = block => {
         let out = `pageNumberMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__recipientId'] = block =>
         `recipientId: ${JSON.stringify(block.getFieldValue('recipientId'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['zip__recipientIdGuid'] = block =>
         `recipientIdGuid: ${JSON.stringify(block.getFieldValue('recipientIdGuid'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__recipientIdGuidMetadata'] = block => {
         let out = `recipientIdGuidMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['zip__recipientIdMetadata'] = block => {
         let out = `recipientIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__requireAll'] = block =>
         `requireAll: ${JSON.stringify(block.getFieldValue('requireAll'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__requireAllMetadata'] = block => {
         let out = `requireAllMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__requireInitialOnSharedChange'] = block =>
         `requireInitialOnSharedChange: ${JSON.stringify(block.getFieldValue('requireInitialOnSharedChange'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__requireInitialOnSharedChangeMetadata'] = block => {
         let out = `requireInitialOnSharedChangeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__required'] = block =>
         `required: ${JSON.stringify(block.getFieldValue('required'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__requiredMetadata'] = block => {
         let out = `requiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__senderRequired'] = block =>
         `senderRequired: ${JSON.stringify(block.getFieldValue('senderRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__senderRequiredMetadata'] = block => {
         let out = `senderRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__shareToRecipients'] = block =>
         `shareToRecipients: ${JSON.stringify(block.getFieldValue('shareToRecipients'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__shareToRecipientsMetadata'] = block => {
         let out = `shareToRecipientsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__shared'] = block =>
         `shared: ${JSON.stringify(block.getFieldValue('shared'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__sharedMetadata'] = block => {
         let out = `sharedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__source'] = block =>
         `source: ${JSON.stringify(block.getFieldValue('source'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['zip__status'] = block =>
         `status: ${JSON.stringify(block.getFieldValue('status'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__statusMetadata'] = block => {
         let out = `statusMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['zip__tabGroupLabels'] = block => {
         let out = `    tabGroupLabels: [\n`;
         out    += `        ${Blockly.JavaScript.statementToCode(block, 'tabGroupLabel', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out    += `    ]${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['zip_tabGroupLabels__tabGroupLabel'] = block =>
         `    ${JSON.stringify(block.getFieldValue('tabGroupLabel'))}${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['zip__tabGroupLabelsMetadata'] = block => {
         let out = `tabGroupLabelsMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__tabId'] = block =>
         `tabId: ${JSON.stringify(block.getFieldValue('tabId'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__tabIdMetadata'] = block => {
         let out = `tabIdMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__tabLabel'] = block =>
         `tabLabel: ${JSON.stringify(block.getFieldValue('tabLabel'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__tabLabelMetadata'] = block => {
         let out = `tabLabelMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__tabOrder'] = block =>
         `tabOrder: ${JSON.stringify(block.getFieldValue('tabOrder'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__tabOrderMetadata'] = block => {
         let out = `tabOrderMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__tabType'] = block =>
         `tabType: ${JSON.stringify(block.getFieldValue('tabType'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__tabTypeMetadata'] = block => {
         let out = `tabTypeMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__templateLocked'] = block =>
         `templateLocked: ${JSON.stringify(block.getFieldValue('templateLocked'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__templateLockedMetadata'] = block => {
         let out = `templateLockedMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__templateRequired'] = block =>
         `templateRequired: ${JSON.stringify(block.getFieldValue('templateRequired'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__templateRequiredMetadata'] = block => {
         let out = `templateRequiredMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

      Blockly.JavaScript['zip__toolTipMetadata'] = block => {
         let out = `toolTipMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__tooltip'] = block =>
         `tooltip: ${JSON.stringify(block.getFieldValue('tooltip'))}${block.getNextBlock() ? ', ': ''}\n`;
         Blockly.JavaScript['zip__underline'] = block =>
         `underline: ${JSON.stringify(block.getFieldValue('underline'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__underlineMetadata'] = block => {
         let out = `underlineMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__useDash4'] = block =>
         `useDash4: ${JSON.stringify(block.getFieldValue('useDash4'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__useDash4Metadata'] = block => {
         let out = `useDash4Metadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__validationMessage'] = block =>
         `validationMessage: ${JSON.stringify(block.getFieldValue('validationMessage'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__validationMessageMetadata'] = block => {
         let out = `validationMessageMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__validationPattern'] = block =>
         `validationPattern: ${JSON.stringify(block.getFieldValue('validationPattern'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__validationPatternMetadata'] = block => {
         let out = `validationPatternMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__value'] = block =>
         `value: ${JSON.stringify(block.getFieldValue('value'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__valueMetadata'] = block => {
         let out = `valueMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__width'] = block =>
         `width: ${JSON.stringify(block.getFieldValue('width'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__widthMetadata'] = block => {
         let out = `widthMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__xPosition'] = block =>
         `xPosition: ${JSON.stringify(block.getFieldValue('xPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__xPositionMetadata'] = block => {
         let out = `xPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;

   Blockly.JavaScript['zip__yPosition'] = block =>
         `yPosition: ${JSON.stringify(block.getFieldValue('yPosition'))}${block.getNextBlock() ? ', ': ''}\n`;
            Blockly.JavaScript['zip__yPositionMetadata'] = block => {
         let out = `yPositionMetadata: {\n`;
         out += `    rights: ${JSON.stringify(block.getFieldValue('rights'))},\n`;
         out += `    options: [\n`;
         out += `        ${Blockly.JavaScript.statementToCode(block, 'attributes', Blockly.JavaScript.ORDER_NONE) || ''}`;
         out += `    ]\n`;
         out += `}${block.getNextBlock() ? ', ': ''}\n`;
         return out
      };
      // Code for the array of scalar items (options)
      Blockly.JavaScript['propertyMetadata_options'] = block =>
         `    "${block.getFieldValue('option')}"${block.getNextBlock() ? ', ': ''}\n`;


}
